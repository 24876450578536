import React, { useCallback, useState } from 'react';
import { useCookies } from 'react-cookie';
import Login from "./container/Login";
import Logout from "./container/Logout";
import BoardReport from "./container/BoardReport.js";
import Home from "./container/Home";
import ClimateStressTest from "./container/ClimateStressTest";
import TravelCarbonEmissions from "./container/TravelCarbonEmissions";
import DataDownload from "./container/DataDownload";
import SingleClimateStressTest from "./container/SingleClimateStressTest";
import ClimateStressTestUpload from "./container/ClimateStressTestUpload";
import TestClimateStressTestUpload from "./container/TestClimateStressTestUpload";
import CarbonEmissionsData from './container/CarbonEmissionsData';
import SingleInvestmentCarbonEmission from './container/SingleInvestmentCarbonEmission';
import InvestmentCarbonEmissions from './container/InvestmentCarbonEmissions';
import Dashboard from './container/Dashboard';
import StressTest from './container/StressTest';
import SICCarbonCoef from './container/SICCarbonCoef';
import SingleTargetITR from './container/SingleTargetITR';
import AppConfig from './const/AppConfig.const';
import AppConfigN from './const/AppConfigN.const';
import EnvVarCDFH_import from './container/EnvVarCDFH_import';
import InvestmentITR from './container/InvestmentITR/InvestmentITR';
import EnvVarCDFH_export from './container/EnvVarCDFH_export';
import KeyCarbonEmissionIndustryLabel from './container/KeyCarbonEmissionIndustryLabel';
import SingleRealEstateValueImpact from './container/SingleRealEstateValueImpact/SingleRealEstateValueImpact';
import PortfolioOptimization from './container/PortfolioOptimization/PortfolioOptimization';
import EnterpriseESGReport from './container/EnterpriseESGReport';
import BatchRealEstateValueImpact from './container/BatchRealEstateValueImpact/BatchRealEstateValueImpact';
const shellChoice_list = { '1': 'loanRisk', }
const yearChoice_list = { '1': '2021' }
const CSChoice_list = { '1': '2.6', '2': '8.5' }


const displayMethod_list = {
  "None": ['Map', 'Chart', 'Report'],
  "loanRisk": ['Map', 'Chart', 'Report'],
  "ClimateStressTestUpload": ['Report', 'Map'],
  //"boardReport":["BoardOfDirectors", "LineChart", "Report"],
  //"ClimateStressTest":['Report']
}

function App() {
  const [activeScreen, switchScreen] = useState('Login')
  const [cookies, setCookie, removeCookie] = useCookies(['user']);
  const [account, switchAccount] = useState({})
  const [loginMsg, setLoginMsg] = useState("")
  const [permission, changePermission] = useState({})
  const [utype, changeUtype] = useState('')
  const [BoardReportData, getBoardReportData] = useState({})
  const [shellChoice, switchShellChoice] = useState('None')
  const [displayMethod, switchDisplayMethod] = useState('Report')
  const [yearChoice, switchYearChoice] = useState('None')
  const [CSChoice, switchCSChoice] = useState('None')
  const [processList, changeProcessList] = useState('')
  const [processData, changeProcessData] = useState('')
  const [processOrder, changeProcessOrder] = useState('')
  const [processData_value, changeProcessData_value] = useState({})
  const [processData_list, changeProcessData_list] = useState({})
  const [downloadData, changeDownloadData] = useState({})
  const [climateStressTestUploadOption, changeClimateStressTestUploadOption] = useState({})
  const [sic_carbonCoef_data, setSIC_carbonCoef] = useState({})

  const getPermission = useCallback((token) => {
      fetch(`${AppConfigN.SERVER_URL}/api/auth/pgroup`, {
        method: 'GET',
        headers: {
          "Authorization": `Token ${token}`,
          "Content-Type": "application/json"
        },
      })
        .then(response => response.json())
        .then((responseJson) => {
          // console.log(responseJson);
          if (responseJson) {
            changePermission(responseJson);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },[]
  );

  const getProcessList = (year, RCP, shell) => {
    // console.log("getProcessList !!", year, RCP, shell)
    if (year !== 'None' && RCP !== 'None' && shell !== 'None') {
      fetch(`${AppConfig.SERVER_URL}/getProcessList`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          params: {
            account: account.user.username,
            yearChoice: year,
            CSChoice: RCP,
            shellChoice: shell,
          },
        }),
      })
        .then(response => response.json())
        .then((responseJson) => {
          console.log("processListResponse:", responseJson);
          let newProcessData_list = responseJson.processData_list;
          changeProcessData_value({});
          changeProcessList(responseJson.process);
          changeProcessData(responseJson.dataName);
          changeProcessData_list(newProcessData_list);
          changeProcessOrder(responseJson.order)
          //console.log(processData_list)
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  const getProcessData_list = (step, requireData) => {
    const entries = Object.entries(processOrder);
    for (const [key, value] of entries) {
      if (value.indexOf(step) >= 0 && Number(key) === Object.keys(processOrder).length) {
        break;
      } else if (step === key) {
        console.log("getProcessData_list!")
        console.log(shellChoice, step, requireData)
        fetch(`${AppConfig.SERVER_URL}/getProcessData_list`, {
          method: 'POST',
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            account: account.user.username,
            CS: CSChoice,
            year: yearChoice,
            shell: shellChoice,
            step: step,
            processData_value: { ...processData_value, [step]: requireData },
          }),
        })
          .then(response => response.json())
          .then((responseJson) => {
            console.log("processData_list response:", responseJson)
            const updateState = {};
            let newProcessData_value = {};
            Object
              .entries(responseJson)
              .forEach(([key, value]) => {
                console.log(key, value);
                updateState[key] = value;
                changeProcessData_list({ ...processData_list, ...updateState });
                newProcessData_value[key] = value[1];
              })
            changeProcessData_value({ ...processData_value, ...newProcessData_value });
          })
          .catch((error) => {
            console.error(error);
          });
        break;
      }
    }
  }

  const getDownloadData = () => {
    fetch(`${AppConfigN.SERVER_URL}/api/download_list`, {
      method: 'GET',
      headers: {
        "Authorization": `Token ${account.token}`,
        "Content-Type": "application/json"
      },
    })
      .then(response => response.json())
      .then((responseJson) => {
        let username = account.user.username;
        // console.log(responseJson);
        // console.log(username);
        changeDownloadData(responseJson[username])
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const loginCheck = (inputUsername, inputPassword) => {
    fetch(`${AppConfigN.SERVER_URL}/api/auth/login`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        username: inputUsername,
        password: inputPassword
      }),
    })
      .then(response => response.json())
      .then((responseJson) => {
        // console.log(responseJson);
        if (responseJson.token !== undefined) {
          switchAccount(responseJson);
          changeUtype(responseJson['utype'])
          getPermission(responseJson.token);
          setLoginMsg("");

          // setCookie('Token', responseJson.token, { path: '/' });
          // setCookie('Username', responseJson.user.username, { path: '/' });
          setCookie('Account', responseJson, { path: '/', maxAge: 32400 });
        }
        else {
          setLoginMsg("帳號或密碼輸入錯誤，請檢查後再輸入")
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const switchToBoardReport = () => {
    // fetch(`${AppConfig.SERVER_URL}/BoardReport`, {
    //   method: 'POST',
    //   headers: {
    //     "Content-Type": "application/json"
    //   },
    //   body: JSON.stringify({
    //     account: account.user.username,
    //   }),
    // })
    //   .then(response => response.json())
    //   .then((responseJson) => {
    //     console.log(responseJson);
    //     // getBoardReportData(responseJson);
    //     getBoardReportData({ ...BoardReportData, ...responseJson });
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });
  }

  const changeDisplayMethod = (shellChoice) => {
    switchDisplayMethod(displayMethod_list[shellChoice][displayMethod_list[shellChoice].length - 1])
  }

  const getClimateStressTestUploadOption = () => {
    fetch(`${AppConfigN.SERVER_URL}/api/rlest_expprd`, {
      method: 'GET',
      headers: {
        "Authorization": `Token ${account.token}`,
        "Content-Type": "application/json"
      },
    })
      .then(response => response.json())
      .then((responseJson) => {
        // console.log(responseJson);
        let option = responseJson;
        changeClimateStressTestUploadOption(option);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const getSIC_CarbonCoef = () => {
    fetch(`${AppConfigN.SERVER_URL}/api/trans_sic_ccoef`, {
      method: 'GET',
      headers: {
        "Authorization": `Token ${account.token}`,
        "Content-Type": "application/json"
      },
    })
      .then(response => response.json())
      .then((responseJson) => {
        let data = responseJson;
        setSIC_carbonCoef(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  switch (activeScreen) {
    case 'Home':
      return (<Home
        account={account}
        permission={permission}
        cookies={cookies}
        removeCookie={removeCookie}
        activeScreen={activeScreen}
        switchScreen={(toScreen) => switchScreen(toScreen)}
        switchShellChoice={(shell) => { switchShellChoice(shell); getProcessList(yearChoice, CSChoice, shell) }}
        getProcessData_list={(index, requireData) => { getProcessData_list(index, requireData) }}
        changeDisplayMethod={(shellChoice) => { changeDisplayMethod(shellChoice) }}
        getProcessList={getProcessList}
        CSChoice={CSChoice}
        yearChoice={yearChoice}
        processData_value={processData_value}
        switchYearChoice={switchYearChoice}
        switchCSChoice={switchCSChoice}
        changeProcessList={changeProcessList}
        changeProcessData={changeProcessData}
        changeProcessOrder={changeProcessOrder}
        changeProcessData_value={changeProcessData_value}
        changeProcessData_list={changeProcessData_list}
        shellChoice={shellChoice}
        displayMethod={displayMethod}
        switchDisplayMethod={switchDisplayMethod}
        processData_list={processData_list}
        processList={processList}
        processData={processData}
        processOrder={processOrder}
        shellChoice_list={shellChoice_list}
        CSChoice_list={CSChoice_list}
        yearChoice_list={yearChoice_list}
      />)
    case 'Login':
      return (<Login
        account={account}
        permission={permission}
        getPermission={getPermission}
        changeUtype={changeUtype}
        cookies={cookies}
        loginMsg={loginMsg}
        switchAccount={switchAccount}
        loginCheck={loginCheck}
        activeScreen={activeScreen}
        switchScreen={(toScreen) => switchScreen(toScreen)} />)
    case 'BoardReport':
      return (<BoardReport
        account={account}
        permission={permission}
        cookies={cookies}
        removeCookie={removeCookie}
        activeScreen={activeScreen}
        switchScreen={(toScreen) => switchScreen(toScreen)}
        switchToBoardReport={switchToBoardReport}
        data={BoardReportData}
        shellChoice={shellChoice}
        switchShellChoice={(shell) => { switchShellChoice(shell); getProcessList(yearChoice, CSChoice, shell) }}
      />)
    case 'ClimateStressTest':
      return (<ClimateStressTest
        account={account}
        permission={permission}
        cookies={cookies}
        removeCookie={removeCookie}
        activeScreen={activeScreen}
        switchScreen={(toScreen) => switchScreen(toScreen)}
        switchShellChoice={(shell) => { switchShellChoice(shell); getProcessList(yearChoice, CSChoice, shell) }}
      />)
    case 'TravelCarbonEmissions':
      return (<TravelCarbonEmissions
        account={account}
        permission={permission}
        cookies={cookies}
        removeCookie={removeCookie}
        activeScreen={activeScreen}
        switchScreen={(toScreen) => switchScreen(toScreen)}
        switchShellChoice={(shell) => { switchShellChoice(shell); getProcessList(yearChoice, CSChoice, shell) }}
      />)
    case 'DataDownload':
      return (<DataDownload
        account={account}
        permission={permission}
        cookies={cookies}
        removeCookie={removeCookie}
        activeScreen={activeScreen}
        switchScreen={(toScreen) => switchScreen(toScreen)}
        getDownloadData={getDownloadData}
        downloadData={downloadData}
      />)
    case 'SingleClimateStressTest':
      return (<SingleClimateStressTest
        account={account}
        permission={permission}
        cookies={cookies}
        removeCookie={removeCookie}
        activeScreen={activeScreen}
        switchScreen={(toScreen) => switchScreen(toScreen)}
        switchShellChoice={(shell) => { switchShellChoice(shell); getProcessList(yearChoice, CSChoice, shell) }}
      />)
    case 'ClimateStressTestUpload':
      return (<ClimateStressTestUpload
        account={account}
        permission={permission}
        cookies={cookies}
        removeCookie={removeCookie}
        activeScreen={activeScreen}
        switchScreen={(toScreen) => switchScreen(toScreen)}
        switchShellChoice={(shell) => { switchShellChoice(shell); getProcessList(yearChoice, CSChoice, shell) }}
        displayMethod={displayMethod}
        switchDisplayMethod={switchDisplayMethod}
        climateStressTestUploadOption={climateStressTestUploadOption}
        getClimateStressTestUploadOption={getClimateStressTestUploadOption}
      />)
    case 'TestClimateStressTestUpload':
      return (<TestClimateStressTestUpload
        account={account}
        permission={permission}
        cookies={cookies}
        removeCookie={removeCookie}
        activeScreen={activeScreen}
        switchScreen={(toScreen) => switchScreen(toScreen)}
        switchShellChoice={(shell) => { switchShellChoice(shell); getProcessList(yearChoice, CSChoice, shell) }}
        displayMethod={displayMethod}
        switchDisplayMethod={switchDisplayMethod}
        climateStressTestUploadOption={climateStressTestUploadOption}
        getClimateStressTestUploadOption={getClimateStressTestUploadOption}
      />)
    case 'CarbonEmissionsData':
      return (<CarbonEmissionsData
        account={account}
        permission={permission}
        cookies={cookies}
        removeCookie={removeCookie}
        activeScreen={activeScreen}
        switchScreen={(toScreen) => switchScreen(toScreen)}
        switchShellChoice={(shell) => { switchShellChoice(shell); getProcessList(yearChoice, CSChoice, shell) }}
      />)
    case 'SingleInvestmentCarbonEmission':
      return (<SingleInvestmentCarbonEmission
        account={account}
        permission={permission}
        cookies={cookies}
        removeCookie={removeCookie}
        activeScreen={activeScreen}
        switchScreen={(toScreen) => switchScreen(toScreen)}
        switchShellChoice={(shell) => { switchShellChoice(shell); getProcessList(yearChoice, CSChoice, shell) }}
      />)
    case 'InvestmentCarbonEmissions':
      return (<InvestmentCarbonEmissions
        account={account}
        permission={permission}
        cookies={cookies}
        removeCookie={removeCookie}
        activeScreen={activeScreen}
        switchScreen={(toScreen) => switchScreen(toScreen)}
        displayMethod={displayMethod}
        switchDisplayMethod={switchDisplayMethod}
        switchShellChoice={(shell) => { switchShellChoice(shell); getProcessList(yearChoice, CSChoice, shell) }}
      />)
    case 'Dashboard':
      return (<Dashboard
        account={account}
        permission={permission}
        cookies={cookies}
        removeCookie={removeCookie}
        activeScreen={activeScreen}
        switchScreen={(toScreen) => switchScreen(toScreen)}
        displayMethod={displayMethod}
        switchDisplayMethod={switchDisplayMethod}
        switchShellChoice={(shell) => { switchShellChoice(shell); getProcessList(yearChoice, CSChoice, shell) }}
      />)
    case 'StressTest':
      return (<StressTest
        account={account}
        permission={permission}
        cookies={cookies}
        removeCookie={removeCookie}
        activeScreen={activeScreen}
        switchScreen={(toScreen) => switchScreen(toScreen)}
        displayMethod={displayMethod}
        switchDisplayMethod={switchDisplayMethod}
        switchShellChoice={(shell) => { switchShellChoice(shell); getProcessList(yearChoice, CSChoice, shell) }}
      />)
    case 'SICCarbonCoef':
      return (<SICCarbonCoef
        account={account}
        permission={permission}
        cookies={cookies}
        removeCookie={removeCookie}
        activeScreen={activeScreen}
        switchScreen={(toScreen) => switchScreen(toScreen)}
        displayMethod={displayMethod}
        switchDisplayMethod={switchDisplayMethod}
        switchShellChoice={(shell) => { switchShellChoice(shell); getProcessList(yearChoice, CSChoice, shell) }}
        getSIC_CarbonCoef={getSIC_CarbonCoef}
        sic_carbonCoef_data={sic_carbonCoef_data}
        setSIC_carbonCoef={setSIC_carbonCoef}
      />)
    case 'SingleTargetITR':
      return (<SingleTargetITR
        account={account}
        permission={permission}
        cookies={cookies}
        removeCookie={removeCookie}
        activeScreen={activeScreen}
        switchScreen={(toScreen) => switchScreen(toScreen)}
        switchShellChoice={(shell) => { switchShellChoice(shell); getProcessList(yearChoice, CSChoice, shell) }}
      />)
    case 'EnvVarCDFHImport':
      return (<EnvVarCDFH_import 
        account={account}
        permission={permission}
        cookies={cookies}
        utype={utype}
        removeCookie={removeCookie}
        activeScreen={activeScreen}
        switchScreen={(toScreen) => switchScreen(toScreen)}
        switchShellChoice={(shell) => { switchShellChoice(shell); getProcessList(yearChoice, CSChoice, shell) }}
      />)
    case 'EnvVarCDFHExport':
      return (<EnvVarCDFH_export
        account={account}
        permission={permission}
        cookies={cookies}
        utype={utype}
        removeCookie={removeCookie}
        activeScreen={activeScreen}
        switchScreen={(toScreen) => switchScreen(toScreen)}
        switchShellChoice={(shell) => { switchShellChoice(shell); getProcessList(yearChoice, CSChoice, shell) }}
      />)
    case 'InvestmentITR':
      return (<InvestmentITR
        account={account}
        permission={permission}
        cookies={cookies}
        removeCookie={removeCookie}
        activeScreen={activeScreen}
        switchScreen={(toScreen) => switchScreen(toScreen)}
        displayMethod={displayMethod}
        switchDisplayMethod={switchDisplayMethod}
        switchShellChoice={(shell) => { switchShellChoice(shell); getProcessList(yearChoice, CSChoice, shell) }}
      />)
    case 'KeyCarbonEmissionIndustryLabel':
      return (<KeyCarbonEmissionIndustryLabel
        account={account}
        permission={permission}
        cookies={cookies}
        removeCookie={removeCookie}
        activeScreen={activeScreen}
        switchScreen={(toScreen) => switchScreen(toScreen)}
        displayMethod={displayMethod}
        switchDisplayMethod={switchDisplayMethod}
        switchShellChoice={(shell) => { switchShellChoice(shell); getProcessList(yearChoice, CSChoice, shell) }}
      />)
    case 'SingleRealEstateValueImpact': // New page with AR6 dataset
      return (<SingleRealEstateValueImpact
        account={account}
        permission={permission}
        cookies={cookies}
        removeCookie={removeCookie}
        activeScreen={activeScreen}
        switchScreen={(toScreen) => switchScreen(toScreen)}
        switchShellChoice={(shell) => { switchShellChoice(shell); getProcessList(yearChoice, CSChoice, shell) }}
      />)
    case 'BatchRealEstateValueImpact': // New page with AR6 dataset
      return (<BatchRealEstateValueImpact
        account={account}
        permission={permission}
        cookies={cookies}
        removeCookie={removeCookie}
        activeScreen={activeScreen}
        switchScreen={(toScreen) => switchScreen(toScreen)}
        switchShellChoice={(shell) => { switchShellChoice(shell); getProcessList(yearChoice, CSChoice, shell) }}
      />)
    case 'PortfolioOptimization':
      return (<PortfolioOptimization
        account={account}
        permission={permission}
        cookies={cookies}
        removeCookie={removeCookie}
        activeScreen={activeScreen}
        switchScreen={(toScreen) => switchScreen(toScreen)}
        displayMethod={displayMethod}
        switchDisplayMethod={switchDisplayMethod}
        switchShellChoice={(shell) => { switchShellChoice(shell); getProcessList(yearChoice, CSChoice, shell) }}
      />)
    case 'EnterpriseESGReport':
      return (<EnterpriseESGReport
        account={account}
        permission={permission}
        cookies={cookies}
        removeCookie={removeCookie}
        activeScreen={activeScreen}
        switchScreen={(toScreen) => switchScreen(toScreen)}
        switchShellChoice={(shell) => { switchShellChoice(shell); getProcessList(yearChoice, CSChoice, shell) }}
      />)
    case 'Logout':
      return (<Logout/>)
    default:
      console.error('Navigation error: ')
      return (<Login
        account={account}
        permission={permission}
        getPermission={getPermission}
        cookies={cookies}
        switchAccount={switchAccount}
        loginCheck={loginCheck}
        activeScreen={activeScreen}
        switchScreen={(toScreen) => switchScreen(toScreen)}
        getClimateStressTestUploadOption={getClimateStressTestUploadOption}
      />)
  }
}

export default App;
