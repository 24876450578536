import React from 'react';
import AppConfigN from '../const/AppConfigN.const';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    link: {
        // fontSize: '1vh',
        textAlign: 'left',
        // margin: '1vh 0 0 1vw',
        textDecoration: "underline",
        width: 'fit-content',
        '&:hover': {
            cursor: "pointer",
        }
    },
}));

function Test_DisplayInvestmentCarbonEmissions_DataExport(props) {
    const classes = useStyles();

    const {
        rcp,
        year,
        vulType,
        batchDataList
    } = props;

    const handleCreatedTimeTransfer = (created_time) => {
        let newTime = "";
        created_time = created_time.replace(/[^0-9]/ig, "");
        newTime = created_time.substring(0, 8) + "_" + created_time.substring(8, 14);
        // console.log(newTime);
        return newTime
    }

    const dataDownload = (batchID, rcp, year, vulType, ownerID, created_time) => {
        // console.log("batch_id:", batchID, ", rcpChoice:", rcp, ", yearChoice:", year, ", vtypeChoice:", vulType);
        let newTime = ""
        if (batchID !== "") {
            newTime = handleCreatedTimeTransfer(created_time);
            fetch(`${AppConfigN.SERVER_URL}/api/rlest_batch_download`, {
                method: 'POST',
                headers: {
                    "Authorization": `Token ${props.account.token}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "batch_id": batchID,
                    "rcpChoice": rcp,
                    "yearChoice": year,
                    "vtypeChoice": vulType
                })
            })
                .then((response) => response.blob())
                .then((blob) => {
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `氣候變遷下不動產總價值影響評估_${ownerID}_${newTime}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
        else {
            fetch(`${AppConfigN.SERVER_URL}/api/rlest_batch_download`, {
                method: 'POST',
                headers: {
                    "Authorization": `Token ${props.account.token}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "batch_id": batchID,
                    "rcpChoice": rcp,
                    "yearChoice": year,
                    "vtypeChoice": vulType
                })
            })
                .then((response) => response.blob())
                .then((blob) => {
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `氣候變遷下不動產總價值影響評估_最新批次資料.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }

    return (
        <div className="display_body_container">
            <div style={{ backgroundColor: '#fafafa', borderRadius: '1.212vw', padding: '2.1vh 1.6vw 4vh', overflowX: 'scroll' }}>
                <div style={{ display: 'inline-grid', width: 'fit-content', height: 'fit-content', border: 'solid 2px #eaeaea', margin: '0vh 1.6vw 1vh 0vw' }}>
                    <div style={{ display: 'inline-flex' }}>
                        <div className='single_display_report_block' style={{ width: '40vw' }}>檔案名稱</div>
                        {/* <div className='single_display_report_block' style={{ width: '8vw' }}>資料數量</div> */}
                        <div className='single_display_report_block' style={{ width: '10vw' }}>上傳使用者</div>
                        <div className='single_display_report_block' style={{ width: '12vw' }}>上傳時間</div>
                        <div className='single_display_report_block' style={{ width: '7vw' }}>下載</div>
                    </div>
                    <div style={{ display: 'inline-flex' }}>
                        <div style={{ display: 'grid' }}>
                            <div key="0" className='single_display_report_block' style={{ width: '40vw' }}>氣候變遷下不動產總價值影響評估_最新批次資料.xlsx</div>
                            {Object
                                .entries(batchDataList)
                                .map(([id, batchData]) => <div key={id + 1} className='single_display_report_block' style={{ width: '40vw' }}>氣候變遷下不動產總價值影響評估_{batchData.ownerID}_{handleCreatedTimeTransfer(batchData.created_time)}.xlsx</div>)
                            }
                        </div>
                        <div style={{ display: 'grid' }}>
                            <div key="0" className='single_display_report_block' style={{ width: '10vw' }}>-</div>
                            {Object
                                .entries(batchDataList)
                                .map(([id, batchData]) => <div key={id + 1} className='single_display_report_block' style={{ width: '10vw' }}>{batchData.ownerID}</div>)
                            }
                        </div>
                        <div style={{ display: 'grid' }}>
                            <div key="0" className='single_display_report_block' style={{ width: '12vw' }}>-</div>
                            {Object
                                .entries(batchDataList)
                                .map(([id, batchData]) => <div key={id + 1} className='single_display_report_block' style={{ width: '12vw' }}>{batchData.created_time}</div>)
                            }
                        </div>
                        <div style={{ display: 'grid' }}>
                            <div key="0" className='single_display_report_block' style={{ width: '7vw' }}>
                                <a className={classes.link} href={'/#'} onClick={() => dataDownload("", rcp, year, vulType)}>檔案下載</a>
                            </div>
                            {Object
                                .entries(batchDataList)
                                .map(([id, batchData]) => <div key={id + 1} className='single_display_report_block' style={{ width: '7vw' }}>
                                    {/* {dataDownload(batchData.batch_id)} */}
                                    <a className={classes.link} href={'/#'} onClick={() => dataDownload(batchData.batch_id, rcp, year, vulType, batchData.ownerID, batchData.created_time)}>檔案下載</a>
                                </div>)
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Test_DisplayInvestmentCarbonEmissions_DataExport;