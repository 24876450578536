import React, { useState, useEffect } from 'react';
import "../style/style.css";
import { makeStyles } from '@material-ui/core/styles';
import { Button, TextField } from '@material-ui/core';
// import NumberFormat from 'react-number-format';
import Menu from './Menu';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputAdornment from '@material-ui/core/InputAdornment';
import Report from '../component/DisplaySingleInvestmentCarbonEmission';
import AppConfigN from '../const/AppConfigN.const';

const useStyles = makeStyles((theme) => ({
  blockContent: {
    margin: "1vh 1vw",
  },
  blockContentTitle: {
    margin: "0 0 0 0vw",
    fontFamily: "PingFangTC",
    fontSize: "2.1vh",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#797979",
  },
  formControl: {
    textAlign: 'center',
    display: 'inline-flex',
    justifyContent: 'center',
    // width: "13vw",
  },
  select: {
    height: "5vh",
    width: "12vw",
    margin: '1.5vh 0vw 0vh 0vw',
    fontSize: '2.3vh',
  },
  input: {
    height: "4.5vh",
    width: "12vw",
    margin: '1.5vh 0vw 0vh 0vw',
    padding: '10px 14px',
    borderRadius: '5px',
    borderWidth: 'thin',
    borderColor: '#b6b6b6',
    borderStyle: 'solid',
    fontSize: "2.3vh",
  },
  button: {
    height: "4.9vh",
    width: "15vw",
    margin: "0 2vw",
    fontSize: "2.1vh",
    backgroundColor: "#ececec",
  },
  adornedStart: {
    height: "5vh",
    width: "12vw",
    fontSize: "2.3vh",
    // margin: '0',
    padding: '0 0 0 0.8vw',
  }
}))

// function NumberFormatCustom(props) {
//   const { inputRef, onChange, ...other } = props;

//   return (
//     <NumberFormat
//       {...other}
//       getInputRef={inputRef}
//       style={{
//         height: "5vh",
//         width: "12vw",
//         margin: '0',
//         padding: '0 0 0 0.5vw',
//         fontSize: "2.3vh",
//       }}
//       onValueChange={(values) => {
//         onChange({
//           target: {
//             name: props.name,
//             value: values.value,
//           },
//         });
//       }}
//       thousandSeparator
//       isNumericString
//       prefix="$"
//     />
//   );
// }

function SingleInvestmentCarbonEmission(props) {
  const classes = useStyles();

  const [typeList, setTypeList] = useState({});
  const [locationList, setLocationList] = useState({});
  const [industryList, setIndustryList] = useState({});
  const [type, changeType] = useState("None");
  const [companyID, setCompanyID] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [location, setLocation] = useState("None");
  const [industry, setIndustry] = useState("None");
  const [carbonData, setCarbonData] = useState(null);
  const [certificationBody, setCertificationBody] = useState("");
  const [carbonDataYear, setCarbonDataYear] = useState(0);
  const [lendAmount, setLendAmount] = useState(null);
  const [evic, setEvic] = useState(null);
  const [revenue, setRevenue] = useState(null);
  const [debt, setDebt] = useState(null);
  const [financeDataYear, setFinanceDataYear] = useState(0);
  const [introOpen, setIntroOpen] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [companyData, setCompanyData] = useState({});

  const {
    account,
    permission,
    cookies,
    removeCookie,
    activeScreen,
    switchScreen,
    switchShellChoice,
    changeDisplayMethod, } = props

  const getOption = () => {
    fetch(`${AppConfigN.SERVER_URL}/api/trans_pcaf_choice`, {
      method: 'GET',
      headers: {
        "Authorization": `Token ${account.token}`,
        "Content-Type": "application/json"
      },
    })
      .then(response => response.json())
      .then((responseJson) => {
        let option = responseJson;
        // console.log(option);
        setTypeList(option.type);
        setIndustryList(option.industry);
        setLocationList(option.location);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const getCompanyData = () => {
    let res = false;
    let locationInput = ""
    let industryInput = ""

    if (location !== "None") locationInput = location
    if (industry !== "None") industryInput = industry

    fetch(`${AppConfigN.SERVER_URL}/api/fin_emis_pcaf_sgl`, {
      method: 'POST',
      headers: {
        "Authorization": `Token ${account.token}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        type: type,
        companyID: companyID,
        companyName: companyName,
        location: locationInput,
        industry: industryInput,
        carbonEmission: carbonData,
        certificationBody: certificationBody,
        carbonDataYear: carbonDataYear,
        lendAmount: lendAmount,
        evic: evic,
        debt: debt,
        revenue: revenue,
        financeDataYear: financeDataYear,
      }),
    })
      .then((response) => {
        // console.log(response)
        if (response.ok) {
          res = true;
        }
        return response.json()
      })
      .then((responseJson) => {
        // console.log(responseJson)
        if (res) {
          setCompanyData(responseJson);
          setErrMsg("");
        }
        else {
          setErrMsg(responseJson.detail);
        }
      })
  }

  const checkDataComplete = (type, companyName, lendAmount) => {
    // console.log("type", type)
    // console.log("companyName", companyName)
    // console.log("lendAmount", lendAmount)
    if (type === "None" || companyName === "" || lendAmount === undefined) {
      setErrMsg("資料填寫不完整，欄位有*為必填資訊")
    }
    else {
      setErrMsg("");
      getCompanyData();
    }
  }

  const clearInput = () => {
    changeType("None");
    setCompanyID("");
    setCompanyName("");
    setLocation("None");
    setIndustry("None");
    setCarbonData(null);
    setCertificationBody("");
    setCarbonDataYear(0);
    setLendAmount(null);
    setEvic(null);
    setRevenue(null);
    setDebt(null);
    setFinanceDataYear(0);
  }

  // const switchIntroOpen = () => {
  //   if (introOpen === false) setIntroOpen(true);
  //   else if (introOpen === true) setIntroOpen(false);
  // }

  useEffect(() => {
    if (Object.values(typeList).length === 0) {
      getOption();
    }
  })

  return (
    <div className="new_home_mainContainer">
      <div className="page_container">
        <div className="page_title_container"><p className="page_title_text">單一投融資標的碳排資料計算</p></div>
        <div className="DataDownload_block" style={{ display: "block", width: "100%", paddingBottom: "2vh", marginBottom: "2vh" }}>
          <div style={{ display: "flex" }}>
            <div style={{ flexDirection: "column", width: "60%" }}>
              <p className="DataDownload_block_title_text">投融資標的基本資料輸入</p>
              <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
              <div className={classes.blockContent}>
                <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                  <div>
                    <p className={classes.blockContentTitle}>案件類別*</p>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <Select
                        className={classes.select}
                        style={type==='None'?{color: '#757575'}:{}}
                        value={type}
                        onChange={(event) => {
                          changeType(event.target.value);
                        }}
                      >
                        <MenuItem value="None">請選擇案件類別</MenuItem>
                        {
                          Object
                            .entries(typeList)
                            .map(([id, type]) => <MenuItem key={`typeList_${id}`} value={type}>{type}</MenuItem>)
                        }
                      </Select>
                    </FormControl>
                  </div>
                  <div>
                    <p className={classes.blockContentTitle}>統一編號/股票代碼</p>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <input
                        className={classes.input}
                        type="text"
                        placeholder="請輸入統編/股票代碼"
                        value={companyID}
                        onChange={(event) => {
                          setCompanyID(event.target.value);
                        }}
                      />
                    </FormControl>
                  </div>
                  <div>
                    <p className={classes.blockContentTitle}>公司名稱*</p>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <input
                        className={classes.input}
                        type="text"
                        placeholder="請輸入公司名稱"
                        value={companyName}
                        onChange={(event) => {
                          setCompanyName(event.target.value);
                        }}
                      />
                    </FormControl>
                  </div>
                </div>
                <div style={{ display: "flex", justifyContent: "space-evenly", marginTop: "1.5vh" }}>
                  <div>
                    <p className={classes.blockContentTitle}>國家/地區</p>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <Select
                        className={classes.select}
                        value={location}
                        style={type==='None'?{color: '#757575'}:{}}
                        onChange={(event) => {
                          setLocation(event.target.value);
                        }}
                      >
                        <MenuItem value="None">請選擇國家/地區</MenuItem>
                        {
                          Object
                            .entries(locationList)
                            .map(([id, location]) => <MenuItem key={`locationList_${id}`} value={location}>{location}</MenuItem>)
                        }
                      </Select>
                    </FormControl>
                  </div>
                  <div>
                    <p className={classes.blockContentTitle}>產業類別</p>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <Select
                        className={classes.select}
                        style={type==='None'?{color: '#757575'}:{}}
                        value={industry}
                        onChange={(event) => {
                          setIndustry(event.target.value);
                        }}
                      >
                        <MenuItem value="None">請選擇產業類別</MenuItem>
                        {
                          Object
                            .entries(industryList)
                            .map(([id, industry]) => <MenuItem key={`industryList_${id}`} value={industry}>{industry}</MenuItem>)
                        }
                      </Select>
                    </FormControl>
                  </div>
                  <div>
                    <p className={classes.blockContentTitle}>碳排放量 (公噸)</p>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <input
                        className={classes.input}
                        type="number"
                        placeholder="請輸入碳排放量"
                        value={carbonData === null ? "" : carbonData}
                        onChange={(event) => {
                          setCarbonData(Number(event.target.value));
                        }}
                      />
                    </FormControl>
                  </div>
                </div>
                <div style={{ display: "flex", justifyContent: "space-evenly", marginTop: "1.5vh" }}>
                  <div>
                    <p className={classes.blockContentTitle}>碳排放查驗單位</p>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <input
                        className={classes.input}
                        style={{ width: '26vw' }}
                        type="string"
                        placeholder="請輸入碳排放查驗單位，若沒有查驗單位請留空"
                        value={certificationBody}
                        onChange={(event) => {
                          setCertificationBody(event.target.value);
                        }}
                      />
                    </FormControl>
                  </div>
                  <div>
                    <p className={classes.blockContentTitle}>碳排資料年分</p>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <input
                        className={classes.input}
                        type="number"
                        placeholder="請輸入碳排資料年分"
                        value={carbonDataYear}
                        onChange={(event) => {
                          setCarbonDataYear(Number(event.target.value));
                        }}
                      />
                    </FormControl>
                  </div>
                </div>

              </div>
            </div>
            <div style={{ flexDirection: "column", width: "40%" }}>
              <p className="DataDownload_block_title_text">財務資料輸入 (以千元計)</p>
              <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
              <div className={classes.blockContent}>
                <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                  <div>
                    <p className={classes.blockContentTitle}>投融資餘額 (千元)*</p>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <TextField
                        style={{ margin: "1.5vh 0vw 0vh 0vw" }}
                        // className={classes.input} 
                        value={lendAmount === null ? "" : lendAmount}
                        variant="outlined"
                        onChange={(event) => {
                          setLendAmount(Number(event.target.value));
                        }}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          classes: { adornedStart: classes.adornedStart }
                        }}
                      />
                    </FormControl>
                  </div>
                  <div>
                    <p className={classes.blockContentTitle}>EVIC (千元)</p>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <TextField
                        style={{ margin: "1.5vh 0vw 0vh 0vw" }}
                        value={evic === null ? "" : evic}
                        variant="outlined"
                        onChange={(event) => {
                          setEvic(Number(event.target.value));
                        }}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          classes: { adornedStart: classes.adornedStart }
                        }}
                      />
                    </FormControl>
                  </div>
                </div>
                <div style={{ display: "flex", justifyContent: "space-evenly", marginTop: "1.5vh" }}>
                  <div>
                    <p className={classes.blockContentTitle}>年營收 (千元)</p>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <TextField
                        style={{ margin: "1.5vh 0vw 0vh 0vw" }}
                        value={revenue === null ? "" : revenue}
                        variant="outlined"
                        onChange={(event) => {
                          setRevenue(Number(event.target.value));
                        }}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          classes: { adornedStart: classes.adornedStart }
                        }}
                      />
                    </FormControl>
                  </div>
                  <div>
                    <p className={classes.blockContentTitle}>權益+總債務 (千元)</p>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <TextField
                        style={{ margin: "1.5vh 0vw 0vh 0vw" }}
                        value={debt === null ? "" : debt}
                        variant="outlined"
                        onChange={(event) => {
                          setDebt(Number(event.target.value));
                        }}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          classes: { adornedStart: classes.adornedStart }
                        }}
                      />
                    </FormControl>
                  </div>
                </div>
                <div style={{ display: "flex", justifyContent: "space-evenly", marginTop: "1.5vh" }}>
                  <div>
                    <p className={classes.blockContentTitle}>財務資料年分</p>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <input
                        className={classes.input}
                        type="number"
                        placeholder="請輸入財報資料年分"
                        value={financeDataYear}
                        onChange={(event) => {
                          setFinanceDataYear(Number(event.target.value));
                        }}
                      />
                    </FormControl>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <p style={{ fontSize: "2vh", color: "#797979", marginLeft: "1.5vw" }}>

          </p>
          <div style={{ textAlign: "center" }}>
            <Button variant="outlined" className={classes.button} onClick={() => clearInput()}>清除資料</Button>
            <Button variant="outlined" className={classes.button} onClick={() => checkDataComplete(type, companyName, lendAmount)}>資料計算</Button>
          </div>
        </div>

        {introOpen === false ?
          <div className="DataDownload_block" style={{ display: "inline-block", width: "100%", cursor: "pointer" }} onClick={() => setIntroOpen(true)}>
            <p className="DataDownload_block_title_text">資料輸入說明</p>
            <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
          </div> :
          <div className="DataDownload_block" style={{ display: "inline-block", width: "100%", cursor: "pointer" }} onClick={() => setIntroOpen(false)}>
            <p className="DataDownload_block_title_text">資料輸入說明</p>
            <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
            <div className={classes.blockContent}>
              <div style={{ display: "flex" }}>
                <div style={{ flexDirection: "column", width: "47%" }}>
                  <p style={{ fontSize: "2vh", color: "#797979" }}>
                    <ul style={{ paddingLeft: "2vw" }}>
                      <li>有*者為必填</li>
                      <li>碳排放量數據為範疇一+範疇二資料</li>
                      <li>若無統一編號/股票代號，請填寫[國家/地區]、產業類別與其他財務資料</li>
                      <li>計算結果會依照輸入完整度而有所不同，請盡量輸入完整資料，詳情請參考右側說明或使用簡介</li>
                    </ul>
                  </p>
                </div>
                <div style={{ flexDirection: "column", width: "53%" }}>
                  <p style={{ fontSize: "2vh", color: "#797979" }}>
                    <ul style={{ paddingLeft: "2vw" }}>
                      <li>完整輸入案件類別、公司名稱、投融資餘額並滿足以下任一組合才易得到合理結果：</li>
                      <ul style={{ paddingLeft: "2vw" }}>
                        <li>有效的統一編號/股票代碼</li>
                        <li>範疇一及範疇二總碳排量 + EVIC或[權益+總債務]</li>
                        <li>[國家/地區] + 產業類別 + EVIC或[權益+總債務] + 年營收</li>
                        <li>[國家/地區] + 產業類別</li>
                      </ul>
                    </ul>
                  </p>
                </div>
              </div>

            </div>
          </div>
        }

        {errMsg.length === 0 ? "" :
          <div style={{ margin: "0.5vh 0 0 1vw", color: "red", fontSize: "2.5vh" }}>
            {errMsg}
          </div>
        }

        {Object.values(companyData).length === 0 ? "" :
          <Report
            account={account}
            companyData={companyData}
          />
        }

      </div>
      <Menu
        account={account}
        permission={permission}
        cookies={cookies}
        removeCookie={removeCookie}
        activeScreen={activeScreen}
        switchScreen={switchScreen}
        switchShellChoice={switchShellChoice}
        changeDisplayMethod={changeDisplayMethod}
      />
    </div>
  );
}

export default SingleInvestmentCarbonEmission;