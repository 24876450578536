import React, { useEffect, useState } from 'react';
import "../style/style.css";
import Menu from './Menu';
import AppConfigN from '../const/AppConfigN.const';
import DisplayReportSingleClimateStressTest from '../component/DisplayReportSingleClimateTest';

function SingleClimateStressTest(props) {
  const {
    account,
    permission,
    cookies,
    removeCookie,
    activeScreen,
    switchScreen,
    switchShellChoice,
  } = props

  const [countryChoiceList, changeCountryChoice_list] = useState({})
  const [countryChoiceValue, changeCountryChoice_value] = useState({})


  const getCountryChoiceList = () => {
    fetch(`${AppConfigN.SERVER_URL}/api/rlest_geotwcnty`, {
      method: 'GET',
      headers: {
        "Authorization": `Token ${account.token}`,
        "Content-Type": "application/json"
      },
    })
      .then(response => response.json())
      .then((responseJson) => {
        // console.log(responseJson)
        let countryChoice_list = responseJson;
        changeCountryChoice_value({});
        changeCountryChoice_list(countryChoice_list);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    if (Object.values(countryChoiceList).length === 0) {
      getCountryChoiceList();
    }
  })

  return (
    <div className="new_home_mainContainer">
      <div className="page_container">
        <div className="page_title_container"><p className="page_title_text">氣候變遷下單一不動產價值影響評估</p></div>

        <div className="display_container">
          <div className="display_header_container">
            <div className='display_header_item_active'>
              <p className='display_header_item_active_text'>Report</p>
            </div>
          </div>
          <div>
            <DisplayReportSingleClimateStressTest
              account={account}
              countryChoiceList={countryChoiceList}
              countryChoiceValue={countryChoiceValue}
              changeCountryChoice_value={changeCountryChoice_value}
            />
          </div>
        </div>

      </div>
      <Menu
        account={account}
        permission={permission}
        cookies={cookies}
        removeCookie={removeCookie}
        activeScreen={activeScreen}
        switchScreen={switchScreen}
        switchShellChoice={switchShellChoice}
      />
    </div>
  );
}

export default SingleClimateStressTest;