import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import Menu from './Menu';
import AppConfigN from '../const/AppConfigN.const';
import pptx_icon from '../img/powerpoint-icon.png';
import xlsx_icon from '../img/excel-icon.png';
import shp_icon from '../img/shp-icon.jpg';
import pdf_icon from '../img/pdf-icon.png';
import png_icon from '../img/png-icon.png';
import mp4_icon from '../img/mp4-icon.png';
import csv_icon from '../img/csv-icon.png';
import txt_icon from '../img/txt-icon.png';
import docx_icon from '../img/docx-icon.png';



const dataType_icon_list = {
    'excel': xlsx_icon,
    'shape file': shp_icon,
    'pptx': pptx_icon,
    'pdf': pdf_icon,
    'mp4': mp4_icon,
    'png': png_icon,
    'csv': csv_icon,
    'txt': txt_icon,
    'word': docx_icon,
}

const useStyles = makeStyles((theme) => ({
    linkButton: {
        margin: 'auto 24% 10% 24%',
        fontSize: '2.5vh',
        color: 'black',
        backgroundColor: 'white',
        '&:hover': {
            backgroundColor: 'gray',
            color: '#FFF'
        }
    },
    dataInformation: {
        margin: '1.5vh 0 0 1.5vw',
        fontSize: '2.5vh',
    },
    DataDownloadProgress: {
        margin: '3% 20% 0 24%',
        fontSize: '2vh'
    }
}));

function DataDownload(props) {
    const classes = useStyles();
    const {
        account,
        permission,
        cookies,
        removeCookie,
        activeScreen,
        switchScreen,
        getDownloadData,
        downloadData,
    } = props

    const [progress, setProgress] = useState(0);
    const [isDownloading, setIsDownloading] = useState(false);
    const [id, setID] = useState(-1);


    async function dataDownload(index, filelink, filename) {
        // let newTime = handleCreatedTimeTransfer(uploadTime);

        let response = await fetch(`${AppConfigN.SERVER_URL}/api/download_dil`, {
            method: 'POST',
            headers: {
                "Authorization": `Token ${props.account.token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "filedownload": filelink
            })
        })
        setIsDownloading(true);
        setID(index);
        // console.log(index);
        const reader = response.body.getReader();
        const contentLength = +response.headers.get('Content-Length');
        let receivedLength = 0; // 当前接收到了这么多字节
        let chunks = []; // 接收到的二进制块的数组（包括 body）
        while (true) {

            const { done, value } = await reader.read();
            if (done) {
                setIsDownloading(false);
                setID(-1);
                break;
            }

            chunks.push(value);
            receivedLength += value.length;

            setProgress(parseInt((receivedLength / contentLength) * 100));

            // console.log(`Received ${receivedLength} of ${contentLength}`)
        }

        const url = window.URL.createObjectURL(new Blob(chunks));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${filename}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    }
    // .then((response) => response.blob())
    // .then((blob) => {
    //     const url = window.URL.createObjectURL(new Blob([blob]));
    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.setAttribute('download', `${filename}`);
    //     document.body.appendChild(link);
    //     link.click();
    //     link.parentNode.removeChild(link);
    // })
    //     .catch ((error) => {
    //     console.error(error);
    // });


    useEffect(() => {
        getDownloadData();
    }, [account, getDownloadData])

    return (
        <div className="new_home_mainContainer">
            <div className="page_container">
                <div className="page_title_container"><p className="page_title_text">FCS專案交付檔案下載</p></div>
                <div style={{display: 'inline-flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                    {
                        Object.values(downloadData)
                            .map((item, index) => {
                                return (
                                    <div key={`downloadData_${index}`} className="DataDownload_block" style={{ flexDirection: "column", width: "18vw", margin: "0 0.5vw 1vh 0.5vw", minWidth: '260px' }}>
                                        <p className="DataDownload_block_title_text" style={{}}>{item.dataName}</p>
                                        <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
                                        <p className={classes.dataInformation}>{`上傳日期：`}</p>
                                        <p className={classes.dataInformation} style={{textAlign: 'center'}}>{`${item.uploadTime}`}</p>
                                        {/* <p className={classes.dataInformation} style={{marginBottom: '2vh'}}>{`資料格式：${item.dataType}`}</p> */}
                                        <div style={{margin: '1.5vh 0 2vh 1.5vw', display: 'flex', flexDirection: 'column'}}>
                                            <p className={classes.dataInformation} style={{margin: '0 0 2vh 0'}}>{`資料格式：`}</p>
                                            <div style={{display: 'inline-flex', justifyContent: 'center'}}>
                                                {item.dataType.split(', ').map((value, dataType_index) => {
                                                    if(Object.keys(dataType_icon_list).includes(value)){
                                                        return(<img alt='DataDownload_dataType_icon' key={`downloadData_${index}_icon_${dataType_index}`} src={dataType_icon_list[value]} className="DataDownload_dataType_icon" />)
                                                    }else{return(<p className={classes.dataInformation} style={{border: '1px solid gray', margin: 'auto 5px', padding: '3px', borderRadius: '3px'}} key={`downloadData_${index}_icon_${dataType_index}`}>{value}</p>)}
                                                })}
                                            </div>
                                        </div>
                                        <Button variant="outlined" className={classes.linkButton} onClick={() => dataDownload(index, item.link, item.filename)} >下載檔案</Button>
                                        {isDownloading === true && id === index ?
                                            <div className={classes.DataDownloadProgress}>資料下載進度 : {progress}%</div> : ""
                                        }
                                    </div>
                                )
                            }
                            )
                    }    
                </div>
            </div>
            <Menu
                account={account}
                permission={permission}
                cookies={cookies}
                removeCookie={removeCookie}
                activeScreen={activeScreen}
                switchScreen={switchScreen}
            />
        </div>
    );
}

export default DataDownload;