import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import DatalistItem from './DatalistItem';
import DataUpload from './DataUpload';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '21.2vw',
    textAlign: 'center',
    marginTop: '0',
    padding:"0px 2.3vw 0 6vw",
    // borderRadius: "6px",
    // border: "solid 1px #939393",
  },
  select: {
    height: "4.8vh",
    fontSize: "1.6vh",
    marginTop: "1.1vh",
  },
  inputLabel:{
    padding:"0 0 0 6.3vw",
  }
}));

function Datalist(props) {
  const classes = useStyles();

  return (
    <div>
      <div className="datalist_top_container">
        <div><img className="closeSubMenu_button" src={require("../img/icon-close-1.svg")} alt="close subMenu" onClick={() => {props.changeSubMenuNow('None')}} /></div>
        <div>
          <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel className={classes.inputLabel} >Upload</InputLabel>
            <Select
              // labelId="demo-simple-select-outlined-label"
              // id="demo-simple-select-outlined"              id="demo-simple-select"
              className={classes.select}
              value={"None"}
              onChange={() => {}}
            >
              <MenuItem value="None">請選擇檔案</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      <div className="datalist_middle_container">
        <div className="datalist_subtitle">
          資料列表
        </div>
        <div>
          {
            Object
            .entries(props.processData)
            .map(([index, value]) => {
              return(
                <DatalistItem key={`datalist_${index}`} value={props.processList[index]} />
                
                // <div key={`datalist_${index}`} style={{width:"100%", padding:"2%"}}>
                //   <div className="Datalist_item_index">*</div>
                //   <div className="Datalist_item_value">{props.processList[index]}</div>
                // </div>
                
              )
            })
          }
        </div>
      </div>
      <div className="datalist_bottom_container">
        <DataUpload />
      </div>
    </div>
  );
}

export default Datalist;