import React from 'react';

function DisplayReport(props) {
  if(props.shell==='loanRisk' && props.data.table !== undefined){
    return (
      <div className="display_body_container">
        <div><p className='display_report_title'>房貸實體風險</p></div>
        <div style={{flexDirection:'column', backgroundColor:'#fafafa', borderRadius: '1.28vw', width:'70.8vw', height:'83vh', padding:'2.1vh 1.6vw 4vh', overflow:'scroll'}}>
          <div style={{display:'flex', flexDirection:'row', border:'solid 2px #eaeaea', marginBottom:'3.3vh'}}>
            <p className='display_report_block' style={{flex:'133', height:'2.9vh', padding:'1.4vh' , border:'solid 1px #eaeaea'}}>評估時間</p>
            <p className='display_report_block' style={{flex:'163', height:'2.9vh', padding:'1.4vh' , border:'solid 1px #eaeaea'}}>{props.data.table[0]}</p>
            <p className='display_report_block' style={{flex:'133', height:'2.9vh', padding:'1.4vh' , border:'solid 1px #eaeaea'}}>排放情境</p>
            <p className='display_report_block' style={{flex:'163', height:'2.9vh', padding:'1.4vh' , border:'solid 1px #eaeaea'}}>{`RCP${props.data.table[1]}`}</p>
            <p className='display_report_block' style={{flex:'133', height:'2.9vh', padding:'1.4vh' , border:'solid 1px #eaeaea'}}>不動產總數</p>
            <p className='display_report_block' style={{flex:'163', height:'2.9vh', padding:'1.4vh' , border:'solid 1px #eaeaea'}}>{props.data.table[3]}</p>
          </div>
          <div style={{display:'flex', flexDirection:'column', border:'solid 2px #eaeaea', marginBottom:'3.3vh'}}>
            <div style={{display:'flex', flex:'43', flexDirection:'row'}}>
              <p className='display_report_block' style={{padding:'1.4vh', width:'70.8vw'}}>風險等級對應不動產數目</p>
            </div>
            <div style={{display:'flex', flex:'34', flexDirection:'row'}}>
              <p className='display_report_block' style={{display:'flex', flex:'1', padding:'0.4vh 0 0 1.4vh'}}>低風險</p>
              <p className='display_report_block' style={{display:'flex', flex:'1', padding:'0.4vh 0 0 1.4vh'}}>輕微風險</p>
              <p className='display_report_block' style={{display:'flex', flex:'1', padding:'0.4vh 0 0 1.4vh'}}>中度風險</p>
              <p className='display_report_block' style={{display:'flex', flex:'1', padding:'0.4vh 0 0 1.4vh'}}>高度風險</p>
              <p className='display_report_block' style={{display:'flex', flex:'1', padding:'0.4vh 0 0 1.4vh'}}>極高度風險</p>
            </div>
            <div style={{display:'flex', flex:'34', flexDirection:'row'}}>
              <p className='display_report_block' style={{display:'flex', flex:'1', padding:'0.4vh 0 0 1.4vh'}}>{props.data.demoDataHarmLevel[0]}</p>
              <p className='display_report_block' style={{display:'flex', flex:'1', padding:'0.4vh 0 0 1.4vh'}}>{props.data.demoDataHarmLevel[1]}</p>
              <p className='display_report_block' style={{display:'flex', flex:'1', padding:'0.4vh 0 0 1.4vh'}}>{props.data.demoDataHarmLevel[2]}</p>
              <p className='display_report_block' style={{display:'flex', flex:'1', padding:'0.4vh 0 0 1.4vh'}}>{props.data.demoDataHarmLevel[3]}</p>
              <p className='display_report_block' style={{display:'flex', flex:'1', padding:'0.4vh 0 0 1.4vh'}}>{props.data.demoDataHarmLevel[4]}</p>
            </div>
          </div>
          <div style={{width:'71vw', height:'30vh', marginBottom:'2vh'}}>
            <p className='display_report_description' >{props.data.text.all[0]}</p>
            <p className='display_report_description' >{props.data.text.all[1]}</p>
            <p className='display_report_description' >{props.data.text.all[2]}</p>
            <p className='display_report_description' >{props.data.text.all[3]}</p>
            <p className='display_report_description' >{props.data.text.all[4]}</p>
            <p className='display_report_description' >{props.data.text.all[5]}</p>
            <p className='display_report_description' >{props.data.text.all[6]}</p>
            <p className='display_report_description' >{props.data.text.all[7]}</p>
            <p className='display_report_description' >{props.data.text.harm}</p>
            <p className='display_report_description' >{props.data.text.vulnerability}</p>
          </div>
        </div>
      </div>
    );
  } else {
    return(
      <div className="display_body_container">
      </div>
    )
  }
}

export default DisplayReport;