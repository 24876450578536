import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
// import Permission from '../const/Permission.const';


const useStyles = makeStyles((theme) => ({
  formControl: {
    paddingBottom: '2vh',
    width: '20.56vw',
    textAlign: 'center',
  },
}));


function ShellChoice(props) {
  const classes = useStyles();
  return (
    <div style={{width: "16.3vw", position:"relative", right:"0", padding:"0px 3vw 0 5.5vw"}}>
      <FormControl className={classes.formControl}>
        <InputLabel>Service</InputLabel>
        <Select
          //id="demo-simple-select"
          value={props.shellChoice}
          onChange={(event)=>{
            props.changeDisplayDataState('notReady')
            props.changeDisplayData({})
            props.switchShellChoice(event.target.value)
            props.changeDisplayMethod(event.target.value)
          }}
        >
          <MenuItem value={"None"}>請選擇服務項目</MenuItem>
          {Object.values(props.permission.shell).indexOf('loanRisk') >=0 ?<MenuItem value={"loanRisk"}>房貸實體風險</MenuItem>:''}    
          {Object.values(props.permission.shell).indexOf('creditOverview') >=0 ?<MenuItem disabled={true} value={"creditOverview"}>授信總覽</MenuItem>:''}    
          {Object.values(props.permission.shell).indexOf('equityInvestment') >=0 ?<MenuItem disabled={true} value={"equityInvestment"}>股權投資總覽</MenuItem>:''}    
          {Object.values(props.permission.shell).indexOf('debtInvestment') >=0 ?<MenuItem disabled={true} value={"debtInvestment"}>債權投資總覽</MenuItem>:''}    
          {Object.values(props.permission.shell).indexOf('SMEInvestment') >=0 ?<MenuItem disabled={true} value={"SMEInvestment"}>中小企業直接投資總覽</MenuItem>:''}    
        </Select>
      </FormControl>
    </div>
  );
}

export default ShellChoice;