import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppConfig from '../const/AppConfig.const';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Button, TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { VictoryChart, VictoryLine, VictoryAxis } from 'victory';
import Menu from './Menu';
import TravelCarbonEmissionsRecordItem from '../component/TravelCarbonEmissionsRecordItem';

const useStyles = makeStyles((theme) => ({
  formControl: {
    maxWidth: '39vw',
    textAlign: 'center',
    // borderRadius: "6px",
    // border: "solid 1px #939393",
  },
  select: {
    height: "5vh",
    width: "15vw",
    margin: '0.5vh 1vw',
  },
  textfield: {
    margin: "0.5vh 2vw",
    height: "5vh",
    width: "13vw",
  },
  recordButton: {
    margin: '0 24% 0 26%',
    color: 'black',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'gray',
      color: '#FFF'
    }
  },
  calculateButton: {
    width: '6vw',
    height: '12vh',
    margin: '1vh 1.5vw 0 2.5vw',
    padding: '1vh 0.5vw',
    color: 'black',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'gray',
      color: '#FFF'
    }
  }
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};


function TravelCarbonEmissions(props) {
  const classes = useStyles();
  const {
    account,
    permission,
    cookies,
    removeCookie,
    activeScreen,
    switchScreen,
    switchShellChoice,
    changeDisplayMethod,
  } = props

  const [pathwayTmp, changePathwayTmp] = useState({})
  const [pathwayRecord, changePathwayRecord] = useState({})
  const [goalType, changeGoalType] = useState('None')
  const [goalBudget, changeGoalBudget] = useState("-")
  const [REYear, changeREYear] = useState(2020)
  const [REPercentage, changeREPercentage] = useState(0)
  const [reductionRatio, changeReductionRatio] = useState({})

  const addPathwayDataToRecord = () => {
    if (Object.values(pathwayTmp).length !== 0) {
      changePathwayRecord((prevPathwayRecord) => ({
        ...prevPathwayRecord,
        [Object.values(prevPathwayRecord).length + 1]: {
          carbonEmissionLimited: [...pathwayTmp.carbonEmissionLimited],
          carbonCost: [...pathwayTmp.carbonCost],
          goalType,
          goalBudget,
          reductionRatio: Object.assign({}, pathwayTmp.reductionRatio),
        }
      }));
    } else {
      console.log('empty record')
    }
  }

  const getPathwayData = () => {
    console.log('getPathwayData')
    let reductionGoal = {}
    Object
      .entries(reductionRatio)
      .forEach(([index, value]) => {
        if (value.isDelete === false) {
          reductionGoal[index] = value
        }
      })
    //console.log(reductionGoal)
    if (goalType !== 'None' && goalBudget !== 'None') {
      fetch(`${AppConfig.SERVER_URL}/TravelCarbonEmissions`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          account: account.ID,
          goalType,
          goalBudget,
          reductionRatio: reductionGoal,
        }),
      })
        .then(response => response.json())
        .then((responseJson) => {
          console.log(responseJson)
          changePathwayTmp(responseJson)
        })
        .catch((error) => {
          console.error(error);
        })
    }
  }

  const onCreateReductionGoal = () => {
    const newGoalIndex = Object.values(reductionRatio).length + 1
    changeReductionRatio({ ...reductionRatio, [newGoalIndex]: { year: REYear, percentage: REPercentage, isDelete: false } })
  }

  const onDeleteReductionRatio = (index) => {
    let item = reductionRatio[index]
    item.isDelete = true
    changeReductionRatio({
      ...reductionRatio,
      [index]: item
    })
  }

  useEffect(() => {
    changePathwayTmp({})
  }, [pathwayRecord]);

  let carbonEmissionLimited = []
  let carbonCost = []
  let maxReduction = 0
  if (Object.values(pathwayTmp).length !== 0) {
    carbonEmissionLimited = []
    carbonCost = []
    pathwayTmp.carbonEmissionLimited
      .forEach((value, index) => {
        carbonEmissionLimited.push({ 'x': 2020 + index + 1, 'y': value })
      })
    pathwayTmp.carbonCost
      .forEach((value, index) => {
        carbonCost.push({ 'x': 2020 + index + 1, 'y': value })
      })
  }
  if (Object.values(pathwayRecord).length !== 0) {
    Object.values(pathwayRecord)
      .forEach((value) => {
        maxReduction = Math.max((maxReduction, value.carbonEmissionLimited[value.carbonEmissionLimited.length - 1]))
      })
  }
  return (
    <div className="new_home_mainContainer">
      <div className="page_container">
        <div className="page_title_container"><p className="page_title_text">企業差旅碳排路徑規劃</p></div>
        <div style={{ display: 'inline-flex', flexDirection: "row", width: "79.2vw", height: "35vh", marginBottom: '2vh' }}>
          <div className="TravelCarbonEmissions_block" style={{ flexDirection: "column", width: "57vw", height: "35vh", marginRight: "0.8vw" }}>
            <p className="TravelCarbonEmissions_block_title_text" style={{}}>目標路徑試算</p>
            <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
            <div style={{ display: 'inline-flex' }}>
              <div>
                <p className='TravelCarbonEmissions_block_subtitle_text' style={{ position: 'relative', left: '1.92vw' }}>目標種類</p>
                <FormControl variant="outlined" className={classes.formControl}>
                  <Select
                    className={classes.select}
                    // labelId="demo-simple-select-outlined-label"
                    // id="demo-simple-select-outlined"
                    value={goalType}
                    onChange={(event) => {
                      changeGoalType(event.target.value);
                      if (event.target.value === "sustainabilityFirst" || event.target.value === "None") {
                        changeGoalBudget("-");
                      } else { changeGoalBudget(0); }
                    }}
                  // label={props.processData[props.index]}
                  >
                    <MenuItem value="None"><em>請選擇目標種類</em></MenuItem>
                    <MenuItem value="totalBudgetFirst"><em>總預算先決</em></MenuItem>
                    <MenuItem value="annualBudgetFirst"><em>年度預算先決</em></MenuItem>
                    <MenuItem value="sustainabilityFirst"><em>永續先決</em></MenuItem>
                  </Select>
                </FormControl>
                <p className='TravelCarbonEmissions_block_subtitle_text' style={{ position: 'relative', left: '1.92vw' }}>預算金額</p>
                <TextField
                  className={classes.textfield}
                  id="TCE_GoalBudget"
                  label="預算金額"
                  name="TCE_GoalBudget"
                  value={goalBudget}
                  onChange={(event) => {
                    changeGoalBudget(event.target.value);
                  }}
                  disabled={goalType === "sustainabilityFirst" || goalType === "None"}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
              </div>
              <div style={{ position: 'relative', left: '1vw' }}>
                <p className='TravelCarbonEmissions_block_subtitle_text' >碳排放減量目標</p>
                <div style={{ display: 'inline-flex' }}>
                  <input
                    disabled={goalType === "sustainabilityFirst" ? false : true}
                    type="number"
                    min="2020"
                    max="2050"
                    step="1"
                    value={REYear}
                    style={{ width: '5vw', height: '4vh', margin: '0.5vh 0', paddingLeft: '1vw', fontSize: '2vh' }}
                    onChange={(event) => {
                      changeREYear(event.target.value);
                    }}
                  />
                  <p style={{ fontSize: '2vh', margin: 'auto 1vw' }}>年 減少</p>
                  <input
                    disabled={goalType === "sustainabilityFirst" ? false : true}
                    type="number"
                    min="0"
                    max="100"
                    step="1"
                    value={REPercentage}
                    style={{ width: '3.2vw', height: '4vh', margin: '0.5vh 0 0', paddingLeft: '1vw', fontSize: '2vh' }}
                    onChange={(event) => {
                      changeREPercentage(event.target.value);
                    }}
                  />
                  <p style={{ fontSize: '2vh', margin: 'auto 1vw' }}>％</p>
                  <Button variant="outlined" disabled={goalType === "sustainabilityFirst" ? false : true} onClick={onCreateReductionGoal} >新增此減量目標</Button>
                </div>
                <div style={{ display: 'inline-flex' }}>
                  <div style={{ overflow: 'scroll', border: 'gray 1px solid', borderRadius: '3px', height: '12vh', marginTop: '1vh', paddingLeft: '2vw', width: '27vw' }}>
                    {
                      goalType === "sustainabilityFirst" ?
                        Object
                          .entries(reductionRatio)
                          .filter(([index, value]) => value.isDelete === false)
                          .map(([index, value]) => {
                            return (
                              <div style={{ display: 'inline-flex' }}>
                                <p style={{ marginTop: '2vh', marginBottom: '0' }}>{`${value.year} 年 減少 ${value.percentage} ％`}</p>
                                <img className="deleteReductionRatio_button" src={require("../img/icon-close-1.svg")} alt="DeleteReductionRatio" onClick={() => { onDeleteReductionRatio(index); }} />
                              </div>
                            )
                          })
                        : ''
                    }
                  </div>
                  <Button variant="outlined" className={classes.calculateButton} onClick={getPathwayData} >計算碳排路徑圖</Button>
                </div>
              </div>
            </div>
          </div>
          <div className="TravelCarbonEmissions_block" style={{ flexDirection: "column", width: "20vw", height: "35vh" }}>
            <p className="TravelCarbonEmissions_block_title_text" style={{}}>碳排放路徑圖</p>
            <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
            <div style={{height: 'fit-content', margin: 'auto'}}>
              <div>
                <VictoryChart
                  width={300}
                  height={190}
                  padding={{ top: 10, bottom: 40, left: 50, right: 50 }}
                  animate={{
                    duration: 1000,
                    onLoad: { duration: 500 }
                  }}
                >
                  <VictoryAxis
                    domain={[2020, 2050]}
                    //tickFormat={tickX}
                    label='年份'
                    style={{
                      axis: { stroke: "#6a6a6a" },
                      // axisLabel: {fontSize: 10 , fill:'#6a6a6a'},
                      ticks: { stroke: "#6a6a6a", size: 3 },
                      tickLabels: { fontSize: 8, padding: 5, fill: '#6a6a6a' },
                      axisLabel: { fontSize: 10, padding: 20, fill: '#6a6a6a' },
                    }}
                  />
                  <VictoryAxis
                    dependentAxis
                    orientation="left"
                    //crossAxis
                    //domain={[2020, 2050]}
                    label='減碳路徑(公斤)'
                    style={{
                      axis: { stroke: "#c43a31" },
                      // axisLabel: {fontSize: 10 , fill:'#6a6a6a'},
                      ticks: { stroke: "#c43a31", size: 3 },
                      tickLabels: { fontSize: 8, padding: 2, fill: '#c43a31' },
                      axisLabel: { fontSize: 10, padding: 35, fill: "#c43a31" },
                    }}
                    standalone={false}
                  />
                  <VictoryAxis
                    dependentAxis
                    //offsetX={300}
                    orientation="right"
                    //crossAxis
                    //domain={[2020, 2050]}
                    label='轉換成本(元/年)'
                    style={{
                      axis: { stroke: "blue" },
                      // axisLabel: {fontSize: 10 , fill:'#6a6a6a'},
                      ticks: { stroke: "blue", size: 3 },
                      tickLabels: { fontSize: 8, padding: 2, fill: 'blue' },
                      axisLabel: { fontSize: 10, padding: 35, fill: 'blue' },
                    }}
                    standalone={false}
                  />
                  <VictoryLine
                    style={{
                      data: { stroke: "#c43a31" },
                      parent: { border: "1px solid #ccc" }
                    }}
                    data={Object.values(pathwayTmp).length === 0 ? [] : carbonEmissionLimited}
                  />
                  <VictoryLine
                    style={{
                      data: { stroke: "blue" },
                      parent: { border: "1px solid #ccc" }
                    }}
                    data={Object.values(pathwayTmp).length === 0 ? [] : carbonCost}
                  />
                </VictoryChart>
              </div>
              <div>
                <Button variant="outlined" className={classes.recordButton} onClick={addPathwayDataToRecord} >紀錄此碳排路徑</Button>
              </div>
            </div>
          </div>
        </div>
        <div className="TravelCarbonEmissions_block" style={{ flexDirection: "column", width: "77.8vw", height: "fit-content" }}>
          <p className="TravelCarbonEmissions_block_title_text" style={{}}>目標路徑比較</p>
          <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
          <div style={{ display: 'inline-flex', height: 'fit-content' }}>
            {
              Object
                .entries(pathwayRecord)
                .filter(([index, value]) => Object.values(pathwayRecord).length - index <= 3)
                .map(([index, value]) =>
                  <TravelCarbonEmissionsRecordItem
                    goalType={value.goalType}
                    goalBudget={value.goalBudget}
                    carbonEmissionLimited={value.carbonEmissionLimited}
                    carbonCost={value.carbonCost}
                    reductionRatio={value.reductionRatio}
                    maxReduction={maxReduction}
                  />)
            }
          </div>
        </div>
      </div>
      <Menu
        account={account}
        permission={permission}
        cookies={cookies}
        removeCookie={removeCookie}
        activeScreen={activeScreen}
        switchScreen={switchScreen}
        switchShellChoice={switchShellChoice}
        changeDisplayMethod={changeDisplayMethod}
      />
    </div>
  );
}

export default TravelCarbonEmissions;