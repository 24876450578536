import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ReplayIcon from '@material-ui/icons/Replay';

const useStyles = makeStyles((theme) => ({
    displayTitle: {
        fontWeight: 'bold',
        fontSize: '3.3vh',
        margin: '1vh 0vw 1vh 0vw',
    },
    outputText: {
        margin: '0 0 0 0',
        fontSize: '2.8vh',
    },
    selectOption: {
        margin: '1vh 0vw 0vh 0vw',
        fontSize: '2vh',
    },
    input: {
        width: "7vw",
        minWidth: '100px',
        height: "3.3vh",
        margin: '0vh 0.5vw 0vh 0vw',
        fontSize: '2.3vh',
        paddingLeft: '0.5vw',
        borderRadius: '5px',
        borderWidth: 'thin',
        borderColor: '#b6b6b6',
        borderStyle: 'solid',
    },
    select: {
        height: "3.8vh",
        width: "4vw",
        margin: '0vh 3vw 0vh 0.5vw',
        fontSize: '2.2vh',
    },
    compute: {
        height: "4vh",
        minWidth: '90px',
        margin: '0vh 0vw 0vh 0.5vw',
        fontSize: '22px',
        backgroundColor: '#ffffff',
    },
    computeIcon: {
        height: "4vh",
        width: "2.5vw",
        minWidth: "2.5vw",
        margin: 'auto 0.5vw',
        fontSize: '2.5vh',
        backgroundColor: '#ffffff',
    },
    menuItem: {
        marginBottom: '0vh',
    },
    icon: {
        height: "3vh",
    }
}));

function DisplayInvestmentCarbonEmissions_FinancialData(props) {
    const classes = useStyles();

    const [companyID, changeCompanyID] = useState("");
    const [type, changeType] = useState("");
    const [owner, changeOwner] = useState("");
    const [filtedData, changeFiltedData] = useState([]);

    const {
        companyData,
        typeList,
        ownerList,
    } = props;

    const handleDataFilter = (companyData, companyID, type, ownerID) => {
        let check = false;
        if (companyID !== "") {
            Object.values(companyData.individual).forEach(data => {
                if (data.companyID === companyID) {
                    check = true;
                }
            })
            if (check === false) {
                changeFiltedData(["not found"])
                console.log("not found")
            }
            else {
                changeFiltedData(Object.values(companyData.individual).map(data => {
                    return data.companyID === companyID
                }))
            }
        }
        else {
            if (type !== "" & ownerID !== "") {
                Object.values(companyData.individual).forEach(data => {
                    if (data.type === type & data.ownerID === ownerID) {
                        check = true;
                    }
                })
                if (check === false) {
                    changeFiltedData(["not found"])
                    console.log("not found")
                }
                else {
                    changeFiltedData(Object.values(companyData.individual).map(data => {
                        return data.type === type & data.ownerID === ownerID
                    }))
                }
            }
            else if (type !== "") {
                changeFiltedData(Object.values(companyData.individual).map(data => {
                    return data.type === type
                }))
            }
            else if (ownerID !== "") {
                changeFiltedData(Object.values(companyData.individual).map(data => {
                    return data.ownerID === ownerID
                }))
            }
        }
        return
    }

    const handleDataFilterDeletion = () => {
        changeCompanyID("");
        changeType("");
        changeOwner("");
        changeFiltedData([]);
    }

    const handleFractionDigits = (number) => {
        if (number === null) return "-"
        else if (number < 1) {
            return new Intl.NumberFormat('en-US', { maximumFractionDigits: 4, minimumFractionDigits: 4 }).format(number)
        }
        else if (number < 10) {
            return new Intl.NumberFormat('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(number)
        }
        else if (number < 100) {
            return new Intl.NumberFormat('en-US', { maximumFractionDigits: 1, minimumFractionDigits: 1 }).format(number)
        }
        else return new Intl.NumberFormat('en-US', { maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(number)
    }

    const handleMoneyFractionDigits = (number) => {
        if (number === null) return "-"
        else if (number < 1) {
            return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 4, minimumFractionDigits: 4 }).format(number)
        }
        else if (number < 10) {
            return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(number)
        }
        else if (number < 100) {
            return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 1, minimumFractionDigits: 1 }).format(number)
        }
        else return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(number)
    }

    return (

        <div className="display_body_container">
            <div style={{ backgroundColor: '#fafafa', borderRadius: '1.212vw', padding: '2.1vh 1.6vw 4vh', overflowX: 'scroll' }}>
                {/* <div className={classes.selectOption} style={{ display: 'inline-flex', alignItems: 'baseline', marginBottom: '3vh' }}>
                    <p style={{minWidth: '210px', margin: '0vh 0.8vw 0vh 0vh' }}>股票代碼/統一編號</p>
                    <input
                        className={classes.input}
                        type="string"
                        value={companyID}
                        onChange={(event) => {
                            changeCompanyID(event.target.value);
                        }}
                    />

                    <p style={{minWidth: '100px', margin: '0vh 0.3vw 0vh 2vw' }}>案件類別</p>
                    <FormControl variant="outlined">
                        <Select
                            className={classes.select}
                            style={{minWidth: '135px', width: "8vw" }}
                            value={type}
                            onChange={(event) => {
                                changeType(event.target.value);
                            }}
                        >
                            {
                                Object
                                    .entries(typeList)
                                    .map(([index, value]) => <MenuItem key={`typeList_${index}`} value={value} className={classes.menuItem} >{value}</MenuItem>)
                            }
                        </Select>
                    </FormControl>

                    <p style={{minWidth: '75px', margin: '0vh 0.3vw 0vh 0vw' }}>使用者</p>
                    <FormControl variant="outlined">
                        <Select
                            className={classes.select}
                            style={{minWidth: '210px', width: '10vw' }}
                            value={owner}
                            onChange={(event) => {
                                changeOwner(event.target.value);
                            }}
                        >
                            {
                                Object
                                    .entries(ownerList)
                                    .map(([index, value]) => <MenuItem key={`ownerChoice_${index}`} value={value} className={classes.menuItem} >{value}</MenuItem>)
                            }
                        </Select>
                    </FormControl>

                    <Button variant="outlined" className={classes.compute} onClick={() => handleDataFilter(companyData, companyID, type, owner)} >篩選</Button>
                    <Button variant="outlined" className={classes.computeIcon} onClick={() => handleDataFilterDeletion()} >
                        <ReplayIcon className={classes.icon} />
                    </Button>

                </div> */}
                <div style={{ display: 'inline-grid', width: 'fit-content', height: 'fit-content', border: 'solid 2px #eaeaea', margin: '0vh 1.6vw 1vh 0vw' }}>
                    <div style={{ display: 'inline-flex' }}>
                        <div className='single_display_report_block' style={{ width: '5vw', minWidth: '100px', textAlign: 'center' }}>案件類別</div>
                        <div className='single_display_report_block' style={{ width: '8vw', minWidth: '140px', textAlign: 'center' }}>股票代碼/<br />統一編號</div>
                        <div className='single_display_report_block' style={{ width: '26vw', minWidth: '260px', textAlign: 'center' }}>公司名稱</div>
                        <div className='single_display_report_block' style={{ width: '18vw', minWidth: '200px', textAlign: 'center' }}>公司名稱<br />(系統帶入)</div>
                        <div className='single_display_report_block' style={{ width: '10vw', minWidth: '110px', textAlign: 'center' }}>國家/地區</div>
                        <div className='single_display_report_block' style={{ width: '18vw', minWidth: '180px', textAlign: 'center' }}>產業類別</div>
                        <div className='single_display_report_block' style={{ width: '8.5vw', minWidth: '170px', textAlign: 'center' }}>投融資餘額<br />(千元)</div>
                        <div className='single_display_report_block' style={{ width: '8.5vw', minWidth: '170px', textAlign: 'center' }}>EVIC<br />(千元)</div>
                        <div className='single_display_report_block' style={{ width: '8vw', minWidth: '170px', textAlign: 'center' }}>權益+總債務<br />(千元)</div>
                        <div className='single_display_report_block' style={{ width: '8vw', minWidth: '170px', textAlign: 'center' }}>年營收<br />(千元)</div>
                        <div className='single_display_report_block' style={{ width: '5vw', minWidth: '105px', textAlign: 'center' }}>財報資料<br />年分</div>
                        <div className='single_display_report_block' style={{ width: '8vw', minWidth: '150px', textAlign: 'center' }}>產業碳排係數<br />(公噸/千元)</div>
                        <div className='single_display_report_block' style={{ width: '7.5vw', minWidth: '170px', textAlign: 'center' }}>產業總營收<br />(千元)</div>
                        <div className='single_display_report_block' style={{ width: '7.5vw', minWidth: '170px', textAlign: 'center' }}>產業總資產<br />(千元)</div>
                        <div className='single_display_report_block' style={{ width: '12vw', minWidth: '145px', textAlign: 'center' }}>資料上傳時間</div>
                    </div>
                    {
                        filtedData[0] === "not found" ? "" :
                        Object.entries(companyData.individual)
                        .map((data, index) => (
                            <div key={index} style={{ display: 'inline-flex' }}>
                                <div className='single_display_report_block' style={{ width: '5vw', minWidth: '100px' }}>{data[1].type === null ? "-" : data[1].type}</div>
                                <div className='single_display_report_block' style={{ width: '8vw', minWidth: '140px' }}>{data[1].companyID === null ? "-" : data[1].companyID}</div>
                                <div className='single_display_report_block' style={{ width: '26vw', minWidth: '260px' }}>{data[1].companyName === null ? "-" : data[1].companyName}</div>
                                <div className='single_display_report_block' style={{ width: '18vw', minWidth: '200px' }}>{data[1].companyNameSys === null ? "-" : data[1].companyNameSys}</div>
                                <div className='single_display_report_block' style={{ width: '10vw', minWidth: '110px' }}>{data[1].location === null ? "-" : data[1].location}</div>
                                <div className='single_display_report_block' style={{ width: '18vw', minWidth: '180px' }}>{data[1].industry === null ? "-" : data[1].industry}</div>
                                <div className='single_display_report_block' style={{ width: '8.5vw', minWidth: '170px', textAlign: 'right' }}>{handleMoneyFractionDigits(data[1].lendAmount)}</div>
                                <div className='single_display_report_block' style={{ width: '8.5vw', minWidth: '170px', textAlign: 'right' }}>{handleMoneyFractionDigits(data[1].evic)}</div>
                                <div className='single_display_report_block' style={{ width: '8vw', minWidth: '170px', textAlign: 'right' }}>{handleMoneyFractionDigits(data[1].debt)}</div>
                                <div className='single_display_report_block' style={{ width: '8vw', minWidth: '170px', textAlign: 'right' }}>{handleMoneyFractionDigits(data[1].revenue)}</div>
                                <div className='single_display_report_block' style={{ width: '5vw', minWidth: '105px', textAlign: 'center' }}>{data[1].financeDataYear === null ? "-" : data[1].financeDataYear}</div>
                                <div className='single_display_report_block' style={{ width: '8vw', minWidth: '150px' }}>{handleFractionDigits(data[1].industryCarbonCoef)}</div>
                                <div className='single_display_report_block' style={{ width: '7.5vw', minWidth: '170px', textAlign: 'right' }}>{handleMoneyFractionDigits(data[1].industryRevenue)}</div>
                                <div className='single_display_report_block' style={{ width: '7.5vw', minWidth: '170px', textAlign: 'right' }}>{handleMoneyFractionDigits(data[1].industryAssets)}</div>
                                <div className='single_display_report_block' style={{ width: '12vw', minWidth: '145px' }}>{data[1].created_time === null ? "-" : data[1].created_time}</div>
                            </div>))
                    }
                </div>
            </div>
        </div >
    )
}

export default DisplayInvestmentCarbonEmissions_FinancialData;