import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { VictoryChart, VictoryScatter, VictoryAxis } from 'victory';


const yearChoiceList = ['2020-2040'];
const CSChoiceList = ['RCP2.6', 'RCP8.5'];
const levelList = ['極低度','低度','中度','高度','極高度']
const dataConfidenceList = ['低度可信賴', '低度可信賴','中度可信賴','高度可信賴']
const grade = ['C','C','B','A']

const yearChoice = [
  {
    value: 0,
    label: '2020-2040',
  },
  // {
  //   value: 1,
  //   label: '2040-2060',
  // },
  // {
  //   value: 2,
  //   label: '2060-2080',
  // },
  // {
  //   value: 3,
  //   label: '2080-2100',
  // },
];

const CSChoice = [
  {
    value: 0,
    label: 'RCP2.6',
  },
  {
    value: 1,
    label: 'RCP8.5',
  },
];



const useStyles = makeStyles((theme) => ({
  textInput: {
    width:'12vw',
  },
  formControl: {
    paddingTop:'1vh',
    paddingBottom: '2vh',
    width: '10vw',
    textAlign: 'center',
  },
}));

function DisplayReportClimateTest(props) {
  const classes = useStyles();
  const[ taxID, changeTaxID ] = useState('')
  const[ year, changeYear ] = useState(0)
  const[ CS, changeCS] = useState(0)

  const { data, getClimateStressTestData } = props;
  if(data.CVaR === undefined){
    return (
      <div className="display_body_container">
        <div style={{flexDirection:'column', backgroundColor:'#fafafa', borderRadius: '1.28vw', width:'68.6vw', padding:'2.1vh 1.6vw 4vh', overflowX:'scroll'}}>
          <div style={{marginBottom:'3vh', height:'auto', display:'inline-flex'}}>
            <FormControl className={classes.formControl}>
              <Select
                //id="demo-simple-select"
                value={taxID}
                onChange={(event)=>{
                  changeTaxID(event.target.value);
                  getClimateStressTestData(CSChoiceList[CS], yearChoiceList[year], event.target.value);
                }}
              >
                <MenuItem value={"None"}>請選擇統編</MenuItem>
                <MenuItem value={"75280209"}>75280209</MenuItem>
              </Select>
            </FormControl>
            <p style={{margin:'0 auto 0 10vw',fontSize:'2.8vh', paddingTop:'0.8vh', fontWeight:'bold' }}>{`產業別： ${data.industryCate===undefined?'':data.industryCate}`}</p>
          </div>
          <div style={{display:'flex', flexDirection:'row', border:'solid 2px #eaeaea', marginBottom:'3.3vh'}}>
            <p className='display_report_block' style={{flex:'10', height:'4vh', padding:'2.4vh 1.5vw 1.4vh', border:'solid 1px #eaeaea', minWidth: '75px'}}>評估時間</p>
            <div className='display_report_block' style={{flex:'30', height:'5vh', padding:'1.8vh 4vw 1vh', border:'solid 1px #eaeaea'}}>
              <Slider
                onChange={(event, value) => {changeYear(value);getClimateStressTestData(CSChoiceList[CS], yearChoiceList[value], taxID)}}
                defaultValue={0}
                aria-labelledby="discrete-slider"
                valueLabelDisplay="off"
                marks={yearChoice}
                step={1}
                min={0}
                max={0}
              />
            </div>
            <p className='display_report_block' style={{flex:'10', height:'4vh', padding:'2.4vh 1.5vw 1.4vh' , border:'solid 1px #eaeaea', minWidth: '75px'}}>排放情境</p>
            <div className='display_report_block' style={{flex:'20', height:'5vh', padding:'1.8vh 4vw 1vh 3vw' , border:'solid 1px #eaeaea'}}>
              <Slider
                onChange={(event, value) => {changeCS(value);getClimateStressTestData(CSChoiceList[value], yearChoiceList[year], taxID)}}
                defaultValue={0}
                aria-labelledby="discrete-slider"
                valueLabelDisplay="off"
                marks={CSChoice}
                step={1}
                min={0}
                max={1}
              />
            </div>
          </div>
          <div style={{display:'grid',width:'fit-content', height:'fit-content' ,border:'solid 2px #eaeaea' }}>
            <div style={{display:'inline-flex'}}>
              <div className='display_report_block'>風險屬性</div>
              <div className='display_report_block'>項目</div>
              <div className='display_report_block'>數值</div>
              <div className='display_report_block'>說明與單位</div>
              <div className='display_report_block'>衝擊層面</div>
              <div className='display_report_block'>C-VaR</div>
              <div className='display_report_block'>屬性等級</div>
              <div className='display_report_block'>總體風險等級</div>
            </div>
            <div style={{display:'inline-flex'}}>
              <div style={{display:'grid'}}>
                <div className='display_report_block' style={{height:'16vh', padding:'3px 0.5vw'}}>實體風險</div>
                <div className='display_report_block' style={{height:'8vh', padding:'1px 0.5vw'}}>轉型風險</div>
                <div className='display_report_block' style={{height:'12vh', padding:'2px 0.5vw'}}>揭露程度</div>
              </div>
              <div style={{display:'grid'}}>
                <div className='display_report_block'>淹水</div>
                <div className='display_report_block'>乾旱</div>
                <div className='display_report_block'>極端高溫</div>
                <div className='display_report_block'>極端低溫</div>
                <div className='display_report_block'>市場</div>
                <div className='display_report_block'>政策法規</div>
                <div className='display_report_block'>CSR報告出版</div>
                <div className='display_report_block'>CSR報告確信</div>
                <div className='display_report_block'>CDP填報</div>
              </div>
              <div style={{display:'grid'}}>
                <div className='display_report_block'></div>
                <div className='display_report_block'></div>
                <div className='display_report_block'></div>
                <div className='display_report_block'></div>
                <div className='display_report_block'></div>
                <div className='display_report_block'></div>
                <div className='display_report_block'></div>
                <div className='display_report_block'></div>
                <div className='display_report_block'></div>
              </div>
              <div style={{display:'grid'}}>
                <div className='display_report_block'>每年淹水機率</div>
                <div className='display_report_block'>每年嚴重乾旱日數</div>
                <div className='display_report_block'>每年極端高溫日數</div>
                <div className='display_report_block'>每年極端低溫日數</div>
                <div className='display_report_block'>排放強度(噸/千元)</div>
                <div className='display_report_block'>排放量(公噸)</div>
                <div className='display_report_block'></div>
                <div className='display_report_block'></div>
                <div className='display_report_block'></div>
              </div>
              <div style={{display:'grid'}}>
                <div className='display_report_block'></div>
                <div className='display_report_block'></div>
                <div className='display_report_block'></div>
                <div className='display_report_block'></div>
                <div className='display_report_block'></div>
                <div className='display_report_block'></div>
                <div className='display_report_block'></div>
                <div className='display_report_block'></div>
                <div className='display_report_block'></div>
              </div>
              <div style={{display:'grid'}}>
                <div className='display_report_block'></div>
                <div className='display_report_block'></div>
                <div className='display_report_block'></div>
                <div className='display_report_block'></div>
                <div className='display_report_block'></div>
                <div className='display_report_block'></div>
                <div className='display_report_block'></div>
                <div className='display_report_block'></div>
                <div className='display_report_block'></div>
              </div>
              <div style={{display:'grid'}}>
                <div className='display_report_block'></div>
                <div className='display_report_block'></div>
                <div className='display_report_block'></div>
                <div className='display_report_block'></div>
                <div className='display_report_block'></div>
                <div className='display_report_block'></div>
                <div className='display_report_block' style={{height:'12vh', padding:'2px 0.5vw'}}><p style={{fontSize:'6vh', margin:'0', width:'6vw'}}>{}</p></div>
              </div>
              <div style={{display:'grid'}}>
                <div className='display_report_block' style={{height:'24vh', padding:'5px 0.5vw'}}></div>
                <div className='display_report_block' style={{height:'12vh', padding:'2px 0.5vw'}}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }else{
    const ChartData = props.data.Chart.X.map((value, index)=>{
      return({'x':value, 'y':props.data.Chart.Y[index]})
    })
    console.log(ChartData)
    return (
      <div className="display_body_container">
        <div style={{flexDirection:'column', backgroundColor:'#fafafa', borderRadius: '1.28vw', width:'68.6vw', padding:'2.1vh 1.6vw 4vh', overflowX:'scroll'}}>
          <div style={{marginBottom:'3vh', height:'auto', display:'inline-flex'}}>
            <FormControl className={classes.formControl}>
              <Select
                //id="demo-simple-select"
                value={taxID}
                onChange={(event)=>{
                  changeTaxID(event.target.value);
                  getClimateStressTestData(CSChoiceList[CS], yearChoiceList[year], event.target.value);
                }}
              >
                <MenuItem value={"None"}>請選擇統編</MenuItem>
                <MenuItem value={"75280209"}>75280209</MenuItem>
              </Select>
            </FormControl>
            <p style={{margin:'0 auto 0 10vw',fontSize:'2.8vh', paddingTop:'0.8vh', fontWeight:'bold' }}>{`產業別： ${data.industry===undefined?'':data.industry}`}</p>
          </div>
          <div style={{display:'flex', flexDirection:'row', border:'solid 2px #eaeaea', marginBottom:'3.3vh'}}>
            <p className='display_report_block' style={{flex:'10', height:'4vh', padding:'2.4vh 0 1.4vh 1vw' , border:'solid 1px #eaeaea'}}>評估時間</p>
            <div className='display_report_block' style={{flex:'30', height:'5vh', padding:'1.8vh 4vw 1vh 3vw' , border:'solid 1px #eaeaea'}}>
              <Slider
                onChange={(event, value) => {changeYear(value);getClimateStressTestData(CSChoiceList[CS], yearChoiceList[value], taxID)}}
                defaultValue={0}
                aria-labelledby="discrete-slider"
                valueLabelDisplay="off"
                marks={yearChoice}
                step={1}
                min={0}
                max={3}
              />
            </div>
            <p className='display_report_block' style={{flex:'10', height:'4vh', padding:'2.4vh 0 1.4vh 1vw' , border:'solid 1px #eaeaea'}}>排放情境</p>
            <div className='display_report_block' style={{flex:'20', height:'5vh', padding:'1.8vh 4vw 1vh 3vw' , border:'solid 1px #eaeaea'}}>
              <Slider
                onChange={(event, value) => {changeCS(value);getClimateStressTestData(CSChoiceList[value], yearChoiceList[year], taxID)}}
                defaultValue={0}
                aria-labelledby="discrete-slider"
                valueLabelDisplay="off"
                marks={CSChoice}
                step={1}
                min={0}
                max={1}
              />
            </div>
          </div>
          <div style={{display:'grid',width:'fit-content', height:'fit-content' ,border:'solid 2px #eaeaea' }}>
            <div style={{display:'inline-flex'}}>
              <div className='display_report_block'>風險屬性</div>
              <div className='display_report_block'>項目</div>
              <div className='display_report_block' style={{width:'6vw'}}>數值</div>
              <div className='display_report_block' style={{width:'14vw'}}>說明與單位</div>
              <div className='display_report_block' style={{width:'14vw'}}>衝擊層面</div>
              <div className='display_report_block'>C-VaR</div>
              <div className='display_report_block' style={{width:'6vw'}}>屬性等級</div>
              <div className='display_report_block'>總體風險等級</div>
            </div>
            <div style={{display:'inline-flex'}}>
              <div style={{display:'grid'}}>
                <div className='display_report_block' style={{height:'16vh', padding:'3px 0.5vw'}}>實體風險</div>
                <div className='display_report_block' style={{height:'8vh', padding:'1px 0.5vw'}}>轉型風險</div>
                <div className='display_report_block' style={{height:'12vh', padding:'2px 0.5vw'}}>揭露程度</div>
              </div>
              <div style={{display:'grid'}}>
                <div className='display_report_block'>淹水</div>
                <div className='display_report_block'>乾旱</div>
                <div className='display_report_block'>極端高溫</div>
                <div className='display_report_block'>極端低溫</div>
                <div className='display_report_block'>市場</div>
                <div className='display_report_block'>政策法規</div>
                <div className='display_report_block'>CSR報告出版</div>
                <div className='display_report_block'>CSR報告確信</div>
                <div className='display_report_block'>CDP填報</div>
              </div>
              <div style={{display:'grid'}}>
                <div className='display_report_block' style={{width:'6vw'}}>{`${data.number[0]}%`}</div>
                <div className='display_report_block' style={{width:'6vw'}}>{data.number[1]}</div>
                <div className='display_report_block' style={{width:'6vw'}}>{data.number[2]}</div>
                <div className='display_report_block' style={{width:'6vw'}}>{data.number[3]}</div>
                <div className='display_report_block' style={{width:'6vw'}}>{data.number[4]}</div>
                <div className='display_report_block' style={{width:'6vw'}}>{data.number[5]}</div>
                <div className='display_report_block' style={{width:'6vw'}}>{data.number[6]?'V':''}</div>
                <div className='display_report_block' style={{width:'6vw'}}>{data.number[7]?'V':''}</div>
                <div className='display_report_block' style={{width:'6vw'}}>{data.number[8]?'V':''}</div>
              </div>
              <div style={{display:'grid'}}>
                <div className='display_report_block' style={{width:'14vw'}}>每年淹水機率</div>
                <div className='display_report_block' style={{width:'14vw'}}>每年嚴重乾旱日數</div>
                <div className='display_report_block' style={{width:'14vw'}}>每年極端高溫日數</div>
                <div className='display_report_block' style={{width:'14vw'}}>每年極端低溫日數</div>
                <div className='display_report_block' style={{width:'14vw'}}>排放強度(噸/千元)</div>
                <div className='display_report_block' style={{width:'14vw'}}>排放量(公噸)</div>
                <div className='display_report_block' style={{width:'14vw'}}></div>
                <div className='display_report_block' style={{width:'14vw'}}></div>
                <div className='display_report_block' style={{width:'14vw'}}></div>
              </div>
              <div style={{display:'grid'}}>
                <div className='display_report_block' style={{width:'14vw'}}>資產耗損(元)</div>
                <div className='display_report_block' style={{width:'14vw'}}>營收減少與成本增加(元)</div>
                <div className='display_report_block' style={{width:'14vw'}}>成本增加(元)</div>
                <div className='display_report_block' style={{width:'14vw'}}></div>
                <div className='display_report_block' style={{width:'14vw'}}>營收減少(元)</div>
                <div className='display_report_block' style={{width:'14vw'}}>成本增加(元)</div>
                <div className='display_report_block' style={{width:'14vw'}}></div>
                <div className='display_report_block' style={{width:'14vw'}}></div>
                <div className='display_report_block' style={{width:'14vw'}}></div>
              </div>
              <div style={{display:'grid'}}>
                <div className='display_report_block'>{data.CVaR[0]}</div>
                <div className='display_report_block'>{data.CVaR[1]}</div>
                <div className='display_report_block'>{data.CVaR[2]}</div>
                <div className='display_report_block'></div>
                <div className='display_report_block'>{data.CVaR[4]}</div>
                <div className='display_report_block'>{data.CVaR[5]}</div>
                <div className='display_report_block'></div>
                <div className='display_report_block'></div>
                <div className='display_report_block'></div>
              </div>
              <div style={{display:'grid'}}>
                <div className='display_report_block' style={{width:'6vw'}}>{levelList[data.level[0]]}</div>
                <div className='display_report_block' style={{width:'6vw'}}>{levelList[data.level[1]]}</div>
                <div className='display_report_block' style={{width:'6vw'}}>{levelList[data.level[2]]}</div>
                <div className='display_report_block' style={{width:'6vw'}}>{levelList[data.level[3]]}</div>
                <div className='display_report_block' style={{width:'6vw'}}>{levelList[data.level[4]]}</div>
                <div className='display_report_block' style={{width:'6vw'}}>{levelList[data.level[5]]}</div>
                <div className='display_report_block' style={{width:'6vw', height:'12vh', padding:'2px 0.5vw'}}><p style={{fontSize:'6vh', margin:'0.5vh 0.5vw 0 1.5vw;', width:'4vw'}}>{grade[data.AllLevel[1]]}</p></div>
              </div>
              <div style={{display:'grid'}}>
                <div className='display_report_block' style={{height:'24vh', padding:'5px 0.5vw'}}>{levelList[data.AllLevel[0]]}</div>
                <div className='display_report_block' style={{height:'12vh', padding:'2px 0.5vw'}}>{`${dataConfidenceList[data.AllLevel[1]]}(位於同業${data.AllLevel[2]}百分位)`}</div>
              </div>
            </div>
          </div>
          {/* <p>揭露程度等級說明</p> */}
          {/* <div style={{border:'solid 3px #eaeaea', marginTop:'3.3vh', padding:'2vh'}}>
            <p className='display_report_description' >{data.report[0]}</p>
            <p className='display_report_description' >{data.report[1]}</p>
            <p className='display_report_description' >{data.report[2]}</p>
            <p className='display_report_description' >{data.report[3]}</p>
          </div>  */}
          <p style={{margin: '0px 14vw',fontSize:'4vh', fontWeight: 'bold', position: 'relative', top: '10vh'}}>{`${data.industry}產業碳排放比較圖`}</p>
          <VictoryChart
            domainPadding={20}
          >
            <VictoryAxis
              // tickValues specifies both the number of ticks and where
              // they are placed on the axis
              //tickValues={['0', '10','20', '30', '40', '50']}
              //tickFormat={props.data.Chart.X}
              label='排放量（公噸）'
              style={{
                axis: {stroke: "#6a6a6a"},
                axisLabel: {fontSize: 10 , fill:'#6a6a6a'},
                ticks: {stroke: "#6a6a6a", size: 5},
                tickLabels: {fontSize: 6, padding: 5, fill:'#6a6a6a'}
              }}
            />
            <VictoryAxis
              dependentAxis
              // tickFormat specifies how ticks should be displayed
              //tickValues={['0', '0.05', '0.1', '0.15', '0.2']}
              //tickFormat={props.data.Chart.Y}
              label='排放強度（噸/千元）'
              style={{
                axis: {stroke: "#797979"},
                axisLabel: {fontSize: 10, padding: 25, fill:"#797979"},
                ticks: {stroke: "#797979", size: 1},
                tickLabels: {fontSize: 8, padding: 5, fill:"#797979"}
              }}
            />
            <VictoryScatter
              style={{ data: { fill: "#679b5a" } }}
              size={2}
              data={ChartData}
            />
            <VictoryScatter
              style={{ data: { fill: "red" } }}
              size={2}
              data={[{'x':data.number[5],'y':data.number[4]}]}
            />
            {/* <VictoryBar
              style={{
                data: { fill: "#679b5a" },
                // parent: { border: "1px solid #ccc"}
              }}
              // alignment='start'
              data={Object
                      .entries(props.data.harm)
                      .map(([key, value]) => {
                        return({'x':key, 'y':value[0]})
                      })}
              // labels={({ datum }) => `y: ${datum.y}`}
            /> */}
            
          </VictoryChart>

        </div>
      </div>
    );
  }
  
}

export default DisplayReportClimateTest;