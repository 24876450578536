import React, { useState, useEffect } from 'react';
import Menu from './Menu';
import { VictoryPie, VictoryAnimation, VictoryLabel } from 'victory';
import { Line } from 'rc-progress';

function getData(data) {
  const percent = Math.round(data * 100) / 100;
  return [{ x: 1, y: percent }, { x: 2, y: 100 - percent }];
}

function BoardReport(props) {
  const {
    account,
    permission,
    cookies,
    removeCookie,
    activeScreen,
    switchScreen,
    // switchToBoardReport,
    shellChoice,
    switchShellChoice,
    data,
  } = props

  const [greenIndustryhovering, changeGreenIndustryHovering] = useState([false, false])

  useEffect(() => {
    // if (Object.values(data).length === 0) {
    //   switchToBoardReport();
    // }
  })

  if (data.time !== undefined) {
    return (
      <div className="new_home_mainContainer">
        {/* <div className="new_home_mainContainer" style={{backgroundImage:`url(${require("../img/ascelleia_main.png")})`, backgroundSize:"100vw 100vh"}}> */}
        {/* <img src={require("../img/ascelleia_main.jpeg")} style={{opacity:"0.5", width:"100vw", height:"100vh"}} /> */}
        <div className="page_BoardReport_container">
          <div className="page_title_container" style={{ width: "73vw" }}><p className="page_title_text">企業董事會報告</p></div>
          <div style={{ display: 'inline-flex', flexDirection: "row", width: "77vw", height: "33.2vh", marginBottom: '2vh' }}>
            <div className="BoardReport_block" style={{ flexDirection: "column", width: "11.1vw", height: "33.2vh", marginRight: "0.8vw" }}>
              <p className="BoardReport_block_title_text" style={{}}>日期/季度</p>
              <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
              <p className='BoardReport_block_date_year' >{data.time[0]}</p>
              <p className='BoardReport_block_date_period' >{data.time[1]}</p>
            </div>
            <div className="BoardReport_block" style={{ flexDirection: "column", width: "67.1vw", height: "33.2vh" }}>
              <p className="BoardReport_block_title_text" style={{}}>金控承諾與執行進度</p>
              <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
              <div style={{ display: 'inline-flex', marginTop: '1.5vh' }}>
                <div style={{ width: '48vw', display: 'inline-grid', height: 'fit-content' }}>
                  {
                    Object
                      .entries(data.promise)
                      .map(([key, value]) =>
                        <div key={`promise_${key}`} style={{ margin: '1vh 0 0 1.92vw' }}>
                          <p className='BoardReport_block_promise_year'>{`${key}年`}</p>
                          <p className='BoardReport_block_promise_content'>{value}</p>
                        </div>
                      )
                  }
                </div>
                <div style={{}}>
                  <div className="DisplayCanvas_PieChart_container">
                    <svg viewBox="40 40 320 320" width="20vh" height="20vh" >
                      <VictoryPie
                        startAngle={90}
                        endAngle={450}
                        standalone={false}
                        animate={{ duration: 1000 }}
                        width={400} height={400}
                        data={getData(Number(data.progressRate))}
                        innerRadius={120}
                        cornerRadius={25}
                        labels={() => null}
                        style={{
                          data: {
                            fill: ({ datum }) => {
                              const color = datum.y > 30 ? '#353d6a' : 'red';
                              return datum.x === 1 ? color : '#f7f7f7';
                            },
                          },
                        }}
                      />
                      <VictoryAnimation duration={1000} data={getData(Number(data.progressRate))}>
                        {() => {
                          return (
                            <VictoryLabel
                              textAnchor="middle" verticalAnchor="middle"
                              x={200} y={170}
                              text={`${Number(data.progressRate)}%`}
                              className='BoardReport_block_progressRate_number'
                              style={{ fontSize: 80, fill: '#939393', }}
                            />
                          );
                        }}
                      </VictoryAnimation>
                    </svg>
                    <p className='BoardReport_block_progressRate_status'>符合進度</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: 'inline-flex', flexDirection: "row", width: "77vw", height: "29.7vh", marginBottom: '2.26vh' }}>
            <div className="BoardReport_block" style={{ flexDirection: "column", width: "35.6vw", height: "29.7vh", marginRight: "0.8vw" }}>
              <p className="BoardReport_block_title_text" style={{}}>不動產業務之氣候風險現況</p>
              <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
              <div style={{ display: 'inline-flex' }}>
                <p className='BoardReport_block_subtitle_text' style={{ position: 'relative', left: '1.92vw' }}>業務</p>
                <p className='BoardReport_block_subtitle_text' style={{ position: 'relative', left: '9vw' }}>暴險程度</p>
                <p className='BoardReport_block_subtitle_text' style={{ position: 'relative', left: '13vw' }}>暴險金額</p>
                <p className='BoardReport_block_subtitle_text' style={{ position: 'relative', left: '16vw' }}>佔業務比重</p>
              </div>
              <div className='BoardReport_realEstate_item'>
                <p className='BoardReport_block_item_name' style={{ position: 'relative', left: '1.92vw' }}>{data.realEstate[1][0]}</p>
                <p className='BoardReport_block_item_name' style={{ position: 'relative', left: '6.7vw' }}>{data.realEstate[1][1]}</p>
                <p className='BoardReport_block_item_name' style={{ position: 'relative', left: '14.2vw' }}>{`$ ${data.realEstate[1][2]}`}</p>
                <p className='BoardReport_block_item_name' style={{ position: 'relative', left: '17.1vw' }}>{data.realEstate[1][3]}</p>
              </div>
              <div className='BoardReport_realEstate_item'>
                <p className='BoardReport_block_item_name' style={{ position: 'relative', left: '1.92vw' }}>{data.realEstate[2][0]}</p>
                <p className='BoardReport_block_item_name' style={{ position: 'relative', left: '5.5vw' }}>{data.realEstate[2][1]}</p>
                <p className='BoardReport_block_item_name' style={{ position: 'relative', left: '13vw' }}>{`$ ${data.realEstate[2][2]}`}</p>
                <p className='BoardReport_block_item_name' style={{ position: 'relative', left: '16.7vw' }}>{data.realEstate[2][3]}</p>
              </div>
            </div>
            <div className="BoardReport_block" style={{ flexDirection: "column", width: "42.6vw", height: "29.7vh" }}>
              <p className="BoardReport_block_title_text" style={{}}>綠能產業授信暨投資目標與現況</p>
              <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
              <div style={{ display: 'inline-flex' }}>
                <p className='BoardReport_block_subtitle_text' style={{ position: 'relative', left: '1.92vw' }}>業務</p>
                <p className='BoardReport_block_subtitle_text' style={{ position: 'relative', left: '8vw' }}>進度</p>
                <p className='BoardReport_block_subtitle_text' style={{ position: 'relative', left: '31vw' }}>達成百分比</p>
              </div>
              <div className='BoardReport_block_greenIndustry_item' onMouseOut={() => { changeGreenIndustryHovering([false, false]) }} onMouseOver={() => { changeGreenIndustryHovering([true, false]) }}>
                <p className='BoardReport_block_subtitle_text' style={{ position: 'relative', left: '1.92vw' }}>綠能授信</p>
                <div style={{ display: 'inline-flex', position: 'relative', left: '5.8vw', marginTop: '0.6vh' }}>
                  <p className="BoardReport_block_greenIndustry_proportion">0%</p>
                  <Line className="BoardReport_block_greenIndustry_Line" percent={Number(data.greenIndustry['綠能授信'])} trailWidth="2" trailColor='#f7f7f7' strokeWidth="2" strokeColor={greenIndustryhovering[0] ? '#f1cb02' : "#679b5a"} />
                  <p className="BoardReport_block_greenIndustry_proportion">100%</p>
                </div>
                <p className='BoardReport_block_greenIndustry_value' style={{ position: 'relative', left: '8vw' }}>{`${data.greenIndustry['綠能授信']}%`}</p>
              </div>
              <div className='BoardReport_block_greenIndustry_item' onMouseOut={() => { changeGreenIndustryHovering([false, false]) }} onMouseOver={() => { changeGreenIndustryHovering([false, true]) }}>
                <p className='BoardReport_block_subtitle_text' style={{ position: 'relative', left: '1.92vw' }}>綠能投資</p>
                <div style={{ display: 'inline-flex', position: 'relative', left: '5.8vw', marginTop: '0.6vh' }}>
                  <p className="BoardReport_block_greenIndustry_proportion">0%</p>
                  <Line className="BoardReport_block_greenIndustry_Line" percent={Number(data.greenIndustry['綠能投資'])} trailWidth="2" trailColor="#f7f7f7" strokeWidth="2" strokeColor={greenIndustryhovering[1] ? '#f1cb02' : "#679b5a"} />
                  <p className="BoardReport_block_greenIndustry_proportion">100%</p>
                </div>
                <p className='BoardReport_block_greenIndustry_value' style={{ position: 'relative', left: '8vw' }}>{`${data.greenIndustry['綠能投資']}%`}</p>
              </div>
            </div>
          </div>
          <div style={{ display: 'inline-flex', flexDirection: "row", width: "77vw", height: "50.8vh", marginBottom: '3.1vh' }}>
            <div className="BoardReport_block" style={{ flexDirection: "column", width: "48.9vw", height: "50.8vh", marginRight: "1.3vw" }}>
              <p className="BoardReport_block_title_text">高度氣候風險之投資限額與現況</p>
              <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
              <div style={{ display: 'inline-flex' }}>
                <p className='BoardReport_block_subtitle_text' style={{ position: 'relative', left: '1.92vw' }}>業務</p>
                <p className='BoardReport_block_subtitle_text' style={{ position: 'relative', left: '9.3vw' }}>投資限制</p>
                <p className='BoardReport_block_subtitle_text' style={{ position: 'relative', left: '33vw' }}>剩餘減量目標</p>
              </div>
              <div style={{ display: 'inline-flex', height: '10vh' }}>
                <p className='BoardReport_block_subtitle_text' style={{ position: 'relative', left: '1.92vw', margin: '3.5vh 0 0 0' }}>石油煉製業</p>
                <div style={{ position: 'relative', left: '6vw', paddingTop: '1.5vh', width: '5vw' }}>
                  <p className='BoardReport_block_highClimateRiskIndustry_Line_index'>基期</p>
                  <p className='BoardReport_block_highClimateRiskIndustry_Line_index'>目標年</p>
                </div>
                <div style={{ position: 'relative', left: '5vw', top: '2.3vh', width: '17.7vw' }}>
                  <Line className="BoardReport_block_highClimateRiskIndustry_Line" percent={100} strokeLinecap='square' trailColor='transparent' strokeWidth="9" strokeColor='rgba(241,203,2,0.6)' />
                  <Line className="BoardReport_block_highClimateRiskIndustry_Line" percent={Number(data.highClimateRiskIndustry['石油煉製業'])} trailColor='transparent' strokeLinecap='square' strokeWidth="9" strokeColor='#f1cb02' />
                  <div style={{ position: 'relative', left: '10.8vw', bottom: '2.7vh' }}>
                    <i className="arrow left" style={{ position: 'absolute', top: '6.6vh', right: '17.3vw' }}></i>
                    <div style={{ width: '7vw', height: "0", borderTop: "solid 2px #979797", position: 'absolute', top: '6.9vh' }}></div>
                    <i className="arrow right" style={{ position: 'absolute', top: '6.6vh', right: '10.6vw' }}></i>
                    <p className='BoardReport_block_highClimateRiskIndustry_Line_text'>{`減少${100 - Number(data.highClimateRiskIndustry['石油煉製業'])}%`}</p>
                  </div>
                </div>
                <p className='BoardReport_block_greenIndustry_value' style={{ position: 'relative', left: '11.5vw', top: '2vh', color: '#f1cb02' }}>{`${data.highClimateRiskIndustry['石油煉製業']}%`}</p>
              </div>
              <div style={{ display: 'inline-flex', height: '10vh' }}>
                <p className='BoardReport_block_subtitle_text' style={{ position: 'relative', left: '1.92vw', margin: '3.5vh 0 0 0' }}>水泥業</p>
                <div style={{ position: 'relative', left: '8.3vw', paddingTop: '1.5vh', width: '5vw' }}>
                  <p className='BoardReport_block_highClimateRiskIndustry_Line_index'>基期</p>
                  <p className='BoardReport_block_highClimateRiskIndustry_Line_index'>目標年</p>
                </div>
                <div style={{ position: 'relative', left: '7.2vw', top: '2.3vh', width: '17.7vw' }}>
                  <Line className="BoardReport_block_highClimateRiskIndustry_Line" percent={100} strokeLinecap='square' trailColor='transparent' strokeWidth="9" strokeColor='rgba(103,155,90,0.6)' />
                  <Line className="BoardReport_block_highClimateRiskIndustry_Line" percent={Number(data.highClimateRiskIndustry['水泥業'])} trailColor='transparent' strokeLinecap='square' strokeWidth="9" strokeColor='#679b5a' />
                  <div style={{ position: 'relative', left: '5.8vw', bottom: '2.7vh' }}>
                    <i className="arrow left" style={{ position: 'absolute', top: '6.6vh', right: '17.3vw' }}></i>
                    <div style={{ width: '12vw', height: "0", borderTop: "solid 2px #979797", position: 'absolute', top: '6.9vh' }}></div>
                    <i className="arrow right" style={{ position: 'absolute', top: '6.6vh', right: '5.6vw' }}></i>                      <p className='BoardReport_block_highClimateRiskIndustry_Line_text' style={{ left: '12.4vw' }}>{`減少${100 - Number(data.highClimateRiskIndustry['水泥業'])}%`}</p>
                  </div>
                </div>
                <p className='BoardReport_block_greenIndustry_value' style={{ position: 'relative', left: '13.7vw', top: '2vh', color: '#679b5a' }}>{`${data.highClimateRiskIndustry['水泥業']}%`}</p>
              </div>
              <div style={{ display: 'inline-flex', height: '10vh' }}>
                <p className='BoardReport_block_subtitle_text' style={{ position: 'relative', left: '1.92vw', margin: '3.5vh 0 0 0' }}>鋼鐵業</p>
                <div style={{ position: 'relative', left: '8.3vw', paddingTop: '1.5vh', width: '5vw' }}>
                  <p className='BoardReport_block_highClimateRiskIndustry_Line_index'>基期</p>
                  <p className='BoardReport_block_highClimateRiskIndustry_Line_index'>目標年</p>
                </div>
                <div style={{ position: 'relative', left: '7.2vw', top: '2.3vh', width: '17.7vw' }}>
                  <Line className="BoardReport_block_highClimateRiskIndustry_Line" percent={100} strokeLinecap='square' trailColor='transparent' strokeWidth="9" strokeColor='rgba(232,106,34,0.6)' />
                  <Line className="BoardReport_block_highClimateRiskIndustry_Line" percent={Number(data.highClimateRiskIndustry['鋼鐵業'])} trailColor='transparent' strokeLinecap='square' strokeWidth="9" strokeColor='#e86a22' />
                  <div style={{ position: 'relative', left: '6.8vw', bottom: '2.7vh' }}>
                    <i className="arrow left" style={{ position: 'absolute', top: '6.6vh', right: '17.3vw' }}></i>
                    <div style={{ width: '11vw', height: "0", borderTop: "solid 2px #979797", position: 'absolute', top: '6.9vh' }}></div>
                    <i className="arrow right" style={{ position: 'absolute', top: '6.6vh', right: '6.6vw' }}></i>
                    <p className='BoardReport_block_highClimateRiskIndustry_Line_text' style={{ left: '11.4vw' }}>{`減少${100 - Number(data.highClimateRiskIndustry['鋼鐵業'])}%`}</p>
                  </div>
                </div>
                <p className='BoardReport_block_greenIndustry_value' style={{ position: 'relative', left: '13.8vw', top: '2vh', color: '#e86a22' }}>{`${data.highClimateRiskIndustry['鋼鐵業']}%`}</p>
              </div>
              <div style={{ margin: '0 8.24vw 1.7vh 1.8vw', height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
              <div style={{ display: 'inline-flex', marginLeft: '1.92vw' }}>
                <img style={{ width: '1.86vh', height: '1.86vh', objectFit: 'contain', marginTop: '0.25vh' }} src={require('../img/icon-info-14.svg')} alt='alertion' />
                <p className='BoardReport_highClimateRiskIndustry_alert_text'>企業每單位營收產生之排放量高於該產業平均值時納入統計量</p>
              </div>
            </div>
            <div className="BoardReport_block" style={{ flexDirection: "column", width: "28.9vw", height: "50.8vh" }}>
              <p className="BoardReport_block_title_text" style={{}}>金控碳排放總量及平均營收碳排放量</p>
              <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
              <div className='BoardReport_carbonEmission_item'>
                <p className='BoardReport_carbonEmission_item_name' style={{ position: 'relative', left: '1.92vw' }}>範疇一</p>
                <p className='BoardReport_carbonEmission_item_value' style={{ position: 'relative', left: '3vw' }}>總量：</p>
                <p className='BoardReport_carbonEmission_item_value' style={{ position: 'relative', left: '4.7vw' }}>{data.carbonEmission['範疇一']}</p>
              </div>
              <div className='BoardReport_carbonEmission_item'>
                <p className='BoardReport_carbonEmission_item_name' style={{ position: 'relative', left: '1.92vw' }}>範疇二</p>
                <p className='BoardReport_carbonEmission_item_value' style={{ position: 'relative', left: '3vw' }}>總量：</p>
                <p className='BoardReport_carbonEmission_item_value' style={{ position: 'relative', left: '4.7vw' }}>{data.carbonEmission['範疇二']}</p>
              </div>
              <div className='BoardReport_carbonEmission_item'>
                <p className='BoardReport_carbonEmission_item_name' style={{ position: 'relative', left: '1.92vw' }}>範疇三</p>
                <p className='BoardReport_carbonEmission_item_value' style={{ position: 'relative', left: '3vw' }}>總量：</p>
                <p className='BoardReport_carbonEmission_item_value' style={{ position: 'relative', left: '4.7vw' }}>{data.carbonEmission['範疇三']}</p>
              </div>
              <div className='BoardReport_carbonEmission_item'>
                <p className='BoardReport_carbonEmission_item_name' style={{ position: 'relative', left: '1.92vw' }}>排放強度</p>
                <p className='BoardReport_carbonEmission_item_value' style={{ position: 'relative', left: '2vw' }}>{data.carbonEmission['排放強度']}</p>
              </div>
            </div>
          </div>
        </div>
        <Menu
          account={account}
          permission={permission}
          activeScreen={activeScreen}
          switchScreen={switchScreen}
          shellChoice={shellChoice}
          switchShellChoice={switchShellChoice}
        />
      </div>
    );
  } else {
    return (
      <div className="new_home_mainContainer">
        {/* <div className="new_home_mainContainer" style={{backgroundImage:`url(${require("../img/ascelleia_main.png")})`, backgroundSize:"100vw 100vh"}}> */}
        {/* <img src={require("../img/ascelleia_main.jpeg")} style={{opacity:"0.5", width:"100vw", height:"100vh"}} /> */}
        <div className="page_BoardReport_container">
          <div className="page_title_container" style={{ width: "73vw" }}><p className="page_title_text">企業董事會報告</p></div>
          <div style={{ display: 'inline-flex', flexDirection: "row", width: "77vw", height: "33.2vh", marginBottom: '2vh' }}>
            <div className="BoardReport_block" style={{ flexDirection: "column", width: "11.1vw", height: "33.2vh", marginRight: "0.8vw" }}>
              <p className="BoardReport_block_title_text" style={{}}>日期/季度</p>
              <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
              <p className='BoardReport_block_date_year' ></p>
              <p className='BoardReport_block_date_period' ></p>
            </div>
            <div className="BoardReport_block" style={{ flexDirection: "column", width: "67.1vw", height: "33.2vh" }}>
              <p className="BoardReport_block_title_text" style={{}}>金控承諾與執行進度</p>
              <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
            </div>
          </div>
          <div style={{ display: 'inline-flex', flexDirection: "row", width: "77vw", height: "29.7vh", marginBottom: '2.26vh' }}>
            <div className="BoardReport_block" style={{ flexDirection: "column", width: "35.6vw", height: "29.7vh", marginRight: "0.8vw" }}>
              <p className="BoardReport_block_title_text" style={{}}>不動產業務之氣候風險現況</p>
              <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
            </div>
            <div className="BoardReport_block" style={{ flexDirection: "column", width: "42.6vw", height: "29.7vh" }}>
              <p className="BoardReport_block_title_text" style={{}}>綠能產業授信暨投資目標與現況</p>
              <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
            </div>
          </div>
          <div style={{ display: 'inline-flex', flexDirection: "row", width: "77vw", height: "50.8vh", marginBottom: '3.1vh' }}>
            <div className="BoardReport_block" style={{ flexDirection: "column", width: "48.9vw", height: "50.8vh", marginRight: "1.3vw" }}>
              <p className="BoardReport_block_title_text" style={{}}>高度氣候風險之投資限額與現況</p>
              <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
            </div>
            <div className="BoardReport_block" style={{ flexDirection: "column", width: "28.9vw", height: "50.8vh" }}>
              <p className="BoardReport_block_title_text" style={{}}>金控碳排放總量及平均營收碳排放量</p>
              <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
            </div>
          </div>
        </div>
        <Menu
          account={account}
          permission={permission}
          cookies={cookies}
          removeCookie={removeCookie}
          activeScreen={activeScreen}
          switchScreen={switchScreen}
          shellChoice={shellChoice}
          switchShellChoice={switchShellChoice}
        />
      </div>
    );
  }

}

export default BoardReport;