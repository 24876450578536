import React, { useState } from 'react';
const hoveringImg = require("../img/../img/icon-close-3.svg");
const unhoveringImg = require("../img/../img/icon-close-2.svg");



function DatalistItem(props) {
  const [hovering, changeHovering] = useState(false)

  return (
    <div className="datalistItem_container" onMouseOver={() => {changeHovering(true)}} onMouseOut={() => {changeHovering(false)}}>
      <p className="datalistItem_text">{props.value}</p>
      <img className="datalistItem_delete_button" src={hovering===true?hoveringImg:unhoveringImg} alt="deleteData" />
    </div>
  );
}

export default DatalistItem;