import React, { useState, useEffect, useCallback } from 'react';
import "../style/style.css";
import Menu from './Menu';
import Display from '../component/Display';
import AppConfig from '../const/AppConfig.const';
import Fade from '@material-ui/core/Fade';
import Process from '../component/Process';
import Datalist from '../component/Datalist';
import ConditionItem from '../component/ConditionItem';

function Home(props) {
  const {
    activeScreen,
    switchScreen,
    account,
    permission,
    cookies,
    removeCookie,
    switchShellChoice,
    getProcessData_list,
    changeDisplayMethod,
    getProcessList,
    CSChoice, yearChoice,
    processData_value,
    switchYearChoice,
    switchCSChoice,
    changeProcessList,
    changeProcessData,
    changeProcessOrder,
    changeProcessData_value,
    changeProcessData_list,
    shellChoice,
    displayMethod,
    switchDisplayMethod,
    processData_list,
    processList,
    processData,
    processOrder,
    shellChoice_list,
    CSChoice_list,
    yearChoice_list
  } = props
  const [subMenuNow, changeSubMenuNow] = useState('None')
  const [displayData, changeDisplayData] = useState({})
  const [displayDataState, changeDisplayDataState] = useState('notReady') //ready, notReady

  const handleProcessData_value = (step, data) => {
    let newProcessData_value = processData_value;
    let keyOrder;
    const entries = Object.entries(processOrder);
    for (const [key, value] of entries) {
      if (value.indexOf(step) >= 0) {
        keyOrder = key;
      } else if (keyOrder !== undefined && key > keyOrder) {
        for (let i of value) {
          newProcessData_value[i] = "None";
        }
      }
    }
    newProcessData_value[step] = data;
    changeProcessData_value({ ...processData_value, ...newProcessData_value });
  }

  const onExecutionClick = useCallback((shell, CS, year) => {
    const entries = Object.values(processData_value)
    let numberOfData = 0
    for (const value of entries) {
      //console.log(value);
      if (value === "None" || value === undefined) {
        //alert(`還有${entries.length-numberOfData}個選項需要輸入資料`)
        break;
      } else { numberOfData += 1 }
    }
    if (numberOfData === entries.length && year !== 'None' && CS !== 'None' && shell !== 'None' && Object.values(processData_value).length !== 0 && displayDataState === 'notReady') {
      console.log(processData_value);
      fetch(`${AppConfig.SERVER_URL}/Execution`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          account: account.ID,
          shell: shell,
          CS,
          year,
          processData_value,
        }),
      })
        .then(response => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          changeDisplayData({ ...displayData, ...responseJson });
          changeDisplayDataState('Ready');
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [account.ID, displayData, displayDataState, processData_value]
  )

  useEffect(() => {
    //console.log(displayDataState);
    if (shellChoice !== 'None' && CSChoice !== 'None' && yearChoice !== 'None' && Object.values(processData_value).length !== 0) {
      if (Object.values(processData_value).indexOf('None') === -1 && displayDataState === 'notReady') {
        onExecutionClick(shellChoice, CSChoice, yearChoice);
      } else if (Object.values(displayData).length !== 0 && displayDataState === 'notReady') {
        console.log(displayData)
        changeDisplayData({});
      }
    }
  }, [shellChoice, CSChoice, yearChoice, processData_value, processData_list, displayData, displayDataState, onExecutionClick]);

  return (
    <div className="new_home_mainContainer">
      <div className="page_container">
        <div className="page_title_container"><p className="page_title_text">金融投資組合風險計算</p></div>
        <div style={{ display: "inline-flex", flexDirection: "row" }}>
          <div className="process_button_container" onClick={() => { changeSubMenuNow('process') }} >
            <img src={require("../img/icon-filter-b-32.svg")} className="iconfilter_B_32" alt="processSteps" />
            <p className="process_button_text">運算步驟</p>
          </div>
          <div className="dataupload_button_container" onClick={() => { changeSubMenuNow('datalist') }}>
            <img src={require("../img/icon-ic-01-18.svg")} className="iconic_01_18" alt="dataUpload" />
            <p className="dataupload_button_text">資料上傳</p>
          </div>
          <div className="condition_container">
            <p className="condition_text">條件列表</p>
            <div style={{ width: "0", height: "auto", borderRight: "solid 1px #979797" }}></div>
            <div className="condition_item_container">
              {
                shellChoice === 'None' ? '' :
                  <ConditionItem
                    key={`conditionItem_shell`}
                    index={'0'}
                    processData={{ '0': 'shell' }}
                    switchChoice={(choice) => {
                      changeDisplayData({});
                      switchShellChoice(choice);
                      changeDisplayMethod(choice);
                    }}
                    value={shellChoice}
                    processData_list={shellChoice_list}
                    changeDisplayDataState={changeDisplayDataState}
                    onExecutionClick={() => { onExecutionClick(shellChoice, CSChoice, yearChoice) }}
                  />
              }
              {
                CSChoice === 'None' ? '' :
                  <ConditionItem
                    key={`conditionItem_CS`}
                    index={'0'}
                    processData={{ '0': 'CS' }}
                    switchChoice={(CS) => {
                      changeDisplayData({});
                      switchCSChoice(CS);
                      getProcessList(yearChoice, CS, shellChoice, changeProcessList, changeProcessData, changeProcessOrder, processData_list, changeProcessData_list, changeProcessData_value)
                    }}
                    value={CSChoice}
                    processData_list={CSChoice_list}
                    changeDisplayDataState={changeDisplayDataState}
                    onExecutionClick={() => { onExecutionClick(shellChoice, CSChoice, yearChoice) }}
                  />
              }
              {
                yearChoice === 'None' ? '' :
                  <ConditionItem
                    key={`conditionItem_year`}
                    index={'0'}
                    processData={{ '0': 'year' }}
                    switchChoice={(year) => {
                      changeDisplayData({});
                      switchYearChoice(year);
                      getProcessList(year, CSChoice, shellChoice, changeProcessList, changeProcessData, changeProcessOrder, processData_list, changeProcessData_list, changeProcessData_value)
                    }}
                    value={yearChoice}
                    processData_list={yearChoice_list}
                    changeDisplayDataState={changeDisplayDataState}
                    onExecutionClick={() => { onExecutionClick(shellChoice, CSChoice, yearChoice) }}
                  />
              }
              {
                Object
                  .entries(props.processList)
                  .map(([index, name]) =>
                    <ConditionItem
                      key={`conditionItem_value_${index}`}
                      index={index}
                      name={name}
                      processData={props.processData}
                      processData_value={props.processData_value[index] === undefined ? "None" : props.processData_value[index]}
                      processData_list={props.processData_list[index] === undefined ? "" : props.processData_list[index]}
                      getProcessData_list={props.getProcessData_list}
                      changeProcessData_value={(index, data) => { handleProcessData_value(index, data) }}
                      onExecutionClick={() => { onExecutionClick(shellChoice, CSChoice, yearChoice) }}
                      changeDisplayData={changeDisplayData}
                      changeDisplayDataState={changeDisplayDataState}
                    />)
                // Object
                // .entries(processData_value)
                // .filter(([key, value]) => value!=='None')
                // .map(([index, name], idx) => 
                // <ConditionItem 
                //   key={`conditionItem_value_${idx}`} 
                //   name={name} 
                //   switchChoice={(choice) => {console.log(index, choice);changeProcessData_value(index, choice);getProcessData_list(index, choice)}} 
                //   value={processData_value[index]===undefined?"":processData_value[index]} 
                //   processData_list={processData_list[index]===undefined?"":processData_list[index]}
                // />)
              }
            </div>
          </div>
        </div>
        <Display
          shell={shellChoice}
          displayData={displayData}
          displayMethod={displayMethod}
          switchDisplayMethod={switchDisplayMethod}
          yearChoice={yearChoice}
          switchYearChoice={switchYearChoice}
          CSChoice={CSChoice}
          switchCSChoice={switchCSChoice}
        />
      </div>
      <Fade in={subMenuNow === 'None' ? false : true}>
        <div style={{ position: "relative", left: "15.36vw", width: "84.64vw", height: "100vh", backgroundColor: "rgba(255, 255, 255, 0.6)" }}>
          <div className="subMenu_container">
            {
              subMenuNow === 'process' ?
                <Process
                  account={account}
                  permission={permission}
                  changeSubMenuNow={changeSubMenuNow}
                  switchYearChoice={(year) => { switchYearChoice(year); getProcessList(year, CSChoice, shellChoice, changeProcessList, changeProcessData, changeProcessOrder, processData_list, changeProcessData_list, changeProcessData_value) }}
                  yearChoice={yearChoice}
                  CSChoice={CSChoice}
                  switchCSChoice={(CS) => { switchCSChoice(CS); getProcessList(yearChoice, CS, shellChoice, changeProcessList, changeProcessData, changeProcessOrder, processData_list, changeProcessData_list, changeProcessData_value) }}
                  shellChoice={shellChoice}
                  switchShellChoice={switchShellChoice}
                  processList={processList}
                  processData={processData}
                  processOrder={processOrder}
                  changeDisplayMethod={changeDisplayMethod}
                  processData_value={processData_value}
                  processData_list={processData_list}
                  getProcessData_list={getProcessData_list}
                  changeProcessData_value={(index, data) => { handleProcessData_value(index, data) }}
                  onExecutionClick={() => { onExecutionClick(shellChoice, CSChoice, yearChoice) }}
                  changeDisplayData={changeDisplayData}
                  changeDisplayDataState={changeDisplayDataState}
                /> :
                <Datalist
                  changeSubMenuNow={changeSubMenuNow}
                  processList={processList}
                  processData={processData}
                />
            }
          </div>
        </div>
      </Fade>
      <Menu
        account={account}
        permission={permission}
        cookies={cookies}
        removeCookie={removeCookie}
        activeScreen={activeScreen}
        switchScreen={switchScreen}
        shellChoice={shellChoice}
        switchShellChoice={switchShellChoice}
        changeDisplayMethod={changeDisplayMethod}
        changeSubMenuNow={changeSubMenuNow}
        changeDisplayData={changeDisplayData}
      />
    </div>
  );
}

export default Home;