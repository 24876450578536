import React, { useState, useEffect, useRef } from 'react';
import "../../style/style.css";
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '../Menu';
import GaugeChart from 'react-gauge-chart'
import LineChart from '../../component/LineChart/LineChart';
import AppConfigN from '../../const/AppConfigN.const';
import '../../style/CreatableTable.css';
import '../../style/PortfolioOptimization.css';

const linechart_legend_text = ['< 1.5°C', '1.5°C~2°C', '2°C~2.6°C', '2.6°C~3.2°C', '> 3.2°C']
const linechart_legend_color = ["#0a75ad", "#4fb39f", "#F0c51f", "#FF5823", "#b70000"]
const linechart_legend_label = ["A", 'B', "C", "D", "E"]
// const linechart_data_sample = [10, 5, 20, 55, 10]
// const linechart_data_sample_2 = [30, 5, 20, 45, 0]

let today = new Date()
let dd = String(today.getDate()).padStart(2, '0');
let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
let yyyy = today.getFullYear();

today = yyyy + mm + dd;

const useStyles = makeStyles((theme) => ({
    blockTitle: {
        margin: "0 0 0 0vw",
        fontFamily: "PingFangTC",
        fontSize: "2.1vh",
        fontWeight: "500",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        color: "#797979",
    },
    blockContent: {
        margin: "0vh 1vw 0vh 1vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        textAlign: "center",
    },
    formControl: {
        width: "100%",
    },
    link: {
        // fontSize: '1vh',
        textAlign: 'left',
        // margin: '1vh 0 0 1vw',
        textDecoration: "underline",
        width: 'fit-content',
        '&:hover': {
            cursor: "pointer",
        }
    },
    inputFile: {
        // margin: '0 0 2vh 0vw',
        width: "100%",
    },
    select: {
        height: "5vh",
        // width: "12vw",
        margin: '1.5vh 0vw 0vh 0vw',
        fontSize: '2.5vh',
    },
    sendInfoButton: {
        height: "4vh",
        fontSize: "2.1vh",
        padding: "5px 10px",
        margin: "0.5vh 1vw",
        // width: "95%",
        backgroundColor: '#ececec',
    }
}))

function PortfolioOptimization(props) {
    const bottomRef = useRef(null);

    const {
        account,
        permission,
        cookies,
        removeCookie,
        activeScreen,
        switchScreen,
        switchShellChoice,
        changeDisplayMethod
    } = props

    const classes = useStyles();

    const [ itrFile, setItrFile ] = useState();
    const [ pfFile, setPfFile ] = useState();
    const [ latest_itr, changeLatest_itr ] = useState("");
    const [ pfDataUsed, changePfDataUsed ] = useState("")
    const [ batchIdList, setBatchIdList ] = useState({});
    const [ batchID, setBatchID ] = useState("");
    const [ pfRsltBatchIdList, setPfRsltBatchIdList ] = useState({});
    const [ pfRsltBatchId, setPfRsltBatchId ] = useState("");
    const [ isClick, setIsClick ] = useState(false);
    const [ alertMsg_cal, changeAlertMsg_cal ] = useState("");
    const [ alertMsg_itr, changeAlertMsg_itr ] = useState("");
    const [ alertMsg_pf, changeAlertMsg_pf ] = useState("");
    const [ alertMsg_target, changeAlertMsg_target ] = useState("");

    const [ resultData, setResultData ] = useState({});
    const [ pfType_display, changePfType_display ] = useState("");
    const [ resultChartData_display, changeResultChartData_display ] = useState({})
    const [ ITR_target, changeITR_target ] = useState('');
    const [ ITR_target_result, changITR_target_result ] = useState({})

    const getLastestITRTime = () => {
        changeAlertMsg_itr("");
        changeAlertMsg_pf("");
        changeAlertMsg_cal("");
        let res = false;
        fetch(`${AppConfigN.SERVER_URL}/api/itr_itr_latest`, {
            method: 'GET',
            headers: {
                "Authorization": `Token ${account.token}`,
                "Content-Type": "application/json"
            },
        })
        .then((response) => {
            if (response.ok) {
                res = true;
            }
            return response.json()
        })
        .then((responseJson) => {
            // console.log(responseJson)
            if (res) {
                changeLatest_itr(responseJson["latest_time"].split('.')[0]);
            }
            else {
                changeAlertMsg_itr(responseJson.detail);
            }
        })
        .catch((error) => {
            console.error(error);
        });
    }

    const getDownloadData = (download_file) => {
        const file_request = {
            'itr_input': 'ITR_ITR_INPUT.xlsx',
            'pf_input': 'ITR_PF_INPUT.xlsx'
        }
        const file_name = {
            'itr_input': 'ITR減碳目標輸入檔',
            'pf_input': '投融資標的組合輸入檔'
        }
        fetch(`${AppConfigN.SERVER_URL}/api/itr_download`, {
            method: 'POST',
            headers: {
                "Authorization": `Token ${props.account.token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "filedownload": file_request[download_file]
            })
        })
        .then((response) => response.blob())
        .then((blob) => {
            // console.log(blob)
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${file_name[download_file]}.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        })
        .catch((error) => {
            console.error(error);
        });
    }
    
    const uploadData = (item) => {
        changeAlertMsg_itr("");
        changeAlertMsg_pf("");
        changeAlertMsg_cal("");
        setIsClick(true);

        const upload_api = {
            'tgt': 'itr_tgt_upload',
            'pf': 'itr_pf_upload'
        }
        let res = false;
        const formData = new FormData();
        if(item === 'tgt'){
            formData.append('fileUpload', itrFile);
            // console.log(itrFile)
        }else{
            formData.append('fileUpload', pfFile);
            // console.log(pfFile)
        }
        
        fetch(`${AppConfigN.SERVER_URL}/api/${upload_api[item]}/`, {
            method: 'POST',
            headers: {
                "Authorization": `Token ${props.account.token}`,
            },
            body: formData
        })
        .then((response) => {
            // console.log(response)
            if (response.ok) {
                res = true;
            }
            return response.json()
        })
        .then((responseJson) => {
            // console.log(responseJson)
            if (res) {
                if(item === 'tgt'){
                    getLastestITRTime()
                    changeAlertMsg_itr('上傳ITR減碳目標資料成功');
                    setIsClick(false);
                }else{
                    setResultData({})
                    let id = responseJson.batch_id;
                    // console.log(id)

                    function refreshBatch(ms){
                        return new Promise(resolve => {
                            setTimeout(() => {
                                // getPfBatchDataList(id);
                                // console.log(batchIdList)
                                // const item = batchIdList.filter((element) => (element.batch_id===id))[0]
                                // changePfDataUsed(item.created_time)
                            }, ms);
                            resolve();
                        })
                    }
                    
                    refreshBatch(0)
                    .then(() => {
                        getPfBatchIdList((List) => {
                        // console.log(List)
                        // const item = List.filter((element) => (element.batch_id===id))[0]
                        // changePfDataUsed(item.created_time)
                        // setIsClick(false);
                        getPfBatchDataList(id, List)
                        setIsClick(false);
                        changeAlertMsg_pf('上傳投融資標的組合資料成功');
                    })})
                    .catch((error) => {console.log(error)})
                    
                    // getPfBatchIdList();
                    // getPfBatchDataList(id);
                }
            }
            else {
                if(item === 'tgt'){
                    changeAlertMsg_itr(responseJson.detail);
                }else{
                    changeAlertMsg_pf(responseJson.detail);
                }
                setIsClick(false);
            }
        })
        .catch((error) => {
            console.error(error);
            setIsClick(false);
        });
    }

    const getPfBatchIdList = (callback) => {
        changeAlertMsg_itr("");
        changeAlertMsg_pf("");
        changeAlertMsg_cal("");
        let res = false;
        fetch(`${AppConfigN.SERVER_URL}/api/itr_pf_pcaf_batchid`, {
            method: 'GET',
            headers: {
                "Authorization": `Token ${account.token}`,
                "Content-Type": "application/json"
            },
        })
        .then((response) => {
            if (response.ok) {
                res = true;
            }
            return response.json()
        })
        .then((responseJson) => {
            // console.log(responseJson)
            if (res) {
                let option = responseJson.batch_list;
                setBatchIdList(option);
                callback(option)
            }
            else {
                changeAlertMsg_pf(responseJson.detail);
            }
        })
        .catch((error) => {
            console.error(error);
        });
    }

    const getPfBatchDataList = (batchID, List) => {
        changeAlertMsg_itr("");
        changeAlertMsg_pf("");
        changeAlertMsg_cal("");
        setResultData({})
        let id = "";
        let res = false;
        if (batchID === "New") {
            id = ""
        }
        else {
            id = batchID;
        }
        // console.log(id)
        if (id.length === 0) {
            changeAlertMsg_pf('請先選擇過去批次資料')
        }
        else {
            fetch(`${AppConfigN.SERVER_URL}/api/itr_pf_pcaf_batch`, {
                method: 'POST',
                headers: {
                    "Authorization": `Token ${account.token}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    batch_id: id
                })
            })
            .then((response) => {
                if (response.ok) {
                    res = true;
                }
                return response.json()
            })
            .then((responseJson) => {
                // console.log(responseJson);
                if (res) {
                    let item = batchIdList.filter((element) => (element.batch_id===id))[0]
                    if(item === undefined){
                        item = List.filter((element) => (element.batch_id===id))[0]
                    }
                    // console.log(item)
                    changePfDataUsed(item.created_time)
                }
                else {
                    changeAlertMsg_pf(responseJson.detail);
                }
            })
            .catch((error) => {
                console.error(error);
            });
        }
    }

    const getPfRsltBatchIdList_Opt = () => {
        changeAlertMsg_itr("");
        changeAlertMsg_pf("");
        changeAlertMsg_cal("");
        let res = false;
        fetch(`${AppConfigN.SERVER_URL}/api/itr_pf_rslt_tag_batchid`, {
            method: 'GET',
            headers: {
                "Authorization": `Token ${account.token}`,
                "Content-Type": "application/json"
            },
        })
        .then((response) => {
            if (response.ok) {
                res = true;
            }
            return response.json()
        })
        .then((responseJson) => {
            // console.log(responseJson)
            if (res) {
                let option = responseJson.batch_list;
                // console.log(option);
                setPfRsltBatchIdList(option);
            }
            else {
                changeAlertMsg_cal(responseJson.detail);
            }
        })
        .catch((error) => {
            console.error(error);
        });
    }

    const getPfItrRsltDataList_Opt = (batchID) => {
        changeAlertMsg_itr("");
        changeAlertMsg_pf("");
        changeAlertMsg_cal("");
        resetInput();
        let res = false;
        fetch(`${AppConfigN.SERVER_URL}/api/itr_pf_rslt_tag_batch`, {
            method: 'POST',
            headers: {
                "Authorization": `Token ${account.token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                batch_id: batchID
            })
        })
        .then((response) => {
            if (response.ok) {
                res = true;
            }
            return response.json()
        })
        .then((responseJson) => {
            // console.log(responseJson);
            if (res) {
                let data = responseJson;
                // const item = pfRsltBatchIdList.filter((element) => (element.batch_id===batchID))[0]
                setResultData(data);
                changePfType_display(Object.keys(data['itr_total'])[0])
                changeResultChartData_display(Object.values(data['itr_total'])[0]);
            }
            else {
                changeAlertMsg_cal(responseJson.detail);
            }
        })
        .catch((error) => {
            console.error(error);
        });
    }

    const getPfItrCalRslt_Opt = () => {
        changeAlertMsg_itr("");
        changeAlertMsg_pf("");
        changeAlertMsg_cal("");
        let res = false;
        if (pfDataUsed === "") {
            changeAlertMsg_cal('請先上傳或選擇投融資組合資料')
        }else if(latest_itr === "") {
            changeAlertMsg_cal('請先上傳ITR減碳目標資料')
        }else{
            const item = batchIdList.filter((element) => (element.created_time===pfDataUsed))[0]
            // console.log(item.batch_id)
            setIsClick(true);
            resetInput();
            fetch(`${AppConfigN.SERVER_URL}/api/itr_pf_cal_tag`, {
                method: 'POST',
                headers: {
                    "Authorization": `Token ${account.token}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    batch_id: item.batch_id
                })
            })
            .then((response) => {
                // console.log(response)
                if (response.ok) {
                    res = true;
                }
                return response.json()
            })
            .then((responseJson) => {
                if (res) {
                    getPfRsltBatchIdList_Opt()
                    setPfRsltBatchId(responseJson.batch_id)
                    getPfItrRsltDataList_Opt(responseJson.batch_id)
                }
                changeAlertMsg_cal(responseJson.detail);
                setIsClick(false);
            })
            .catch((error) => {
                console.error(error);
            });
        }
    }

    const getPfItrTarget_Opt = () => {
        let res = false;
        if(ITR_target !== ''){
            // console.log(ITR_target)
            fetch(`${AppConfigN.SERVER_URL}/api/itr_pf_tag_opt`, {
                method: 'POST',
                headers: {
                    "Authorization": `Token ${account.token}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    batch_id: pfRsltBatchId,
                    category: pfType_display,
                    target: ITR_target
                })
            })
            .then((response) => {
                if(response.ok){
                    res = true;
                }
                return response.json()
            })
            .then((responseJson) => {
                // console.log(responseJson);
                if(res){
                    changITR_target_result(responseJson)
                }
                changeAlertMsg_target(responseJson.detail)
            })
            .catch((error) => {
                console.error(error);
            });
        }
    }

    const downloadPfItrTarget_Opt = (target_id) => {
        fetch(`${AppConfigN.SERVER_URL}/api/itr_opt_batch_download`, {
            method: 'POST',
            headers: {
                "Authorization": `Token ${props.account.token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                target_id: target_id,
            })
        })
        .then((response) => response.blob())
        .then((blob) => {
            // console.log(blob)
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `投融資組合ITR目標調整建議_${today}.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        })
        .catch((error) => {
            console.error(error);
        });
    }

    const resetInput = () => {
        changITR_target_result({})
        changeITR_target('')
        changeResultChartData_display({})
        changePfType_display('')
        setResultData({})
    }

    useEffect(() => {
        getPfBatchIdList(() => {});
        getPfRsltBatchIdList_Opt();
        getLastestITRTime();
    }, [])

    useEffect(() => {
        if(bottomRef.current !== null){
            bottomRef.current.scrollIntoView({behavior: 'smooth'});
        }
    }, [resultData, resultChartData_display, ITR_target_result])

    return ( 
        <div className="new_home_mainContainer">
            <div className="page_container">
                <div className="page_title_container"><p className="page_title_text">投融資組合最佳化配置</p></div>
                <div className="page_subtitle_container"><p className="page_subtitle_text">上傳ITR減碳目標與投融資標的組合基本資料</p></div>              
                <div style={{ display: "inline-flex", flexWrap: 'wrap', justifyContent: "space-between", width: 'calc(100% + 10px)' }}>
                    <div className="DataDownload_block" style={{ flexDirection: "column", minWidth: "300px", flex:3, margin: '0 10px 10px 0' }}>
                        <p className="DataDownload_block_title_text">ITR減碳目標基本資料</p>
                        <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
                        <div style={{ display: 'flex', margin: "1vh 1vw 0 1vw" }}>
                            <div style={{ width: "96%", margin: "0 3% 0 1%" }}><p className={classes.blockTitle}>上傳資料 (<a className={classes.link} href={'/#'} onClick={() => getDownloadData('itr_input')}>下載範例檔案</a>)</p></div>
                        </div>
                        <div className={classes.blockContent}>
                            <div style={{ width: "96%", margin: "0 3% 0 1%" }}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <input
                                        className={classes.inputFile}
                                        style={{ marginTop: "0.8vh" }}
                                        type="file"
                                        name="file"
                                        onChange={(event) => {
                                            setItrFile(event.target.files[0]);
                                        }} />
                                </FormControl>
                            </div>
                        </div>
                        <div style={{ display: 'flex', margin: "1vh 1vw 1vh 1vw", textAlign: "center" }}>
                            <div style={{ width: "96%", margin: "0 3% 0 1%" }}>
                                <Button variant="outlined" className={classes.sendInfoButton} onClick={() => {changeAlertMsg_cal('');uploadData('tgt')}}>上傳</Button>
                            </div>
                        </div>
                        {
                            alertMsg_itr === '' ? '' :
                            <div style={{ display: 'flex', margin: "2vh 2vw", textAlign: "left" }}>
                                <p style={{fontSize: '2.1vh', color: 'red', margin: '0'}}>{alertMsg_itr}</p>
                            </div>
                        }
                    </div>
                    <div className="DataDownload_block" style={{ flexDirection: "column", minWidth: "490px", flex: 5, margin: '0 10px 10px 0' }}>
                        <p className="DataDownload_block_title_text">投融資標的組合基本資料</p>
                        <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
                        <div style={{ display: 'flex', margin: "1vh 1vw 0 1vw" }}>
                            <div style={{ width: "56%", margin: "0 3% 0 1%" }}><p className={classes.blockTitle}>上傳資料 (<a className={classes.link} href={'/#'} onClick={() => getDownloadData('pf_input')}>下載範例檔案</a>)</p></div>
                            <div style={{ width: "40%", margin: "0 0 0 0%" }}><p className={classes.blockTitle}>查看過去資料</p></div>
                        </div>
                        <div className={classes.blockContent}>
                            <div style={{ width: "56%", margin: "0 3% 0 1%" }}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <input
                                        className={classes.inputFile}
                                        style={{ marginTop: "0.8vh" }}
                                        type="file"
                                        name="file"
                                        onChange={(event) => {
                                            setPfFile(event.target.files[0]);
                                        }} />
                                </FormControl>
                            </div>
                            <div style={{ width: "40%", margin: "0 0 0 0%" }}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <Select
                                        className={classes.select}
                                        style={{fontSize: '2vh'}}
                                        value={batchID}
                                        onChange={(event) => {
                                            setBatchID(event.target.value);
                                        }}
                                    >
                                        {/* <MenuItem value="New">最新批次資料</MenuItem> */}
                                        {
                                            Object
                                                .entries(batchIdList)
                                                .map(([id, batchData]) => <MenuItem key={`batchIDList_${id}`} value={batchData.batch_id}>{batchData.created_time}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div style={{ display: 'flex', margin: "1vh 1vw 1vh 1vw", textAlign: "center" }}>
                            <div style={{ width: "56%", margin: "0 3% 0 1%" }}>
                                <Button variant="outlined" className={classes.sendInfoButton} onClick={() => {changeAlertMsg_cal('');uploadData('pf')}}>上傳</Button>
                            </div>
                            <div style={{ width: "40%", margin: "0 0 0 0%", display: 'inline-flex', justifyContent: 'space-evenly' }}>
                                <Button variant="outlined" className={classes.sendInfoButton} onClick={() => {resetInput();changeAlertMsg_cal('');getPfBatchDataList(batchID, [])}}>選擇</Button>
                            </div>
                        </div>
                        {
                            alertMsg_pf === '' ? '' :
                            <div style={{ display: 'flex', margin: "2vh 2vw", textAlign: "left" }}>
                                <p style={{fontSize: '2.1vh', color: 'red', margin: '0'}}>{alertMsg_pf}</p>
                            </div>
                        }
                    </div>
                    <div className="DataDownload_block" style={{ flexDirection: "column", minWidth: "490px", flex: 5, margin: '0 10px 10px 0' }}>
                        <p className="DataDownload_block_title_text">計算投融資組合ITR</p>
                        <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
                        <div style={{ display: 'flex', margin: "1vh 1vw 0 1vw", textAlign: "center" }}>
                            <div style={{ width: "50%", margin: "0 3% 0 1%" }}><p className={classes.blockTitle}>{`ITR資料版本: ${latest_itr || '-'}`}</p></div>
                            <div style={{ width: "50%", margin: "0 0 0 0%" }}><p className={classes.blockTitle}>過去投融資組合ITR資料</p></div>
                        </div>
                        <div className={classes.blockContent}>
                            <div style={{ width: "50%", margin: "0 3% 0 1%" }}>
                                <p className={classes.blockTitle}>{`投融資組合資料版本: ${pfDataUsed || '-'}`}</p>
                            </div>
                            <div style={{ width: "50%", margin: "0 0 0 0%" }}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <Select
                                        className={classes.select}
                                        style={{fontSize: '2vh'}}
                                        value={pfRsltBatchId}
                                        onChange={(event) => {
                                            setPfRsltBatchId(event.target.value);
                                        }}
                                    >
                                        {/* <MenuItem value="New">最新批次資料</MenuItem> */}
                                        {
                                            Object
                                                .entries(pfRsltBatchIdList)
                                                .map(([id, batchData]) => <MenuItem key={`batchIDList_${id}`} value={batchData.batch_id}>{batchData.created_time}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div style={{ display: 'flex', margin: "1vh 1vw 1vh 1vw", textAlign: "center" }}>
                            <div style={{ width: "50%", margin: "0 3% 0 1%" }}>
                                <Button variant="outlined" className={classes.sendInfoButton} onClick={() => {getPfItrCalRslt_Opt(batchID)}}>計算</Button>
                            </div>
                            <div style={{ width: "50%", margin: "0 0 0 0%" }}>
                                <Button 
                                    variant="outlined" 
                                    className={classes.sendInfoButton} 
                                    onClick={() => {
                                        changITR_target_result({});
                                        changeITR_target('');
                                        changePfType_display('');
                                        changeAlertMsg_cal('');
                                        getPfItrRsltDataList_Opt(pfRsltBatchId)
                                    }}>查看</Button>
                                </div>
                        </div>
                        { !isClick ? "" :
                            <div style={{ margin: "0.5vh 0 0 1vw", fontSize: "2.5vh" }}>
                                資料計算中...
                            </div>
                        }
                        {
                            alertMsg_cal === '' ? '' :
                            <div style={{ display: 'flex', margin: "2vh 2vw", textAlign: "left" }}>
                                <p style={{fontSize: '2.1vh', color: 'red', margin: '0'}}>{alertMsg_cal}</p>
                            </div>
                        }
                    </div>
                </div>
                {
                    Object.keys(resultData).length===0 ? '' :
                    <div>
                        <div className="page_subtitle_container"><p className="page_subtitle_text">投資組合ITR結果</p></div>
                        <div style={{ display: "inline-flex", flexWrap: 'wrap', justifyContent: "space-between", width: 'calc(100% + 10px)' }}>
                            <div className="DataDownload_block" style={{ flexDirection: "column", minWidth: "490px", margin: '0 10px 10px 0' }}>
                                <p className="DataDownload_block_title_text">查看投融資組合ITR結果</p>
                                <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
                                {
                                    Object.keys(resultChartData_display).length===0 ? 
                                    <div style={{ display: "inline-flex", flexWrap: 'wrap', justifyContent: "center", width: '100%', margin: '5px 0' }}>
                                        <div style={{margin: '2vh 1vw', minWidth: '200px', maxWidth: '350px', flex: 1}}>
                                            <div style={{ margin: "auto", textAlign: "center" }}>
                                                <p className={classes.blockTitle}>選擇案件類別</p>
                                            </div>
                                            <div className={classes.blockContent} style={{height: 'auto'}}>
                                                <FormControl variant="outlined" className={classes.formControl}>
                                                    <Select
                                                        className={classes.select}
                                                        style={{fontSize: '2vh'}}
                                                        value={pfType_display}
                                                        onChange={(event) => {
                                                            changePfType_display(event.target.value);
                                                            changeResultChartData_display(resultData['itr_total'][event.target.value]);
                                                        }}
                                                    >
                                                        {/* <MenuItem value="New">最新批次資料</MenuItem> */}
                                                        {
                                                            resultData.type_list
                                                            ?.map((type_item) => <MenuItem key={`pf_type_${type_item}`} value={type_item}>{type_item}</MenuItem>)
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            {/* <div style={{ margin: "1vh 1vw 1vh 1vw", textAlign: "center" }}>
                                                <Button variant="outlined" className={classes.sendInfoButton} onClick={() => {changeResultChartData_display(resultData['itr_total'][pfType_display])}}>查看</Button>
                                            </div> */}
                                        </div>
                                    </div> : 
                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                        <div style={{margin: '2vh 1vw', width: '100%', display: 'flex', flexDirection: 'column'}}>
                                            <div style={{ margin: "auto", textAlign: "center", width: '100%' }}>
                                                <p className={classes.blockTitle}>選擇案件類別</p>
                                            </div>
                                            <div style={{width: '100%', display: 'inline-flex', justifyContent: 'center', margin: '10px 0'}}>
                                                <div className={classes.blockContent} style={{height: 'auto' }}>
                                                    <FormControl variant="outlined" className={classes.formControl}>
                                                        <Select
                                                            className={classes.select}
                                                            style={{fontSize: '2vh', margin: '0', width: '200px'}}
                                                            value={pfType_display}
                                                            onChange={(event) => {
                                                                changePfType_display(event.target.value);
                                                                changITR_target_result({});
                                                                changeITR_target('');
                                                                changeResultChartData_display({});
                                                                changeResultChartData_display(resultData['itr_total'][event.target.value]);
                                                            }}
                                                        >
                                                            {
                                                                resultData.type_list
                                                                .map((type_item) => <MenuItem key={`pf_type_${type_item}`} value={type_item}>{type_item}</MenuItem>)
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                                {/* <div style={{ margin: "1vh 1vw 1vh 1vw", textAlign: "center" }}>
                                                    <Button 
                                                        variant="outlined"
                                                        style={{width: '100px'}}
                                                        className={classes.sendInfoButton}
                                                        onClick={() => {
                                                            changITR_target_result({});
                                                            changeITR_target('');
                                                            changeResultChartData_display({});
                                                            changeResultChartData_display(resultData['itr_total'][pfType_display])
                                                        }}>查看</Button>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div style={{ display: "inline-flex", flexWrap: 'wrap', justifyContent: "left", width: '100%' }}>
                                            <div style={{ margin: '2vh 1vw', minWidth: '415px', flex: 10}}>
                                                <div style={{ margin: "auto", textAlign: "center" }}>
                                                    <p className={classes.blockTitle} >{`現況${pfType_display}ITR分佈狀況`}</p>
                                                </div>
                                                <LineChart
                                                    width={'90%'}
                                                    colors={linechart_legend_color.slice(0, resultData['itr_group']['label'].length)}
                                                    labels={resultData['itr_group']['label']}
                                                    lineLength={resultChartData_display['pr_by_group']} // percentage
                                                    lineWidth={'30px'}
                                                    labelColor={'#444444'}
                                                    labelSize={'24px'}
                                                    nrOfLevels={5}
                                                    linePadding={'1%'}
                                                    backgroundColor={'#e5e5e5'}
                                                />
                                                <div style={{minWidth: '280px', display: 'flex', flex: 4, flexDirection: 'column', margin: '2vh 1.5vw'}}>
                                                    <div style={{display: 'inline-flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between'}}>
                                                        {linechart_legend_color.slice(0, resultData['itr_group']['label'].length).map((color, index) => (
                                                            <div key={`linechart_legend_${color}`} className="linechart-legend-item" style={{maxWidth: '46%'}}>
                                                                <div className="linechart-legend-colorLabel" style={{backgroundColor: color}} />
                                                                <p className="linechart-legend-text" style={{fontWeight: 'bold', margin: '0 10px 0 0'}}>{`${resultData['itr_group']['label'][index]}(${linechart_legend_text[index]}):`}</p>
                                                                <p className="linechart-legend-text" >{`${resultChartData_display['pr_by_group'][index]}%`}</p>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{height: 'fit-content', margin: '2vh 1vw', textAlign: 'center', minWidth: '300px', maxWidth: '350px', flex: 1}}>
                                                <div style={{ margin: "auto", textAlign: "center" }}>
                                                    <p className={classes.blockTitle}>{`${pfType_display}`}</p>
                                                </div>
                                                <GaugeChart 
                                                    colors={linechart_legend_color.slice(0, resultData['itr_group']['label'].length)} 
                                                    arcWidth={0.3} 
                                                    // percent={Math.round(value['itr']*10)/10/5}
                                                    percent={Math.round(resultChartData_display['itr']*10)/10/5}
                                                    textColor={'gray'}
                                                    arcsLength={[1.5, 0.5, 0.6, 0.6, 1.8]}
                                                    nrOfLevels={5}
                                                    // formatTextValue={value => (value/100)*6-1+'°C'}
                                                    style={{fontWeight: 'bold', maxWidth: '450px', margin: '2vh auto 0 auto'}}
                                                    animateDuration={2000}
                                                    hideText={true}
                                                    arcPadding={0.03}
                                                    cornerRadius={4}
                                                />
                                                <p style={{fontSize: '28px', fontWeight: 'bold', margin: '0'}}>{`${parseFloat(Math.round(resultChartData_display['itr']*100)/100)}°C`}</p>
                                            </div>
                                        </div>
                                        <div style={{ display: "inline-flex", flexWrap: 'wrap', justifyContent: "left", width: '100%' }}>
                                            <div style={{minWidth: '280px', display: 'flex', flex: 4, flexDirection: 'column', margin: '2vh 2vw'}}>
                                                <div className="linechart-legend-item"><li>2015年聯合國巴黎協定中各國達成共識將本世紀末全球均溫上升控制在2°C以內，並以1.5°C為積極目標</li></div>
                                                <div className="linechart-legend-item"><li>在國家自主貢獻（Unconditional NDCs, NGFS）皆被執行的情境下，世紀末升溫預估將控制在2.6°C</li></div>
                                                <div className="linechart-legend-item"><li>在維持現有政策（Current Policies, NGFS）的情境下，世紀末升溫預估將達到3.2°C</li></div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            {
                                Object.keys(resultChartData_display).length===0 ? '' :
                                <div className="DataDownload_block" style={{ flexDirection: "column", width: "100%", margin: '0 10px 10px 0' }}>
                                    <p className="DataDownload_block_title_text">投融資組合目標設定</p>
                                    <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
                                    <div style={{ display: "inline-flex", flexWrap: 'wrap', justifyContent: "space-between", width: '100%' }}>
                                        <div style={{margin: '1vh 1vw', display: 'inline-flex'}}>
                                            <div className={classes.blockContent} style={{height: 'auto', display: 'inline-flex', flexWrap: 'wrap', justifyContent: 'left', textAlign: 'center'}}>
                                                <p className='text_input' style={{maxWidth: 'none', fontWeight: 'bold', color: 'red'}}>{`${pfType_display}`}</p>                                                
                                                <p className='text_input' style={{maxWidth: 'none'}}>{`目前ITR為`}</p>
                                                <p className='text_input' style={{maxWidth: 'none', fontWeight: 'bold', color: 'red'}}>{`${parseFloat(Math.round(resultChartData_display['itr']*100)/100)}°C`}</p>
                                                <p className='text_input' style={{maxWidth: 'none'}}>{`，目標ITR設定為`}</p>
                                                <span style={{display: 'inline-flex', minWidth: '156px'}}>
                                                    <input
                                                        className='text_input'
                                                        style={{textAlign: 'center', paddingRight: '10px'}}
                                                        placeholder='ex: 2.6'
                                                        value={ITR_target}
                                                        onChange={(event) => {changeITR_target(event.target.value)}}
                                                    />
                                                    <p className='text_input'>° C</p>
                                                </span>
                                                <Button variant="outlined" className={classes.sendInfoButton} onClick={() => {getPfItrTarget_Opt()}}>輸入目標</Button>
                                            </div>
                                            {
                                                alertMsg_target === '' ? '' :
                                                <div style={{ display: 'flex', margin: "2vh 2vw", textAlign: "left" }}>
                                                    <p style={{fontSize: '2.1vh', color: 'red', margin: '0'}}>{alertMsg_target}</p>
                                                </div>
                                            }
                                        </div>
                                    </div>        
                                </div>
                            }
                        </div>
                        {
                            Object.keys(ITR_target_result).length===0 ? '':
                            <div>
                                <div className="page_subtitle_container"><p className="page_subtitle_text">投資組合調整建議</p></div>
                                <div style={{ display: "inline-flex", flexWrap: 'wrap', justifyContent: "space-between", width: 'calc(100% + 10px)' }}>
                                    <div className="DataDownload_block" style={{ flexDirection: "column", minWidth: "490px", margin: '0 10px 10px 0' }}>
                                        <p className="DataDownload_block_title_text">投融資組合調整建議</p>
                                        <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
                                        <div style={{ display: "inline-flex", flexWrap: 'wrap', justifyContent: "left", width: '100%' }}>
                                            <div style={{ margin: '2vh 1vw', minWidth: '415px', flex: 10}}>
                                                <div style={{ margin: "auto", textAlign: "center" }}>
                                                    <p className={classes.blockTitle} >{`建議之${pfType_display}ITR分佈狀況`}</p>
                                                </div>
                                                <LineChart
                                                    width={'90%'}
                                                    colors={linechart_legend_color.slice(0, ITR_target_result['itr_group']['label'].length)}
                                                    labels={ITR_target_result['itr_group']['label']}
                                                    lineLength={ITR_target_result['itr_total'][pfType_display]['pr_by_group']} // percentage
                                                    lineWidth={'30px'}
                                                    labelColor={'#444444'}
                                                    labelSize={'24px'}
                                                    nrOfLevels={5}
                                                    linePadding={'1%'}
                                                    backgroundColor={'#e5e5e5'}
                                                />
                                                <div style={{minWidth: '280px', display: 'flex', flex: 4, flexDirection: 'column', margin: '2vh 1.5vw'}}>
                                                    <div style={{display: 'inline-flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between'}}>
                                                        {linechart_legend_color.map((color, index) => (
                                                            <div key={`lineChart_legend_item_${index}`} className="linechart-legend-item" style={{maxWidth: '46%'}}>
                                                                <div className="linechart-legend-colorLabel" style={{backgroundColor: color}} />
                                                                <p className="linechart-legend-text" style={{fontWeight: 'bold', margin: '0 10px 0 0'}}>{`${linechart_legend_label[index]}(${linechart_legend_text[index]}):`}</p>
                                                                <p className="linechart-legend-text" >{`${ITR_target_result['itr_total'][pfType_display]['pr_by_group'][index]}%`}</p>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{height: 'fit-content', margin: '2vh 1vw', textAlign: 'center', minWidth: '300px', maxWidth: '350px', flex: 1}}>
                                                <div style={{ margin: "auto", textAlign: "center" }}>
                                                    <p className={classes.blockTitle}>{`${pfType_display}`}</p>
                                                </div>
                                                <GaugeChart 
                                                    colors={linechart_legend_color.slice(0, ITR_target_result['itr_group']['label'].length)} 
                                                    arcWidth={0.3} 
                                                    // percent={Math.round(value['itr']*10)/10/5}
                                                    percent={Math.round(ITR_target_result['itr_total'][pfType_display]['itr']*10)/10/5}
                                                    textColor={'gray'}
                                                    arcsLength={[1.5, 0.5, 0.6, 0.6, 1.8]}
                                                    nrOfLevels={5}
                                                    // formatTextValue={value => (value/100)*6-1+'°C'}
                                                    style={{fontWeight: 'bold', maxWidth: '450px', margin: '2vh auto 0 auto'}}
                                                    animateDuration={2000}
                                                    hideText={true}
                                                    arcPadding={0.03}
                                                    cornerRadius={4}
                                                />
                                                <p style={{fontSize: '28px', fontWeight: 'bold', margin: '0'}}>{`${parseFloat(Math.round(ITR_target_result['itr_total'][pfType_display]['itr']*100)/100)}°C`}</p>
                                                <p style={{fontSize: '24px', color: 'red', margin: '10px'}}>{ITR_target_result['itr_total'][pfType_display]['opt_msg']}</p>
                                            </div>
                                        </div>
                                        <div style={{margin: 'auto 0', textAlign: 'center', width: '100%'}}>
                                            <Button variant="outlined" className={classes.sendInfoButton} style={{height: 'auto', margin: '20px 20px 20px 0'}} onClick={() => {downloadPfItrTarget_Opt(ITR_target_result['target_id'])}}>輸出檔下載</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }
                <div ref={bottomRef} />
            </div>
            <Menu
                account={account}
                permission={permission}
                cookies={cookies}
                removeCookie={removeCookie}
                activeScreen={activeScreen}
                switchScreen={switchScreen}
                switchShellChoice={switchShellChoice}
                changeDisplayMethod={changeDisplayMethod}
            />
        </div>
    );
}

export default PortfolioOptimization;