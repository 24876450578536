import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ReplayIcon from '@material-ui/icons/Replay';

// const handleDataFilter = (datas_ori, selectLTVAtRisk, selectCALTV, county, changeDatas) => {
//     console.log("filting data!")
//     changeDatas(Object.values(datas_ori.individual).filter(data => {
//         return data.LTVatRisk > selectLTVAtRisk/100 & data.CA_LTV > selectCALTV/100 & data.address.includes(county)
//     }))
// }

const handleDataFilter = (datas_ori, ltvatRisk, ca_ltv, county, ownerID, isInud, changeDatas) => {
    let data_filtered = {}
    Object.assign(data_filtered, datas_ori.individual)
    // console.log(data_filtered)

    if (county !== "") {
        data_filtered = Object.values(data_filtered).filter((data) => (
            data.address === county
        ));
    }

    if (ownerID !== "") {
        data_filtered = Object.values(data_filtered).filter((data) => (
            data.ownerID === ownerID
        ));
    }

    if (ca_ltv !== 100) {
        data_filtered = Object.values(data_filtered).filter((data) => (
            data.ca_ltv * 100 < ca_ltv
        ));
    }

    if (ltvatRisk !== 0) {
        data_filtered = Object.values(data_filtered).filter((data) => (
            data.ltvatRisk * 100 < ltvatRisk
        ));
    }

    if (isInud !== "") {
        data_filtered = Object.values(data_filtered).filter((data) => (
            data.inud_yn === isInud
        ));
    }

    // console.log(data_filtered)

    if (data_filtered.length !== 0) {
        changeDatas(data_filtered)
    }
    else {
        changeDatas(["not found"])
        // console.log("not found")
    }

}

const handleDataFilterDeletion = (changeSelectLTVAtRisk, changeSelectCALTV, changeCounty, changeOwner, changeDatas) => {
    changeSelectLTVAtRisk(0);
    changeSelectCALTV(100);
    changeCounty("");
    changeOwner("");
    changeDatas([]);
}

const useStyles = makeStyles((theme) => ({
    displayText: {
        fontWeight: 'bold',
        fontSize: '30px',
        margin: '1vh 2.5vw',
    },
    selectOption: {
        margin: '1vh 0vw 0vh 0vw',
        fontSize: '2.5vh',
    },
    input: {
        width: "70px",
        textAlign: "center",
        height: "3.3vh",
        margin: '0vh 0.5vw 0vh 0vw',
        fontSize: '2.3vh',
        paddingLeft: '0.5vw',
        borderRadius: '5px',
        borderWidth: 'thin',
        borderColor: '#b6b6b6',
        borderStyle: 'solid',
        backgroundColor: '#e5e5e5',
    },
    select: {
        height: "3.8vh",
        width: "4vw",
        margin: '1vh 2vw 1vh 0.5vw',
        fontSize: '2.2vh',
    },
    compute: {
        height: "4vh",
        width: "4.5vw",
        minWidth: "90px",
        margin: '1vh 0vw 1vh 0.5vw',
        fontSize: '2.5vh',
        backgroundColor: '#ffffff',
    },
    computeIcon: {
        height: "4vh",
        width: "2.5vw",
        minWidth: "35px",
        margin: '1vh 0vw 1vh 0.5vw',
        fontSize: '2.5vh',
        backgroundColor: '#ffffff',
    },
    menuItem: {
        marginBottom: '0vh',
    },
    icon: {
        height: "3vh",
    }
}));

function BatchRealEstateValueImpact_Report(props) {
    const classes = useStyles();

    const [selectLTVAtRisk, changeSelectLTVAtRisk] = useState(0);
    const [selectCALTV, changeSelectCALTV] = useState(100);
    const [county, changeCounty] = useState("");
    const [owner, changeOwner] = useState("");
    const [is_Inud, changeIs_Inud] = useState("");
    const [datas, changeDatas] = useState([]);

    const {
        datas_ori,
        countyList,
        ownerList,
        inudList,
    } = props;

    return (
        <div className="display_body_container">
            <div style={{ backgroundColor: '#fafafa', borderRadius: '1.212vw', padding: '2.1vh 1.6vw 4vh', overflowX: 'scroll' }}>
                {/* {console.log(datas_ori)} */}
                {datas_ori.total === undefined ? "" :
                    <div>
                        <div style={{ display: 'inline-flex', alignItems: 'flex-start' }}>
                            <p className={classes.displayText}>總體價值減損量：{(datas_ori.total.CA_VAL_DELTA / 100000000).toFixed(5)}億元 ({datas_ori.total.CA_VAL_PERCENT})</p>
                            <p className={classes.displayText}>總體RWA改變量：{(datas_ori.total.RWA / 100000000).toFixed(5)}億元 ({datas_ori.total.RWA_PERCENT})</p>
                        </div>
                        <div className={classes.selectOption} style={{ display: 'inline-flex', alignItems: 'flex-start', marginBottom: '3vh', flexWrap: 'wrap' }}>
                            {/* <p style={{ margin: '0vh 0vw 0vh 0vh' }}>篩選條件：</p> */}
                            <div style={{flex: 1, minWidth: '260px'}}>
                                <p style={{ margin: '1vh 0.3vw 1vh 0', display: 'inline-block' }}>CA-LTV 小於 </p>
                                <input
                                    className={classes.input}
                                    type="number"
                                    min="-100"
                                    max="100"
                                    step="0.01"
                                    value={selectCALTV}
                                    onChange={(event) => {
                                        changeSelectCALTV(event.target.value);
                                    }}
                                />
                                <p style={{ margin: '1vh 0.3vw 1vh 0vw', display: 'inline-block' }}>%</p>
                            </div>
                            <div style={{flex: 1, minWidth: '280px'}}>
                                <p style={{ margin: '1vh 0.3vw 1vh 0', display: 'inline-block' }}>LTV at risk 小於 </p>
                                <input
                                    className={classes.input}
                                    type="number"
                                    min="-100"
                                    max="100"
                                    step="0.01"
                                    value={selectLTVAtRisk}
                                    onChange={(event) => {
                                        changeSelectLTVAtRisk(event.target.value);
                                    }}
                                />
                                <p style={{ margin: '1vh 0.3vw 1vh 0vw', display: 'inline-block' }}>%</p>
                            </div>
                            <div style={{flex: 1, minWidth: '200px'}}>
                                <p style={{ margin: '1vh 0.3vw 1vh 0', display: 'inline-block'  }}>地區</p>
                                <FormControl variant="outlined">
                                    <Select
                                        className={classes.select}
                                        style={{ width: "170px" }}
                                        value={county}
                                        onChange={(event) => {
                                            changeCounty(event.target.value);
                                        }}
                                    >
                                        {
                                            Object
                                                .entries(countyList)
                                                .map(([index, value]) => <MenuItem key={`countyChoice_${index}`} value={value} className={classes.menuItem} >{value}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                            <div style={{flex: 1, minWidth: '330px'}}>
                                <p style={{ margin: '1vh 0.3vw 1vh 0', display: 'inline-block'  }}>使用者</p>
                                <FormControl variant="outlined">
                                    <Select
                                        className={classes.select}
                                        style={{ width: '200px' }}
                                        value={owner}
                                        onChange={(event) => {
                                            changeOwner(event.target.value);
                                        }}
                                    >
                                        {
                                            Object
                                                .entries(ownerList)
                                                .map(([index, value]) => <MenuItem key={`ownerChoice_${index}`} value={value} className={classes.menuItem} >{value}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                            <div style={{flex: 1, minWidth: '320px'}}>
                                <p style={{ margin: '1vh 0.3vw 1vh 0', display: 'inline-block'  }}>是否位在潛勢區</p>
                                <FormControl variant="outlined">
                                    <Select
                                        className={classes.select}
                                        style={{ width: '100px' }}
                                        value={is_Inud}
                                        onChange={(event) => {
                                            changeIs_Inud(event.target.value);
                                        }}
                                    >
                                        {
                                            inudList
                                            .map((value, index) => <MenuItem key={`inudChoice_${index}`} value={value} className={classes.menuItem} >{value}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                            {/* <Button variant="outlined" className={classes.compute} onClick={() => handleDataFilter(datas_ori, selectLTVAtRisk, selectCALTV, county, changeDatas)} >篩選</Button>
                            <Button variant="outlined" className={classes.compute} onClick={() => handleDataFilterDeletion(changeSelectLTVAtRisk, changeSelectCALTV, changeCounty, changeDatas)} >取消篩選</Button> */}
                            <div style={{flex: 1, minWidth: '200px'}}>
                                <Button variant="outlined" className={classes.compute} onClick={() => handleDataFilter(datas_ori, selectLTVAtRisk, selectCALTV, county, owner, is_Inud, changeDatas)} >篩選</Button>
                                <Button variant="outlined" className={classes.computeIcon} onClick={() => handleDataFilterDeletion(changeSelectLTVAtRisk, changeSelectCALTV, changeCounty, changeOwner, changeDatas)} >
                                    <ReplayIcon className={classes.icon} />
                                </Button>
                            </div>
                        </div>
                        {datas[0] === undefined ?
                            <div style={{ display: 'inline-grid', width: 'fit-content', height: 'fit-content', border: 'solid 2px #eaeaea', margin: '0vh 1.6vw 1vh 0vw' }}>
                                <div style={{ display: 'inline-flex' }}>
                                    <div className='single_display_report_block' style={{ width: '4vw', textAlign: 'center' }}>標的</div>
                                    <div className='single_display_report_block' style={{ width: '25vw', textAlign: 'center' }}>地址</div>
                                    <div className='single_display_report_block' style={{ width: '10vw', textAlign: 'center' }}>位在潛勢區</div>                                    <div className='single_display_report_block' style={{ width: '10vw' }}>危害*脆弱度</div>
                                    <div className='single_display_report_block' style={{ width: '10vw', textAlign: 'center' }}>原價值</div>
                                    <div className='single_display_report_block' style={{ width: '10vw', textAlign: 'center' }}>減損後價值</div>
                                    <div className='single_display_report_block' style={{ width: '8vw', textAlign: 'center' }}>CA-LTV</div>
                                    <div className='single_display_report_block' style={{ width: '10vw', textAlign: 'center' }}>LTV at risk</div>
                                </div>
                                <div style={{ display: 'inline-flex' }}>
                                    <div style={{ display: 'grid' }}>
                                        {
                                            Object
                                                .entries(datas_ori.individual)
                                                .map((data_ori) => <div key={data_ori[1].id} className='single_display_report_block' style={{ width: '4vw', textAlign: 'center' }}>{data_ori[1].target}</div>)
                                        }
                                    </div>
                                    <div style={{ display: 'grid' }}>
                                        {
                                            Object
                                                .entries(datas_ori.individual)
                                                .map((data_ori) => <div key={data_ori[1].id} className='single_display_report_block' style={{ width: '25vw' }}>{data_ori[1].address}</div>)
                                        }
                                    </div>
                                    <div style={{ display: 'grid' }}>
                                        {
                                            Object
                                                .entries(datas_ori.individual)
                                                .map((data_ori) => <div key={data_ori[1].id} className='single_display_report_block' style={{ width: '10vw', textAlign: 'center' }}>{data_ori[1].inud_yn}</div>)
                                        }
                                    </div>
                                    <div style={{ display: 'grid' }}>
                                        {
                                            Object
                                                .entries(datas_ori.individual)
                                                .map((data_ori) => <div key={data_ori[1].id} className='single_display_report_block' style={{ width: '10vw', textAlign: 'right' }}>{(data_ori[1].haz_vul_val * 100).toFixed(2)}%</div>)
                                        }
                                    </div>
                                    <div style={{ display: 'grid' }}>
                                        {
                                            Object
                                                .entries(datas_ori.individual)
                                                .map((data_ori) => <div key={data_ori[1].id} className='single_display_report_block' style={{ width: '10vw', textAlign: 'right' }}>
                                                    {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: '0', minimumFractionDigits: '0' }).format(data_ori[1].original_value)}
                                                </div>)
                                        }
                                    </div>
                                    <div style={{ display: 'grid' }}>
                                        {
                                            Object
                                                .entries(datas_ori.individual)
                                                .map((data_ori) => <div key={data_ori[1].id} className='single_display_report_block' style={{ width: '10vw', textAlign: 'right' }}>
                                                    {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: '0', minimumFractionDigits: '0', textAlign: 'right' }).format(data_ori[1].loss_value)}
                                                </div>)
                                        }
                                    </div>
                                    <div style={{ display: 'grid' }}>
                                        {
                                            Object
                                                .entries(datas_ori.individual)
                                                .map((data_ori) => <div key={data_ori[1].id} className='single_display_report_block' style={{ width: '8vw', textAlign: 'right' }}>{(data_ori[1].ca_ltv * 100).toFixed(2)}%</div>)
                                        }
                                    </div>
                                    <div style={{ display: 'grid' }}>
                                        {
                                            Object
                                                .entries(datas_ori.individual)
                                                .map((data_ori) => <div key={data_ori[1].id} className='single_display_report_block' style={{ width: '10vw', textAlign: 'right' }}>{(data_ori[1].ltvatRisk * 100).toFixed(2)}%</div>)
                                        }
                                    </div>
                                </div>
                            </div> : datas[0] === "not found" ?
                                <div style={{ display: 'inline-grid', width: 'fit-content', height: 'fit-content', border: 'solid 2px #eaeaea', margin: '0vh 1.6vw 1vh 0vw' }}>
                                    <div style={{ display: 'inline-flex' }}>
                                        <div className='single_display_report_block' style={{ width: '4vw', textAlign: 'center' }}>標的</div>
                                        <div className='single_display_report_block' style={{ width: '25vw', textAlign: 'center' }}>地址</div>
                                        <div className='single_display_report_block' style={{ width: '10vw', textAlign: 'center' }}>位在潛勢區</div>
                                        <div className='single_display_report_block' style={{ width: '10vw', textAlign: 'center' }}>危害*脆弱度</div>
                                        <div className='single_display_report_block' style={{ width: '10vw', textAlign: 'center' }}>原價值</div>
                                        <div className='single_display_report_block' style={{ width: '10vw', textAlign: 'center' }}>減損後價值</div>
                                        <div className='single_display_report_block' style={{ width: '8vw', textAlign: 'center' }}>CA-LTV</div>
                                        <div className='single_display_report_block' style={{ width: '10vw', textAlign: 'center' }}>LTV at risk</div>
                                    </div>
                                </div> :
                                <div style={{ display: 'inline-grid', width: 'fit-content', height: 'fit-content', border: 'solid 2px #eaeaea', margin: '0vh 1.6vw 1vh 0vw' }}>
                                    <div style={{ display: 'inline-flex' }}>
                                        <div className='single_display_report_block' style={{ width: '4vw', textAlign: 'center' }}>標的</div>
                                        <div className='single_display_report_block' style={{ width: '25vw', textAlign: 'center' }}>地址</div>
                                        <div className='single_display_report_block' style={{ width: '10vw', textAlign: 'center' }}>位在潛勢區</div>
                                        <div className='single_display_report_block' style={{ width: '10vw', textAlign: 'center' }}>危害*脆弱度</div>
                                        <div className='single_display_report_block' style={{ width: '10vw', textAlign: 'center' }}>原價值</div>
                                        <div className='single_display_report_block' style={{ width: '10vw', textAlign: 'center' }}>減損後價值</div>
                                        <div className='single_display_report_block' style={{ width: '8vw', textAlign: 'center' }}>CA-LTV</div>
                                        <div className='single_display_report_block' style={{ width: '10vw', textAlign: 'center' }}>LTV at risk</div>
                                    </div>
                                    <div style={{ display: 'inline-flex' }}>
                                        <div style={{ display: 'grid' }}>
                                            {
                                                Object
                                                    .entries(datas)
                                                    .map((data) => <div key={data[1].id} className='single_display_report_block' style={{ width: '4vw', textAlign: 'center' }}>{data[1].target}</div>)
                                            }
                                        </div>
                                        <div style={{ display: 'grid' }}>
                                            {
                                                Object
                                                    .entries(datas)
                                                    .map((data) => <div key={data[1].id} className='single_display_report_block' style={{ width: '25vw' }}>{data[1].address}</div>)
                                            }
                                        </div>
                                        <div style={{ display: 'grid' }}>
                                            {
                                                Object
                                                    .entries(datas)
                                                    .map((data) => <div key={data[1].id} className='single_display_report_block' style={{ width: '10vw', textAlign: 'center' }}>{data[1].inud_yn}</div>)
                                            }
                                        </div>
                                        <div style={{ display: 'grid' }}>
                                            {
                                                Object
                                                    .entries(datas)
                                                    .map((data) => <div key={data[1].id} className='single_display_report_block' style={{ width: '10vw', textAlign: 'right' }}>{(data[1].haz_vul_val * 100).toFixed(2)}%</div>)
                                            }
                                        </div>
                                        <div style={{ display: 'grid' }}>
                                            {
                                                Object
                                                    .entries(datas)
                                                    .map((data) => <div key={data[1].id} className='single_display_report_block' style={{ width: '10vw', textAlign: 'right' }}>
                                                        {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: '0', minimumFractionDigits: '0' }).format(data[1].original_value)}
                                                    </div>)
                                            }
                                        </div>
                                        <div style={{ display: 'grid' }}>
                                            {
                                                Object
                                                    .entries(datas)
                                                    .map((data) => <div key={data[1].id} className='single_display_report_block' style={{ width: '10vw', textAlign: 'right' }}>
                                                        {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: '0', minimumFractionDigits: '0' }).format(data[1].loss_value)}
                                                    </div>)
                                            }
                                        </div>
                                        <div style={{ display: 'grid' }}>
                                            {
                                                Object
                                                    .entries(datas)
                                                    .map((data) => <div key={data[1].id} className='single_display_report_block' style={{ width: '8vw', textAlign: 'right' }}>{(data[1].ca_ltv * 100).toFixed(2)}%</div>)
                                            }
                                        </div>
                                        <div style={{ display: 'grid' }}>
                                            {
                                                Object
                                                    .entries(datas)
                                                    .map((data) => <div key={data[1].id} className='single_display_report_block' style={{ width: '10vw', textAlign: 'right' }}>{(data[1].ltvatRisk * 100).toFixed(2)}%</div>)
                                            }
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                }
            </div>
        </div >
    )
}

export default BatchRealEstateValueImpact_Report;