import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

let today = new Date()
let dd = String(today.getDate()).padStart(2, '0');
let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
let yyyy = today.getFullYear();

today = yyyy + '-' + mm + '-' + dd;

const useStyles = makeStyles((theme) => ({
    inputContainer: {
        borderRadius: 0,
        // border: 'solid 1px',
        // borderColor: 'rgb(173, 171, 171)',
        // '&:hover': {
        //     border: 'solid 2px',
        // }
    },
    input: {
        padding: '7px 5px 5px 5px',
        textAlign: 'center',
        minHeight: '20px',
    },
    textField: {
    },
    helperText: {
        fontSize: '12px',
        color: 'red',
        left: 0,
        position: 'absolute',
        width: '25px',
        margin: '2px 5px',
        lineHeight: '14px',
    }
}))

function CreatableTableInputRow(props) {
    const classes = useStyles();

    const {
        columnWidth,
        columnList,
        columnDisplay,
        inputValue,
        changeInputValue,
        selectList,
        changeInputRowStatus,
        checkDependency,
    } = props

    const onLimitsCheck = (newInputValue) => {
        let check = true
        columnList
        .filter((item) => (item['limit'] !== null))
        .forEach((item) => {
            if((item['limit'][0] !== null && newInputValue[item['dataItem']] < item['limit'][0]) || (item['limit'][1] !== null && newInputValue[item['dataItem']] > item['limit'][1])){
                check = false
            }
        })
        return check
    }

    const onInputChange = (dataItem, value) => {
        const newInputValue = {
            ...inputValue,
            [dataItem]: value,
        }
        const newValueList = Object.values(newInputValue).map((value) => (value))
        // console.log(newInputValue)
        changeInputValue(newInputValue)
        checkDependency(newInputValue)
        if(!newValueList.includes('')){
            if(onLimitsCheck(newInputValue)){
                changeInputRowStatus('ready')
            }else{
                changeInputRowStatus('edit')
            }
        }else{
            changeInputRowStatus('edit')
        }
    }
    
    return ( 
        <div className="table_row_container table_inputRow">
            {columnList.filter((value) => (columnDisplay[value['dataItem']])).map((value, index) => {
                if(!value['userInput']){
                    return(<p key={`inputRow_column_${index}`} className="table_text table_inputRow_text" style={{width: `${columnWidth[value['dataItem']]}`}}>-</p>)
                }else if(value['selectList'] !== null){
                    return(
                        <FormControl key={`inputRow_column_${index}`} variant='outlined' className="table_inputRow_text">
                            {/* <InputLabel style={{fontSize:'2vh', fontWeight: 'bold'}}><p style={companyChecked?{display: 'inline-block', fontSize: 'small', margin: '0', color: 'red', position: 'absolute', top: '-5px', paddingLeft: '3px'}:{display: 'none'}}>*</p></InputLabel> */}
                            <Select
                                style={{
                                    textAlign: 'center',
                                    width: `calc(${columnWidth[value['dataItem']]} + 12px)` 
                                    // width: `${inputValue[index] === '' ? `calc(${columnWidth[value['dataItem']]} + 12px)` : `calc(${columnWidth[value['dataItem']]} + 12px)` }`,
                                    // height: `${inputValue[index] === '' ? '' : '32px'}`,
                                    // border: `${inputValue[index] === '' ? '' : 'black 1px solid'}`,
                                }}
                                className={classes.inputContainer}
                                value={inputValue[value['dataItem']] || ''}
                                onChange={(event)=>{
                                    onInputChange(value['dataItem'], event.target.value)
                                }}
                                inputProps={{
                                    className: classes.input,
                                }}
                                
                            >
                            {/* <MenuItem value={""}></MenuItem> */}
                            {selectList[value['dataItem']]?.map((element, index) => (
                                <MenuItem key={`MenuItem_${index}`} style={{fontSize: '1.5vh'}} value={element}>{element}</MenuItem>
                            ))}
                            </Select>
                        </FormControl>
                    )
                }else if(value['columnType'] === 'date'){
                    return(
                        <FormControl key={`inputRow_column_${index}`} variant="outlined" className="table_inputRow_text">
                            <TextField
                                id="date"
                                type="date"
                                value={inputValue[value['dataItem']]}
                                variant="outlined"
                                onChange={(event) => {
                                    onInputChange(value['dataItem'], event.target.value);
                                }}
                                style={{
                                    textAlign:'center',
                                    height: `${inputValue[index] === '' ? '' : '30px'}`,
                                }}
                                InputProps={{
                                    className: classes.inputContainer,
                                }}
                                inputProps={{
                                    className: classes.input,
                                    style:{
                                        width: `calc(${columnWidth[value['dataItem']]} + 2px)`,
                                        padding: '7px 5px 5px 5px',
                                        cursor: 'text',
                                    }
                                }}
                            />
                        </FormControl>
                    )
                }else if(value['columnType'] === 'int'){
                    return(
                        <FormControl key={`inputRow_column_${index}`} variant="outlined" className="table_inputRow_text">
                            <TextField
                                disabled={!value['userInput']}
                                style={{
                                    textAlign:'center',
                                    height: `${inputValue[index] === '' ? '' : '30px'}`,
                                    // border: `${inputValue[index] === '' ? '' : 'black solid'}`,
                                    // borderWidth: `${inputValue[index] === '' ? '' : '1px'}`,
                                }}
                                value={inputValue[value['dataItem']] || ''}
                                variant="outlined"
                                onChange={(event) => {
                                    onInputChange(value['dataItem'], event.target.value)
                                }}
                                InputProps={{
                                    className: classes.inputContainer,
                                    
                                    // inputProps: {
                                    //     min: value['limit'][0],
                                    // }
                                }}
                                inputProps={{
                                    className: classes.input,
                                    style:{
                                        width: `calc(${columnWidth[value['dataItem']]} + 2px)`
                                        // width: `${inputValue[index] === '' ? `calc(${columnWidth[value['dataItem']]} + 2px)` : `calc(${columnWidth[value['dataItem']]})` }`
                                    },
                                    inputMode: 'numeric',
                                    pattern: '[0-9]*',
                                }}
                                type="number"
                                helperText={ // null < 1 and null > -1
                                    inputValue[value['dataItem']] === ''?"":
                                    value['limit'][0] === null && value['limit'][1] === null ? '' :
                                    value['limit'][0] !== null && value['limit'][0] > parseInt(inputValue[value['dataItem']])?"格式錯誤":
                                    value['limit'][1] !== null && value['limit'][1] < parseInt(inputValue[value['dataItem']])?"格式錯誤":""
                                }
                                FormHelperTextProps={{
                                    className: classes.helperText,
                                }}
                            />
                        </FormControl>
                    )
                }else if(value['columnType'] === 'float'){
                    return(
                        <FormControl key={`inputRow_column_${index}`} variant="outlined" className="table_inputRow_text">
                            <TextField
                                disabled={!value['userInput']}
                                style={{
                                    textAlign:'center',
                                    height: `${inputValue[index] === '' ? '' : '30px'}`,
                                    // border: `${inputValue[index] === '' ? '' : 'black solid'}`,
                                    // borderWidth: `${inputValue[index] === '' ? '' : '1px'}`,
                                }}
                                value={inputValue[value['dataItem']] || ''}
                                variant="outlined"
                                // onChange={(event) => {
                                //     if(!(value['limit'][0] !== null && event.target.value < value['limit'][0]) && !(value['limit'][1] !== null && event.target.value > value['limit'][1])){
                                //         onInputChange(value['dataItem'], event.target.value)
                                //     }
                                // }}
                                onChange={(event) => {
                                    onInputChange(value['dataItem'], event.target.value)
                                }}
                                InputProps={{
                                    className: classes.inputContainer,
                                    
                                    // inputProps: {
                                    //     min: value['limit'][0],
                                    // }
                                }}
                                inputProps={{
                                    className: classes.input,
                                    style:{
                                        width: `calc(${columnWidth[value['dataItem']]} + 2px)`
                                        // width: `${inputValue[index] === '' ? `calc(${columnWidth[value['dataItem']]} + 2px)` : `calc(${columnWidth[value['dataItem']]})` }`
                                    },
                                    inputMode: 'numeric',
                                    pattern: '[0-9]*',
                                }}
                                type="number"
                                helperText={ // null < 1 and null > -1
                                    inputValue[value['dataItem']] === ''?"":
                                    value['limit'][0] === null && value['limit'][1] === null ? '' :
                                    value['limit'][0] !== null && value['limit'][0] > parseFloat(inputValue[value['dataItem']])?"格式錯誤":
                                    value['limit'][1] !== null && value['limit'][1] < parseFloat(inputValue[value['dataItem']])?"格式錯誤":""
                                }
                                FormHelperTextProps={{
                                    className: classes.helperText,
                                }}
                            />
                        </FormControl>
                    )
                }else{
                    return(
                        <FormControl key={`inputRow_column_${index}`} variant="outlined" className="table_inputRow_text">
                            <TextField
                                disabled={!value['userInput']}
                                style={{
                                    textAlign:'center',
                                    height: `${inputValue[index] === '' ? '' : '30px'}`,
                                    // border: `${inputValue[index] === '' ? '' : 'black solid'}`,
                                    // borderWidth: `${inputValue[index] === '' ? '' : '1px'}`,
                                }}
                                value={inputValue[value['dataItem']] || ''}
                                variant="outlined"
                                onChange={(event) => {
                                    onInputChange(value['dataItem'], event.target.value)
                                }}
                                InputProps={{
                                    className: classes.inputContainer,
                                }}
                                inputProps={{
                                    className: classes.input,
                                    style:{
                                        width: `calc(${columnWidth[value['dataItem']]} + 2px)`
                                        // width: `${inputValue[index] === '' ? `calc(${columnWidth[value['dataItem']]} + 2px)` : `calc(${columnWidth[value['dataItem']]})` }`
                                    }
                                }}
                                type="keyword"
                            />
                        </FormControl>
                    )
                }
            })}
        </div>
    );
}

export default CreatableTableInputRow;