import React from 'react';
import { Viewer, Entity, BoxGraphics, Camera, CameraFlyTo, GeoJsonDataSource } from "resium";
import { Cartesian3, Color, HeadingPitchRange, ArcType } from "cesium";
import json_data from '../100年全國鄉鎮市區界圖.json';
import grid_data from '../grid.json';

let cameraLoc = {
  '台北市': [121.53190, 24.77141],
  '台中市': [120.67530645783097, 23.88734602242779],
  '台南市': [120.22490433343303, 22.723120334277223],
  '高雄市': [120.3003325333458, 22.353581969921304]
}
const colorCode = {
  'very low': Color.GREEN,
  'low': Color.BLUE,
  'medium': Color.YELLOW,
  'high': Color.ORANGE,
  'very high': Color.RED
}

function SelectCountry(county) {
  //篩選全台縣市
  let dataList = []
  for (let i = 0; i < json_data.features.length; i++) {
    if (json_data.features[i].properties.COUNTY === county) {
      dataList.push(json_data.features[i])
    }
  }
  return {
    "type": "FeatureCollection",
    "features": dataList
  }
}

function DisplayMap(props) {
  if (props.data.area !== undefined) {
    return (
      // <Viewer style={{width:"78vw", height:"auto" }}>
      //   <Scene>
      //     <Globe>
      //       <Camera position={test} >
      //         <Entity position={position} point={pointGraphics} name="Tokyo"  description="Hello, world." >
      //           <PointGraphics pixelSize={10} />
      //           <EntityDescription>
      //             <h1>Hello, world.</h1>
      //             <p>{}</p>
      //           </EntityDescription>
      //         </Entity>
      //     </Globe>
      //   </Scene>  colorCode[value[0]]
      // </Viewer>
      <Viewer style={{ width: "78vw", height: "auto" }}>
        {
          Object
            .entries(props.data.harm)
            .map(([key, value]) => {
              console.log((-Number(value[1]) + 65) * 100)
              console.log("Number=", value)
              return (
                <Entity
                  key={`harmbox_${key}`}
                  name={`${key}區 淹水潛勢`}
                  description={Math.round(Number(value[1] * 100)) / 100}
                  position={Cartesian3.fromDegrees(Number(value[3]), Number(value[4]), 0)}>
                  {/* 柱狀圖 */}
                  <BoxGraphics material={colorCode[value[0]]} dimensions={new Cartesian3(500, 500, (-Number(value[1]) + 65) * 100)} />
                  <GeoJsonDataSource
                    data={SelectCountry(props.data.area)}
                    stroke={Color.BLACK}
                    fill={Color.TRANSPARENT}
                    onLoad={ds => {
                      ds.entities.values.forEach(e => { e.polygon.arcType = ArcType.GEODESIC; });
                    }} />
                </Entity>
              )
            })
        }
        {grid_data.features.map(
          e => {
            return (
              <GeoJsonDataSource
                data={{
                  "type": "FeatureCollection",
                  "features": [e]
                }}
                stroke={Color.TRANSPARENT}
                fill={colorCode[e.properties.IMPACT]}
                onLoad={ds => {
                  ds.entities.values.forEach(e => { e.polygon.arcType = ArcType.GEODESIC; });
                }} />
            );
          }
        )}

        <Camera />
        {/* 飛去各縣市的動畫 */}
        <CameraFlyTo duration={2} destination={Cartesian3.fromDegrees(cameraLoc[props.data.area][0], cameraLoc[props.data.area][1], 20000)} orientation={new HeadingPitchRange(0, -Math.PI / 5, 30000)} />
      </Viewer>

      // <img style={{width:"78vw", height:"auto" }} src={require('../img/exposure_taipei.jpg')} /> 

    );
  } else {
    return (
      <div></div>
      // <Viewer style={{width:"78vw", height:"auto" }}>
      //   <Entity
      //     name="BoxGraphics"
      //     description="BoxGraphics!!"
      //     position={Cartesian3.fromDegrees(121.5397, 25.018422945450503, 0)}>
      //     <BoxGraphics material={ Color.RED} dimensions={new Cartesian3(20, 20, 5000)} />
      //   </Entity>
      //   <Camera />
      //   <CameraFlyTo duration={1} destination={Cartesian3.fromDegrees(cameraLoc[props.data.area][0], cameraLoc[props.data.area][1], 20000)} orientation={new HeadingPitchRange(0,  -Math.PI / 5, 30000)} />
      // </Viewer>
    )
  }
}

export default DisplayMap;