import React, { useState } from 'react';
import '../style/SingleTargetITR.css';
import "../style/style.css";
import { makeStyles } from '@material-ui/core/styles';
import Menu from './Menu';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import Grid from "@material-ui/core/Grid";
import { Modal, Box } from "@material-ui/core";
import InputAdornment from '@material-ui/core/InputAdornment';
import AppConfigN from '../const/AppConfigN.const';
import GaugeChart from 'react-gauge-chart'
// import CircularChart from '../component/CircularChart';


const nowYear = new Date().getFullYear()


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin:'0 50px 0 10px',
        minHeight: '3.5vh',
        flex: 1,
    },
    select: {
        height: "5vh",
        width: "12vw",
        margin: '1.5vh 0vw 0vh 0vw',
        fontSize: '2.3vh',
    },
    choiceFormControl: {
        paddingBottom: '2vh',
        width: '15vw',
        textAlign: 'center',
        margin: '0 20px',
    },
    adorned: {
      fontSize: "2.3vh",
    },
    alertBox: {
        position: "absolute",
        top: '50%',
        left: '40%',
        width: "400px",
        borderRadius: '5px',
    }
}))

function SingleTargetITR(props) {
    const classes = useStyles();

    const {
        account,
        permission,
        cookies,
        removeCookie,
        activeScreen,
        switchScreen,
        switchShellChoice,
        changeDisplayMethod,
    } = props

    const[ companyID, changeCompanyID ] = useState('')
    const[ companyName, changecompanyName ] = useState('')
    const[ isFinance, changeIsFinance ] = useState('N')
    const[ isSBT, changeIsSBT ] = useState('N')
    const[ companyChecked, changeCompanyChecked] = useState(false)
    const[ ITRScenChecked, changeITRScenChecked ] = useState(false) // period and scope
    const[ targetType, changeTargetType ] = useState('')
    const[ targetReportYear, changeTargetReportYear ] = useState('')
    const[ targetBaseYear, changeTargetBaseYear ] = useState('')
    const[ targetYear, changeTargetYear ] = useState('')
    const[ baseYearCE, changeBaseYearCE ] = useState('')
    const[ degreeOfReduction, changeDegreeOfReduction ] = useState('')
    const[ targetCoverage, changeTargetCoverage ] = useState('')

    const[ outputTag, changeOutputTag ] = useState('overview')
    const[ periodChoice, changePeriodChoice ] = useState('')
    const[ scopeChoice, changeScopeChoice] = useState('')
    const[ outputData, changeOutputData] = useState({
        "ITR-overview": {
            "SHORT": {
                "1+2": '',
                "3": '',
                "1+2+3": '',
            },
            "MID": {
                "1+2": '',
                "3": '',
                "1+2+3": '',
            },
            "LONG": {
                "1+2": '',
                "3": '',
                "1+2+3": '',
            }
        }, "updateRecord": {}
    })
    const[ alert_company, changeAlert_company] = useState('')
    const[ alert_ITR, changeAlert_ITR] = useState('')

    const[ alertBoxOpen, changeAlertBoxOpen ] = useState(false)
    const[ alertBoxText, changeAlertBoxText ] = useState('')
    const[ remark, changRemark ] = useState('')
    
    const onCompanyDataUpload = () => {
        if(companyName === ''){
            changeAlert_company('「公司名稱」為必填項目')
        }else{
            // console.log('fetch company')
            // console.log(JSON.stringify({
            //         companyID,
            //         companyName,
            //         isFinance,
            //         isSBT,
            //     }))
            fetch(`${AppConfigN.SERVER_URL}/api/itr_tgt_sgl_check`, {
            method: 'POST',
            headers: {
                "Authorization": `Token ${account.token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                companyID,
                companyName,
                isFinance,
                isSBT,
            }),
            })
            .then((response) => {
                return response.json()
            })
            .then((responseJson) => {
                // console.log(responseJson)
                if(responseJson.isRecorded){
                    changeAlert_company('已帶入過去紀錄資料')
                }else{changeAlert_company('無過去紀錄，新增此公司資料')}
                
                changeCompanyID(responseJson.companyID)
                changecompanyName(responseJson.companyName)
                changeIsFinance(responseJson.isFinance)
                changeIsSBT(responseJson.isSBT)
                changeOutputData(responseJson.data)
                changeCompanyChecked(true)
            })
        }
    }

    const onCompanyDataUpdate = () => {
        // console.log('company data update')
        // console.log(JSON.stringify({
        //         companyID,
        //         companyName,
        //         isFinance,
        //         isSBT,
        //     }))
        fetch(`${AppConfigN.SERVER_URL}/api/itr_co_sgl`, {
        method: 'POST',
        headers: {
            "Authorization": `Token ${account.token}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            companyID,
            companyName,
            isFinance,
            isSBT,
        }),
        })
        .then((response) => {
            return response.json()
        })
        .then((responseJson) => {
            // console.log(responseJson)
            // changeCompanyID(responseJson.companyID)
            // changecompanyName(responseJson.companyName)
            changeIsFinance(responseJson.isFinance)
            changeIsSBT(responseJson.isSBT)
            changeAlert_company('已更新公司基本資料')
        })
    }

    const onITRDataUpload = (fetchOrNot) => {
        const fetchData = (bool) => {
            // console.log(JSON.stringify({
            //     companyID,
            //     companyName,
            //     isFinance,
            //     isSBT,
            //     periodChoice,
            //     scopeChoice,
            //     targetType,
            //     targetYear: Number(targetYear),
            //     targetReportYear: Number(targetReportYear),
            //     targetBaseYear: Number(targetBaseYear),
            //     degreeOfReduction: ~~(parseFloat(degreeOfReduction)*100/1)/100,
            //     targetCoverage: ~~(parseFloat(targetCoverage)*100/1)/100,
            //     baseYearCE: Math.round(parseFloat(baseYearCE)*10000)/10000 || null,
            //     remark,
            // }))
            if(bool){
                fetch(`${AppConfigN.SERVER_URL}/api/itr_tgt_sgl`, {
                method: 'POST',
                headers: {
                    "Authorization": `Token ${account.token}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    companyID,
                    companyName,
                    isFinance,
                    isSBT,
                    periodChoice,
                    scopeChoice,
                    targetType,
                    targetYear: Number(targetYear),
                    targetReportYear: Number(targetReportYear),
                    targetBaseYear: Number(targetBaseYear),
                    degreeOfReduction: ~~(parseFloat(degreeOfReduction)*100/1)/100,
                    targetCoverage: ~~(parseFloat(targetCoverage)*100/1)/100,
                    baseYearCE: Math.round(parseFloat(baseYearCE)*10000)/10000 || null,
                    remark,
                }),
                })
                .then((response) => {
                // console.log(response)
                return response.json()
                })
                .then((responseJson) => {
                    // console.log(responseJson)
                    changeOutputData(responseJson)
                })
            }
        }

        fetchData(fetchOrNot)

        if(periodChoice === ''){
            changeAlert_ITR('「未來時期」為必選項目')
        }else if(scopeChoice === ''){
            changeAlert_ITR('「範疇」為必選項目')
        }else if(targetType === ''){
            changeAlert_ITR('「目標型態」為必填項目')
        }else if(targetYear === ''){
            changeAlert_ITR('「目標年度」為必填項目')
        }else if(targetReportYear === ''){
            changeAlert_ITR('「目標宣告年」為必填項目')
        }else if(targetBaseYear === ''){
            changeAlert_ITR('「目標基準年」為必填項目')
        }else if(degreeOfReduction === ''){
            changeAlert_ITR('「減量程度」為必填項目')
        }else if(targetCoverage === ''){
            changeAlert_ITR('「目標覆蓋率」為必填項目')
        }else if(Number(targetYear)<=Number(targetReportYear)){
            changeAlert_ITR('「目標年度」需大於「目標宣告年」')
        }else if(Number(targetYear)<=Number(targetBaseYear)){
            changeAlert_ITR('「目標年度」需大於「目標基準年」')
        }else{
            if(((Number(targetYear)-Number(targetReportYear) < 5) && periodChoice === 'SHORT') || ((Number(targetYear)-Number(targetReportYear) < 16) && periodChoice === 'MID') || ((Number(targetYear)-Number(targetReportYear) >= 16) && periodChoice === 'LONG')){
                fetchData(true)
            }else if((Number(targetYear)-Number(targetReportYear) < 5) && periodChoice !== 'SHORT'){
                changeAlertBoxText("您確定要更新「短期目標」的ITR資料嗎?")
                changePeriodChoice('SHORT')
                changeAlertBoxOpen(true)
            }else if((Number(targetYear)-Number(targetReportYear) < 16) && periodChoice !== 'MID'){
                changeAlertBoxText("您確定要更新「中期目標」的ITR資料嗎?")
                changePeriodChoice('MID')
                changeAlertBoxOpen(true)
            }else{
                changeAlertBoxText("您確定要更新「長期目標」的ITR資料嗎?")
                changePeriodChoice('LONG')
                changeAlertBoxOpen(true)
            }
        }
    }

    const onITRItemSelect = (period, scope) => {
        if(period !== '' && scope !== ''){
            changeITRScenChecked(true)
            let data = outputData["ITR-detail"][period][scope]
            // Object.keys(data).forEach(key => {
            //     if(data[key] === null){
            //         data[key] = ''
            //     }
            // })
            changeTargetType(data["targetType"] || '')
            changeTargetYear(data["targetYear"] || '')
            changeTargetReportYear(data["targetReportYear"] || '')
            changeTargetBaseYear(data["targetBaseYear"] || '')
            changeDegreeOfReduction(data["degreeOfReduction"] || '')
            changeTargetCoverage(data["targetCoverage"] || '')
            changeBaseYearCE(data["baseYearCE"] || '')
            changRemark(data["remark"] || '')
        }else{
            changeITRScenChecked(false)
        }
    }

    const onCompanyChecked = (bool) => {
        if(!bool){
            changeITRScenChecked(false)
        }
        changeCompanyChecked(bool)
        changePeriodChoice('')
        changeScopeChoice('')
        changeTargetType('')
        changeTargetYear('')
        changeTargetReportYear('')
        changeTargetBaseYear('')
        changeDegreeOfReduction('')
        changeTargetCoverage('')
        changeBaseYearCE('')
        changeOutputData({
            "ITR-overview": {
                "SHORT": {
                    "1+2": '',
                    "3": '',
                    "1+2+3": '',
                },
                "MID": {
                    "1+2": '',
                    "3": '',
                    "1+2+3": '',
                },
                "LONG": {
                    "1+2": '',
                    "3": '',
                    "1+2+3": '',
                }
            }, "updateRecord": {}
        })
    }

    return (
        <div className="new_home_mainContainer">
            <div className="page_container">
                <div className="page_title_container"><p className="page_title_text">單一標的之ITR</p></div>
                <div className="blockContainer" style={{ display: "inline-block", width: "100%" }}>
                    <p className="blockContentTitle">輸入資料</p>
                    <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
                    <div className="blockContent">
                        <div style={{flex:1}}>
                            <div className="inputItemContainer">
                                <div className="inputDataName">統一編號/股票代號</div>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <TextField
                                        style={{marginRight:"30px", width:"100%", textAlign:'center'}}
                                        value={companyID || ''}
                                        variant="outlined"
                                        onChange={(event) => {
                                            changeAlert_company('');
                                            onCompanyChecked(false)
                                            changeCompanyID(event.target.value);
                                        }}
                                        inputProps={{
                                            style:{
                                                padding: '10px',
                                                fontSize: '2vh',
                                                textAlign: 'center'
                                            }
                                        }}
                                        type="text"
                                    />
                                </FormControl>
                            </div>
                            <div className="inputItemContainer">
                                <div className="inputDataName">公司名稱<p style={{display: 'inline-block', fontSize: 'small', margin: '0', color: 'red', position: 'absolute', top: '-5px', paddingLeft: '3px'}}>*</p></div>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <TextField
                                        style={{marginRight:"30px", width:"100%", textAlign:'center'}}
                                        value={companyName}
                                        variant="outlined"
                                        onChange={(event) => {
                                            changeAlert_company('');
                                            onCompanyChecked(false)
                                            changecompanyName(event.target.value);
                                        }}
                                        inputProps={{
                                            style:{
                                                padding: '10px',
                                                fontSize: '2vh',
                                                textAlign: 'center'
                                            }
                                        }}
                                        type="keyword"
                                    />
                                </FormControl>
                            </div>
                            <div className="inputItemContainer">
                                <div className="inputDataName">是否為金融單位</div>
                                <Grid component="label" container alignItems="center" spacing={1} style={{flex: '1'}}>
                                    <Grid item style={{fontSize: '2vh'}}>否</Grid>
                                    <Grid item>
                                        <Switch
                                            checked={isFinance === 'Y'?true:false}
                                            onChange={(event) => {
                                                changeAlert_company('');
                                                changeIsFinance(event.target.checked?'Y':'N');
                                            }}
                                            color='primary'
                                        />
                                    </Grid>
                                    <Grid item style={{fontSize: '2vh'}}>是</Grid>
                                </Grid>
                            </div>
                            <div className="inputItemContainer">
                                <div className="inputDataName">目標是否經SBT認證</div>
                                <Grid component="label" container alignItems="center" spacing={1} style={{flex: '1'}}>
                                    <Grid item style={{fontSize: '2vh'}}>否</Grid>
                                    <Grid item>
                                        <Switch
                                            checked={isSBT === 'Y'?true:false}
                                            onChange={(event) => {
                                                changeAlert_company('');
                                                changeIsSBT(event.target.checked?'Y':'N');
                                            }}
                                            color='primary'
                                        />
                                    </Grid>
                                    <Grid item style={{fontSize: '2vh'}}>是</Grid>
                                </Grid>
                            </div>
                        </div>
                        <div style={{flex:1, display:"flex", flexDirection: "column", justifyContent: "center", alignItems: "start", position: 'relative'}}>
                            <div className="alertBlock" style={{height: '5vh', flex: '3'}}>{alert_company}</div>
                            {
                                companyChecked ?
                                <button className="inputButton" style={{position: 'initial', margin: '1vh auto', flex: '1'}} onClick={() => {onCompanyDataUpdate()}}>更新公司基本資料</button>
                                :<button className="inputButton" style={{position: 'initial', margin: '1vh auto', flex: '1'}} onClick={() => {onCompanyDataUpload()}}>輸入公司基本資料</button>
                            }
                        </div>
                    </div>
                    <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
                    <div className="blockContent" style={{position: "relative"}}>
                        <FormControl variant='standard' className={classes.choiceFormControl}>
                            <InputLabel style={{fontSize:'2vh', fontWeight: 'bold'}}>未來時期選擇<p style={companyChecked?{display: 'inline-block', fontSize: 'small', margin: '0', color: 'red', position: 'absolute', top: '-5px', paddingLeft: '3px'}:{display: 'none'}}>*</p></InputLabel>
                            <Select
                                disabled={!companyChecked}
                                style={{height: '5vh', fontSize: '2vh'}}
                                value={periodChoice}
                                onChange={(event)=>{
                                    changeAlert_ITR('');
                                    changeAlert_company('');
                                    changePeriodChoice(event.target.value);
                                    onITRItemSelect(event.target.value, scopeChoice);
                                }}
                            >
                            {/* <MenuItem value={""}>請選擇未來時期</MenuItem> */}
                            <MenuItem style={{fontSize: '1.8vh'}} value={"SHORT"}>短期</MenuItem>
                            <MenuItem style={{fontSize: '1.8vh'}} value={"MID"}>中期</MenuItem>
                            <MenuItem style={{fontSize: '1.8vh'}} value={"LONG"}>長期</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl variant='standard' className={classes.choiceFormControl}>
                            <InputLabel style={{fontSize:'2vh', fontWeight: 'bold'}}>範疇選擇<p style={companyChecked?{display: 'inline-block', fontSize: 'small', margin: '0', color: 'red', position: 'absolute', top: '-5px', paddingLeft: '3px'}:{display: 'none'}}>*</p></InputLabel>
                            <Select
                                disabled={!companyChecked}
                                style={{height: '5vh', fontSize: '2vh'}}
                                value={scopeChoice}
                                onChange={(event)=>{
                                    changeAlert_ITR('');
                                    changeAlert_company('');
                                    changeScopeChoice(event.target.value)
                                    onITRItemSelect(periodChoice, event.target.value);
                                }}
                            >
                            {/* <MenuItem value={""}>請選擇未來時期</MenuItem> */}
                            <MenuItem style={{fontSize: '1.8vh'}} value={"1"}>範疇一</MenuItem>
                            <MenuItem style={{fontSize: '1.8vh'}} value={"2"}>範疇二</MenuItem>
                            <MenuItem style={{fontSize: '1.8vh'}} value={"1+2"}>範疇一+二</MenuItem>
                            <MenuItem style={{fontSize: '1.8vh'}} value={"3"}>範疇三</MenuItem>
                            <MenuItem style={{fontSize: '1.8vh'}} value={"1+2+3"}>範疇一+二+三</MenuItem>
                            </Select>
                        </FormControl>
                        <a className="questionMark" href={'/#'}>?</a>
                        <ul className="questionInfo">
                            <li>{"短期目標：(目標年度-目標宣告年) <1~4年"}</li>
                            <li>{"中期目標：(目標年度-目標宣告年) =5~15年"}</li>
                            <li>{"長期目標：(目標年度-目標宣告年) >15年"}</li>
                        </ul>
                        {/* <img className="questionIcon" alt="question icon for help" src={questionIcon} /> */}
                    </div>
                    <div className="blockContent">
                        <div style={{flex:1}}>
                            <div className="inputItemContainer">
                                <div className="inputDataName">目標型態<p style={ITRScenChecked?{display: 'inline-block', fontSize: 'small', margin: '0', color: 'red', position: 'absolute', top: '-5px', paddingLeft: '3px'}:{display: 'none'}}>*</p></div>
                                <FormControl variant='outlined' className={classes.formControl}>
                                    <Select
                                        disabled={!ITRScenChecked}
                                        style={{marginRight:"30px", width:"100%", textAlign:'center', fontSize: '2vh'}}
                                        value={targetType}
                                        onChange={(event)=>{
                                            changeAlert_ITR('');
                                            changeAlert_company('');
                                            changeTargetType(event.target.value)
                                        }}
                                        SelectDisplayProps={{
                                            style: {
                                                padding: '10px'
                                            }
                                        }}
                                    >
                                    <MenuItem style={{fontSize: '1.8vh'}} value="絕對碳排放量">絕對碳排放量</MenuItem>
                                    <MenuItem style={{fontSize: '1.8vh'}} value="營收碳排強度">營收碳排強度</MenuItem>
                                    <MenuItem style={{fontSize: '1.8vh'}} value="產量碳排強度">產量碳排強度</MenuItem>
                                    <MenuItem style={{fontSize: '1.8vh'}} value="電力碳排強度">電力碳排強度</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="inputItemContainer">
                                <div className="inputDataName">目標年度<p style={ITRScenChecked?{display: 'inline-block', fontSize: 'small', margin: '0', color: 'red', position: 'absolute', top: '-5px', paddingLeft: '3px'}:{display: 'none'}}>*</p></div>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <TextField
                                        disabled={!ITRScenChecked}
                                        style={{marginRight:"30px", width:"100%", textAlign:'center'}}
                                        value={targetYear || ''}
                                        variant="outlined"
                                        onChange={(event) => {
                                            changeAlert_ITR('');
                                            changeAlert_company('');
                                            changeTargetYear(event.target.value);
                                        }}
                                        inputProps={{
                                            style:{
                                                padding: '10px',
                                                fontSize: '2vh',
                                                textAlign: 'center'
                                            },
                                            min: nowYear-100,
                                            max: nowYear+100
                                        }}
                                        type="number"
                                    />
                                </FormControl>
                            </div>
                            <div className="inputItemContainer">
                                <div className="inputDataName">目標宣告年<p style={ITRScenChecked?{display: 'inline-block', fontSize: 'small', margin: '0', color: 'red', position: 'absolute', top: '-5px', paddingLeft: '3px'}:{display: 'none'}}>*</p></div>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <TextField
                                        disabled={!ITRScenChecked}
                                        style={{marginRight:"30px", width:"100%", textAlign:'center'}}
                                        value={targetReportYear || ''}
                                        variant="outlined"
                                        onChange={(event) => {
                                            changeAlert_ITR('');
                                            changeAlert_company('');
                                            changeTargetReportYear(event.target.value);
                                        }}
                                        inputProps={{
                                            style:{
                                                padding: '10px',
                                                fontSize: '2vh',
                                                textAlign: 'center'
                                            },
                                            min: nowYear-100,
                                            max: nowYear+100
                                        }}
                                        type="number"
                                    />
                                </FormControl>
                            </div>
                            <div className="inputItemContainer">
                                <div className="inputDataName">目標基準年<p style={ITRScenChecked?{display: 'inline-block', fontSize: 'small', margin: '0', color: 'red', position: 'absolute', top: '-5px', paddingLeft: '3px'}:{display: 'none'}}>*</p></div>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <TextField
                                        disabled={!ITRScenChecked}
                                        style={{marginRight:"30px", width:"100%", textAlign:'center'}}
                                        value={targetBaseYear || ''}
                                        variant="outlined"
                                        onChange={(event) => {
                                            changeAlert_ITR('');
                                            changeAlert_company('');
                                            changeTargetBaseYear(event.target.value);
                                        }}
                                        inputProps={{
                                            style:{
                                                padding: '10px',
                                                fontSize: '2vh',
                                                textAlign: 'center'
                                            },
                                            min: nowYear-100,
                                            max: nowYear+100
                                        }}
                                        type="number"
                                    />
                                </FormControl>
                            </div>
                            <div className="inputItemContainer">
                                <div className="inputDataName">目標覆蓋率<p style={ITRScenChecked?{display: 'inline-block', fontSize: 'small', margin: '0', color: 'red', position: 'absolute', top: '-5px', paddingLeft: '3px'}:{display: 'none'}}>*</p></div>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <TextField
                                        disabled={!ITRScenChecked}
                                        style={{marginRight:"30px", width:"100%", textAlign:'center'}}
                                        value={targetCoverage || ''}
                                        variant="outlined"
                                        onChange={(event) => {
                                            changeAlert_ITR('');
                                            changeAlert_company('');
                                            changeTargetCoverage(event.target.value);
                                        }}
                                        inputProps={{
                                            style:{
                                                padding: '10px',
                                                fontSize: '2vh',
                                                textAlign: 'center'
                                            },
                                            min: 0,
                                            max: 100
                                        }}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                            classes: { adornedEnd: classes.adorned }
                                        }}
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <div style={{flex:1, position: 'relative'}}>
                            <div className="inputItemContainer">
                                <div className="inputDataName">減量程度<p style={ITRScenChecked?{display: 'inline-block', fontSize: 'small', margin: '0', color: 'red', position: 'absolute', top: '-5px', paddingLeft: '3px'}:{display: 'none'}}>*</p></div>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <TextField
                                        disabled={!ITRScenChecked}
                                        style={{marginRight:"30px", width:"100%", textAlign:'center'}}
                                        value={degreeOfReduction || ''}
                                        variant="outlined"
                                        onChange={(event) => {
                                            changeAlert_ITR('');
                                            changeAlert_company('');
                                            changeDegreeOfReduction(event.target.value);
                                        }}
                                        inputProps={{
                                            style:{
                                                padding: '10px',
                                                fontSize: '2vh',
                                                textAlign: 'center'
                                            },
                                            min: 0,
                                            max: 100,
                                        }}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                            classes: { adornedEnd: classes.adorned }
                                        }}
                                    />
                                </FormControl>
                            </div>
                            <div className="inputItemContainer">
                                <div className="inputDataName">基準年碳排放<br/>（目標範疇）</div>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <TextField
                                        disabled={!ITRScenChecked}
                                        style={{marginRight:"30px", width:"100%", textAlign:'center'}}
                                        value={baseYearCE || ''}
                                        variant="outlined"
                                        onChange={(event) => {
                                            changeAlert_ITR('');
                                            changeAlert_company('');
                                            changeBaseYearCE(event.target.value);
                                        }}
                                        inputProps={{
                                            style:{
                                                padding: '10px',
                                                fontSize: '2vh',
                                                textAlign: 'center'
                                            },
                                            min: 0
                                        }}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">噸</InputAdornment>,
                                            classes: { adornedEnd: classes.adorned }
                                        }}
                                    />
                                </FormControl>
                            </div>
                            <div className="inputItemContainer" style={{flex: '2', width: '100%', position: 'absolute', bottom: '0', height: 'auto'}}>
                                <div className="inputDataName">備註</div>
                                <FormControl variant="outlined" className={classes.formControl} style={{flex: '4', marginLeft: '0 auto 0 20px'}}>
                                    <TextField
                                        style={{marginRight:"30px", width:"95%", textAlign:'center'}}
                                        value={remark}
                                        variant="outlined"
                                        multiline
                                        rows={5}
                                        onChange={(event) => {
                                            changeAlert_ITR('');
                                            changeAlert_company('');
                                            changRemark(event.target.value);
                                        }}
                                        inputProps={{
                                            style:{
                                                fontSize: '2vh',
                                                textAlign: 'left',
                                            }
                                        }}
                                        InputProps={{
                                            style:{
                                                padding: '10px',
                                            }
                                        }}
                                        type="keyword"
                                    />
                                </FormControl>
                            </div>
                        </div>
                    </div>
                    <div className="blockContent" style={{position: "relative", height: "8vh"}}>
                        <div className="alertBlock" style={{height: '5vh', width: '60%'}}>{alert_ITR}</div>
                        <button className="inputButton" disabled={!ITRScenChecked} style={{position: "absolute", right: "calc(25% - 7.5vw)", display: !ITRScenChecked?"none":"initial"}} onClick={() => {onITRDataUpload(false)}}>更新承諾資料</button>
                        <Modal
                            style={{position: 'absolute', bottom: '0'}}
                            open={alertBoxOpen}
                            onClose={() => {changeAlertBoxOpen(false)}}
                            >
                            <Box className={classes.alertBox} style={{ backgroundColor: 'white' }}>
                                <h4 style={{margin: '15px'}}>{alertBoxText}</h4>
                                <div style={{textAlign: 'center'}}>
                                    <button className="alertBox-button" onClick={() => {onITRDataUpload(true);changeAlertBoxOpen(false)}}>是</button>
                                    <button className="alertBox-button" onClick={() => {changeAlertBoxOpen(false)}}>否</button>
                                </div>
                            </Box>
                        </Modal>
                    </div>
                </div>
                <div className="blockContainer" style={{ display: "inline-block", width: "100%" }}>
                    <p className="blockContentTitle">輸出資料</p>
                    <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
                    <div className="display_header_container">
                        <div className={outputTag === 'overview' ? 'display_header_item_active' : 'display_header_item_inactive'} onClick={() => { changeOutputTag('overview') }}>
                            <p className={outputTag === 'overview' ? 'display_header_item_active_text' : 'display_header_item_inactive_text'} >總覽</p>
                        </div>
                        <div className={outputTag === 'record' ? 'display_header_item_active' : 'display_header_item_inactive'} onClick={() => { changeOutputTag('record') }}>
                            <p className={outputTag === 'record' ? 'display_header_item_active_text' : 'display_header_item_inactive_text'} >資料更新紀錄</p>
                        </div>
                    </div>
                    {
                        outputTag === 'overview'?
                        <div className="blockContent outputTable-container">
                            <div className="outputTable">
                                <div className="outputTable-row">
                                    <p className="outputTable-row-cell">公司名稱</p>
                                    <p className="outputTable-row-cell">範疇1+2</p>
                                    <p className="outputTable-row-cell">範疇3</p>
                                    <p className="outputTable-row-cell">範疇1+2+3</p>
                                </div>
                                <div className="outputTable-row">
                                    <p className="outputTable-row-cell">短期</p>
                                    <p className="outputTable-row-cell">{outputData["ITR-overview"]["SHORT"]["1+2"]}</p>
                                    <p className="outputTable-row-cell">{outputData["ITR-overview"]["SHORT"]["3"]}</p>
                                    <p className="outputTable-row-cell">{outputData["ITR-overview"]["SHORT"]["1+2+3"]}</p>
                                </div>
                                <div className="outputTable-row">
                                    <p className="outputTable-row-cell">中期</p>
                                    <p className="outputTable-row-cell">{outputData["ITR-overview"]["MID"]["1+2"]}</p>
                                    <p className="outputTable-row-cell">{outputData["ITR-overview"]["MID"]["3"]}</p>
                                    <p className="outputTable-row-cell">{outputData["ITR-overview"]["MID"]["1+2+3"]}</p>
                                </div>
                                <div className="outputTable-row">
                                    <p className="outputTable-row-cell">長期</p>
                                    <p className="outputTable-row-cell">{outputData["ITR-overview"]["LONG"]["1+2"]}</p>
                                    <p className="outputTable-row-cell">{outputData["ITR-overview"]["LONG"]["3"]}</p>
                                    <p className="outputTable-row-cell">{outputData["ITR-overview"]["LONG"]["1+2+3"]}</p>
                                </div>
                            </div>
                            <div style={{height: 'fit-content', margin: 'auto 1vw', textAlign: 'center', position: 'relative', flex: 3}}>
                                {/* <CircularChart SHORT={{'範疇1+2': outputData["ITR-overview"]["SHORT"]["1+2"],'範疇3': outputData["ITR-overview"]["SHORT"]["3"], '範疇1+2+3': outputData["ITR-overview"]["SHORT"]["1+2+3"]}} MID={{'範疇1+2': outputData["ITR-overview"]["MID"]["1+2"],'範疇3': outputData["ITR-overview"]["MID"]["3"], '範疇1+2+3': outputData["ITR-overview"]["MID"]["1+2+3"]}} LONG={{'範疇1+2': outputData["ITR-overview"]["LONG"]["1+2"],'範疇3': outputData["ITR-overview"]["LONG"]["3"], '範疇1+2+3': outputData["ITR-overview"]["LONG"]["1+2+3"]}} /> */}
                                {/* <CircularChart SHORT={{'範疇1+2': 3.2,'範疇3': 1.925, '範疇1+2+3': 3.2}} MID={{'範疇1+2': 3.2,'範疇3': 2.235, '範疇1+2+3': 3.2}} LONG={{'範疇1+2': 3.2,'範疇3': 1.925, '範疇1+2+3': 3.2}} /> */}
                                {/* <GaugeChart 
                                    // colors={["green", "yellow", "orange", "red"]} 
                                    arcWidth={0.3} 
                                    percent={(outputData["ITR-overview"]["MID"]["1+2+3"])/5}
                                    textColor={'gray'}
                                    // arcsLength={[1.5, 0.5, 1.2, 1.8]}
                                    nrOfLevels={10}
                                    // formatTextValue={value => (value/100)*6-1+'°C'}
                                    style={{fontWeight: 'bold', maxWidth: '450px', margin: '2vh auto 0 auto'}}
                                    animateDuration={2000}
                                    hideText={true}
                                    arcPadding={0.04}
                                    cornerRadius={4}
                                /> */}
                                <GaugeChart 
                                    colors={["#0a75ad", "#4fb39f", "#F0c51f", "#FF5823", "#b70000"]} 
                                    arcWidth={0.3} 
                                    percent={(outputData["ITR-overview"]["MID"]["1+2+3"])/5}
                                    textColor={'gray'}
                                    arcsLength={[1.5, 0.5, 0.6, 0.6, 1.8]}
                                    // nrOfLevels={5}
                                    // formatTextValue={value => (value/100)*6-1+'°C'}
                                    style={{fontWeight: 'bold', maxWidth: '450px', margin: '2vh auto 0 auto'}}
                                    animateDuration={2000}
                                    hideText={true}
                                    arcPadding={0.03}
                                    cornerRadius={4}
                                />
                                <p style={{fontSize: 34, fontWeight: 'bold', margin: '0'}}>{outputData["ITR-overview"]["MID"]["1+2+3"]+'°C'}</p>
                                <p style={{fontSize: 24, fontWeight: 'bold'}}>中期目標 範疇1+2+3 ITR</p>
                            </div>
                            <div style={{minWidth: '280px', display: 'flex', flex: 4, flexDirection: 'column', margin: 'auto 1vw', padding: '15px'}}>
                                <div style={{display: 'inline-flex', flexDirection: 'row', maxWidth: '400px'}}>
                                    <div className="gaugeChart-legend-item"><div className="gaugeChart-legend-colorLabel" style={{backgroundColor: '#0a75ad'}} /><p className="gaugeChart-legend-text">{`< 1.5°C`}</p></div>
                                    <div className="gaugeChart-legend-item"><div className="gaugeChart-legend-colorLabel" style={{backgroundColor: '#4fb39f'}} /><p className="gaugeChart-legend-text">1.5°C~2°C</p></div>
                                </div>
                                <div style={{display: 'inline-flex', flexDirection: 'row', maxWidth: '400px'}}>
                                    <div className="gaugeChart-legend-item"><div className="gaugeChart-legend-colorLabel" style={{backgroundColor: '#F0c51f'}} /><p className="gaugeChart-legend-text">2°C~2.6°C</p></div>
                                    <div className="gaugeChart-legend-item"><div className="gaugeChart-legend-colorLabel" style={{backgroundColor: '#FF5823'}} /><p className="gaugeChart-legend-text">2.6°C~3.2°C</p></div>
                                </div>
                                <div style={{display: 'inline-flex', flexDirection: 'row', maxWidth: '400px'}}>
                                    <div className="gaugeChart-legend-item"><div className="gaugeChart-legend-colorLabel" style={{backgroundColor: '#b70000'}} /><p className="gaugeChart-legend-text">{`> 3.2°C`}</p></div>
                                </div>
                                <div className="gaugeChart-legend-item"><li>2015年聯合國巴黎協定中各國達成共識將本世紀末全球均溫上升控制在2°C以內，並以1.5°C為積極目標</li></div>
                                <div className="gaugeChart-legend-item"><li>在國家自主貢獻（Unconditional NDCs, NGFS）皆被執行的情境下，世紀末升溫預估將控制在2.6°C</li></div>
                                <div className="gaugeChart-legend-item"><li>在維持現有政策（Current Policies, NGFS）的情境下，世紀末升溫預估將達到3.2°C</li></div>
                            </div>
                        </div>
                        :<div className="blockContent" style={{maxHeight: "80vh", minHeight: "20vh", width: 'calc(100% - 2vw)', overflow: "auto"}}>
                            <div className="recordTable-container">
                                <div className="recordTable-row">
                                    <p className="recordTable-row-cell" style={{width: '80px'}}>股票代號</p>
                                    <p className="recordTable-row-cell" style={{width: '80px'}}>統一編號</p>
                                    <p className="recordTable-row-cell" style={{width: '250px'}}>公司名稱</p>
                                    <p className="recordTable-row-cell" style={{width: '250px'}}>公司名稱（系統帶入）</p>
                                    <p className="recordTable-row-cell" style={{width: '100px'}}>目標宣告年</p>
                                    <p className="recordTable-row-cell" style={{width: '100px'}}>目標基準年</p>
                                    <p className="recordTable-row-cell" style={{width: '80px'}}>目標年度</p>
                                    <p className="recordTable-row-cell" style={{width: '80px'}}>目標時期</p>
                                    <p className="recordTable-row-cell" style={{width: '80px'}}>目標範疇</p>
                                    <p className="recordTable-row-cell" style={{width: '100px'}}>目標覆蓋率</p>
                                    <p className="recordTable-row-cell" style={{width: '80px'}}>減量程度</p>
                                    <p className="recordTable-row-cell" style={{width: '120px'}}>目標型態</p>
                                    <p className="recordTable-row-cell" style={{width: '80px'}}>溫度分數</p>
                                    <p className="recordTable-row-cell" style={{width: '180px'}}>修改時間</p>
                                </div>
                                {Object.values(outputData["updateRecord"]).map((value, index) => {
                                    if(value.companyID !== undefined){
                                        return(
                                            <div className="recordTable-row" key={`updataRecord-${index}`}>
                                                <p className="recordTable-row-cell" style={{width: '80px'}}>{value.companyID === null ? '' : value.companyID.length !== 8 ? value.companyID:''}</p>
                                                <p className="recordTable-row-cell" style={{width: '80px'}}>{value.companyID === null ? '' : value.companyID.length === 8 ? value.companyID:''}</p>
                                                <p className="recordTable-row-cell" style={{width: '250px'}}>{value.companyName}</p>
                                                <p className="recordTable-row-cell" style={{width: '250px'}}>{value.companyNameSys}</p>
                                                <p className="recordTable-row-cell" style={{width: '100px'}}>{value.targetReportYear}</p>
                                                <p className="recordTable-row-cell" style={{width: '100px'}}>{value.targetBaseYear}</p>
                                                <p className="recordTable-row-cell" style={{width: '80px'}}>{value.targetYear}</p>
                                                <p className="recordTable-row-cell" style={{width: '80px'}}>{value.periodChoice === 'SHORT' ? '短期' :value.periodChoice === 'MID' ? '中期' : value.periodChoice === 'LONG' ? '長期' : ''}</p>
                                                <p className="recordTable-row-cell" style={{width: '80px'}}>{value.scopeChoice}</p>
                                                <p className="recordTable-row-cell" style={{width: '100px'}}>{value.targetCoverage}</p>
                                                <p className="recordTable-row-cell" style={{width: '80px'}}>{value.degreeOfReduction}</p>
                                                <p className="recordTable-row-cell" style={{width: '120px'}}>{value.targetType}</p>
                                                <p className="recordTable-row-cell" style={{width: '80px'}}>{value.itr}</p>
                                                <p className="recordTable-row-cell" style={{width: '180px'}}>{value.created_time}</p>
                                            </div>
                                        )
                                    }else{
                                        return(<div />)
                                    }
                                })}
                            </div>
                        </div>
                    }
                </div>

            </div>
            <Menu
                account={account}
                permission={permission}
                cookies={cookies}
                removeCookie={removeCookie}
                activeScreen={activeScreen}
                switchScreen={switchScreen}
                switchShellChoice={switchShellChoice}
                changeDisplayMethod={changeDisplayMethod}
            />
        </div>
    );
}

export default SingleTargetITR;