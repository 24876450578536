import React from 'react';
import ClimateChoice from '../component/ClimateChoice';
import ShellChoice from '../component/ShellChoice';
import ShellSteps from '../component/ShellSteps';
import DataValidation from'../component/DataValidation';
import Execution from '../component/Execution';

function Process(props) {
  return (
    <div>
      <div className="process_top_container">
        <div><img className="closeSubMenu_button" src={require("../img/icon-close-1.svg")} alt="closeSubMenu" onClick={() => {props.changeSubMenuNow('None')}} /></div>
        <ShellChoice 
          account={props.account} 
          permission={props.permission}
          shellChoice={props.shellChoice} 
          switchShellChoice={props.switchShellChoice} 
          changeDisplayMethod={props.changeDisplayMethod} 
          changeDisplayData={props.changeDisplayData}
          changeDisplayDataState={props.changeDisplayDataState} />
        <ClimateChoice 
          yearChoice={props.yearChoice} 
          switchYearChoice={props.switchYearChoice} 
          CSChoice={props.CSChoice} 
          switchCSChoice={props.switchCSChoice}
          changeDisplayDataState={props.changeDisplayDataState} />
      </div>
      <ShellSteps
        shellChoice={props.shellChoice}
        processList={props.processList} 
        processData={props.processData} 
        processOrder={props.processOrder}
        processData_value={props.processData_value}
        processData_list={props.processData_list}
        getProcessData_list={props.getProcessData_list} 
        changeProcessData_value={props.changeProcessData_value}
        changeDisplayDataState={props.changeDisplayDataState}
      />
      <div className="process_bottom_container">
        <DataValidation />
        <Execution onExecutionClick={props.onExecutionClick} />
      </div>
    </div>
  );
}

export default Process;