import React from 'react';

function Logout() {

    return (
        <div className="login_page_container">
            <div style={{ position: 'absolute', backgroundSize: 'cover', width: '100vw', height: '100vh', backgroundImage: `url(${require('../img/login-bg-2@2x.png')})` }}>
                {/* <div style={{ backgroundSize: 'cover', width: '100vw', height: '100vh', position: 'absolute', top: '0', left: '0' }}> */}
                <p className='background_text' style={{ paddingTop: '40vh', paddingBottom: '40vh', height: '20vh' }}>您已成功登出Ascelleia平台，若要繼續使用此服務，請重新整理網頁。</p>
                {/* </div> */}
            </div>
        </div>
    );
}

export default Logout;