import React from 'react';
import Gauge from 'react-gauge-chart';
import '../../style/GaugeChart.css';

const numberFormat = (value) => {
    if(typeof value === 'number'){
        return Number(value).toLocaleString('en')
    }else{
        return value
    }
}

function GaugeChart(props) {

    const {
        title,
        subtitle,
        arcsLength,
        color,
        data,
        colorLegend,
        hideText,
        min,
        max,
        nrOfLevels,
        arcWidth,
        arcPadding,
        cornerRadius,
    } = props
    return (
        <div style={{position: 'relative', padding: '20px'}}>
            <p style={{fontSize: '22px', fontWeight: 'bold', color: 'gray', textAlign: 'center', margin: '0 auto'}}>{title}</p>
            <p style={{fontSize: '18px', color: 'gray', textAlign: 'center', margin: '10px auto'}}>{subtitle}</p>
            <Gauge
                colors={colorLegend} 
                arcWidth={arcWidth} 
                // percent={Math.round(value['itr']*10)/10/5}
                percent={data/100}
                textColor={color}
                arcsLength={arcsLength}
                nrOfLevels={nrOfLevels}
                // formatTextValue={value => (value/100)*6-1+'°C'}
                style={{fontSize: '1vh', fontWeight: 'bold', width: '100%', margin: `${min===undefined? '0' : '3vh 0'}`}}
                animateDuration={2000}
                hideText={hideText}
                text={`${data/100}`}
                arcPadding={arcPadding}
                cornerRadius={cornerRadius}
                // label={chart_item['label']}
                // unit={chart_item['unit']}
                // dependent_chart={chart_item['dependent_chart'] || ''}
            />
            {
                hideText ? '' :
                <div className='label_container'>
                    <p className='label_text' style={{marginLeft: '10%'}}>{min}</p>
                    <p className='label_text' style={{marginRight: '10%'}}>{max}</p>
                </div>
            }
        </div>
    );
}

export default GaugeChart