import React from 'react';
import GaugeChart from 'react-gauge-chart'


function InvestmentITR_itr(props) {
    const {
        resultData
    } = props

    // console.log(resultData)
    return (
        <div>
            <p style={{margin: '20px 20px 0 20px'}}>註:投融資組合ITR計算方法採用「投融資餘額權重分配法」(WATS，Weighted average temperature score )，本法係以各標的之投融資餘額作為ITR的權重分配依據加以計算而來。</p>
            <div style={{ display: "inline-flex", flexWrap: 'wrap', justifyContent: "left", width: '100%', margin: '2vh 0', overflowX: 'scroll' }}>{
                Object.entries(resultData).map(([key, value], index) => {
                    return(
                        <div key={`ITR_result_${index}`} style={{height: 'fit-content', margin: '0 0 2vh 0', textAlign: 'center', minWidth: '300px'}}>
                            <p style={{fontSize: 24, fontWeight: 'bold'}}>{value['type']}</p>
                            <GaugeChart 
                                colors={["#0a75ad", "#4fb39f", "#F0c51f", "#FF5823", "#b70000"]} 
                                arcWidth={0.3} 
                                percent={Math.round(value['itr']*10)/10/5}
                                textColor={'gray'}
                                arcsLength={[1.5, 0.5, 0.6, 0.6, 1.8]}
                                nrOfLevels={5}
                                // formatTextValue={value => (value/100)*6-1+'°C'}
                                style={{fontWeight: 'bold', maxWidth: '450px', margin: '2vh auto 0 auto'}}
                                animateDuration={2000}
                                hideText={true}
                                arcPadding={0.03}
                                cornerRadius={4}
                            />
                            <p style={{fontSize: 34, fontWeight: 'bold', margin: '0'}}>{`${Math.round(value['itr']*10)/10}°C`}</p>
                        </div>)
                })}
                <div style={{minWidth: '280px', display: 'flex', flex: 4, flexDirection: 'column', margin: 'auto 1vw', padding: '15px', background: '#eee', borderRadius: '5px'}}>
                    <div style={{display: 'inline-flex', flexDirection: 'row', maxWidth: '400px'}}>
                        <div className="gaugeChart-legend-item"><div className="gaugeChart-legend-colorLabel" style={{backgroundColor: '#0a75ad'}} /><p className="gaugeChart-legend-text">{`< 1.5°C`}</p></div>
                        <div className="gaugeChart-legend-item"><div className="gaugeChart-legend-colorLabel" style={{backgroundColor: '#4fb39f'}} /><p className="gaugeChart-legend-text">1.5°C~2°C</p></div>
                    </div>
                    <div style={{display: 'inline-flex', flexDirection: 'row', maxWidth: '400px'}}>
                        <div className="gaugeChart-legend-item"><div className="gaugeChart-legend-colorLabel" style={{backgroundColor: '#F0c51f'}} /><p className="gaugeChart-legend-text">2°C~2.6°C</p></div>
                        <div className="gaugeChart-legend-item"><div className="gaugeChart-legend-colorLabel" style={{backgroundColor: '#FF5823'}} /><p className="gaugeChart-legend-text">2.6°C~3.2°C</p></div>
                    </div>
                    <div style={{display: 'inline-flex', flexDirection: 'row', maxWidth: '400px'}}>
                        <div className="gaugeChart-legend-item"><div className="gaugeChart-legend-colorLabel" style={{backgroundColor: '#b70000'}} /><p className="gaugeChart-legend-text">{`> 3.2°C`}</p></div>
                    </div>
                    <div className="gaugeChart-legend-item"><li>2015年聯合國巴黎協定中各國達成共識將本世紀末全球均溫上升控制在2°C以內，並以1.5°C為積極目標</li></div>
                    <div className="gaugeChart-legend-item"><li>在國家自主貢獻（Unconditional NDCs, NGFS）皆被執行的情境下，世紀末升溫預估將控制在2.6°C</li></div>
                    <div className="gaugeChart-legend-item"><li>在維持現有政策（Current Policies, NGFS）的情境下，世紀末升溫預估將達到3.2°C</li></div>
                </div>
            </div>
        </div>
        
    );
}

export default InvestmentITR_itr;