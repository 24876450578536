import React from 'react';
import "../style/style.css";
import { makeStyles } from '@material-ui/core/styles';
import Menu from './Menu';

const useStyles = makeStyles((theme) => ({
    blockContent: {
        margin: "1.5vh 1.5vw",
        padding: "3vh 1.5vw 1vh"
    },
    sectorTitle: {
        fontWeight: "bold",
        textAlign: "center",
        backgroundColor: "gray",
        color: "white",
        fontSize: "2.7vh",
        lineHeight: "4.5vh",
        marginBottom: "2vh",
    },
    sectorSecondTitle: {
        textAlign: "center",
        backgroundColor: "#081F8F",
        color: "white",
        fontSize: "2.7vh",
        lineHeight: "3.5vh",
        marginBottom: "1vh",
    },
    sectorTableTitle: {
        textAlign: "center",
        fontSize: "2.3vh",
        lineHeight: "3.5vh",
        margin: "2vh 0 1vh",
        borderRadius: "0.8vh",
        border: "1.5px solid",
    },
    sectorTableBlue: {
        paddingTop: "0.4vh",
        paddingLeft: "0.5vw",
        paddingRight: "0.5vw",
        borderBottom: "solid 1px #081F8F",
        borderLeft: "solid 1px #081F8F",
        margin: "0",
        fontFamily: "Roboto",
        fontSize: "2vh",
        lineHeight: "1.71",
    },
    sectorTableGreen: {
        paddingTop: "0.4vh",
        paddingLeft: "0.5vw",
        paddingRight: "0.5vw",
        borderBottom: "solid 1px #345F6B",
        borderLeft: "solid 1px #345F6B",
        margin: "0",
        fontFamily: "Roboto",
        fontSize: "2vh",
        lineHeight: "1.71",
    },
    sectorTableBlueLight: {
        paddingTop: "0.4vh",
        paddingLeft: "0.5vw",
        paddingRight: "0.5vw",
        borderBottom: "solid 1px #345F6B",
        borderLeft: "solid 1px #345F6B",
        margin: "0",
        fontFamily: "Roboto",
        fontSize: "2vh",
        lineHeight: "1.35",
    }
}))

function StressTest(props) {
    const classes = useStyles();

    const {
        account,
        permission,
        cookies,
        removeCookie,
        activeScreen,
        switchScreen,
        switchShellChoice,
        changeDisplayMethod, } = props

    const tableObject = {
        year: [2030, 2031, 2032, 2033, 2034, 2035, 2036, 2037, 2038, 2039, 2040, 2041, 2042, 2043, 2044, 2045, 2046, 2047, 2048, 2049],
        amount: [
            '$ 209,742.68', '$ 199,255.55', '$ 189,292.77', '$ 179,828.13', '$ 170,836.73', '$ 162,294.89', '$ 154,180.14', '$ 146,471.14', '$ 139,147.58', '$ 132,190.20',
            '$ 125,580.69', '$ 119,301.66', '$ 113,336.57', '$ 107,669.75', '$ 102,286.26', '$  97,171.95', '$  92,313.35', '$  87,697.68', '$  83,312.80', '$  79,147.16'
        ],
        pdDelta: [-0.03, 0.02, 0.06, 0.10, 0.14, 0.19, 0.28, 0.38, 0.47, 0.56, 0.66, 0.68, 0.71, 0.73, 0.76, 0.79, 0.82, 0.85, 0.88, 0.92],
        lgd: [50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50],
        financeImpact: [
            '-$ 28.59', '$ 15.72', '$ 55.68', '$ 91.60', '$ 123.79', '$ 152.53', '$ 217.26', '$ 275.14', '$ 326.69', '$ 372.39',
            '$ 412.71', '$ 407.35', '$ 401.49', '$ 395.20', '$ 388.53', '$ 381.54', '$ 377.65', '$ 373.20', '$ 368.25', '$ 362.86'
        ]
    }

    return (
        <div className="new_home_mainContainer">
            <div className="page_container">
                <div className="page_title_container"><p className="page_title_text">氣候變遷壓力測試流程展示</p></div>
                <div className="DataDownload_block" style={{ display: "block", width: "100%", marginBottom: "2vh" }}>
                    <div className={classes.blockContent}>
                        <img src={require(`../img/stresstest/main.png`)} alt='氣候變遷壓力測試流程' style={{ width: "100%" }} />
                    </div>
                </div>
                <div className="display_container">
                    <div className="display_header_container">
                        <div className={props.displayMethod === 'step0' ? 'display_header_item_active' : 'display_header_item_inactive'} onClick={() => { props.switchDisplayMethod('step0') }}>
                            <p className={props.displayMethod === 'step0' ? 'display_header_item_active_text' : 'display_header_item_inactive_text'} >0. 前期準備</p>
                        </div>
                        <div className={props.displayMethod === 'step1' ? 'display_header_item_active' : 'display_header_item_inactive'} onClick={() => { props.switchDisplayMethod('step1') }}>
                            <p className={props.displayMethod === 'step1' ? 'display_header_item_active_text' : 'display_header_item_inactive_text'} >1. 情境定義</p>
                        </div>
                        <div className={props.displayMethod === 'step2' ? 'display_header_item_active' : 'display_header_item_inactive'} onClick={() => { props.switchDisplayMethod('step2') }}>
                            <p className={props.displayMethod === 'step2' ? 'display_header_item_active_text' : 'display_header_item_inactive_text'} >2. 衝擊因子</p>
                        </div>
                        <div className={props.displayMethod === 'step3' ? 'display_header_item_active' : 'display_header_item_inactive'} onClick={() => { props.switchDisplayMethod('step3') }}>
                            <p className={props.displayMethod === 'step3' ? 'display_header_item_active_text' : 'display_header_item_inactive_text'} >3.鏈結要素</p>
                        </div>
                        <div className={props.displayMethod === 'step4' ? 'display_header_item_active' : 'display_header_item_inactive'} onClick={() => { props.switchDisplayMethod('step4') }}>
                            <p className={props.displayMethod === 'step4' ? 'display_header_item_active_text' : 'display_header_item_inactive_text'} >4.風險計算</p>
                        </div>
                        <div className={props.displayMethod === 'step5' ? 'display_header_item_active' : 'display_header_item_inactive'} onClick={() => { props.switchDisplayMethod('step5') }}>
                            <p className={props.displayMethod === 'step5' ? 'display_header_item_active_text' : 'display_header_item_inactive_text'} >5.結果與應用</p>
                        </div>
                    </div>
                    {
                        {
                            'step0':
                                <div className="display_body_container">
                                    <div style={{ display: "flex" }}>
                                        <div style={{ width: "50%", margin: "1vh 1vw" }}>
                                            <div className={classes.sectorTitle}>暴險盤點：不動產</div>
                                            <img src={require(`../img/stresstest/0-1.png`)} alt='暴險盤點：不動產' style={{ width: "100%", height: "20vh" }} />
                                            <div className={classes.sectorTableTitle} style={{ color: "#081F8F", borderColor: "#081F8F" }}>不動產暴險盤點內容</div>
                                            <div style={{ display: 'grid', width: 'fit-content', height: 'fit-content', border: 'solid 1.5px #081F8F', margin: '1vh auto 1vh' }}>
                                                <div style={{ display: 'inline-flex', textAlign: "center" }}>
                                                    <div className={classes.sectorTableBlue} style={{ width: '16vw', borderLeft: "none" }}>標的地址</div>
                                                    <div className={classes.sectorTableBlue} style={{ width: '5vw' }}>暴險金額</div>
                                                    <div className={classes.sectorTableBlue} style={{ width: '5vw' }}>建物型態</div>
                                                    <div className={classes.sectorTableBlue} style={{ width: '3vw' }}>總樓層</div>
                                                </div>
                                                <div style={{ display: 'inline-flex' }}>
                                                    <div style={{ display: 'grid' }}>
                                                        <div className={classes.sectorTableBlue} style={{ width: '16vw', borderLeft: "none" }}>臺北市中山區中山北路一段126巷14號</div>
                                                        <div className={classes.sectorTableBlue} style={{ width: '16vw', borderLeft: "none" }}>臺北市中山區中山北路二段118號2樓</div>
                                                        <div className={classes.sectorTableBlue} style={{ width: '16vw', borderLeft: "none" }}>臺北市中山區民權東路二段134號</div>
                                                        <div className={classes.sectorTableBlue} style={{ width: '16vw', borderLeft: "none" }}>臺北市中山區合江街144巷23之3號</div>
                                                    </div>
                                                    <div style={{ display: 'grid' }}>
                                                        <div className={classes.sectorTableBlue} style={{ width: '5vw' }}>$ 731,000</div>
                                                        <div className={classes.sectorTableBlue} style={{ width: '5vw' }}>$1,520,000</div>
                                                        <div className={classes.sectorTableBlue} style={{ width: '5vw' }}>$1,424,500</div>
                                                        <div className={classes.sectorTableBlue} style={{ width: '5vw' }}>$ 892,000</div>
                                                    </div>
                                                    <div style={{ display: 'grid' }}>
                                                        <div className={classes.sectorTableBlue} style={{ width: '5vw' }}>住宅大樓</div>
                                                        <div className={classes.sectorTableBlue} style={{ width: '5vw' }}>住宅大樓</div>
                                                        <div className={classes.sectorTableBlue} style={{ width: '5vw' }}>透天</div>
                                                        <div className={classes.sectorTableBlue} style={{ width: '5vw' }}>公寓</div>
                                                    </div>
                                                    <div style={{ display: 'grid' }}>
                                                        <div className={classes.sectorTableBlue} style={{ width: '3vw' }}>13</div>
                                                        <div className={classes.sectorTableBlue} style={{ width: '3vw' }}>14</div>
                                                        <div className={classes.sectorTableBlue} style={{ width: '3vw' }}>3</div>
                                                        <div className={classes.sectorTableBlue} style={{ width: '3vw' }}>5</div>
                                                    </div>
                                                </div>
                                                <div style={{ display: 'inline-flex' }}>
                                                    <div className={classes.sectorTableBlue} style={{ width: '16vw', textAlign: "center", border: "none" }}>台北市中山區統計</div>
                                                    <div className={classes.sectorTableBlue} style={{ width: '15vw', borderBottom: "none" }}>$4,567,500</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ width: "50%", margin: "1vh 1vw" }}>
                                            <div className={classes.sectorTitle}>暴險盤點：投融資標的產業類別</div>
                                            <img src={require(`../img/stresstest/0-2.png`)} alt='暴險盤點：投融資標的產業類別' style={{ width: "100%", height: "20vh" }} />
                                            <div className={classes.sectorTableTitle} style={{ color: "#345F6B", borderColor: "#345F6B" }}>投融資標的暴險盤點內容</div>
                                            <div style={{ display: 'grid', width: 'fit-content', height: 'fit-content', border: 'solid 1.5px #345F6B', margin: '1vh auto 1vh' }}>
                                                <div style={{ display: 'inline-flex', textAlign: "center" }}>
                                                    <div className={classes.sectorTableGreen} style={{ width: '4vw', borderLeft: "none" }}>案件類別</div>
                                                    <div className={classes.sectorTableGreen} style={{ width: '4vw' }}>統一編號</div>
                                                    <div className={classes.sectorTableGreen} style={{ width: '9vw' }}>產業別</div>
                                                    <div className={classes.sectorTableGreen} style={{ width: '3vw' }}>國家</div>
                                                    <div className={classes.sectorTableGreen} style={{ width: '4vw' }}>到期時間</div>
                                                    <div className={classes.sectorTableGreen} style={{ width: '5vw' }}>業務金額</div>
                                                </div>
                                                <div style={{ display: 'inline-flex' }}>
                                                    <div style={{ display: 'grid' }}>
                                                        <div className={classes.sectorTableGreen} style={{ width: '4vw', borderLeft: "none" }}>債券投資</div>
                                                        <div className={classes.sectorTableGreen} style={{ width: '4vw', borderLeft: "none" }}>債券投資</div>
                                                        <div className={classes.sectorTableGreen} style={{ width: '4vw', borderLeft: "none" }}>債券投資</div>
                                                        <div className={classes.sectorTableGreen} style={{ width: '4vw', borderLeft: "none" }}>債券投資</div>
                                                    </div>
                                                    <div style={{ display: 'grid' }}>
                                                        <div className={classes.sectorTableGreen} style={{ width: '4vw' }}>34051920</div>
                                                        <div className={classes.sectorTableGreen} style={{ width: '4vw' }}>04401392</div>
                                                        <div className={classes.sectorTableGreen} style={{ width: '4vw', color: "#00000000" }}>1</div>
                                                        <div className={classes.sectorTableGreen} style={{ width: '4vw', color: "#00000000" }}>1</div>
                                                    </div>
                                                    <div style={{ display: 'grid' }}>
                                                        <div className={classes.sectorTableGreen} style={{ width: '9vw' }}>35電力及燃氣供應業</div>
                                                        <div className={classes.sectorTableGreen} style={{ width: '9vw' }}>35電力及燃氣供應業</div>
                                                        <div className={classes.sectorTableGreen} style={{ width: '9vw' }}>35電力及燃氣供應業</div>
                                                        <div className={classes.sectorTableGreen} style={{ width: '9vw' }}>35電力及燃氣供應業</div>
                                                    </div>
                                                    <div style={{ display: 'grid' }}>
                                                        <div className={classes.sectorTableGreen} style={{ width: '3vw' }}>台灣</div>
                                                        <div className={classes.sectorTableGreen} style={{ width: '3vw' }}>台灣</div>
                                                        <div className={classes.sectorTableGreen} style={{ width: '3vw' }}>美國</div>
                                                        <div className={classes.sectorTableGreen} style={{ width: '3vw' }}>中國</div>
                                                    </div>
                                                    <div style={{ display: 'grid' }}>
                                                        <div className={classes.sectorTableGreen} style={{ width: '4vw' }}>2030</div>
                                                        <div className={classes.sectorTableGreen} style={{ width: '4vw' }}>2035</div>
                                                        <div className={classes.sectorTableGreen} style={{ width: '4vw' }}>2035</div>
                                                        <div className={classes.sectorTableGreen} style={{ width: '4vw' }}>2040</div>
                                                    </div>
                                                    <div style={{ display: 'grid' }}>
                                                        <div className={classes.sectorTableGreen} style={{ width: '5vw' }}>$1,250,000</div>
                                                        <div className={classes.sectorTableGreen} style={{ width: '5vw' }}>$5,315,000</div>
                                                        <div className={classes.sectorTableGreen} style={{ width: '5vw' }}>$1,424,500</div>
                                                        <div className={classes.sectorTableGreen} style={{ width: '5vw' }}>$2,954,000</div>
                                                    </div>
                                                </div>
                                                <div style={{ display: 'inline-flex' }}>
                                                    <div className={classes.sectorTableGreen} style={{ width: '23vw', textAlign: "center", border: "none" }}>35電力及燃氣供應業 暴險統計</div>
                                                    <div className={classes.sectorTableGreen} style={{ borderBottom: "none" }}>$10,943,500</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>,
                            'step1':
                                <div className="display_body_container">
                                    <div className={classes.sectorTitle}>全球通用情境說明</div>
                                    <div style={{ display: "flex" }}>
                                        <div style={{ width: "50%" }}>
                                            <img src={require(`../img/stresstest/1-1.png`)} alt='全球通用情境說明1' style={{ width: "100%" }} />
                                            <img src={require(`../img/stresstest/1-2.png`)} alt='全球通用情境說明2' style={{ width: "100%" }} />
                                        </div>
                                        <div style={{ display: "flex", width: "50%" }}>
                                            <div style={{ width: "50%", fontSize: "1.8vh" }}>
                                                <ul>
                                                    <li>RCP2.6:屬於暖化減緩的情境，是唯一符合《巴黎協定》規定2°C目標，於21 世紀末增加2.6W (約升溫0.9度~2.3度)，且全球溫室氣體排放量為負值。</li>
                                                    <li>RCP8.5:屬於高度排放情境，表示各國並無任何減量作為，以致溫室氣體排放量不斷增加，於21 世紀末增加8.5W (約升溫 3.2 度~5.4度)。</li>
                                                </ul>
                                                <ul>
                                                    <li>NGFS(Network for Greening the Financial System，綠色金融網絡) 所提供之Orderly、Disorderly與Hot house world三種，代表未來的社會轉型力道是線性、非線性還是沒有積極轉型。歐洲大部分銀行之壓力測試採用情境。</li>
                                                </ul>
                                            </div>
                                            <div style={{ width: "50%", marginLeft: "0.3vw" }}>
                                                <img src={require(`../img/stresstest/1-3.jpg`)} alt='IPCC情境' style={{ width: "100%" }} />
                                                <img src={require(`../img/stresstest/1-4.png`)} alt='NGFS情境' style={{ width: "100%" }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>,
                            'step2':
                                <div className="display_body_container">
                                    <div style={{ display: "flex" }}>
                                        <div style={{ width: "50%", margin: "1vh 1vw" }}>
                                            <div className={classes.sectorTitle}>個體經濟通道－實體因子</div>
                                            <div style={{ fontWeight: "bolder", fontSize: "2.5vh", textAlign: "center" }}>
                                                不動產及營運據點主要受到的衝擊因子：<br />其個體經濟通道與位置相關
                                            </div>
                                            <div style={{ fontSize: "2.5vh" }}>
                                                <ul>
                                                    <li>依各個行政區之設計排水標準與氣候變遷下之降雨量做比較，作為各區域淹水機率之計算基準。</li>
                                                    <li>共有2個分行會在最高等級危害的區域(淹水機率{`>`}50%)；10個分行的淹水機率會介於20~50%之間</li>
                                                </ul>
                                            </div>
                                            <div style={{ display: "flex", marginTop: "1vh" }}>
                                                <div style={{ width: "50%", padding: "0 3vw" }}><img src={require(`../img/stresstest/2-1.png`)} alt='RCP2.6淹水機率' style={{ width: "100%" }} /></div>
                                                <div style={{ width: "50%", padding: "0 3vw" }}><img src={require(`../img/stresstest/2-2.png`)} alt='RCP8.5淹水機率' style={{ width: "100%" }} /></div>
                                            </div>
                                        </div>
                                        <div style={{ width: "50%", margin: "1vh 1vw" }}>
                                            <div className={classes.sectorTitle}>個體經濟通道－轉型因子</div>
                                            <div style={{ fontWeight: "bolder", fontSize: "2.5vh", textAlign: "center" }}>
                                                投融資相關資產主要受到的衝擊因子：<br />其個體經濟通道與產業及所屬國家相關
                                            </div>
                                            <div style={{ display: "flex", marginTop: "2vh", fontSize: "2.3vh" }}>
                                                <div style={{ width: "50%", marginRight: "0.5vh" }}>
                                                    <div className={classes.sectorSecondTitle}>國家風險等級定義</div>
                                                    <img src={require(`../img/stresstest/2-3.png`)} alt='國家風險等級定義' style={{ width: "100%", height: "35vh" }} />
                                                    以兩項判斷做為風險數值計算：
                                                    <ol>
                                                        <li>國家電力排碳係數</li>
                                                        <li>國家碳中和相關政策</li>
                                                    </ol>
                                                    <br />分數4分以下為低風險等級、5-7分為中風險等級、8分以上為高風險等級
                                                </div>
                                                <div style={{ width: "50%" }}>
                                                    <div className={classes.sectorSecondTitle}>產業風險等級定義</div>
                                                    <img src={require(`../img/stresstest/2-4.png`)} alt='產業風險等級定義' style={{ width: "100%", height: "35vh" }} />
                                                    以兩項判斷做為風險數值計算：
                                                    <ol>
                                                        <li>產業碳排係數</li>
                                                        <li>依據國際標準是否判定為高碳排及高敏感產業</li>
                                                    </ol>
                                                    分數2分以下為低風險等級、3-4分為中風險等級、5分以上為高風險等級
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>,
                            'step3':
                                <div className="display_body_container">
                                    <div style={{ display: "flex" }}>
                                        <div style={{ width: "50%", margin: "1vh 1vw" }}>
                                            <div className={classes.sectorTitle}>分析標的與衝擊因子間之關聯性－實體風險</div>
                                            <img src={require(`../img/stresstest/3-1-1.png`)} alt='分析標的與衝擊因子間之關聯性－實體風險' style={{ width: "100%" }} />
                                            <div style={{ display: "flex", marginTop: "1vh" }}>
                                                <div style={{ width: "50%", padding: "0 1vw" }}><img alt='risk map with RN, RCP2.6,and 2030s scenario' src={require(`../img/stresstest/3-1-2.png`)} style={{ width: "100%" }} /></div>
                                                <div style={{ width: "50%", padding: "0 1vw" }}><img alt='risk map with RN, RCP8.5 ,and 2030s scenario' src={require(`../img/stresstest/3-1-3.png`)} style={{ width: "100%" }} /></div>
                                            </div>
                                        </div>
                                        <div style={{ width: "50%", margin: "1vh 1vw" }}>
                                            <div className={classes.sectorTitle}>分析標的與衝擊因子間之關聯性－轉型風險</div>
                                            <img src={require(`../img/stresstest/3-2.png`)} alt='分析標的與衝擊因子間之關聯性－轉型風險' style={{ width: "100%" }} />
                                            <div style={{ borderRadius: "0.8vh", border: "3px solid #fad71a", textAlign: "center", fontSize: "2.5vh", lineHeight: "4vh", margin: "5vh auto 0", width: "70%" }}>
                                                依購買憑證之WTP(willing to pay)<br />計算一公噸碳的價格為 5570NTD(198USD)
                                            </div>
                                        </div>
                                    </div>
                                </div>,
                            'step4':
                                <div className="display_body_container">
                                    <div style={{ display: "flex" }}>
                                        <div style={{ width: "50%", margin: "1vh 1vw" }}>
                                            <div className={classes.sectorTitle}>風險衝擊結果－房貸抵押品實體風險衝擊</div>
                                            <div style={{ marginBottom: "1vh", border: "1.5px solid #081F8F", textAlign: "center", padding: "0.5vh 0.5vw" }}>
                                                <img src={require(`../img/stresstest/4-1.png`)} alt='風險衝擊結果－房貸抵押品實體風險衝擊結果表格' style={{ width: "100%" }} />
                                            </div>
                                            <div style={{ marginBottom: "1vh", border: "1.5px solid #081F8F", textAlign: "center", padding: "0.5vh 0.5vw" }}>
                                                <img src={require(`../img/stresstest/4-2.png`)} alt='風險衝擊結果－房貸抵押品實體風險衝擊結果圖像化統計' style={{ width: "100%" }} />
                                            </div>
                                        </div>
                                        <div style={{ width: "50%", margin: "1vh 1vw" }}>
                                            <div className={classes.sectorTitle}>風險衝擊結果－高碳排產業信用風險財務衝擊</div>
                                            <div style={{ display: 'grid', width: 'fit-content', height: 'fit-content', border: 'solid 1.5px #345F6B', margin: '1vh auto 1vh' }}>
                                                <div style={{ display: 'inline-flex', textAlign: "center", backgroundColor: "#79AFDA", color: "#ffffff" }}>
                                                    <div className={classes.sectorTableBlueLight} style={{ width: '4vw', borderLeft: "none" }}>年度</div>
                                                    <div className={classes.sectorTableBlueLight} style={{ width: '7vw' }}>鋪顯額 (百萬)</div>
                                                    <div className={classes.sectorTableBlueLight} style={{ width: '4vw' }}>PD差異</div>
                                                    <div className={classes.sectorTableBlueLight} style={{ width: '4vw' }}>LGD</div>
                                                    <div className={classes.sectorTableBlueLight} style={{ width: '8vw' }}>財務衝擊(百萬)</div>
                                                </div>
                                                <div style={{ display: 'inline-flex', backgroundColor: "#E4EFF8", borderColor: "#345F6B" }}>
                                                    <div style={{ display: 'grid' }}>
                                                        {Object
                                                            .entries(tableObject.year)
                                                            .map(([id, data]) => <div key={id} className={classes.sectorTableBlueLight} style={{ width: '4vw', borderLeft: "none" }}>{data}</div>)
                                                        }
                                                        <div className={classes.sectorTableGreen} style={{ width: '4vw', borderBottom: "none", borderLeft: "none" }}>2050</div>
                                                    </div>
                                                    <div style={{ display: 'grid' }}>
                                                        {Object
                                                            .entries(tableObject.amount)
                                                            .map(([id, data]) => <div key={id} className={classes.sectorTableBlueLight} style={{ width: '7vw' }}>{data}</div>)
                                                        }
                                                        <div className={classes.sectorTableGreen} style={{ width: '7vw', borderBottom: "none" }}>$ 75,189.80</div>
                                                    </div>
                                                    <div style={{ display: 'grid' }}>
                                                        {Object
                                                            .entries(tableObject.pdDelta)
                                                            .map(([id, data]) => <div key={id} className={classes.sectorTableBlueLight} style={{ width: '4vw' }}>{data}%</div>)
                                                        }
                                                        <div className={classes.sectorTableGreen} style={{ width: '4vw', borderBottom: "none" }}>0.95%</div>
                                                    </div>
                                                    <div style={{ display: 'grid' }}>
                                                        {Object
                                                            .entries(tableObject.lgd)
                                                            .map(([id, data]) => <div key={id} className={classes.sectorTableBlueLight} style={{ width: '4vw' }}>{data}%</div>)
                                                        }
                                                        <div className={classes.sectorTableGreen} style={{ width: '4vw', borderBottom: "none" }}>50%</div>
                                                    </div>
                                                    <div style={{ display: 'grid' }}>
                                                        {Object
                                                            .entries(tableObject.financeImpact)
                                                            .map(([id, data]) => <div key={id} className={classes.sectorTableBlueLight} style={{ width: '8vw' }}>{data}</div>)
                                                        }
                                                        <div className={classes.sectorTableGreen} style={{ width: '8vw', borderBottom: "none" }}>$ 357.09</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>,
                            'step5':
                                <div className="display_body_container">
                                    <div style={{ textAlign: "center", fontWeight: "bold", fontSize: "2.7vh", marginBottom: "1vh" }}>
                                        完成暴險盤點與財務衝擊後，將依據組織內部驅動力因子建立探索型情境，並且對應情境建立未來策略。
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <div style={{ width: "50%", margin: "1vh 1vw" }}>
                                            <div className={classes.sectorTitle}>探索型情境分析結果</div>
                                            <img src={require(`../img/stresstest/5-1.png`)} alt='探索型情境分析結果' style={{ width: "100%" }} />
                                        </div>
                                        <div style={{ width: "50%", margin: "1vh 1vw" }}>
                                            <div className={classes.sectorTitle}>五大策略制定</div>
                                            <img src={require(`../img/stresstest/5-2.png`)} alt='五大策略制定' style={{ width: "100%" }} />
                                        </div>
                                    </div>
                                </div>,
                        }[props.displayMethod]
                    }
                </div>
            </div>
            <Menu
                account={account}
                permission={permission}
                cookies={cookies}
                removeCookie={removeCookie}
                activeScreen={activeScreen}
                switchScreen={switchScreen}
                switchShellChoice={switchShellChoice}
                changeDisplayMethod={changeDisplayMethod}
            />
        </div >
    );
}

export default StressTest;