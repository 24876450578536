import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
// import NumberFormat from 'react-number-format';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import taiwan from '../../img/taiwan/taiwan.png';
// import AppConfig from '../const/AppConfig.const';

const useStyles = makeStyles((theme) => ({
    displayText: {
        fontWeight: 'bold',
        fontSize: '30px',
        margin: '1vh 2.5vw 1vh 0vw',
    },
    selectOption: {
        margin: '1vh 0vw 0vh 0vw',
        fontSize: '2.5vh',
    },
    select: {
        height: "3.8vh",
        width: "4vw",
        margin: '0vh 3vw 0vh 0.5vw',
        fontSize: '2.2vh',
    },
    compute: {
        height: "4vh",
        width: "4.5vw",
        minWidth: "90px",
        margin: '0vh 0vw 0vh 0.5vw',
        fontSize: '2.5vh',
        backgroundColor: '#ffffff',
    },
    menuItem: {
        marginBottom: '0vh',
    },
    displayTextTitle: {
        fontSize: '2.5vh',
        margin: '0.5vh 0vw 0.5vh 0vw',
    },
    displayMap: {
        width: '60%',
        minWidth: '400px',
        // height: '68vh',
        margin: '0 4vw 0 0',
        // borderStyle: 'solid',
        // borderColor: 'black',
    },
    displayMapText: {
        width: '35vw',
        margin: '10vh 0vw 0 3vw',
        // height: '68vh',
        // borderStyle: 'solid',
        // borderColor: 'black',
    }
}));

function BatchRealEstateValueImpact_Map(props) {
    const classes = useStyles();

    const [county, setCounty] = useState("");
    const [countyChoice, setCountyChoice] = useState("");
    const [townList, setTownList] = useState([]);
    const [town, setTown] = useState("");
    const [data, setData] = useState({});

    const {
        datas_town,
        countyList,
        countytownList
    } = props;

    const handleDataFinder = (datas_town, county, town) => {
        let temp = {};
        for (let i = 0; i < datas_town.individual.length; i++) {
            if (datas_town.individual[i].countyname === county && datas_town.individual[i].townname === town) {
                temp = datas_town.individual[i];
                break;
            }
        }
        // console.log(temp);
        setData(temp);
        setCountyChoice(county);
    }

    return (
        <div className="display_body_container">
            <div style={{ backgroundColor: '#fafafa', borderRadius: '1.28vw', padding: '2.1vh 1.6vw 2vh' }}>
                {datas_town.total === undefined ? "" :
                    <div>
                        <div style={{ display: 'inline-flex', alignItems: 'flex-start' }}>
                            <p className={classes.displayText}>總體價值減損量：{(datas_town.total.CA_VAL_DELTA / 100000000).toFixed(5)}億元 ({datas_town.total.CA_VAL_PERCENT})</p>
                            <p className={classes.displayText}>總體RWA改變量：{(datas_town.total.RWA / 100000000).toFixed(5)}億元 ({datas_town.total.RWA_PERCENT})</p>
                        </div>
                        <div className={classes.selectOption} style={{ display: 'inline-flex', alignItems: 'flex-start', marginBottom: '3vh' }}>
                            <p style={{ margin: '0vh 0.3vw 0vh 0vw' }}>地區</p>
                            <FormControl variant="outlined">
                                <Select
                                    className={classes.select}
                                    style={{ width: "8vw", minWidth: "120px" }}
                                    value={county}
                                    onChange={(event) => {
                                        setCounty(event.target.value);
                                        setTownList(countytownList[event.target.value]);
                                        // changeTownList(event.target.value);
                                        // console.log(countytownList);
                                        // console.log(countytownList[event.target.value]);
                                    }}
                                >
                                    {
                                        Object
                                            .entries(countyList)
                                            .map(([index, value]) => <MenuItem key={`countyChoice_${index}`} value={value} className={classes.menuItem} >{value}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>

                            <p style={{ margin: '0vh 0.3vw 0vh 0vw' }}>鄉鎮市區</p>
                            <FormControl variant="outlined">
                                <Select
                                    className={classes.select}
                                    style={{ width: '8vw', minWidth: "120px" }}
                                    value={town}
                                    onChange={(event) => {
                                        setTown(event.target.value);
                                    }}
                                >
                                    {
                                        Object
                                            .entries(townList)
                                            .map(([index, value]) => <MenuItem key={`townChoice_${index}`} value={value} className={classes.menuItem} >{value}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                            <Button variant="outlined" className={classes.compute} onClick={() => handleDataFinder(datas_town, county, town)} >查看</Button>

                        </div>

                        <div style={{ display: 'flex', margin: '2vh 0 2vh 0', height: "73vh" }}>
                            <div className={classes.displayMap}>
                                {
                                    countyChoice === "" ? <img alt='map of all towns in taiwan' src={taiwan} style={{ width: "100%" }} /> :
                                        <img alt={`map of towns of ${countyChoice}`} src={require(`../../img/taiwan/taiwan_${countyChoice}.png`)} style={{ width: "100%" }} />
                                }
                            </div>
                            {data.townid === undefined ? "" :
                                <div className={classes.displayMapText}>
                                    <p className={classes.displayText}>{data.countyname}{data.townname}</p>
                                    <p className={classes.displayTextTitle}>標的數：{data.len}</p>
                                    <p className={classes.displayTextTitle}>平均CA-LTV：{data.ca_ltv_mean}</p>
                                    <p className={classes.displayTextTitle}>平均LTV at risk：{data.ltvatRisk_mean}</p>
                                    <p className={classes.displayTextTitle}>行政區總CA-RWA：{(data.ca_rwa_sum / 100000000).toFixed(4)}億元</p>
                                    <p className={classes.displayTextTitle}>行政區總RWA增加量：{(data.rwa_delta_sum / 100000000).toFixed(4)}億元</p>
                                    <p className={classes.displayTextTitle} style={{ marginTop: "2vh" }}>最大單一標的CA-LTV：{data.ca_ltv_max}</p>
                                    <p className={classes.displayTextTitle}>最大單一標的LTV at risk：{data.ltvatRisk_max}</p>
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
        </div >
    )
}

export default BatchRealEstateValueImpact_Map;