import React from 'react';
// import Permission from '../const/Permission.const'
import AppConfigN from '../const/AppConfigN.const';


function Menu(props) {

  function logOut() {
    props.removeCookie("Account", { path: '/' });
    props.switchScreen("Logout");

    fetch(`${AppConfigN.SERVER_URL}/api/auth/logout`, {
      method: 'POST',
      headers: {
        "Authorization": `Token ${props.account.token}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        account: props.account.user.username,
      }),
    })
      .then((response) => {
        // console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <div className="menu_container" style={{ overflowY: "scroll", overflowX: "hidden" }}>
      <p className="platform_title platform_title_text">Ascelleia 金融企業風險平台</p>
      <p className="user_name">{props.account.user.username}</p>
      <div className="divider"></div>
      {
        Object.values(props.permission.pages).indexOf('Home') >= 0 ?
          <div
            className={props.activeScreen === "Home" ? "menuitem_active" : "menuitem_inactive"}
            onClick={() => {
              props.switchScreen("Home");
              // props.switchShellChoice("None");
            }}>
            <p className="menuitem_text">金融投資組合風險計算</p>
          </div> : ''
      }
      {
        Object.values(props.permission.pages).indexOf('ClimateStressTest') >= 0 ?
          <div
            className={props.activeScreen === "ClimateStressTest" ? "menuitem_active" : "menuitem_inactive"}
            onClick={() => {
              props.switchScreen("ClimateStressTest");
              //props.changeDisplayMethod("ClimateStressTest");
              // if(props.activeScreen==="Home"){
              //   props.changeDisplayData({});
              //   props.changeSubMenuNow("None");
              // }
            }}>
            <p className="menuitem_text">標的氣候風險評估</p>
          </div> : ''
      }
      {
        Object.values(props.permission.pages).indexOf('SingleClimateStressTest') >= 0 ?
          <div
            className={props.activeScreen === "SingleClimateStressTest" ? "menuitem_active" : "menuitem_inactive"}
            onClick={() => {
              props.switchScreen("SingleClimateStressTest");
            }}>
            <p className="menuitem_text">氣候變遷下單一不動產價值影響評估</p>
          </div> : ''
      }
      {
        Object.values(props.permission.pages).indexOf('ClimateStressTestUpload') >= 0 ?
          <div
            className={props.activeScreen === "ClimateStressTestUpload" ? "menuitem_active" : "menuitem_inactive"}
            onClick={() => {
              props.switchScreen("ClimateStressTestUpload");
            }}>
            <p className="menuitem_text">氣候變遷下不動產總價值影響評估</p>
          </div> : ''
      }
      {
        Object.values(props.permission.pages).indexOf('TestClimateStressTestUpload') >= 0 ?
          <div
            className={props.activeScreen === "TestClimateStressTestUpload" ? "menuitem_active" : "menuitem_inactive"}
            onClick={() => {
              props.switchScreen("TestClimateStressTestUpload");
            }}>
            <p className="menuitem_text">氣候變遷下不動產總價值影響評估-測試</p>
          </div> : ''
      }
      {
        Object.values(props.permission.pages).indexOf('CarbonEmissionsData') >= 0 ?
          <div
            className={props.activeScreen === "CarbonEmissionsData" ? "menuitem_active" : "menuitem_inactive"}
            onClick={() => {
              props.switchScreen("CarbonEmissionsData");
            }}>
            <p className="menuitem_text">企業碳排資料查詢</p>
          </div> : ''
      }
      {
        Object.values(props.permission.pages).indexOf('SingleInvestmentCarbonEmission') >= 0 ?
          <div
            className={props.activeScreen === "SingleInvestmentCarbonEmission" ? "menuitem_active" : "menuitem_inactive"}
            onClick={() => {
              props.switchScreen("SingleInvestmentCarbonEmission");
            }}>
            <p className="menuitem_text">單一投融資標的碳排計算</p>
          </div> : ''
      }
      {
        Object.values(props.permission.pages).indexOf('InvestmentCarbonEmissions') >= 0 ?
          <div
            className={props.activeScreen === "InvestmentCarbonEmissions" ? "menuitem_active" : "menuitem_inactive"}
            onClick={() => {
              props.switchScreen("InvestmentCarbonEmissions");
            }}>
            <p className="menuitem_text">投融資組合碳排放計算</p>
          </div> : ''
      }
      {
        Object.values(props.permission.pages).indexOf('SICCarbonCoef') >= 0 ?
          <div
            className={props.activeScreen === "SICCarbonCoef" ? "menuitem_active" : "menuitem_inactive"}
            onClick={() => {
              props.switchScreen("SICCarbonCoef");
            }}>
            <p className="menuitem_text">產業碳排係數查詢</p>
          </div> : ''
      }
      {
        Object.values(props.permission.pages).indexOf('BoardReport') >= 0 ?
          <div
            className={props.activeScreen === "BoardReport" ? "menuitem_active" : "menuitem_inactive"}
            onClick={() => {
              props.switchScreen("BoardReport");
            }}>
            <p className="menuitem_text">企業董事會報告</p>
          </div> : ''
      }
      {
        Object.values(props.permission.pages).indexOf('TravelCarbonEmissions') >= 0 ?
          <div
            className={props.activeScreen === "TravelCarbonEmissions" ? "menuitem_active" : "menuitem_inactive"}
            onClick={() => {
              props.switchScreen("TravelCarbonEmissions");
            }}>
            <p className="menuitem_text">企業差旅碳排路徑規劃</p>
          </div> : ''
      }
      {
        Object.values(props.permission.pages).indexOf('Dashboard') >= 0 ?
          <div
            className={props.activeScreen === "Dashboard" ? "menuitem_active" : "menuitem_inactive"}
            onClick={() => {
              props.switchScreen("Dashboard");
            }}>
            <p className="menuitem_text">子公司儀表板</p>
          </div> : ''
      }
      {
        Object.values(props.permission.pages).indexOf('StressTest') >= 0 ?
          <div
            className={props.activeScreen === "StressTest" ? "menuitem_active" : "menuitem_inactive"}
            onClick={() => {
              props.switchScreen("StressTest");
            }}>
            <p className="menuitem_text">氣候壓力測試系統</p>
          </div> : ''
      }
      {
        Object.values(props.permission.pages).indexOf('DataDownload') >= 0 ?
          <div
            className={props.activeScreen === "DataDownload" ? "menuitem_active" : "menuitem_inactive"}
            onClick={() => {
              props.switchScreen("DataDownload");
            }}>
            <p className="menuitem_text">FCS專案交付檔案下載</p>
          </div> : ''
      }
      {
        Object.values(props.permission.pages).indexOf('SingleTargetITR') >= 0 ?
          <div
            className={props.activeScreen === "SingleTargetITR" ? "menuitem_active" : "menuitem_inactive"}
            onClick={() => {
              props.switchScreen("SingleTargetITR");
            }}>
            <p className="menuitem_text">單一標的之ITR</p>
          </div> : ''
      }
      {
        Object.values(props.permission.pages).indexOf('EnvVarCDFHImport') >= 0 ?
          <div
            className={props.activeScreen === "EnvVarCDFHImport" ? "menuitem_active" : "menuitem_inactive"}
            onClick={() => {
              props.switchScreen("EnvVarCDFHImport");
            }}>
            <p className="menuitem_text">環境變數整合分析</p>
          </div> : ''
      }
      {
        Object.values(props.permission.pages).indexOf('EnvVarCDFHExport') >= 0 ?
          <div
            className={props.activeScreen === "EnvVarCDFHExport" ? "menuitem_active" : "menuitem_inactive"}
            onClick={() => {
              props.switchScreen("EnvVarCDFHExport");
            }}>
            <p className="menuitem_text">環境變數結果輸出</p>
          </div> : ''
      }
      {
        Object.values(props.permission.pages).indexOf('InvestmentITR') >= 0 ?
          <div
            className={props.activeScreen === "InvestmentITR" ? "menuitem_active" : "menuitem_inactive"}
            onClick={() => {
              props.switchScreen("InvestmentITR");
            }}>
            <p className="menuitem_text">投融資組合之ITR</p>
          </div> : ''
      }
      {
        Object.values(props.permission.pages).indexOf('KeyCarbonEmissionIndustryLabel') >= 0 ?
          <div
            className={props.activeScreen === "KeyCarbonEmissionIndustryLabel" ? "menuitem_active" : "menuitem_inactive"}
            onClick={() => {
              props.switchScreen("KeyCarbonEmissionIndustryLabel");
            }}>
            <p className="menuitem_text">重點排碳產業標示</p>
          </div> : ''
      }
      {
        Object.values(props.permission.pages).indexOf('SingleRealEstateValueImpact') >= 0 ?
          <div
            className={props.activeScreen === "SingleRealEstateValueImpact" ? "menuitem_active" : "menuitem_inactive"}
            onClick={() => {
              props.switchScreen("SingleRealEstateValueImpact");
            }}>
            <p className="menuitem_text">單一不動產價值影響評估-AR6</p>
          </div> : ''
      }
      {
        Object.values(props.permission.pages).indexOf('BatchRealEstateValueImpact') >= 0 ?
          <div
            className={props.activeScreen === "BatchRealEstateValueImpact" ? "menuitem_active" : "menuitem_inactive"}
            onClick={() => {
              props.switchScreen("BatchRealEstateValueImpact");
            }}>
            <p className="menuitem_text">氣候變遷下不動產總價值影響評估-AR6</p>
          </div> : ''
      }
      {
        Object.values(props.permission.pages).indexOf('PortfolioOptimization') >= 0 ?
          <div
            className={props.activeScreen === "PortfolioOptimization" ? "menuitem_active" : "menuitem_inactive"}
            onClick={() => {
              props.switchScreen("PortfolioOptimization");
            }}>
            <p className="menuitem_text">投融資組合最佳化配置</p>
          </div> : ''
      }
      {
        Object.values(props.permission.pages).indexOf('EnterpriseESGReport') >= 0 ?
          <div
            className={props.activeScreen === "EnterpriseESGReport" ? "menuitem_active" : "menuitem_inactive"}
            onClick={() => {
              props.switchScreen("EnterpriseESGReport");
            }}>
            <p className="menuitem_text">企業ESG資訊查詢</p>
          </div> : ''
      }
      <div className="logout_button" onClick={() => { logOut(); }}><p className="logout_text">登出</p></div>
    </div>
  );
}

export default Menu;