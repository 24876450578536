import React from 'react';
import '../../style/LineChart.css';


function LineChart(props) {
    const {
        width,
        colors,
        labels,
        lineLength,
        lineWidth,
        labelColor,
        labelSize,
        nrOfLevels,
        linePadding,
        backgroundColor,
    } = props
    return (
        <div className='linechart_container' style={{width: width}}>
            <div className='linechart_text_container' style={{marginBottom: linePadding}}>
                {labels.map((label, index) => (
                    <p
                        key={`scale_label_${index}`}
                        className='linechart_label'
                        style={{
                            color: labelColor,
                            fontSize: labelSize,
                            flex: lineLength[index],
                        }}
                    >{label}</p>
                ))}
            </div>
            <div 
                className='lineChart_colorScale_container'
                style={{
                    backgroundColor: backgroundColor,
                    height: lineWidth,
                }}
            >
                {colors.map((color, index) => (
                <div
                    key={`colorScale_${index}`}
                    className='linechart_colorScale'
                    style={{
                        backgroundColor: color,
                        flex: lineLength[index],
                    }}
                >
                    <p
                        key={`scale_remark_${index}`}
                        className='linechart_remark'
                        style={{
                            color: 'black',
                        }}
                    >{`${lineLength[index]} %`}</p>
                </div>))}
            </div>
            {/* <div className='linechart_text_container' style={{marginTop: '5px'}}>
                {labels.map((label, index) => (
                    <p
                        key={`scale_remark_${index}`}
                        className='linechart_remark'
                        style={{
                            color: 'black',
                            flex: lineLength[index],
                        }}
                    >{`${lineLength[index]} %`}</p>
                ))}
            </div> */}
        </div>
         
    );
}

export default LineChart;