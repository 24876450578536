import React from 'react';
import { VictoryChart, VictoryBar, VictoryAxis } from 'victory';


function DisplayChart(props) {
  if(props.shell==='loanRisk' && props.data.table !== undefined){
    const exposureData = []
    const tick = []
    Object
    .entries(props.data.harm)
    .forEach(([key, value], index) => {
      if(value[0]==='high' || value[0]==='very high'){
        exposureData.push({'x':key, 'y':props.data.exposure[index]})
        tick.push(key)
      }
    })
    console.log(exposureData)
    console.log(tick)
    return (
      <div className="display_body_container">
        <p className='display_chart_title'>{`${props.data.area} 高度危害區域暴險金額`}</p>
        <VictoryChart
          domainPadding={20}
          style={{top:'-25vh'}}
        >
          <VictoryAxis
            // tickValues specifies both the number of ticks and where
            // they are placed on the axis
            // tickValues={Object.keys(props.data.vulnerability)}
            tickFormat={tick}
            // label='地區'
            style={{
              axis: {stroke: "#6a6a6a"},
              // axisLabel: {fontSize: 10 , fill:'#6a6a6a'},
              ticks: {stroke: "#6a6a6a", size: 3},
              tickLabels: {fontSize: 8, padding: 5, fill:'#6a6a6a'}
            }}
          />
          <VictoryAxis
            dependentAxis
            // tickFormat specifies how ticks should be displayed
            // tickValues={['1', '2', '3', '4', '5']}
            // tickFormat={['very low', 'low', 'medium', 'high', 'very high']}
            // label='淹水危害等級'
            style={{
              axis: {stroke: "#797979"},
              // axisLabel: {fontSize: 10, padding: 25, fill:"#797979"},
              ticks: {stroke: "#797979", size: 1},
              tickLabels: {fontSize: 8, padding: 5, fill:"#797979"}
            }}
          />
          <VictoryBar
            style={{
              data: { fill: "#679b5a" },
              // parent: { border: "1px solid #ccc"}
            }}
            alignment='start'
            data={exposureData}
            // labels={({ datum }) => `y: ${datum.y}`}
          />
          
        </VictoryChart>
        <p style={{position:'relative', top:'-90vh', left:'5vw' , color:'#797979', fontSize:'3vh'}}>暴險金額(億元)</p>
        <p style={{position:'relative', top:'-28vh', left:'69vw' , color:'#797979', fontSize:'3vh'}}>地區</p>
      </div>
    );
  } else {
    return (
      <div className="display_body_container">
      </div>
    );
  }

}

export default DisplayChart;