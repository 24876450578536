import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    paddingBottom: '2vh',
    width: '20.56vw',
    textAlign: 'center',
  },
}));

function ClimateChoice(props) {
  const classes = useStyles();
  return (
    <div style={{width: "16.3vw", position:"relative", right:"0", padding:"0px 3vw 0 5.5vw"}}>
      <FormControl className={classes.formControl}>
        <InputLabel>Year</InputLabel>
        <Select
          value={props.yearChoice}
          onChange={(event)=>{props.changeDisplayDataState('notReady');props.switchYearChoice(event.target.value)}}
        >
          <MenuItem value={"None"}>請選擇時間</MenuItem>
          <MenuItem value={"2021"}>2021-2040</MenuItem>
          <MenuItem value={"2041"} disabled={true}>2041-2060</MenuItem>
          <MenuItem value={"2061"} disabled={true}>2061-2080</MenuItem>
          <MenuItem value={"2081"} disabled={true}>2081-2100</MenuItem>
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel>Climate scenario</InputLabel>
        <Select
          value={props.CSChoice}
          onChange={(event)=>{props.changeDisplayDataState('notReady');props.switchCSChoice(event.target.value)}}
        >
          <MenuItem value={"None"}>請選擇情境</MenuItem>
          <MenuItem value={"2.6"}>RCP 2.6</MenuItem>
          <MenuItem value={"8.5"}>RCP 8.5</MenuItem>
          <MenuItem value={"orderly"} disabled={true}>Orderly</MenuItem>
          <MenuItem value={"disorderly"} disabled={true}>Disorderly</MenuItem>
          <MenuItem value={"HHW"} disabled={true}>HHW</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}

export default ClimateChoice;