import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    maxWidth: '39vw',
    textAlign: 'center',
    marginTop: '1.3vh',
    // borderRadius: "6px",
    // border: "solid 1px #939393",
  },
  select: {
    height: "3.5vh",
    fontSize: "1.6vh",
  }
}));

function ConditionItem(props) {
  const classes = useStyles();

  if (props.index === '0'){
    return (
      <div className="conditionItem_wrapper">
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            className={classes.select}
            // labelId="demo-simple-select-outlined-label"
            // id="demo-simple-select-outlined"
            value={props.value}
            onChange={(event) => {props.changeDisplayDataState('notReady');props.switchChoice(event.target.value)}}
            // label={props.processData[props.index]}
          >
            {/* <MenuItem value="None"><em>{props.name}</em></MenuItem> */}
            {
              Object
              .entries(props.processData_list)
              .map(([index, value]) => <MenuItem key={`process_${props.processData[props.index]}_Datalist_${index}`} value={value}>{value}</MenuItem>)
            }
          </Select>
        </FormControl>
        {/* <p className="conditionItem_text">{props.text}</p> */}
      </div>
    );
  } else if (props.processData[props.index] !== undefined){
    return (
      <div className="conditionItem_wrapper">
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            className={classes.select}
            // labelId="demo-simple-select-outlined-label"
            // id="demo-simple-select-outlined"
            value={props.processData_value}
            onChange={(event) => {
              props.changeDisplayDataState('notReady')
              console.log(props.index, event.target.value);
              props.changeDisplayData({});
              props.changeProcessData_value(props.index, event.target.value);
              props.getProcessData_list(props.index, event.target.value);
              //props.onExecutionClick();
            }}
            // label={props.processData[props.index]}
          >
            <MenuItem value="None"><em>{props.name}</em></MenuItem>
            {
              Object
              .entries(props.processData_list)
              .map(([index, value]) => <MenuItem key={`process_${props.index}_Datalist_${index}`} value={value}>{value}</MenuItem>)
            }
          </Select>
        </FormControl>
        {/* <p className="conditionItem_text">{props.text}</p> */}
      </div>
    );
  } else {
    return("")
  }
}

export default ConditionItem;