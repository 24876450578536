import React, { useState } from 'react';
import "../style/style.css";
import Menu from './Menu';
import AppConfig from '../const/AppConfig.const';
import DisplayReportClimateStressTest from '../component/DisplayReportClimateTest';

function ClimateStressTest(props) {
  const {
    activeScreen,
    switchScreen,
    account,
    permission,
    cookies,
    removeCookie,
    switchShellChoice,
    changeDisplayMethod, } = props

  const [climateStressTestData, changeClimateStressTestData] = useState({})

  const getClimateStressTestData = (CS, year, taxID) => {
    console.log(CS, year, taxID);
    if (taxID !== '' && taxID.length === 8) {
      fetch(`${AppConfig.SERVER_URL}/ClimateStressTest`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          account: account.ID,
          CS,
          year,
          taxID,
        }),
      })
        .then(response => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          changeClimateStressTestData({ ...climateStressTestData, ...responseJson });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  return (
    <div className="new_home_mainContainer">
      <div className="page_container">
        <div className="page_title_container"><p className="page_title_text">標的氣候風險評估</p></div>

        <div className="display_container">
          <div className="display_header_container">
            <div className='display_header_item_active'>
              <p className='display_header_item_active_text'>Report</p>
            </div>
          </div>
          <div>
            <DisplayReportClimateStressTest
              data={climateStressTestData}
              getClimateStressTestData={(CS, year, taxID) => { getClimateStressTestData(CS, year, taxID) }}
            />
          </div>
        </div>

      </div>
      <Menu
        account={account}
        permission={permission}
        cookies={cookies}
        removeCookie={removeCookie}
        activeScreen={activeScreen}
        switchScreen={switchScreen}
        switchShellChoice={switchShellChoice}
        changeDisplayMethod={changeDisplayMethod}
      />
    </div>
  );
}

export default ClimateStressTest;