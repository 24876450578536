import React, { useState } from 'react';
import "../style/style.css";
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import Menu from './Menu';
import { VictoryChart, VictoryScatter, VictoryAxis } from 'victory';
import AppConfigN from '../const/AppConfigN.const';

const useStyles = makeStyles((theme) => ({
    blockContent: {
        margin: "0vh 1vw 0vh 1vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        textAlign: "center",
    },
    input: {
        height: "4.5vh",
        width: "10vw",
        margin: '0vh 0.5vw 0vh 0vh',
        paddingLeft: '0.3vw',
        borderRadius: '5px',
        borderWidth: 'thin',
        borderColor: '#b6b6b6',
        borderStyle: 'solid',
        fontSize: "2vh",
    },
    sendInfoButton: {
        height: "4.9vh",
        fontSize: "2vh",
        padding: '5px',
    }
}))

function CarbonEmissionsData(props) {
    const classes = useStyles();

    const [companyID, setCompanyID] = useState("");
    const [companyData, setCompanyData] = useState({});
    const [chartData, setChartData] = useState({});
    const [companyChartData, setCompanyChartData] = useState([]);
    const [errorMsg, setErrorMsg] = useState("");

    const {
        account,
        permission,
        cookies,
        removeCookie,
        activeScreen,
        switchScreen,
        switchShellChoice,
        changeDisplayMethod, } = props

    const reset = () => {
        setChartData({});
        setCompanyChartData([]);
        setCompanyData({});
    }

    const getCompanyData = () => {
        fetch(`${AppConfigN.SERVER_URL}/api/trans_emis_fin`, {
            method: 'POST',
            headers: {
                "Authorization": `Token ${props.account.token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                companyID: companyID
            })
        })
            .then(response => response.json())
            .then((responseJson) => {
                // console.log(responseJson);
                if (responseJson.detail !== undefined) {
                    setErrorMsg(responseJson.detail);
                    reset();
                }
                else if (responseJson.companyID !== undefined) {
                    setErrorMsg(responseJson.companyID);
                    reset();
                }
                else {
                    let data = responseJson;
                    console.log(data);
                    setErrorMsg("");
                    if (data.chartData !== undefined) {
                        let chartData = data.chartData.X.map((value, index) => {
                            return ({ 'x': value, 'y': data.chartData.Y[index] })
                        })
                        setChartData(chartData);
                        let companyChartData = [data.chartData.company_X, data.chartData.company_Y]
                        setCompanyChartData(companyChartData);
                        // console.log(chartData)
                        // console.log(companyChartData)
                    }
                    else {
                        setCompanyChartData([]);
                        setCompanyData({});
                    }
                    setCompanyData(data);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    return (
        <div className="new_home_mainContainer">
            <div className="page_container">
                <div className="page_title_container"><p className="page_title_text">碳排資料查詢</p></div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div className="DataDownload_block" style={{ flexDirection: "column", width: "18vw", height: "20vh" }}>
                        <p className="DataDownload_block_title_text">企業資料輸入</p>
                        <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
                        <div className={classes.blockContent}>
                            <input
                                className={classes.input}
                                type="text"
                                placeholder="輸入統編/股票代碼"
                                value={companyID}
                                onChange={(event) => {
                                    setCompanyID(event.target.value);
                                }}
                            />
                            <Button variant="outlined" className={classes.sendInfoButton} onClick={() => getCompanyData()}>查詢</Button>
                        </div>

                    </div>
                    <div className="DataDownload_block" style={{ flexDirection: "column", width: "18vw", height: "20vh" }}>
                        <p className="DataDownload_block_title_text">企業名稱</p>
                        <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
                        {errorMsg.length !== 0 ?
                            <div className={classes.blockContent} style={{ fontSize: "2vh", color: "red" }}>
                                {errorMsg}
                            </div> :
                            Object.values(companyData).length === 0 ? "" :
                                <div className={classes.blockContent} style={{ fontSize: "3vh", fontWeight: "bold" }}>
                                    {companyData.company}
                                </div>
                        }

                    </div>
                    <div className="DataDownload_block" style={{ flexDirection: "column", width: "18vw", height: "20vh" }}>
                        <p className="DataDownload_block_title_text">產業別</p>
                        <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
                        <div className={classes.blockContent} style={{ fontSize: "3vh", fontWeight: "bold" }}>
                            {Object.values(companyData).length === 0 ? "" :
                                "第" + companyData.industry_id + "類 " + companyData.industry
                            }
                        </div>
                    </div>
                    <div className="DataDownload_block" style={{ flexDirection: "column", width: "18vw", height: "20vh" }}>
                        {Object.values(companyData).length === 0 ?
                            <>
                                <p className="DataDownload_block_title_text">碳排放資訊</p>
                                <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
                            </> :
                            companyData.carbonEmissionInfo === "總碳排放量" ?
                                <>
                                    <p className="DataDownload_block_title_text">碳排放資訊 - 總碳排放量</p>
                                    <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
                                    <div className={classes.blockContent} style={{ fontSize: "3vh", fontWeight: "bold", display: "block" }}>
                                        <div style={{ marginTop: "2vh" }}>
                                            {new Intl.NumberFormat().format(companyData.carbonData)}<br />公噸二氧化碳當量
                                        </div>

                                        <div style={{ textAlign: "right", fontSize: "2vh", fontWeight: "normal", color: "#797979", marginRight: "-0.7vw" }}>
                                            {companyData.carbonDataUpdate}年資料
                                        </div>
                                    </div>
                                </> :
                                <>
                                    <p className="DataDownload_block_title_text">碳排放資訊 - 碳排放強度</p>
                                    <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
                                    <div className={classes.blockContent} style={{ fontSize: "3vh", fontWeight: "bold", display: "block" }}>
                                        <div style={{ marginTop: "2vh" }}>{companyData.carbonData}<br />公噸 / 千元</div>
                                        <div style={{ textAlign: "right", fontSize: "2vh", fontWeight: "normal", color: "#797979", marginRight: "-0.7vw" }}>
                                            {companyData.carbonDataUpdate}年資料
                                        </div>
                                    </div>
                                </>
                        }
                    </div>
                </div>

                {Object.values(chartData).length === 0 | companyData.carbonEmissionInfo === "碳排放強度" ? "" :
                    <div className="display_container" style={{ height: '100%' }}>
                        <div className="display_header_container">
                            <div className="display_header_item_active">
                                <p className="display_header_item_active_text">Report</p>
                            </div>
                        </div>
                        <div className="display_body_container" style={{ height: '100%' }}>
                            <p style={{ margin: '0', fontSize: '4vh', fontWeight: 'bold', position: 'relative', top: '3vh', textAlign: 'center' }}>{`${companyData.industry}碳排放比較圖`}</p>
                            <VictoryChart
                                domainPadding={20}
                            >
                                <VictoryAxis
                                    // tickValues specifies both the number of ticks and where
                                    // they are placed on the axis
                                    //tickValues={['0', '10','20', '30', '40', '50']}
                                    //tickFormat={props.data.Chart.X}
                                    label='排放量（公噸）'
                                    style={{
                                        axis: { stroke: "#6a6a6a" },
                                        axisLabel: { fontSize: 10, fill: '#6a6a6a' },
                                        ticks: { stroke: "#6a6a6a", size: 5 },
                                        tickLabels: { fontSize: 6, padding: 5, fill: '#6a6a6a' }
                                    }}
                                />
                                <VictoryAxis
                                    dependentAxis
                                    // tickFormat specifies how ticks should be displayed
                                    //tickValues={['0', '0.05', '0.1', '0.15', '0.2']}
                                    //tickFormat={props.data.Chart.Y}
                                    label='排放強度（噸/千元）'
                                    style={{
                                        axis: { stroke: "#797979" },
                                        axisLabel: { fontSize: 10, padding: 35, fill: "#797979" },
                                        ticks: { stroke: "#797979", size: 1 },
                                        tickLabels: { fontSize: 8, padding: 5, fill: "#797979" }
                                    }}
                                />
                                <VictoryScatter
                                    style={{ data: { fill: "#679b5a" } }}
                                    size={2}
                                    data={chartData}
                                />
                                <VictoryScatter
                                    style={{ data: { fill: "red" } }}
                                    size={2}
                                    data={[{ 'x': companyChartData[0], 'y': companyChartData[1] }]}
                                />
                                {/* <VictoryBar
              style={{
                data: { fill: "#679b5a" },
                // parent: { border: "1px solid #ccc"}
              }}
              // alignment='start'
              data={Object
                      .entries(props.data.harm)
                      .map(([key, value]) => {
                        return({'x':key, 'y':value[0]})
                      })}
              // labels={({ datum }) => `y: ${datum.y}`}
            /> */}
                            </VictoryChart>
                        </div>
                    </div>
                }

            </div>
            <Menu
                account={account}
                permission={permission}
                cookies={cookies}
                removeCookie={removeCookie}
                activeScreen={activeScreen}
                switchScreen={switchScreen}
                switchShellChoice={switchShellChoice}
                changeDisplayMethod={changeDisplayMethod}
            />
        </div>
    );
}

export default CarbonEmissionsData;