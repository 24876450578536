import React from 'react';
import ShellStepItem from './ShellStepItem';

function ShellSteps(props) {
  
  return (
    <div className="ShellSteps_container">
      <div className="shellSteps_subtitle">
        運算步驟
      </div>
      <div>
        {
          Object
          .entries(props.processList)
          .map(([index, name]) => 
          <ShellStepItem 
            key={`processList_${index}`}
            index={index} 
            name={name} 
            processData={props.processData} 
            processData_value={props.processData_value[index]===undefined?"None":props.processData_value[index]}
            processData_list={props.processData_list[index]===undefined?"":props.processData_list[index]}
            getProcessData_list={props.getProcessData_list} 
            changeProcessData_value={props.changeProcessData_value}
            changeDisplayDataState={props.changeDisplayDataState}
          />)
        }
      </div>
    </div>
  );
}

export default ShellSteps;