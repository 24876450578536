import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    displayTitle: {
        fontWeight: 'bold',
        fontSize: '3.3vh',
        margin: '1vh 0vw 1vh 0vw',
    },
    outputText: {
        margin: '0 0 0 0',
        fontSize: '2.8vh',
    }
}));

function DisplaySingleInvestmentCarbonEmission(props) {
    const classes = useStyles();

    const {
        companyData,
    } = props;

    const handleFractionDigits = (number) => {
        if (number === null) return "-"
        else if (number < 1) {
            return new Intl.NumberFormat('en-US', { maximumFractionDigits: 4, minimumFractionDigits: 4 }).format(number)
        }
        else if (number < 10) {
            return new Intl.NumberFormat('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(number)
        }
        else if (number < 100) {
            return new Intl.NumberFormat('en-US', { maximumFractionDigits: 1, minimumFractionDigits: 1 }).format(number)
        }
        else return new Intl.NumberFormat('en-US', { maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(number)
    }

    const handleMoneyFractionDigits = (number) => {
        if (number === null) return "-"
        else if (number < 1) {
            return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 4, minimumFractionDigits: 4 }).format(number)
        }
        else if (number < 10) {
            return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(number)
        }
        else if (number < 100) {
            return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 1, minimumFractionDigits: 1 }).format(number)
        }
        else return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(number)
    }

    return (
        <div className="display_container">
            <div className="display_header_container">
                <div className="display_header_item_active">
                    <p className="display_header_item_active_text">Report</p>
                </div>
            </div>
            <div className="display_body_container">
                <div style={{ backgroundColor: '#fafafa', borderRadius: '1.212vw', padding: '2.1vh 1.6vw 4vh', overflowX: 'scroll' }}>
                    <p className={classes.displayTitle}>投資標的基本資料</p>
                    <div style={{ marginBottom: '1.5vh' }}>
                        <p className={classes.outputText}>案件類別：{companyData.type}</p>
                        <p className={classes.outputText}>股票代碼/統一編號：{companyData.companyID === "" ? "-" : companyData.companyID}</p>
                        <p className={classes.outputText}>公司名稱：{companyData.companyName}</p>
                    </div>
                    <div style={{ display: 'grid', width: 'fit-content', height: 'fit-content', border: 'solid 2px #eaeaea', marginBottom: '2vh' }}>
                        <div style={{ display: 'inline-flex' }}>
                            <div className='single_display_report_block' style={{ width: '15vw', fontSize: '2.8vh' }}>公司名稱 (系統帶入)</div>
                            <div className='single_display_report_block' style={{ width: '8vw', fontSize: '2.8vh' }}>國家/地區</div>
                            <div className='single_display_report_block' style={{ width: '20vw', fontSize: '2.8vh' }}>產業類別</div>
                            <div className='single_display_report_block' style={{ width: '10vw', fontSize: '2.8vh' }}>碳排放量 (公噸)</div>
                            <div className='single_display_report_block' style={{ width: '10vw', fontSize: '2.8vh' }}>碳排放查驗單位</div>
                            <div className='single_display_report_block' style={{ width: '10vw', fontSize: '2.8vh' }}>碳排資料年分</div>
                        </div>
                        <div style={{ display: 'inline-flex' }}>
                            <div className='single_display_report_block' style={{ width: '15vw', fontSize: '2.8vh' }}>{companyData.companyNameSys == null ? "-" : companyData.companyNameSys}</div>
                            <div className='single_display_report_block' style={{ width: '8vw', fontSize: '2.8vh' }}>{companyData.location === "" ? "-" : companyData.location}</div>
                            <div className='single_display_report_block' style={{ width: '20vw', fontSize: '2.8vh' }}>{companyData.industry === "" ? "-" : companyData.industry}</div>
                            <div className='single_display_report_block' style={{ width: '10vw', fontSize: '2.8vh' }}>{handleFractionDigits(companyData.carbonData)}</div>
                            <div className='single_display_report_block' style={{ width: '10vw', fontSize: '2.8vh' }}>{companyData.certificationBody === "" ? "-" : companyData.certificationBody}</div>
                            <div className='single_display_report_block' style={{ width: '10vw', fontSize: '2.8vh' }}>{companyData.carbonDataYear === "0" ? "-" : companyData.carbonDataYear}</div>
                        </div>
                    </div>
                    <p className={classes.displayTitle}>財務資料</p>
                    <div style={{ display: 'grid', width: 'fit-content', height: 'fit-content', border: 'solid 2px #eaeaea', marginBottom: '2vh' }}>
                        <div style={{ display: 'inline-flex' }}>
                            <div className='single_display_report_block' style={{ width: '12vw', fontSize: '2.8vh' }}>投融資餘額 (千元)</div>
                            <div className='single_display_report_block' style={{ width: '10vw', fontSize: '2.8vh' }}>EVIC (千元)</div>
                            <div className='single_display_report_block' style={{ width: '13vw', fontSize: '2.8vh' }}>權益+總債務 (千元)</div>
                            <div className='single_display_report_block' style={{ width: '10vw', fontSize: '2.8vh' }}>年營收 (千元)</div>
                            <div className='single_display_report_block' style={{ width: '10vw', fontSize: '2.8vh' }}>財報資料年分</div>
                        </div>
                        <div style={{ display: 'inline-flex' }}>
                            <div className='single_display_report_block' style={{ width: '12vw', textAlign: 'right', fontSize: '2.8vh' }}>
                                {handleMoneyFractionDigits(companyData.lendAmount)}
                            </div>
                            <div className='single_display_report_block' style={{ width: '10vw', textAlign: 'right', fontSize: '2.8vh' }}>
                                {handleMoneyFractionDigits(companyData.evic)}
                            </div>
                            <div className='single_display_report_block' style={{ width: '13vw', textAlign: 'right', fontSize: '2.8vh' }}>
                                {handleMoneyFractionDigits(companyData.debt)}
                            </div>
                            <div className='single_display_report_block' style={{ width: '10vw', textAlign: 'right', fontSize: '2.8vh' }}>
                                {handleMoneyFractionDigits(companyData.revenue)}
                            </div>
                            <div className='single_display_report_block' style={{ width: '10vw', fontSize: '2.8vh' }}>{companyData.financeDataYear === "0" ? "-" : companyData.financeDataYear}</div>
                        </div>
                    </div>
                    <p className={classes.displayTitle}>統計資料 (FCS提供)</p>
                    <div style={{ display: 'grid', width: 'fit-content', height: 'fit-content', border: 'solid 2px #eaeaea', marginBottom: '2vh' }}>
                        <div style={{ display: 'inline-flex' }}>
                            <div className='single_display_report_block' style={{ width: '17vw', fontSize: '2.8vh' }}>產業碳排係數 (公噸/千元)</div>
                            <div className='single_display_report_block' style={{ width: '12vw', fontSize: '2.8vh' }}>產業總營收 (千元)</div>
                            <div className='single_display_report_block' style={{ width: '12vw', fontSize: '2.8vh' }}>產業總資產 (千元)</div>
                        </div>
                        <div style={{ display: 'inline-flex' }}>
                            <div className='single_display_report_block' style={{ width: '17vw', fontSize: '2.8vh' }}>{handleFractionDigits(companyData.industryCarbonCoef)}</div>
                            <div className='single_display_report_block' style={{ width: '12vw', textAlign: 'right', fontSize: '2.8vh' }}>
                                {handleMoneyFractionDigits(companyData.industryRevenue)}
                            </div>
                            <div className='single_display_report_block' style={{ width: '12vw', textAlign: 'right', fontSize: '2.8vh' }}>
                                {handleMoneyFractionDigits(companyData.industryAssets)}
                            </div>
                        </div>
                    </div>
                    <p className={classes.displayTitle}>投融資碳排資料</p>
                    <div style={{ display: 'grid', width: 'fit-content', height: 'fit-content', border: 'solid 2px #eaeaea', marginBottom: '2vh' }}>
                        <div style={{ display: 'inline-flex' }}>
                            <div className='single_display_report_block' style={{ width: '19vw', fontSize: '2.8vh' }}>投融資產生的碳排放量 (公噸)</div>
                            <div className='single_display_report_block' style={{ width: '16vw', fontSize: '2.8vh' }}>投融資碳足跡 (公噸/千元)</div>
                            <div className='single_display_report_block' style={{ width: '10vw', fontSize: '2.8vh' }}>PCAF 計算方式</div>
                            <div className='single_display_report_block' style={{ width: '10vw', fontSize: '2.8vh' }}>PCAF 資料品質</div>
                        </div>
                        <div style={{ display: 'inline-flex' }}>
                            <div className='single_display_report_block' style={{ width: '19vw', fontSize: '2.8vh' }}>{handleFractionDigits(companyData.scope3Data)}</div>
                            <div className='single_display_report_block' style={{ width: '16vw', fontSize: '2.8vh' }}>{handleFractionDigits(companyData.lendingCarbonData)}</div>
                            <div className='single_display_report_block' style={{ width: '10vw', fontSize: '2.8vh' }}>{companyData.pcaf}</div>
                            <div className='single_display_report_block' style={{ width: '10vw', fontSize: '2.8vh' }}>{handleFractionDigits(companyData.pcafQuality)}</div>
                        </div>
                    </div>
                    <p className={classes.displayTitle}>備註</p>
                    <div style={{ marginBottom: '1.5vh' }}>
                        <p className={classes.outputText}>{companyData.remark}</p>
                    </div>
                </div>
            </div >
        </div>
    )
}

export default DisplaySingleInvestmentCarbonEmission;