import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ReplayIcon from '@material-ui/icons/Replay';

// const handleDataFilter = (datas_ori, selectLTVAtRisk, selectCALTV, county, changeDatas) => {
//     console.log("filting data!")
//     changeDatas(Object.values(datas_ori.individual).filter(data => {
//         return data.LTVatRisk > selectLTVAtRisk/100 & data.CA_LTV > selectCALTV/100 & data.address.includes(county)
//     }))
// }

const handleDataFilter = (datas_ori, ltvatRisk, ca_ltv, county, ownerID, changeDatas) => {
    let data_temp1 = [];
    let data_temp2 = [];
    let data_temp = [];

    if (county !== "" && ownerID !== "") {
        data_temp1 = Object.values(datas_ori.individual).filter(data => {
            return data.county === county && data.ownerID === ownerID
        });
    }
    else if (county !== "") {
        data_temp1 = Object.values(datas_ori.individual).filter(data => {
            return data.county === county
        });
    }
    else if (ownerID !== "") {
        data_temp1 = Object.values(datas_ori.individual).filter(data => {
            return data.ownerID === ownerID
        });
    }

    if (ca_ltv !== 100 && ltvatRisk !== 0) {
        data_temp2 = Object.values(datas_ori.individual).filter(data => {
            return data.ca_ltv * 100 < ca_ltv && data.ltvatRisk * 100 < ltvatRisk
        });
    }
    else if (ca_ltv !== 100) {
        data_temp2 = Object.values(datas_ori.individual).filter(data => {
            return data.ca_ltv * 100 < ca_ltv
        });
    }
    else if (ltvatRisk !== 0) {
        data_temp2 = Object.values(datas_ori.individual).filter(data => {
            return data.ltvatRisk * 100 < ltvatRisk
        });
    }

    if ((ca_ltv !== 100 || ltvatRisk !== 0) && (county !== "" || ownerID !== "")) {
        for (let i = 0; i < Object.keys(data_temp1).length; i++) {
            for (let j = 0; j < Object.keys(data_temp2).length; j++) {
                if (data_temp1[Object.keys(data_temp1)[i]] === data_temp2[Object.keys(data_temp2)[j]]) {
                    data_temp.push(data_temp1[Object.keys(data_temp1)[i]])
                }
            }
        }
        if (data_temp.length === 0) {
            changeDatas(["not found"])
            console.log("not found")
        }
        else {
            changeDatas(data_temp)
        }
    }
    else if (ca_ltv !== 100 || ltvatRisk !== 0) {
        if (data_temp2.length !== 0) {
            changeDatas(data_temp2)
        }
        else {
            changeDatas(["not found"])
            console.log("not found")
        }
    }
    else if (county !== "" || ownerID !== "") {
        if (data_temp1.length !== 0) {
            changeDatas(data_temp1)
        }
        else {
            changeDatas(["not found"])
            console.log("not found")
        }
    }
    else {
        changeDatas(["not found"])
        console.log("not found")
    }
    console.log("data_temp1:", data_temp1, ", data_temp2:", data_temp2, ", data_temp:", data_temp);
}

const handleDataFilterDeletion = (changeSelectLTVAtRisk, changeSelectCALTV, changeCounty, changeOwner, changeDatas) => {
    changeSelectLTVAtRisk(0);
    changeSelectCALTV(100);
    changeCounty("");
    changeOwner("");
    changeDatas([]);
}

const useStyles = makeStyles((theme) => ({
    displayText: {
        fontWeight: 'bold',
        fontSize: '3.3vh',
        margin: '1vh 2.5vw 1vh 0vw',
    },
    selectOption: {
        margin: '1vh 0vw 0vh 0vw',
        fontSize: '2.5vh',
    },
    input: {
        width: "4vw",
        height: "3.3vh",
        margin: '0vh 0.5vw 0vh 0vw',
        fontSize: '2.3vh',
        paddingLeft: '0.5vw',
        borderRadius: '5px',
        borderWidth: 'thin',
        borderColor: '#b6b6b6',
        borderStyle: 'solid',
        backgroundColor: '#e5e5e5',
    },
    select: {
        height: "3.8vh",
        width: "4vw",
        margin: '0vh 3vw 0vh 0.5vw',
        fontSize: '2.2vh',
    },
    compute: {
        height: "4vh",
        width: "4.5vw",
        margin: '0vh 0vw 0vh 0.5vw',
        fontSize: '2.5vh',
        backgroundColor: '#ffffff',
    },
    computeIcon: {
        height: "4vh",
        width: "2.5vw",
        minWidth: "2.5vw",
        margin: '0vh 0vw 0vh 0.5vw',
        fontSize: '2.5vh',
        backgroundColor: '#ffffff',
    },
    menuItem: {
        marginBottom: '0vh',
    },
    icon: {
        height: "3vh",
    }
}));

function Test_DisplayReportClimateStressTestUpload(props) {
    const classes = useStyles();

    const [selectLTVAtRisk, changeSelectLTVAtRisk] = useState(0);
    const [selectCALTV, changeSelectCALTV] = useState(100);
    const [county, changeCounty] = useState("");
    const [owner, changeOwner] = useState("");
    const [datas, changeDatas] = useState([]);

    const {
        datas_ori,
        countyList,
        ownerList,
    } = props;

    return (
        <div className="display_body_container">
            <div style={{ backgroundColor: '#fafafa', borderRadius: '1.212vw', padding: '2.1vh 1.6vw 4vh', overflowX: 'scroll' }}>
                {/* {console.log(datas_ori)} */}
                {datas_ori.total === undefined ? "" :
                    <div>
                        <div style={{ display: 'inline-flex', alignItems: 'flex-start' }}>
                            <p className={classes.displayText}>總體價值減損量：{(datas_ori.total.CA_VAL_DELTA / 100000000).toFixed(5)}億元 ({datas_ori.total.CA_VAL_PERCENT})</p>
                            <p className={classes.displayText}>總體RWA改變量：{(datas_ori.total.RWA / 100000000).toFixed(5)}億元 ({datas_ori.total.RWA_PERCENT})</p>
                        </div>
                        <div className={classes.selectOption} style={{ display: 'inline-flex', alignItems: 'flex-start', marginBottom: '3vh' }}>
                            {/* <p style={{ margin: '0vh 0vw 0vh 0vh' }}>篩選條件：</p> */}
                            <p style={{ margin: '0vh 0.3vw 0vh 0vw' }}>CA-LTV 小於 </p>
                            <input
                                className={classes.input}
                                type="number"
                                min="-100"
                                max="100"
                                step="0.01"
                                value={selectCALTV}
                                onChange={(event) => {
                                    changeSelectCALTV(event.target.value);
                                }}
                            />%
                            <p style={{ margin: '0vh 0.3vw 0vh 2vh' }}>LTV at risk 小於 </p>
                            <input
                                className={classes.input}
                                type="number"
                                min="-100"
                                max="100"
                                step="0.01"
                                value={selectLTVAtRisk}
                                onChange={(event) => {
                                    changeSelectLTVAtRisk(event.target.value);
                                }}
                            />%
                            <p style={{ margin: '0vh 0.3vw 0vh 2vw' }}>地區</p>
                            <FormControl variant="outlined">
                                <Select
                                    className={classes.select}
                                    style={{ width: "8vw" }}
                                    value={county}
                                    onChange={(event) => {
                                        changeCounty(event.target.value);
                                    }}
                                >
                                    {
                                        Object
                                            .entries(countyList)
                                            .map(([index, value]) => <MenuItem key={`countyChoice_${index}`} value={value} className={classes.menuItem} >{value}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>


                            <p style={{ margin: '0vh 0.3vw 0vh 0vw' }}>使用者</p>
                            <FormControl variant="outlined">
                                <Select
                                    className={classes.select}
                                    style={{ width: '7vw' }}
                                    value={owner}
                                    onChange={(event) => {
                                        changeOwner(event.target.value);
                                    }}
                                >
                                    {
                                        Object
                                            .entries(ownerList)
                                            .map(([index, value]) => <MenuItem key={`ownerChoice_${index}`} value={value} className={classes.menuItem} >{value}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>

                            {/* <Button variant="outlined" className={classes.compute} onClick={() => handleDataFilter(datas_ori, selectLTVAtRisk, selectCALTV, county, changeDatas)} >篩選</Button>
                            <Button variant="outlined" className={classes.compute} onClick={() => handleDataFilterDeletion(changeSelectLTVAtRisk, changeSelectCALTV, changeCounty, changeDatas)} >取消篩選</Button> */}

                            <Button variant="outlined" className={classes.compute} onClick={() => handleDataFilter(datas_ori, selectLTVAtRisk, selectCALTV, county, owner, changeDatas)} >篩選</Button>
                            <Button variant="outlined" className={classes.computeIcon} onClick={() => handleDataFilterDeletion(changeSelectLTVAtRisk, changeSelectCALTV, changeCounty, changeOwner, changeDatas)} >
                                <ReplayIcon className={classes.icon} />
                            </Button>

                        </div>
                        {datas[0] === undefined ?
                            <div style={{ display: 'inline-grid', width: 'fit-content', height: 'fit-content', border: 'solid 2px #eaeaea', margin: '0vh 1.6vw 1vh 0vw' }}>
                                <div style={{ display: 'inline-flex' }}>
                                    <div className='single_display_report_block' style={{ width: '4vw' }}>標的</div>
                                    <div className='single_display_report_block' style={{ width: '25vw' }}>地址</div>
                                    <div className='single_display_report_block' style={{ width: '10vw' }}>危害*脆弱度</div>
                                    <div className='single_display_report_block' style={{ width: '10vw' }}>原價值</div>
                                    <div className='single_display_report_block' style={{ width: '10vw' }}>減損後價值</div>
                                    <div className='single_display_report_block' style={{ width: '8vw' }}>CA-LTV</div>
                                    <div className='single_display_report_block' style={{ width: '8vw' }}>LTV at risk</div>
                                </div>
                                <div style={{ display: 'inline-flex' }}>
                                    <div style={{ display: 'grid' }}>
                                        {
                                            Object
                                                .entries(datas_ori.individual)
                                                .map((data_ori) => <div key={data_ori[1].id} className='single_display_report_block' style={{ width: '4vw' }}>{data_ori[1].target}</div>)
                                        }
                                    </div>
                                    <div style={{ display: 'grid' }}>
                                        {
                                            Object
                                                .entries(datas_ori.individual)
                                                .map((data_ori) => <div key={data_ori[1].id} className='single_display_report_block' style={{ width: '25vw' }}>{data_ori[1].address}</div>)
                                        }
                                    </div>
                                    <div style={{ display: 'grid' }}>
                                        {
                                            Object
                                                .entries(datas_ori.individual)
                                                .map((data_ori) => <div key={data_ori[1].id} className='single_display_report_block' style={{ width: '10vw' }}>{(data_ori[1].haz_vul_val * 100).toFixed(2)}%</div>)
                                        }
                                    </div>
                                    <div style={{ display: 'grid' }}>
                                        {
                                            Object
                                                .entries(datas_ori.individual)
                                                .map((data_ori) => <div key={data_ori[1].id} className='single_display_report_block' style={{ width: '10vw' }}>
                                                    {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: '0', minimumFractionDigits: '0' }).format(data_ori[1].original_value)}
                                                </div>)
                                        }
                                    </div>
                                    <div style={{ display: 'grid' }}>
                                        {
                                            Object
                                                .entries(datas_ori.individual)
                                                .map((data_ori) => <div key={data_ori[1].id} className='single_display_report_block' style={{ width: '10vw' }}>
                                                    {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: '0', minimumFractionDigits: '0' }).format(data_ori[1].loss_value)}
                                                </div>)
                                        }
                                    </div>
                                    <div style={{ display: 'grid' }}>
                                        {
                                            Object
                                                .entries(datas_ori.individual)
                                                .map((data_ori) => <div key={data_ori[1].id} className='single_display_report_block' style={{ width: '8vw' }}>{(data_ori[1].ca_ltv * 100).toFixed(2)}%</div>)
                                        }
                                    </div>
                                    <div style={{ display: 'grid' }}>
                                        {
                                            Object
                                                .entries(datas_ori.individual)
                                                .map((data_ori) => <div key={data_ori[1].id} className='single_display_report_block' style={{ width: '8vw' }}>{(data_ori[1].ltvatRisk * 100).toFixed(2)}%</div>)
                                        }
                                    </div>
                                </div>
                            </div> : datas[0] === "not found" ?
                                <div style={{ display: 'inline-grid', width: 'fit-content', height: 'fit-content', border: 'solid 2px #eaeaea', margin: '0vh 1.6vw 1vh 0vw' }}>
                                    <div style={{ display: 'inline-flex' }}>
                                        <div className='single_display_report_block' style={{ width: '4vw' }}>標的</div>
                                        <div className='single_display_report_block' style={{ width: '25vw' }}>地址</div>
                                        <div className='single_display_report_block' style={{ width: '10vw' }}>危害*脆弱度</div>
                                        <div className='single_display_report_block' style={{ width: '10vw' }}>原價值</div>
                                        <div className='single_display_report_block' style={{ width: '10vw' }}>減損後價值</div>
                                        <div className='single_display_report_block' style={{ width: '8vw' }}>CA-LTV</div>
                                        <div className='single_display_report_block' style={{ width: '8vw' }}>LTV at risk</div>
                                    </div>
                                </div> :
                                <div style={{ display: 'inline-grid', width: 'fit-content', height: 'fit-content', border: 'solid 2px #eaeaea', margin: '0vh 1.6vw 1vh 0vw' }}>
                                    <div style={{ display: 'inline-flex' }}>
                                        <div className='single_display_report_block' style={{ width: '4vw' }}>標的</div>
                                        <div className='single_display_report_block' style={{ width: '25vw' }}>地址</div>
                                        <div className='single_display_report_block' style={{ width: '10vw' }}>危害*脆弱度</div>
                                        <div className='single_display_report_block' style={{ width: '10vw' }}>原價值</div>
                                        <div className='single_display_report_block' style={{ width: '10vw' }}>減損後價值</div>
                                        <div className='single_display_report_block' style={{ width: '8vw' }}>CA-LTV</div>
                                        <div className='single_display_report_block' style={{ width: '8vw' }}>LTV at risk</div>
                                    </div>
                                    <div style={{ display: 'inline-flex' }}>
                                        <div style={{ display: 'grid' }}>
                                            {
                                                Object
                                                    .entries(datas)
                                                    .map((data) => <div key={data[1].id} className='single_display_report_block' style={{ width: '4vw' }}>{data[1].target}</div>)
                                            }
                                        </div>
                                        <div style={{ display: 'grid' }}>
                                            {
                                                Object
                                                    .entries(datas)
                                                    .map((data) => <div key={data[1].id} className='single_display_report_block' style={{ width: '25vw' }}>{data[1].address}</div>)
                                            }
                                        </div>
                                        <div style={{ display: 'grid' }}>
                                            {
                                                Object
                                                    .entries(datas)
                                                    .map((data) => <div key={data[1].id} className='single_display_report_block' style={{ width: '10vw' }}>{(data[1].haz_vul_val * 100).toFixed(2)}%</div>)
                                            }
                                        </div>
                                        <div style={{ display: 'grid' }}>
                                            {
                                                Object
                                                    .entries(datas)
                                                    .map((data) => <div key={data[1].id} className='single_display_report_block' style={{ width: '10vw' }}>
                                                        {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: '0', minimumFractionDigits: '0' }).format(data[1].original_value)}
                                                    </div>)
                                            }
                                        </div>
                                        <div style={{ display: 'grid' }}>
                                            {
                                                Object
                                                    .entries(datas)
                                                    .map((data) => <div key={data[1].id} className='single_display_report_block' style={{ width: '10vw' }}>
                                                        {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: '0', minimumFractionDigits: '0' }).format(data[1].loss_value)}
                                                    </div>)
                                            }
                                        </div>
                                        <div style={{ display: 'grid' }}>
                                            {
                                                Object
                                                    .entries(datas)
                                                    .map((data) => <div key={data[1].id} className='single_display_report_block' style={{ width: '8vw' }}>{(data[1].ca_ltv * 100).toFixed(2)}%</div>)
                                            }
                                        </div>
                                        <div style={{ display: 'grid' }}>
                                            {
                                                Object
                                                    .entries(datas)
                                                    .map((data) => <div key={data[1].id} className='single_display_report_block' style={{ width: '8vw' }}>{(data[1].ltvatRisk * 100).toFixed(2)}%</div>)
                                            }
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                }
            </div>
        </div >
    )
}

export default Test_DisplayReportClimateStressTestUpload;