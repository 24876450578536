import React, { useState, useEffect } from 'react';
import "../style/style.css";
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from './Menu';
import AppConfigN from '../const/AppConfigN.const';
import CarbonData from '../component/DisplayInvestmentCarbonEmissions_CarbonData';
import FinancialData from '../component/DisplayInvestmentCarbonEmissions_FinancialData';
import DataExport from '../component/DisplayInvestmentCarbonEmissions_DataExport';


const useStyles = makeStyles((theme) => ({
    blockTitle: {
        margin: "0 0 0 0vw",
        fontFamily: "PingFangTC",
        fontSize: "2.1vh",
        fontWeight: "500",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        color: "#797979",
    },
    blockContent: {
        margin: "1vh 1vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        textAlign: "center",
    },
    formControl: {
        width: "100%",
    },
    link: {
        // fontSize: '1vh',
        textAlign: 'left',
        // margin: '1vh 0 0 1vw',
        textDecoration: "underline",
        width: 'fit-content',
        '&:hover': {
            cursor: "pointer",
        }
    },
    inputFile: {
        // margin: '0 0 2vh 0vw',
        width: "100%",
    },
    select: {
        height: "5vh",
        // width: "12vw",
        margin: '1.5vh 0vw 0vh 0vw',
        fontSize: '2vh',
    },
    sendInfoButton: {
        height: "4vh",
        fontSize: "2.1vh",
        padding: "5px 10px",
        margin: "0.5vh 0",
        // width: "95%",
        backgroundColor: '#ececec',
    }
}))

function InvestmentCarbonEmissions(props) {
    const classes = useStyles();

    const [companyData, setCompanyData] = useState({});
    const [errorMsg, setErrorMsg] = useState("");
    const [file, setFile] = useState();
    const [batchDataList, setBatchDataList] = useState({});
    const [batchID, setBatchID] = useState("");
    const [typeList, setTypeList] = useState([]);
    const [ownerList, setOwnerList] = useState([]);
    const [isClick, setIsClick] = useState(false);
    // const [uploadTimeList, setUploadTimeList] = useState([]);

    const {
        account,
        permission,
        cookies,
        removeCookie,
        activeScreen,
        switchScreen,
        switchShellChoice,
        changeDisplayMethod, } = props

    const getBatchDataList = () => {
        let res = false;
        fetch(`${AppConfigN.SERVER_URL}/api/pcaf_batch`, {
            method: 'GET',
            headers: {
                "Authorization": `Token ${account.token}`,
                "Content-Type": "application/json"
            },
        })
            .then((response) => {
                // console.log(response)
                if (response.ok) {
                    res = true;
                }
                return response.json()
            })
            .then((responseJson) => {
                if (res) {
                    setErrorMsg("");
                    let option = responseJson.batch_list;
                    // console.log(option);
                    setBatchDataList(option);
                }
                else {
                    setErrorMsg(responseJson.detail);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const getCompanyData = (batchID) => {
        let id = "";
        let res = false;
        // console.log(batchID)
        if (batchID === "New") {
            id = ""
        }
        else {
            id = batchID;
        }
        fetch(`${AppConfigN.SERVER_URL}/api/fin_emis_pcaf_batch`, {
            method: 'POST',
            headers: {
                "Authorization": `Token ${account.token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                batch_id: id
            })
        })
            .then((response) => {
                // console.log(response)
                if (response.ok) {
                    res = true;
                }
                return response.json()
            })
            .then((responseJson) => {
                // console.log(responseJson);
                if (res) {
                    setErrorMsg("");
                    let data = responseJson;
                    setCompanyData(data);
                    getBatchDataList();
                    setIsClick(false);

                    let typeList = data.individual.map(type => Object.values(type)[2]);
                    typeList = typeList.filter(function (item, pos) {
                        return typeList.indexOf(item) === pos;
                    });
                    setTypeList(typeList);
                    // console.log(typeList);

                    let ownerList = data.individual.map(owner => Object.values(owner)[24]);
                    ownerList = ownerList.filter(function (item, pos) {
                        return ownerList.indexOf(item) === pos;
                    });
                    setOwnerList(ownerList);
                    // console.log(ownerList);
                }
                else {
                    setErrorMsg(responseJson.detail);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const getDownloadData = () => {
        fetch(`${AppConfigN.SERVER_URL}/api/fin_emis_download`, {
            method: 'POST',
            headers: {
                "Authorization": `Token ${props.account.token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "filedownload": "FIN_EMIS_INPUT.xlsx"
            })
        })
            .then((response) => response.blob())
            .then((blob) => {
                console.log(blob)
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `投融資組合碳排放計算輸入檔.xlsx`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const uploadData = () => {
        setIsClick(true);
        let res = false;
        const formData = new FormData();
        formData.append('fileUpload', file);

        fetch(`${AppConfigN.SERVER_URL}/api/fin_emis_upload/`, {
            method: 'POST',
            headers: {
                "Authorization": `Token ${props.account.token}`,
            },
            body: formData
        })
            .then((response) => {
                // console.log(response)
                if (response.ok) {
                    res = true;
                }
                return response.json()
            })
            .then((responseJson) => {
                if (res) {
                    let id = responseJson.batch_id;
                    getCompanyData(id);
                    setErrorMsg("");
                    getBatchDataList();
                    setBatchID("");
                }
                else {
                    setErrorMsg(responseJson.detail);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const handleFractionDigits = (number) => {
        if (number === null) return "-"
        else if (number < 1) {
            return new Intl.NumberFormat('en-US', { maximumFractionDigits: 4, minimumFractionDigits: 4 }).format(number)
        }
        else if (number < 10) {
            return new Intl.NumberFormat('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(number)
        }
        else if (number < 100) {
            return new Intl.NumberFormat('en-US', { maximumFractionDigits: 1, minimumFractionDigits: 1 }).format(number)
        }
        else return new Intl.NumberFormat('en-US', { maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(number)
    }

    useEffect(() => {
        if (Object.values(batchDataList).length === 0) {
            getBatchDataList();
        }
    })


    return (
        <div className="new_home_mainContainer">
            <div className="page_container">
                <div className="page_title_container"><p className="page_title_text">投融資組合碳排放計算</p></div>
                <div style={{ display: "flex", justifyContent: "left", flexWrap: 'wrap', width: 'calc(100% + 10px)' }}>
                    <div className="DataDownload_block" style={{ flexDirection: "column", flex: "2", minWidth: '490px', margin: '0 10px 10px 0' }}>
                        <p className="DataDownload_block_title_text">投融資標的組合基本資料</p>
                        <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
                        <div style={{ display: 'flex', margin: "1vh 1vw 0 1vw" }}>
                            <div style={{ width: "56%", margin: "0 3% 0 1%" }}><p className={classes.blockTitle}>上傳資料 (<a className={classes.link} href={'/#'} onClick={() => getDownloadData()}>下載範例檔案</a>)</p></div>
                            <div style={{ width: "40%", margin: "0 0 0 0%" }}><p className={classes.blockTitle}>查看過去資料</p></div>
                        </div>
                        <div className={classes.blockContent}>
                            <div style={{ width: "56%", margin: "0 3% 0 1%" }}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <input
                                        className={classes.inputFile}
                                        style={{ marginTop: "0.8vh" }}
                                        type="file"
                                        name="file"
                                        onChange={(event) => {
                                            setFile(event.target.files[0]);
                                        }} />
                                </FormControl>
                            </div>
                            <div style={{ width: "40%", margin: "0 0 0 0%" }}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <Select
                                        className={classes.select}
                                        value={batchID}
                                        onChange={(event) => {
                                            setBatchID(event.target.value);
                                        }}
                                    >
                                        <MenuItem value="New">最新批次資料</MenuItem>
                                        {
                                            Object
                                                .entries(batchDataList)
                                                .map(([id, batchData]) => <MenuItem key={`batchIDList_${id}`} value={batchData.batch_id}>{batchData.created_time}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div style={{ display: 'flex', margin: "1vh 1vw 1vh 1vw", textAlign: "center" }}>
                            <div style={{ width: "56%", margin: "0 3% 0 1%" }}>

                                <Button variant="outlined" className={classes.sendInfoButton} onClick={() => uploadData()}>計算</Button>
                            </div>
                            <div style={{ width: "40%", margin: "0 0 0 0%" }}><Button variant="outlined" className={classes.sendInfoButton} onClick={() => getCompanyData(batchID)}>查看</Button></div>
                        </div>

                    </div>
                    <div className="DataDownload_block" style={{ flexDirection: "column", flex: "1", minWidth: '230px', margin: '0 10px 10px 0' }}>
                        <p className="DataDownload_block_title_text">投融資組合總碳排放量</p>
                        <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
                        {Object.values(companyData).length === 0 ? "" :
                            <div className={classes.blockContent} style={{ fontSize: "3vh", fontWeight: "bold", display: "block" }}>
                                <div style={{ marginTop: "4vh" }}>
                                    {handleFractionDigits(companyData.total.totalCarbonData)}<br />公噸二氧化碳當量
                                </div>

                                {/* <div style={{ textAlign: "left", fontSize: "1.8vh", fontWeight: "normal", color: "#797979", margin: "1.8vh 0vw 0vh -0.7vw" }}>
                                    *此數據僅包含範疇1+2
                                </div> */}
                            </div>
                        }

                    </div>
                    <div className="DataDownload_block" style={{ flexDirection: "column", flex: "1", minWidth: '235px', margin: '0 10px 10px 0' }}>
                        <p className="DataDownload_block_title_text" style={{ margin: "2vh 1.7vw 2vh 1.7vw" }}>投融資組合總碳排放量區間</p>
                        <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>

                        {Object.values(companyData).length === 0 ? "" :
                            <div className={classes.blockContent} style={{ fontSize: "3vh", fontWeight: "bold", display: "block" }}>
                                <div style={{ marginTop: "4vh" }}>
                                    {handleFractionDigits(companyData.total.minTotalCarbonData)}~
                                    {handleFractionDigits(companyData.total.maxTotalCarbonData)}<br />公噸二氧化碳當量
                                </div>
                                {/* <div style={{ textAlign: "left", fontSize: "1.8vh", fontWeight: "normal", color: "#797979", margin: "1.8vh 0vw 0vh -0.7vw" }}>
                                    *此數據僅包含範疇1+2
                                </div> */}
                            </div>
                        }
                    </div>
                    <div className="DataDownload_block" style={{ flexDirection: "column", flex: "1", minWidth: '230px', margin: '0 10px 10px 0' }}>
                        <p className="DataDownload_block_title_text">PCAF資料品質</p>
                        <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
                        {Object.values(companyData).length === 0 ? "" :
                            <>
                                <div className={classes.blockContent} style={{ fontSize: "3vh", fontWeight: "bold", display: "block", margin: '2vh 2vw' }}>
                                    <div style={{ marginTop: "2vh" }}>
                                        {handleFractionDigits(companyData.total.totalPCAFQuality)}
                                    </div>
                                    <div style={{ textAlign: "left", fontSize: "1.8vh", fontWeight: "normal", color: "#797979", margin: "1.8vh -0.7vw 0vh -0.7vw" }}>
                                        *根據估算方式不同，數值準確度與誤差值亦不同。品質分數介於1 ~ 5，分數越接近1代表資料品質越好
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
                {errorMsg.length === 0 ? "" :
                    <div style={{ margin: "0.5vh 0 0 1vw", color: "red", fontSize: "2.5vh" }}>
                        {errorMsg}
                    </div>
                }
                {isClick === false ? "" :
                    <div style={{ margin: "0.5vh 0 0 1vw", fontSize: "2.5vh" }}>
                        資料計算中...
                    </div>
                }
                {Object.values(companyData).length === 0 ? "" :
                    <div className="display_container">
                        <div className="display_header_container">
                            <div className={props.displayMethod === 'Report' ? 'display_header_item_active' : 'display_header_item_inactive'} onClick={() => { props.switchDisplayMethod('Report') }}>
                                <p className={props.displayMethod === 'Report' ? 'display_header_item_active_text' : 'display_header_item_inactive_text'} >投融資碳排放資料</p>
                            </div>
                            <div className={props.displayMethod === 'FinancialData' ? 'display_header_item_active' : 'display_header_item_inactive'} onClick={() => { props.switchDisplayMethod('FinancialData') }}>
                                <p className={props.displayMethod === 'FinancialData' ? 'display_header_item_active_text' : 'display_header_item_inactive_text'} >標的財務資料</p>
                            </div>
                            <div className={props.displayMethod === 'DataExport' ? 'display_header_item_active' : 'display_header_item_inactive'} onClick={() => { props.switchDisplayMethod('DataExport') }}>
                                <p className={props.displayMethod === 'DataExport' ? 'display_header_item_active_text' : 'display_header_item_inactive_text'} >資料匯出</p>
                            </div>
                        </div>

                        <div>
                            {
                                props.displayMethod === 'Report' ?
                                    <CarbonData account={account} companyData={companyData} typeList={typeList} ownerList={ownerList} /> :
                                    props.displayMethod === 'FinancialData' ?
                                        <FinancialData account={account} companyData={companyData} typeList={typeList} ownerList={ownerList} /> :
                                        <DataExport account={account} companyData={companyData} batchDataList={batchDataList} />
                            }
                        </div>
                    </div>
                }
            </div>
            <Menu
                account={account}
                permission={permission}
                cookies={cookies}
                removeCookie={removeCookie}
                activeScreen={activeScreen}
                switchScreen={switchScreen}
                switchShellChoice={switchShellChoice}
                changeDisplayMethod={changeDisplayMethod}
            />
        </div>
    );
}

export default InvestmentCarbonEmissions;