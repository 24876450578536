import React, { useState, useEffect } from 'react';
import "../../style/style.css";
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import Menu from '../Menu';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Map from './BatchRealEstateValueImpact_Map';
import Report from './BatchRealEstateValueImpact_Report';
import DataExport from './BatchRealEstateValueImpact_Export';
import AppConfigN from '../../const/AppConfigN.const';

const useStyles = makeStyles((theme) => ({
  ClimateStressTestUpload_block_wide: {
    borderRadius: "2px",
    boxShadow: "2px 2px 2px 0 rgba(121, 121, 121, 0.2)",
    backgroundColor: "#ffffff",
    height: "12.5vh",
    padding: "1vh 1vw 1vh 1vw",
    display: 'flex'
  },
  ClimateStressTestUpload_block_title: {
    margin: "0 0 0 0vw",
    fontFamily: "PingFangTC",
    fontSize: "2.1vh",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#797979",
  },
  formControl: {
    textAlign: 'center',
    display: 'inline-flex',
    justifyContent: 'center',
    width: "100%",
  },
  select: {
    height: "5vh",
    margin: '1.5vh 0vw 0vh 0vw',
    fontSize: '2vh',
  },
  compute: {
    height: "5vh",
    width: "8vw",
    minWidth: "80px",
    marginTop: '0.5vh',
    fontSize: '2vh',
    backgroundColor: '#ececec',
  },
  link: {
    fontSize: '1.5vh',
    textAlign: 'left',
    margin: '0.5vh 0 0 0vw',
    textDecoration: "underline",
    width: 'fit-content',
    '&:hover': {
      cursor: "pointer",
    }
  },
  inputFile: {
    marginTop: '2vh',
    fontSize: '2vh',
  }
}))

function BatchRealEstateValueImpact(props) {
  const classes = useStyles();

  const [scenario, changeScenario] = useState("None");
  const [year, changeYear] = useState("None");
  const [vulType, changeVulType] = useState("None");
  const [datas_ori, changeDatasOri] = useState({});
  const [data_town, changeDataTown] = useState({});
  const [countyList, changeCountyList] = useState([]);
  const [ownerList, changeOwnerList] = useState([]);
  const [inudList, changeInudList] = useState([]);
  const [countytownList, changeCountyTownList] = useState({});
  const [selectedFile, setSelectedFile] = useState();
  const [batchID, setBatchID] = useState("");
  const [batchDataList, setBatchDataList] = useState([]);
  const [isOptionSelected, setIsOptionSelected] = useState(true);
  const [isResultExist, setIsResultExist] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [climateStressTestUploadOption, changeClimateStressTestUploadOption] = useState({})
  const [displayMethod, changeDisplayMethod] = useState('')

  const {
    account,
    permission,
    cookies,
    removeCookie,
    activeScreen,
    switchScreen,
    switchShellChoice,
  } = props



  useEffect(() => {

    const getClimateStressTestUploadOption = () => {
        fetch(`${AppConfigN.SERVER_URL}/api/rlest_expprd_ar6`, {
          method: 'GET',
          headers: {
            "Authorization": `Token ${account.token}`,
            "Content-Type": "application/json"
          },
        })
          .then(response => response.json())
          .then((responseJson) => {
            let option = responseJson;
            changeClimateStressTestUploadOption(option);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    getClimateStressTestUploadOption();
  }, [])

  const getDownloadData = () => {
    fetch(`${AppConfigN.SERVER_URL}/api/tpl_download_ar6`, {
      method: 'POST',
      headers: {
        "Authorization": `Token ${props.account.token}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        "filedownload": "REALESTATE_AR6_INPUT.xlsx"
      })
    })
      .then((response) => response.blob())
      .then((blob) => {
        // console.log("getDownloadData!");
        // console.log(blob);
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `房貸LTV輸入架構.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const getBatchDataList = (scenario, year, vulType) => {
    let res = false;
    fetch(`${AppConfigN.SERVER_URL}/api/rlest_batch_ar6`, {
      method: 'POST',
      headers: {
        "Authorization": `Token ${account.token}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        scenarioChoice: scenario,
        yearChoice: year,
        vtypeChoice: vulType
      })
    })
      .then((response) => {
        if (response.ok) {
          res = true;
        }
        return response.json()
      })
      .then((responseJson) => {
        if (res) {
          setErrorMessage("");
          let data = responseJson.batch_list;
          setBatchDataList(data);
        }
        else {
          // console.log(responseJson.detail)
          setErrorMessage(responseJson.detail);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const getData = (batchID) => {
    let id = "";
    if (batchID === "New") {
      id = ""
    }
    else {
      id = batchID;
    }

    if (scenario === "None" || year === "None" || vulType === "None") {
      setIsOptionSelected(false);
    }
    else {
      setIsOptionSelected(true);

      fetch(`${AppConfigN.SERVER_URL}/api/rlest_ltvrw_ar6`, {
        method: 'POST',
        headers: {
          "Authorization": `Token ${props.account.token}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          batch_id: id,
          scenarioChoice: scenario,
          yearChoice: year,
          vtypeChoice: vulType
        })
      })
        .then(response => response.json())
        .then((responseJson) => {
          // console.log(responseJson);
          if (responseJson.detail === undefined) {
            let data = responseJson;
            changeDatasOri(data);
            console.log(data);

            let countyList = data.individual.map((item) => (item['address']));
            countyList = countyList.filter(function (item, pos) {
              return countyList.indexOf(item) === pos;
            });
            changeCountyList(countyList);
            // console.log(countyList)

            let ownerList = data.individual.map(item => (item['ownerID']));
            ownerList = ownerList.filter(function (item, pos) {
              return ownerList.indexOf(item) === pos;
            });
            changeOwnerList(ownerList);
            // console.log(ownerList)

            let inudList = data.individual.map(item => (item['inud_yn']));
            inudList = inudList.filter(function (item, pos) {
              return inudList.indexOf(item) === pos;
            });
            changeInudList(inudList);
            // console.log(inudList)
          }
          else {
            setIsResultExist(false);
            setErrorMessage(responseJson.detail);
          }
        })
        .catch((error) => {
          console.error(error);
        });

      fetch(`${AppConfigN.SERVER_URL}/api/rlest_ltvrw_town_ar6`, {
        method: 'POST',
        headers: {
          "Authorization": `Token ${props.account.token}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          batch_id: id,
          scenarioChoice: scenario,
          yearChoice: year,
          vtypeChoice: vulType
        })
      })
        .then(response => response.json())
        .then((responseJson) => {
          // console.log(responseJson);
          if (responseJson.detail === undefined) {
            let data = responseJson;
            changeDataTown(data);
            // console.log(data);
            let countytownList = {};
            let tempCountyList = [];
            for (let i = 0; i < data.individual.length; i++) {
              if (tempCountyList.includes(data.individual[i].countyname) === false) {
                tempCountyList.push(data.individual[i].countyname);
                let name = data.individual[i].countyname;
                let temp = [];
                temp.push(data.individual[i].townname);
                countytownList[name] = temp;
              }
              else {
                let name = data.individual[i].countyname;
                countytownList[name].push(data.individual[i].townname)
              }
            }
            changeCountyTownList(countytownList);
            // console.log(countytownList);
          }
          else {
            setIsResultExist(false);
            setErrorMessage(responseJson.detail);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  const uploadData = () => {
    let res = false;
    if (scenario === "None" || year === "None" || vulType === "None") {
      setIsOptionSelected(false);
    }
    else {
      setIsOptionSelected(true);
      const formData = new FormData();
      formData.append('fileUpload', selectedFile);
      formData.append('scenarioChoice', scenario);
      formData.append('yearChoice', year);
      formData.append('vtypeChoice', vulType);

      fetch(`${AppConfigN.SERVER_URL}/api/rlest_ltvrw_upload_ar6/`, {
        method: 'POST',
        headers: {
          "Authorization": `Token ${props.account.token}`,
        },
        body: formData
      })
        .then((response) => {
          if (response.ok) {
            res = true;
          }
          return response.json()
        })
        .then((responseJson) => {
          if (res) {
            getData();
            setErrorMessage("");
          }
          else {
            setIsResultExist(false);
            setErrorMessage(responseJson.detail);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  return (
    <div className="new_home_mainContainer">
      <div className="page_container">
        <div className="page_title_container"><p className="page_title_text">氣候變遷下不動產總價值影響評估</p></div>
        {climateStressTestUploadOption.scenarioChoice === undefined ? "" :
          <div style={{ display: "flex", justifyContent: "space-between", flexWrap: 'wrap' }}>
            <div className="DataDownload_block" style={{ flexDirection: "column", flex: 1, minWidth: "530px", paddingBottom: "1vh", margin: '10px' }}>
              <p className="DataDownload_block_title_text">基本情境設定</p>
              <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
              <div style={{ display: "flex", padding: "1vh 1vw", justifyContent: 'space-around' }}>
                <div style={{ margin: '0vh 0.5vw 0.5vh 0.5vw', width: '30%' }}>
                  <p className={classes.ClimateStressTestUpload_block_title}>氣候情境</p>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <Select
                      className={classes.select}
                      value={scenario}
                      onChange={(event) => {
                        changeScenario(event.target.value);
                      }}
                    >
                      <MenuItem value="None"><em>請選擇情境</em></MenuItem>
                      {
                        Object
                          .entries(climateStressTestUploadOption.scenarioChoice)
                          .map(([index, value]) => <MenuItem key={`scenarioChoice_${index}`} value={value} >{value}</MenuItem>)
                      }
                    </Select>
                  </FormControl>
                </div>
                <div style={{ margin: '0vh 0.5vw 0.5vh 0.5vw', width: '30%' }}>
                  <p className={classes.ClimateStressTestUpload_block_title}>時期</p>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <Select
                      className={classes.select}
                      value={year}
                      onChange={(event) => {
                        changeYear(event.target.value);
                      }}
                    >
                      <MenuItem value="None"><em>請選擇時期</em></MenuItem>
                      {
                        Object
                          .entries(climateStressTestUploadOption.yearChoice)
                          .map(([index, value]) => <MenuItem key={`scenarioChoice_${index}`} value={value} >{value}</MenuItem>)
                      }
                    </Select>
                  </FormControl>
                </div>
                <div style={{ margin: '0vh 0.5vw 0.5vh 0.5vw', width: '30%' }}>
                  <p className={classes.ClimateStressTestUpload_block_title}>風險偏好</p>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <Select
                      className={classes.select}
                      value={vulType}
                      onChange={(event) => {
                        changeVulType(event.target.value);
                      }}
                    >
                      <MenuItem value="None"><em>請選擇風險偏好</em></MenuItem>
                      {
                        Object
                          .entries(climateStressTestUploadOption.vtypeChoice)
                          .map(([index, value]) => <MenuItem key={`vtypeChoice_${index}`} value={value} >{value}</MenuItem>)
                      }
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div style={{ textAlign: "center" }}>
                <Button variant="outlined" className={classes.compute} onClick={() => getBatchDataList(scenario, year, vulType)}>設定</Button>
              </div>
            </div>
            <div className="DataDownload_block" style={{ flexDirection: "column", flex: 1, minWidth: "500px", paddingBottom: "1vh", margin: '10px' }}>
              <p className="DataDownload_block_title_text">資料上傳與計算</p>
              <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
              <div style={{ display: 'flex', margin: "1vh 1vw 0 1vw", justifyContent: 'space-around' }}>
                <div style={{ width: "40%", margin: "0 3% 0 1%" }}><p className={classes.ClimateStressTestUpload_block_title}>上傳資料 (<a className={classes.link} href={'/#'} onClick={() => getDownloadData()}>下載範例檔案</a>)</p></div>
                <div style={{ width: "40%", margin: "0 0 0 0%" }}><p className={classes.ClimateStressTestUpload_block_title}>查看過去資料</p></div>
              </div>
              <div className={classes.blockContent} style={{ display: 'flex', margin: "1vh 1vw 0 1vw", justifyContent: 'space-around' }}>
                <div style={{ width: "40%", margin: "0 3% 0 1%" }}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <input
                      className={classes.inputFile}
                      type="file"
                      name="file"
                      onChange={(event) => {
                        setSelectedFile(event.target.files[0]);
                      }} />
                  </FormControl>
                </div>
                <div style={{ width: "40%", margin: "0 0 0 0%" }}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <Select
                      className={classes.select}
                      value={batchID}
                      onChange={(event) => {
                        setBatchID(event.target.value);
                      }}
                    >
                      <MenuItem value="New">最新批次資料</MenuItem>
                      {
                        Object
                          .entries(batchDataList)
                          .map(([id, batchData]) => <MenuItem key={`batchIDList_${id}`} value={batchData.batch_id}>{batchData.created_time}</MenuItem>)
                      }
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div style={{ display: 'flex', margin: "1vh 1vw 1vh 1vw", textAlign: "center", justifyContent: 'space-around' }}>
                <div style={{ width: "40%", margin: "0 3% 0 1%" }}>
                  <Button variant="outlined" className={classes.compute} onClick={() => uploadData()}>計算</Button>
                </div>
                <div style={{ width: "40%", margin: "0 0 0 0%" }}><Button variant="outlined" className={classes.compute} onClick={() => getData(batchID)}>查看</Button></div>
              </div>

            </div>
          </div>
        }

        {isOptionSelected === false ?
          <div style={{ margin: "0.5vh 0 0 1vw", color: "red", fontSize: "2.5vh" }}>
            輸入條件不完整：<br />
            若只需查看最新資料，請完整選擇氣候情境、時期與風險偏好；<br />
            若要計算標的資料，請完整選擇氣候情境、時期、風險偏好與上傳檔案
          </div> :
          ""
        }
        <div style={{ margin: "0.5vh 0 0 1vw", color: "red", fontSize: "2.5vh" }}>
          {errorMessage}
        </div>

        {Object.values(datas_ori).length === 0 ? "" :
          <div className="display_container">
            <div className="display_header_container">
              <div className={displayMethod=== 'Report' ? 'display_header_item_active' : 'display_header_item_inactive'} onClick={() => { changeDisplayMethod('Report') }}>
                <p className={displayMethod=== 'Report' ? 'display_header_item_active_text' : 'display_header_item_inactive_text'} >列表 (個別標的)</p>
              </div>
              {/* <div className={displayMethod=== 'Map' ? 'display_header_item_active' : 'display_header_item_inactive'} onClick={() => { changeDisplayMethod('Map') }}>
                <p className={displayMethod=== 'Map' ? 'display_header_item_active_text' : 'display_header_item_inactive_text'} >地圖 (統計數據)</p>
              </div> */}
              <div className={displayMethod=== 'DataExport' ? 'display_header_item_active' : 'display_header_item_inactive'} onClick={() => { changeDisplayMethod('DataExport') }}>
                <p className={displayMethod=== 'DataExport' ? 'display_header_item_active_text' : 'display_header_item_inactive_text'} >資料匯出</p>
              </div>
            </div>

            <div>
              {
                displayMethod === 'Report' ?
                  <Report datas_ori={datas_ori} countyList={countyList} ownerList={ownerList} inudList={inudList} isOptionSelected={isOptionSelected} isResultExist={isResultExist} /> :
                // displayMethod === 'Map' ?
                //   <Map datas_town={data_town} countyList={countyList} countytownList={countytownList} /> :
                displayMethod === 'DataExport' ?
                  <DataExport account={account} scenario={scenario} year={year} vulType={vulType} batchDataList={batchDataList} />:
                ''
              }
            </div>
          </div>
        }
      </div>
      <Menu
        account={account}
        permission={permission}
        cookies={cookies}
        removeCookie={removeCookie}
        activeScreen={activeScreen}
        switchScreen={switchScreen}
        switchShellChoice={switchShellChoice}
      />
    </div>
  );
}

export default BatchRealEstateValueImpact;