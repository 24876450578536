import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import Grid from "@material-ui/core/Grid";
import AppConfigN from '../../const/AppConfigN.const';

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      style={{ height: '5vh', marginLeft: '1vw', padding: '0' }}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

const useStyles = makeStyles((theme) => ({
  textInput: {
    height: "4.5vh",
    width: "38vw",
    margin: '0vh 1vw 0vh 1.5vh',
    paddingLeft: '1vw',
    borderRadius: '5px',
    borderWidth: 'thin',
    borderColor: '#b6b6b6',
    borderStyle: 'solid',
    backgroundColor: '#fafafa',
  },
  textInputDisabled: {
    height: "4.5vh",
    width: "38vw",
    margin: '0vh 1vw 0vh 1.5vh',
    paddingLeft: '1vw',
    borderRadius: '5px',
    borderWidth: 'thin',
    borderColor: '#b6b6b6',
    borderStyle: 'solid',
    backgroundColor: '#e5e5e5',
  },
  formControl: {
    maxWidth: '39vw',
    textAlign: 'center',
  },
  select: {
    height: "5vh",
    width: "100%",
    margin: '0vh 1vw 0vh 1.5vh',
  },
  input: {
    height: "4.5vh",
    width: "10vw",
    margin: '0vh 1vw 0vh 1.5vh',
    paddingLeft: '1vw',
    borderRadius: '5px',
    borderWidth: 'thin',
    borderColor: '#b6b6b6',
    borderStyle: 'solid',
    backgroundColor: '#fafafa',
  },
  calculateButton: {
    fontSize: '2vh',
    width: '10vw',
    height: '5vh',
    margin: '0vh 1vw 0vh 1.5vw',
    padding: '1vh 0.5vw',
    color: 'black',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'gray',
      color: '#FFF'
    }
  },
  outputText: {
    // width: '15vw',
    margin: '3vh 0vw 1vh 2vw',
    fontSize: '2.8vh',
  },
  informText: {
    fontSize: '2.2vh',
    margin: '0',
    color: '#7b7b7b',
  }
}));

function SingleRealEstateValueImpact_display(props) {
  const classes = useStyles();
  const [country, changeCountry] = useState("None")
  const [township, changeTownship] = useState("None")
  const [townshipChoiceList, changeTownshipChoice_list] = useState({})
  const [buildingType, changeBuildingType] = useState("None")
  const [buildingTypeList, changeBuildingType_list] = useState({})
  const [totalFloor, changeTotalFloor] = useState(0)
  const [riskType, changeRiskType] = useState("None")
  const [riskAmount, changeRiskAmount] = useState(0)
  const [riskInfo, changeRiskInfo] = useState([])
  const [isOptionSelected, setIsOptionSelected] = useState(true)
  const [isFloodPotential, changeIsFloodPotential] = useState(false)

  const {
    countryChoiceList,
    account,
  } = props;

  const getTownshipChoiceList = (country) => {
    fetch(`${AppConfigN.SERVER_URL}/api/rlest_geotwtown_ar6`, {
      method: 'POST',
      headers: {
        "Authorization": `Token ${account.token}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        county: country,
      }),
    })
      .then(response => response.json())
      .then((responseJson) => {
        // console.log(responseJson)
        let townshipChoice_list = responseJson;
        changeTownshipChoice_list(townshipChoice_list);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const getBuildingTypeList = (township) => {
    fetch(`${AppConfigN.SERVER_URL}/api/rlest_btype_ar6`, {
      method: 'POST',
      headers: {
        "Authorization": `Token ${account.token}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        county: country,
        town: township,
      }),
    })
      .then(response => response.json())
      .then((responseJson) => {
        let buildingType_list = responseJson;
        changeBuildingType_list(buildingType_list);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const getData = () => {
    fetch(`${AppConfigN.SERVER_URL}/api/rlest_risk_ar6`, {
      method: 'POST',
      headers: {
        "Authorization": `Token ${account.token}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        countyname: country,
        townname: township,
        building_type: buildingType,
        floor_tot: totalFloor,
        vul_type: riskType,
        inud_ptnl: isFloodPotential,
        exp_amt: riskAmount,
      }),
    })
      .then(response => response.json())
      .then((responseJson) => {
        // console.log(responseJson);
        changeRiskInfo(responseJson);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const errorHandling = () => {
    if (country === "None" || township === "None" || buildingType === "None" || totalFloor === 0 || riskType === "None") {
      setIsOptionSelected(false)
    }
    else {
      setIsOptionSelected(true)
      getData()
    }
  }

  return (
    <div className="display_body_container">
      <div style={{ backgroundColor: '#fafafa', borderRadius: '1.28vw', width: '68.6vw', height: 'fit-content', padding: '2.1vh 1.6vw 4vh', overflowX: 'scroll' }}>
        <div className='input_area_container'>
          <div className='selector_container'>
            <p className='subtitle_text'>縣市</p>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                className={classes.select}
                value={country}
                onChange={(event) => {
                  changeCountry(event.target.value);
                  getTownshipChoiceList(event.target.value)
                }}
              >
                <MenuItem value="None"><em>請選擇縣市</em></MenuItem>
                {
                  Object
                    .entries(countryChoiceList)
                    .map(([index, value]) => <MenuItem key={`countryChoice_${index}`} value={value}>{value}</MenuItem>)
                }
              </Select>
            </FormControl>
          </div>
          <div className='selector_container'>
            <p className='subtitle_text'>鄉鎮市</p>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                className={classes.select}
                value={township}
                onChange={(event) => {
                  changeTownship(event.target.value);
                  getBuildingTypeList(event.target.value);
                }}
              >
                <MenuItem value="None"><em>請選擇鄉鎮市</em></MenuItem>
                {
                  Object
                    .entries(townshipChoiceList)
                    .map(([index, value]) => <MenuItem key={`townshipChoice_${index}`} value={value}>{value}</MenuItem>)
                }
              </Select>
            </FormControl>
          </div>
          <div className='selector_container'>
            <p className='subtitle_text'>建物型態</p>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                className={classes.select}
                value={buildingType}
                onChange={(event) => {
                  changeBuildingType(event.target.value);
                }}
              >
                <MenuItem value="None"><em>請選擇建物型態</em></MenuItem>
                {
                  Object
                    .entries(buildingTypeList)
                    .map(([index, value]) => <MenuItem key={`buildingType_${index}`} value={value}>{value}</MenuItem>)
                }
              </Select>
            </FormControl>
          </div>
          <div className='selector_container'>
            <p className='subtitle_text'>建物總樓層</p>
            <input
              className={classes.input}
              type="number"
              min="1"
              max="100"
              step="1"
              value={totalFloor}
              onChange={(event) => {
                changeTotalFloor(event.target.value);
              }}
            />
          </div>
          <div className='selector_container'>
            <p className='subtitle_text'>風險偏好</p>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                className={classes.select}
                value={riskType}
                onChange={(event) => {
                  changeRiskType(event.target.value);
                }}
              >
                <MenuItem value="None"><em>請選擇風險偏好</em></MenuItem>
                <MenuItem value="Risk Seeking">Risk Seeking</MenuItem>
                <MenuItem value="Risk Neutral">Risk Neutral</MenuItem>
                <MenuItem value="Risk Aversion">Risk Aversion</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className='selector_container'>
            <p className='subtitle_text'>是否落在淹水潛勢區</p>
            <div className='switch_container'>
              <Grid component="label" container alignItems="center" spacing={1} style={{flex: '1'}}>
                <Grid item style={{fontSize: '2vh'}}>否</Grid>
                <Grid item>
                    <Switch
                        checked={isFloodPotential}
                        onChange={(event) => {
                            changeIsFloodPotential(event.target.checked);
                        }}
                        color='primary'
                    />
                </Grid>
                <Grid item style={{fontSize: '2vh'}}>是</Grid>
              </Grid>
            </div>
          </div>
          <div className='selector_container'>
            <p className='subtitle_text'>暴險金額</p>
            <TextField
              style={{ paddingLeft: '1.1vw' }}
              value={riskAmount}
              variant="outlined"
              onChange={(event) => {
                changeRiskAmount(event.target.value);
              }}
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />
          </div>
          <div style={{ marginTop: '4.6vh', marginLeft: '2vh' }}>
            <Button variant="outlined" className={classes.calculateButton} onClick={() => errorHandling()}>查詢</Button>
          </div>
        </div>
        {/* <div style={{ display: 'inline-flex' }}>
          
        </div> */}
        {isOptionSelected === true ? "" :
          <div style={{ margin: "0.5vh 0 0 1vw", color: "red", fontSize: "2.5vh" }}>
            輸入條件不完整：請完整輸入縣市、鄉鎮市、建物型態、總樓層與風險偏好
          </div>
        }
        {riskInfo[0] === undefined ? "" :
          <div className='result_container' style={{height: '510px'}}>
            <div style={{ display: 'grid', border: 'solid 2px #eaeaea', width: 'fit-content' }}>
              <div style={{ display: 'inline-flex' }}>
                <div className='result_table_block'>評估時間</div>
                <div className='result_table_block'>排放情境</div>
                <div className='result_table_block'>危害</div>
                <div className='result_table_block'>脆弱度</div>
                <div className='result_table_block'>危害*脆弱度</div>
                <div className='result_table_block'>風險</div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ display: 'grid' , flex: 1}}>
                  {riskInfo.map((item, index) => (
                    <div key={`result_period_${index}`} className='result_table_block'>{item.period}</div>
                  ))}
                </div>
                <div style={{ display: 'grid' , flex: 1}}>
                  {riskInfo.map((item, index) => (
                    <div key={`result_experiment_${index}`} className='result_table_block'>{item.experiment}</div>
                  ))}
                </div>
                <div style={{ display: 'grid' , flex: 1}}>
                  {riskInfo.map((item, index) => (
                    <div key={`result_hazard_${index}`} className='result_table_block'>{`${(item.haz * 100).toFixed(2)} %`}</div>
                  ))}
                </div>
                <div style={{ display: 'grid' , flex: 1}}>
                  {riskInfo.map((item, index) => (
                    <div key={`result_vulnerability_${index}`} className='result_table_block'>{`${(item.vul * 100).toFixed(2)} %`}</div>
                  ))}
                </div>
                <div style={{ display: 'grid' , flex: 1}}>
                  {riskInfo.map((item, index) => (
                    <div key={`result_hazard_vulnerability_${index}`} className='result_table_block'>{`${(item.haz_vul * 100).toFixed(2)} %`}</div>
                  ))}
                </div>
                <div style={{ display: 'grid' , flex: 1}}>
                  {riskInfo.map((item, index) => (
                    <div key={`result_risk_amount_${index}`} className='result_table_block' style={{ textAlign: 'right' }}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: '0', minimumFractionDigits: '0' }).format(item.risk_amt)}</div>
                  ))}
                </div>
              </div>
            </div>
            <div style={{ display: 'inline-flex', margin: '2vh auto auto 0' }}>
              <div style={{ marginBottom: '1.5vh', height: '8vh' }}>
                <p className={classes.informText} style={{ marginBottom: '0.5vh' }}>數值說明</p>
                <p className={classes.informText}>1. 危害：該區域未來可能的淹水機率</p>
                <p className={classes.informText}>2. 脆弱度：淹水後擔保品的價值減損比例</p>
                <p className={classes.informText}>3. 風險：擔保品減損的價值 (風險 = 危害 * 脆弱度 * 暴險金額)</p>
              </div>
            </div>
          </div>
        }
      </div>
    </div >
  )
}

export default SingleRealEstateValueImpact_display;