import React, { useEffect, useState } from 'react';
// import DataDownload from '../DataDownload';
import AppConfigN from '../../const/AppConfigN.const';


const columnNameList = {
    'created_time': '新增時間',
    'ownerID': '使用者名稱',
}

function InvestmentITR_export(props) {
    const {
        account,
        resultData
    } = props

    const [ columnWidth, changeColumnWidth ] = useState({})

    // console.log(resultData)

    const DataDownload = (batchID) => {
        fetch(`${AppConfigN.SERVER_URL}/api/itr_batch_download`, {
            method: 'POST',
            headers: {
                "Authorization": `Token ${account.token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "batch_id": batchID
            })
        })
        .then((response) => response.blob())
        .then((blob) => {
            // console.log(blob)
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `投融資ITR_結果_${batchID}.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        })
        .catch((error) => {
            console.error(error);
        });
    }

    useEffect(() => {
        const getColumnWidth = () => {
            let newColumnWidth = {}
            Object.entries(columnNameList).forEach(([key, name]) => {
                const dataLengthList = resultData.map((record_element) => {
                    return(String(record_element[key]).length)
                })
                
                newColumnWidth[key] = `${Math.max(name.length*20, Math.max(...dataLengthList)*12+60)}px`
            })
            changeColumnWidth(newColumnWidth)
        }
        
        getColumnWidth()
    }, [resultData])
    return (
        <div className='blockContent' style={{display: 'flex', border: '1px solid', flexDirection: 'column', height: 'fit-content', width: 'fit-content', overflowX: 'scroll'}}>
            <div className="table_row_container table_title">
                {
                    Object.entries(columnNameList).map(([key, value]) => (
                        <div 
                            key={`column_title_${key}`}
                            className="table_title_container" 
                            style={{width: `${columnWidth[key]}`, display: 'inline-flex'}}
                            >
                            <p 
                                key={`title_column_${key}`} 
                                className="table_title_text" 
                            >{value}</p>
                        </div>
                    ))
                }
                <div
                    className="table_title_container" 
                    style={{width: '80px', display: 'inline-flex'}}
                >
                    <p
                        className="table_title_text"
                        style={{
                            width: '80px'
                        }}
                    >下載</p>
                </div>
            </div>
            {
                resultData.map((value, index) => (
                    <div className="table_row_container" key={`record_${index}`} >
                        {
                            Object.entries(columnNameList).map(([key, column_name]) => (
                                <p 
                                    key={`record_${index}_column_${key}`} 
                                    className="table_text"
                                    style={{
                                        width: `${columnWidth[key]}`
                                    }}>{value[key]}
                                </p>))
                        }
                        <p
                            className="table_text"
                            style={{
                                width: '80px',
                                cursor: 'pointer',
                                textDecoration: 'underline'
                            }}
                            onClick={() => DataDownload(value['batch_id'])}
                        >檔案下載</p>
                    </div>
                ))
            }
        </div>
    );
}

export default InvestmentITR_export;