import React from 'react';
import { VictoryChart, VictoryLine, VictoryAxis } from 'victory';


function TravelCarbonEmissionsRecordItem(props) {
  const {
    goalType,
    goalBudget,
    carbonEmissionLimited,
    carbonCost,
    reductionRatio,
  } = props
  let carbonEmissionLimited_chart = []
  let carbonCost_chart = []
  carbonEmissionLimited
  .forEach((value, index) => {
    carbonEmissionLimited_chart.push({'x':2020+index+1, 'y':value})
  })
  carbonCost
  .forEach((value, index) => {
    carbonCost_chart.push({'x':2020+index+1, 'y':value})
  })

  return (
    <div style={{width:'19.4vw', height:'fit-content'}}>
      <VictoryChart 
        width={300}
        height={190}
        padding={{ top: 5, bottom: 40, left: 50, right: 50 }}
        animate={{
          duration: 1000,
          onLoad: { duration: 500 }
        }}
      >
        <VictoryAxis
          domain={[2020, 2050]}
          //tickFormat={tickX}
          label='年份'
          style={{
            axis: {stroke: "#6a6a6a"},
            // axisLabel: {fontSize: 10 , fill:'#6a6a6a'},
            ticks: {stroke: "#6a6a6a", size: 3},
            tickLabels: {fontSize: 8, padding: 5, fill:'#6a6a6a'},
            axisLabel: {fontSize: 10, padding: 20, fill:'#6a6a6a'},
          }}
        />
        <VictoryAxis
          dependentAxis
          orientation="left"
          //crossAxis
          //domain={[2020, 2050]}
          label='減碳路徑(公斤)'
          style={{
            axis: {stroke: "#c43a31"},
            // axisLabel: {fontSize: 10 , fill:'#6a6a6a'},
            ticks: {stroke: "#c43a31", size: 3},
            tickLabels: {fontSize: 8, padding: 2, fill:'#c43a31'},
            axisLabel: {fontSize: 10, padding: 35, fill:"#c43a31"},
          }}
          standalone={false}
        />
        <VictoryAxis
          dependentAxis
          //offsetX={300}
          orientation="right"
          //crossAxis
          //domain={[2020, 2050]}
          label='轉換成本(元/年)'
          style={{
            axis: {stroke: "blue"},
            // axisLabel: {fontSize: 10 , fill:'#6a6a6a'},
            ticks: {stroke: "blue", size: 3},
            tickLabels: {fontSize: 8, padding: 2, fill:'blue'},
            axisLabel: {fontSize: 10, padding: 35, fill:'blue'},
          }}
          standalone={false}
        />
        <VictoryLine
          style={{
            data: { stroke: "#c43a31" },
            parent: { border: "1px solid #ccc"}
          }}
          data={carbonEmissionLimited_chart}
        />
        <VictoryLine
          style={{
            data: { stroke: "blue" },
            parent: { border: "1px solid #ccc"}
          }}
          data={carbonCost_chart}
        />
      </VictoryChart>
      {
      goalType==="totalBudgetFirst"?<div className="TravelCarbonEmissionsRecordItem_goalType" style={{backgroundColor:"#feac00"}}><p className="TravelCarbonEmissionsRecordItem_p" style={{fontSize:'3vh'}}>總預算先決</p><p className="TravelCarbonEmissionsRecordItem_p">規劃期間制定一筆預算上限</p></div>
      :goalType==="annualBudgetFirst"?<div  className="TravelCarbonEmissionsRecordItem_goalType" style={{backgroundColor:"#cc7400"}}><p className="TravelCarbonEmissionsRecordItem_p" style={{fontSize:'3vh'}}>年度預算先決</p><p className="TravelCarbonEmissionsRecordItem_p">每年度規劃預算金額上限</p></div>
      :<div  className="TravelCarbonEmissionsRecordItem_goalType" style={{backgroundColor:"#45d091"}}><p className="TravelCarbonEmissionsRecordItem_p" style={{fontSize:'3vh'}}>永續先決</p><p className="TravelCarbonEmissionsRecordItem_p">以集團總目標作為貫徹執行</p></div>
      }
      <p 
        className="TravelCarbonEmissionsRecordItem_goalBudget" 
        style={
          goalType==="totalBudgetFirst"?{backgroundColor:"#feac00"}
          :goalType==="annualBudgetFirst"?{backgroundColor:"#cc7400"}
          :{backgroundColor:"#45d091"}
        }
      >{goalBudget==="-"?`總預算： ${Math.round(carbonCost.reduce((a,b)=>a+b)/10000)}萬`:`${Math.round(goalBudget)/10000}萬`}</p>
      {/* <div>
        <svg width={200} height={200}>
          <VictoryPie
            colorScale={
              goalType==="totalBudgetFirst"?["white", "#feac00"]
              :goalType==="annualBudgetFirst"?["white", "cc7400"]
              :["white", "#45d091"]
              
            }
            standalone={false}
            data={[
              { x: "", y: 100 - maxReduction },
              { x: "", y: carbonEmissionLimited[carbonEmissionLimited.length-1] },
            ]}
          />
          <circle cx={320} cy={320} r={160} 
            fill={
              goalType==="totalBudgetFirst"?"#feac00"
              :goalType==="annualBudgetFirst"?"cc7400"
              :"#45d091"
            }/>
        </svg>
      </div> */}
      {
        Object
        .values(reductionRatio)
        .filter((value) => value.isDelete === false)
        .map((value) => <p className="TravelCarbonEmissionsRecordItem_reductionRatio">{`${value.year} 年 減少 ${Math.round(value.percentage)} ％`}</p>)
      }

    </div>
  );
}

export default TravelCarbonEmissionsRecordItem;