import React from 'react';
import Map from './DisplayMap';
import Chart from './DisplayChart';
import Report from './DisplayReport';

function Display(props) {
  return (
    <div className="display_container">
      <div className="display_header_container">
        <div className={props.displayMethod==='Map'?'display_header_item_active':'display_header_item_inactive'} onClick={() => {props.switchDisplayMethod('Map')}}>
          <p className={props.displayMethod==='Map'?'display_header_item_active_text':'display_header_item_inactive_text'} >Map</p>
        </div>
        <div className={props.displayMethod==='Chart'?'display_header_item_active':'display_header_item_inactive'} onClick={() => {props.switchDisplayMethod('Chart')}}>
          <p className={props.displayMethod==='Chart'?'display_header_item_active_text':'display_header_item_inactive_text'} >Chart</p>
        </div>
        <div className={props.displayMethod==='Report'?'display_header_item_active':'display_header_item_inactive'} onClick={() => {props.switchDisplayMethod('Report')}}>
          <p className={props.displayMethod==='Report'?'display_header_item_active_text':'display_header_item_inactive_text'} >Report</p>
        </div>
      </div>
      <div>
        {
          props.displayMethod==='Map'?
            <Map shell={props.shell} data={props.displayData} />:props.displayMethod==='Chart'?
              <Chart shell={props.shell} data={props.displayData} />:
              <Report shell={props.shell} data={props.displayData} />
        }
      </div>
    </div>
  );
}

export default Display;