import React, { useState, useEffect } from 'react';
import "../style/style.css";
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import Menu from './Menu';
import { VictoryBar, VictoryLegend } from 'victory';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import AppConfigN from '../const/AppConfigN.const';

const useStyles = makeStyles((theme) => ({
    blockContent: {
        margin: "1.5vh 1.5vw",
        fontSize: "2.5vh",
    },
    companyBlock: {
        display: 'flex',
        width: '28vw',
        alignItems: 'flex-end',
    },
    companyTitle: {
        // width: '7vw',
        fontWeight: 'bold',
    },
    rowBlock: {
        display: 'inline-flex',
        textAlign: 'center',
        marginBottom: '2vh',
        '&:hover': {
            borderLeft: 'solid 3px #f1cb02',
            backgroundColor: 'rgba(194, 194, 194, 0.09)',
        }
    },
    projectBlock: {
        boxSizing: 'border-box',
        width: '8vw',
        padding: '1vh 1vw',
        backgroundColor: '#eaeaea',
        margin: 'auto 1vw',
    },
    targetBlock: {
        width: '7vw',
        display: 'flex',
        flexDirection: 'column',
        marginRight: '1vw',
    },
    target: {
        // height: '5vh',
        margin: 'auto',
    },
    tableBlock: {
        display: 'flex',
        flexDirection: 'column',
        width: '28vw',
    },
    tableGrid: {
        display: 'flex',
        margin: 'auto 0',
    },
    table: {
        width: '7vw',
        borderBottomStyle: 'solid',
        borderBottomColor: 'black',
        borderBottomWidth: '1px',
    },
    unitBlock: {
        width: '15vw',
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '1vw',
    },
    unit: {
        textAlign: 'left',
        margin: 'auto 1vw',
    },
    chartBlock: {
        width: '30%',
        marginLeft: '-2vw',
    },
    blockTitle: {
        margin: '0',
        fontSize: "2.1vh",
        color: "#797979",
    },
    formControl: {
        textAlign: 'center',
        display: 'inline-flex',
        justifyContent: 'center',
        flexDirection: 'row',
        // width: "13vw",
    },
    select: {
        height: "5vh",
        width: "12vw",
        margin: '1.5vh 0vw 0vh 0vw',
        fontSize: '2.5vh',
    },
    input: {
        height: "4.5vh",
        width: "12vw",
        margin: '1.5vh 0.5vw 0vh 0vw',
        paddingLeft: '0.5vw',
        borderRadius: '5px',
        borderWidth: 'thin',
        borderColor: '#b6b6b6',
        borderStyle: 'solid',
        fontSize: "2.3vh",
    },
    compute: {
        height: "5vh",
        width: "6vw",
        margin: '1.5vh 1vw 0vh 0vw',
        fontSize: '2.5vh',
        backgroundColor: '#ececec',
    },
}))

function Dashboard(props) {
    const classes = useStyles();
    const [dashboardData, setDashboardData] = useState({});
    // const [chartData, setChartData] = useState([]);
    const [project, setProject] = useState("None");
    const [target, setTarget] = useState("None");
    const [targetList, setTargetList] = useState([]);
    const [targetValueType, setTargetValueType] = useState("");
    const [targetValueUnit, setTargetValueUnit] = useState("");
    const [company, setCompany] = useState("None");
    const [companyList, setCompanyList] = useState([]);
    const [companyChoiceList, setCompanyChoiceList] = useState([]);
    const [updateValue, setUpdateValue] = useState();
    const [hintMsg, setHintMsg] = useState("");

    var colorList = ['#FFC000', '#A5A5A5', '#ED7D31', '#4472C4'];

    const {
        account,
        permission,
        cookies,
        removeCookie,
        activeScreen,
        switchScreen,
        switchShellChoice,
        changeDisplayMethod, } = props

    const getDashboardData = () => {
        let res = false;
        // console.log(props.account);
        fetch(`${AppConfigN.SERVER_URL}/api/emis_tgt`, {
            method: 'GET',
            headers: {
                "Authorization": `Token ${props.account.token}`,
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                // console.log(response)
                if (response.ok) {
                    res = true;
                }
                return response.json()
            })
            .then((responseJson) => {
                if (res) {
                    // setHintMsg("");
                    let data = responseJson;
                    // console.log(data);
                    setDashboardData(data);

                    let companyList = [];
                    for (let i = 0; i < data.idx_nm[0].status.length; i++) {
                        // let data = {"name": data.idx_nm[0].status[i].org_nm};
                        companyList.push({ "name": data.idx_nm[0].status[i].org_nm });
                    }
                    setCompanyList(companyList);
                    // console.log(companyList);

                    let companyChoiceList = [];
                    // console.log(props.account);
                    // console.log(dashboardData.idx_nm);
                    if (props.account.utype === "proj_admin") {
                        for (let i = 0; i < data.idx_nm[0].status.length; i++) {
                            companyChoiceList.push(data.idx_nm[0].status[i].org_nm);
                        }
                    }
                    else if (props.account.utype === "org_admin") {
                        companyChoiceList.push(props.account.user.organization);
                    }
                    setCompanyChoiceList(companyChoiceList);
                }
                else {
                    // setHintMsg(responseJson.detail);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const updateFromProjectAdmin = (project, target, company, updateValue) => {
        let res = false;
        if (updateValue < 0) {
            setHintMsg("修改數值不能為負");
        }
        else {
            fetch(`${AppConfigN.SERVER_URL}/api/emis_tgt_upd`, {
                method: 'POST',
                headers: {
                    "Authorization": `Token ${props.account.token}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    year: 2021,
                    idx_nm: project,
                    idx_desc: target,
                    org_nm: company,
                    val: updateValue,
                })
            })
                .then((response) => {
                    // console.log(response)
                    if (response.ok) {
                        res = true;
                    }
                    return response.json()
                })
                .then((responseJson) => {
                    if (res) {
                        let data = responseJson.detail;
                        // console.log(data);
                        setHintMsg(data);
                        getDashboardData();
                        setProject("None");
                        setTarget("None");
                        setCompany("None");
                        setUpdateValue("");
                        setTargetValueType("");
                        setTargetValueUnit("");
                        // var timeoutID = window.setTimeout((() => setHintMsg("")), 3000);
                    }
                    else {
                        setHintMsg(responseJson.detail);
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }

    }

    const changeTargetbyProjectChosen = (project) => {
        let targetList = [];

        for (let i = 0; i < dashboardData.idx_nm.length; i++) {
            if (dashboardData.idx_nm[i].name === project) {
                for (let j = 0; j < dashboardData.idx_nm[i].idx_desc.length; j++) {
                    targetList.push({
                        "name": dashboardData.idx_nm[i].idx_desc[j].name,
                        "type": dashboardData.idx_nm[i].idx_desc[j].val_type,
                        "unit": dashboardData.idx_nm[i].idx_desc[j].unit,
                    })
                }
                break;
            }
        }
        setTargetList(targetList);
        // console.log(targetList);
    }

    // const changeCompanyList = () => {
    //     let companyList = [];
    //     // console.log(props.account);
    //     // console.log(dashboardData.idx_nm);
    //     if (props.account.utype === "proj_admin") {
    //         for (let i = 0; i < dashboardData.idx_nm[0].status.length; i++) {
    //             companyList.push(dashboardData.idx_nm[0].status[i].org_nm);
    //         }
    //     }
    //     else if (props.account.utype === "org_admin") {
    //         companyList.push(props.account.user.organization);
    //     }
    //     setCompanyChoiceList(companyList);
    // }

    const handleFractionDigits = (number) => {
        if (number === null) return "-"
        else if (number < 1) {
            return new Intl.NumberFormat('en-US', { maximumFractionDigits: 4, minimumFractionDigits: 4 }).format(number)
        }
        else if (number < 10) {
            return new Intl.NumberFormat('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(number)
        }
        else if (number < 100) {
            return new Intl.NumberFormat('en-US', { maximumFractionDigits: 1, minimumFractionDigits: 1 }).format(number)
        }
        else return new Intl.NumberFormat('en-US', { maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(number)
    }

    useEffect(() => {
        if (Object.values(dashboardData).length === 0) {
            getDashboardData();
        }
        // else changeCompanyList();
    })

    // const chartData = [
    //     { "org_nm": "中國人壽", "val": 0.41375557732070956 },
    //     { "org_nm": "凱基銀行", "val": 0.09933774834437085 },
    //     { "org_nm": "凱基證券", "val": 0.8500294637595758 },
    //     { "org_nm": "開發資本", "val": 1.364612504897195 }
    // ];

    return (
        <div className="new_home_mainContainer">
            <div className="page_container">
                <div className="page_title_container"><p className="page_title_text">子公司儀表板</p></div>
                <div className="DataDownload_block" style={{ display: "block", width: "100%", marginBottom: "2vh" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ display: "inline-flex" }}>
                            <p className="DataDownload_block_title_text" style={{ width: "75%" }}>各子公司碳淨零策略目標及目前盤點結果</p>
                            <p className="DataDownload_block_title_text" style={{ width: "25%" }}>目標達成率</p>
                        </div>
                        <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
                        <div className={classes.blockContent}>
                            <div style={{ display: 'inline-flex', textAlign: 'center' }}>
                                <div className={classes.projectBlock} style={{ backgroundColor: "#ffffff" }}></div>
                                <div className={classes.targetBlock}></div>
                                <div className={classes.companyBlock}>
                                    {Object.entries(companyList).map((company) =>
                                        <div key={"company_" + company[0]} className={classes.companyTitle} style={{ width: `${28 / companyList.length}vw` }}>{company[1].name}</div>
                                    )}
                                    {/* <div className={classes.companyTitle}>中國人壽</div>
                                    <div className={classes.companyTitle}>凱基銀行</div>
                                    <div className={classes.companyTitle}>凱基證券</div>
                                    <div className={classes.companyTitle}>開發資本</div> */}
                                </div>
                                <div className={classes.unitBlock}></div>
                                <div className={classes.chartBlock}>
                                    <VictoryLegend x={35}
                                        orientation="horizontal"
                                        height={70}
                                        gutter={160}
                                        rowGutter={-10}
                                        itemsPerRow={2}
                                        colorScale={colorList}
                                        // colorScale={["blue", "gray", "orange", "yellow"]}
                                        style={{ labels: { fontSize: 23 }, data: { type: "square" } }}
                                        data={companyList}
                                    // data={[
                                    //     { name: "中國人壽" }, { name: "凱基銀行" }, { name: "凱基證券" }, { name: "開發資本" }
                                    // ]}
                                    />
                                </div>
                            </div>
                            {
                                Object.values(dashboardData).length === 0 ? "" :
                                    Object.entries(dashboardData.idx_nm).map((project) =>
                                        <div className={classes.rowBlock} key={"wrap_" + project[0]}>
                                            <div key={"project_" + project[0]} className={classes.projectBlock}>{project[1].name}</div>
                                            <div className={classes.targetBlock}>
                                                {
                                                    Object.entries(project[1].idx_desc).map((target) =>
                                                        <div key={"project_" + project[0] + "_target_" + target[0]} className={classes.target}>{target[1].name}</div>
                                                    )
                                                }
                                            </div>
                                            <div className={classes.tableBlock}>
                                                {
                                                    Object.entries(project[1].idx_desc).map((target) =>
                                                        <div key={"project_" + project[0] + "_target_" + target[0] + "_table_" + target[0]} className={classes.tableGrid}>
                                                            {target[1].val_type === "PCT" ?
                                                                Object.entries(target[1].data).map((data) =>
                                                                    <div key={"project_" + project[0] + "_target_" + target[0] + "_table_" + target[0] + "_data_" + data[0]} className={classes.table} style={{ width: `${28 / companyList.length}vw` }}>{data[1].val}%</div>
                                                                )
                                                                : Object.entries(target[1].data).map((data) =>
                                                                    <div key={"project_" + project[0] + "_target_" + target[0] + "_table_" + target[0] + "_data_" + data[0]} className={classes.table} style={{ width: `${28 / companyList.length}vw` }}>{handleFractionDigits(data[1].val)}</div>
                                                                )
                                                            }
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            <div className={classes.unitBlock}>
                                                {
                                                    Object.entries(project[1].idx_desc).map((target) =>
                                                        <div key={"project_" + project[0] + "_target_" + target[0] + "_unit"} className={classes.unit}>單位：{target[1].unit}</div>
                                                    )
                                                }
                                            </div>
                                            <div className={classes.chartBlock}>
                                                <VictoryBar horizontal
                                                    style={{
                                                        data: {
                                                            fill: ({ datum }) => {
                                                                for (let i = 0; i < companyList.length; i++) {
                                                                    if (datum.org_nm === companyList[i].name) return colorList[i];
                                                                }
                                                            }
                                                        },
                                                        labels: {
                                                            fontSize: 23,
                                                        }
                                                    }}
                                                    maxDomain={{ y: 1.5 }}
                                                    // barWidth={20}
                                                    barRatio={1.8}
                                                    data={project[1].status}
                                                    // data={chartData}
                                                    x="org_nm"
                                                    y="val"
                                                    height={170}
                                                    labels={({ datum }) => `${handleFractionDigits(datum.val * 100)}%`}
                                                />
                                            </div>
                                        </div>
                                    )
                            }
                        </div>
                    </div>
                </div>
                <div className="DataDownload_block" style={{ display: "inline-block", width: "100%", paddingBottom: "2vh", marginBottom: "2vh" }}>
                    <p className="DataDownload_block_title_text">資料修改</p>
                    <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
                    {Object.values(dashboardData).length === 0 ? "" :
                        <div className={classes.blockContent}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ margin: '0vh 0.5vw 2.5vh 0.5vw' }}>
                                    <p className={classes.blockTitle}>策略項目</p>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <Select
                                            className={classes.select}
                                            value={project}
                                            onChange={(event) => {
                                                setProject(event.target.value);
                                                changeTargetbyProjectChosen(event.target.value);
                                                setTarget("None");
                                                setTargetValueType("");
                                                setTargetValueUnit("");
                                            }}
                                        >
                                            <MenuItem value="None"><em>請選擇策略</em></MenuItem>
                                            {
                                                Object
                                                    .entries(dashboardData.idx_nm)
                                                    .map(([index, project]) =>
                                                        <MenuItem key={`projectChoice_${index}`} value={project.name} >
                                                            {project.name}
                                                        </MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                                <div style={{ margin: '0vh 0.5vw 2.5vh 0.5vw' }}>
                                    <p className={classes.blockTitle}>目標項目</p>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <Select
                                            className={classes.select}
                                            value={target}
                                            onChange={(event) => {
                                                // console.log(event.target.value);
                                                // console.log(event.currentTarget);
                                                // console.log(event.currentTarget.dataset);
                                                // console.log(event.currentTarget.dataset.type);
                                                setTarget(event.target.value);
                                                setTargetValueType(event.currentTarget.dataset.type);
                                                setTargetValueUnit(event.currentTarget.dataset.unit);
                                            }}
                                        >
                                            <MenuItem value="None"><em>請選擇項目</em></MenuItem>
                                            {
                                                Object
                                                    .entries(targetList)
                                                    .map(([index, value]) =>
                                                        <MenuItem key={`targetChoice_${index}`} value={value.name} data-type={value.type} data-unit={value.unit}>
                                                            {value.name}
                                                        </MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                                <div style={{ margin: '0vh 0.5vw 2.5vh 0.5vw' }}>
                                    <p className={classes.blockTitle}>子公司</p>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <Select
                                            className={classes.select}
                                            value={company}
                                            onChange={(event) => {
                                                setCompany(event.target.value);
                                            }}
                                        >
                                            <MenuItem value="None"><em>請選擇子公司</em></MenuItem>
                                            {
                                                Object
                                                    .entries(companyChoiceList)
                                                    .map(([index, value]) =>
                                                        <MenuItem key={`companyChoice_${index}`} value={value} >
                                                            {value}
                                                        </MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                                <div style={{ margin: '0vh 0.5vw 2.5vh 0.5vw' }}>
                                    <p className={classes.blockTitle}>修改數值</p>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <input
                                            className={classes.input}
                                            type="text"
                                            placeholder="請輸入欲修改數值"
                                            value={updateValue}
                                            onChange={(event) => {
                                                setUpdateValue(event.target.value);
                                            }}
                                        />
                                        <div style={{ margin: '2vh 0.5vw 0vh 0vw', }}>
                                            {targetValueType === "PCT" ? "%" : ""}
                                        </div>

                                        <div style={{ margin: '2vh 0.5vw 0vh 0.5vw', }}>
                                            {targetValueUnit === "" ? "" : `(單位：${targetValueUnit})`}
                                        </div>

                                    </FormControl>
                                </div>
                                <div style={{ margin: '2.7vh 0vw 2.5vh 0.5vw' }}>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <Button
                                            variant="outlined"
                                            className={classes.compute}
                                            onClick={() => updateFromProjectAdmin(project, target, company, updateValue)}>
                                            送出
                                        </Button>
                                    </FormControl>
                                </div>
                            </div>
                            <div style={{ marginLeft: '0.5vw', marginTop: '-2vh', color: 'red', fontSize: '2.3vh' }}>
                                {hintMsg.length === 0 ? "" : `*${hintMsg}`}
                            </div>
                        </div>
                    }
                </div>
            </div>
            <Menu
                account={account}
                permission={permission}
                cookies={cookies}
                removeCookie={removeCookie}
                activeScreen={activeScreen}
                switchScreen={switchScreen}
                switchShellChoice={switchShellChoice}
                changeDisplayMethod={changeDisplayMethod}
            />
        </div >
    );
}

export default Dashboard;