import React, { useState, useEffect } from 'react';
import "../style/style.css";
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
// import GaugeChart from 'react-gauge-chart'
import Menu from './Menu';
import AppConfigN from '../const/AppConfigN.const';

let today = new Date()
let dd = String(today.getDate()).padStart(2, '0');
let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
let yyyy = today.getFullYear();

today = yyyy + mm + dd;

const num_to_string_decimal = (number, digit) => {
    const handleFractionDigits = (number) => {
        if (number === null) return "-"
        else {return new Intl.NumberFormat('en-US', { maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(number)}
    }

    let str_int = String(handleFractionDigits(parseInt(number)))
    let str_decimal = (String(Math.round((number-parseInt(number))*Math.pow(10, digit))/Math.pow(10, digit))+'0'.repeat(digit)).substring(2, 2+digit)
    return str_int+'.'+str_decimal
}

const ITR_result_sample_data = {
    '股權投資': 3.2,
    '專案融資': 3.2,
    '專案投資': 3.2,
    '債券投資': 3.2,
    '授信': 3.2,
    '其他': 3.2,
}

const useStyles = makeStyles((theme) => ({
    blockTitle: {
        margin: "0 0 0 0vw",
        fontFamily: "PingFangTC",
        fontSize: "2.1vh",
        fontWeight: "500",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        color: "#797979",
    },
    blockContent: {
        margin: "0vh 1vw 0vh 1vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        textAlign: "center",
    },
    formControl: {
        width: "100%",
        maxWidth: "16vw",
    },
    link: {
        // fontSize: '1vh',
        textAlign: 'left',
        // margin: '1vh 0 0 1vw',
        textDecoration: "underline",
        width: 'fit-content',
        '&:hover': {
            cursor: "pointer",
        }
    },
    inputFile: {
        // margin: '0 0 2vh 0vw',
        width: "100%",
    },
    select: {
        height: "5vh",
        // width: "12vw",
        margin: '1.5vh 0vw 0vh 0vw',
        fontSize: '2vh',
    },
    sendInfoButton: {
        height: "4vh",
        fontSize: "2.1vh",
        padding: "5px 10px",
        margin: "0.5vh 1vw",
        // width: "95%",
        backgroundColor: '#ececec',
    }
}))

function InvestmentITR(props) {
    const classes = useStyles();

    const [SICData, setSICData] = useState({});
    // const [file, setFile] = useState();
    // const [typeList, setTypeList] = useState([]);
    // const [ownerList, setOwnerList] = useState([]);
    const [isClick, setIsClick] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [batchID, setBatchID] = useState("");
    const [batchDataList, setBatchDataList] = useState({});
    const [sortType, setSortType] = useState("ID");

    const {
        account,
        permission,
        cookies,
        removeCookie,
        activeScreen,
        switchScreen,
        switchShellChoice,
        changeDisplayMethod,
    } = props

    const changeDataSorting = (sortType, newData) => {
        let data
        if(newData === ''){
            data = SICData;
        }else{
            data = newData
        }

        switch (sortType) {
            case 'ID':
                return (
                    data.list.sort((a, b) => {
                        return a.industryId - b.industryId
                    })
                )
            case 'CarbonAmtSmall':
                return (
                    data.list.sort((a, b) => {
                        return a.industryCarbonAmt - b.industryCarbonAmt
                    })
                )
            case 'CarbonAmtBig':
                return (
                    data.list.sort((a, b) => {
                        return b.industryCarbonAmt - a.industryCarbonAmt
                    })
                )
            case 'CarbonFootSmall':
                return (
                    data.list.sort((a, b) => {
                        return a.industryCarbonFootPrint - b.industryCarbonFootPrint
                    })
                )
            case 'CarbonFootBig':
                return (
                    data.list.sort((a, b) => {
                        return b.industryCarbonFootPrint - a.industryCarbonFootPrint
                    })
                )
        }
    }
    
    const getBatchDataList = () => {
        let res = false;
        fetch(`${AppConfigN.SERVER_URL}/api/pcaf_batch`, {
            method: 'GET',
            headers: {
                "Authorization": `Token ${account.token}`,
                "Content-Type": "application/json"
            },
        })
            .then((response) => {
                // console.log(response)
                if (response.ok) {
                    res = true;
                }
                return response.json()
            })
            .then((responseJson) => {
                if (res) {
                    setErrorMsg("");
                    let option = responseJson.batch_list;
                    // console.log(option);
                    setBatchDataList(option);
                }
                else {
                    setErrorMsg(responseJson.detail);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const getBatchSicSta = (batchID) => {
        let id = "";
        let res = false;
        // console.log(batchID)
        if (batchID === "New") {
            id = ""
        }
        else {
            id = batchID;
        }
        fetch(`${AppConfigN.SERVER_URL}/api/pcaf_batch_sic`, {
            method: 'POST',
            headers: {
                "Authorization": `Token ${account.token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                batch_id: id
            })
        })
            .then((response) => {
                // console.log(response)
                if (response.ok) {
                    res = true;
                }
                return response.json()
            })
            .then((responseJson) => {
                // console.log(responseJson);
                if (res) {
                    setErrorMsg("");
                    let data = responseJson;
                    // console.log(data)
                    setSICData(data);
                    changeDataSorting('ID', responseJson);
                    getBatchDataList();
                    setIsClick(false);
                }
                else {
                    setErrorMsg(responseJson.detail);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const reportDownload = (batch_id) => {
        fetch(`${AppConfigN.SERVER_URL}/api/pcaf_sic_batch_download`, {
            method: 'POST',
            headers: {
                "Authorization": `Token ${props.account.token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                batch_id: batch_id,
            })
        })
        .then((response) => response.blob())
        .then((blob) => {
            // console.log(blob)
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `重點排碳產業輸出_${today}.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        })
        .catch((error) => {
            console.error(error);
        });
    }

    useEffect(() => {
        getBatchDataList();
    },[])
    
    return ( 
        <div className="new_home_mainContainer">
            <div className="page_container">
                <div className="page_title_container"><p className="page_title_text">重點排碳產業標示</p></div>
                <div style={{ display: "inline", width: 'calc(100% + 10px)' }}>
                    <div className="DataDownload_block" style={{ flexDirection: "column", width: '25%', minWidth: '300px', margin: '0 10px 10px 0' }}>
                        <p className="DataDownload_block_title_text">投融資標的組合基本資料</p>
                        <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
                        <div className={classes.blockContent} style={{ display: 'flex', margin: "1vh 1vw 0 1vw" }}>
                            <div style={{ width: "95%", margin: "0 0 0 0%" }}><p className={classes.blockTitle}>查看過去資料</p></div>
                        </div>
                        <div className={classes.blockContent}>
                            <div style={{ width: "95%", margin: "0 0 0 0%" }}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <Select
                                        className={classes.select}
                                        value={batchID}
                                        onChange={(event) => {
                                            setBatchID(event.target.value);
                                        }}
                                    >
                                        <MenuItem value="New">最新批次資料</MenuItem>
                                        {
                                            Object
                                                .entries(batchDataList)
                                                .map(([id, batchData]) => <MenuItem key={`batchIDList_${id}`} value={batchData.batch_id}>{batchData.created_time}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className={classes.blockContent} style={{ display: 'flex', margin: "1vh 1vw 1vh 1vw", textAlign: "center" }}>
                            <div style={{ width: "95%", margin: "0 0 0 0%" }}><Button variant="outlined" className={classes.sendInfoButton} onClick={() => {getBatchSicSta(batchID)}}>查看</Button></div>
                        </div>
                    </div>
                </div>
                {SICData.list === undefined ? "" :
                    <div className="display_container">
                        <div className="display_header_container">
                            <div className='display_header_item_active'>
                                <p className='display_header_item_active_text'>Report</p>
                            </div>
                        </div>
                        <div className="display_body_container">
                            <div style={{ backgroundColor: '#fafafa', borderRadius: '1.28vw', padding: '2.1vh 1.6vw 4vh', overflowX: 'scroll' }}>
                                <div style={{ display: 'flex', flexDirection: "column", margin: '2vh 2vw' }}>
                                    <p style={{fontSize: '20px', margin: '5px', display: 'inline-flex'}}>資料排序方式：</p>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <Select
                                            className={classes.select}
                                            value={sortType}
                                            onChange={(event) => {
                                                setSortType(event.target.value);
                                                changeDataSorting(event.target.value, '');
                                            }}
                                        >
                                            <MenuItem value="ID">編號順序</MenuItem>
                                            <MenuItem value="CarbonAmtBig">投融資碳排放量(由大到小)</MenuItem>
                                            <MenuItem value="CarbonAmtSmall">投融資碳排放量(由小到大)</MenuItem>
                                            <MenuItem value="CarbonFootBig">投融資碳足跡(由大到小)</MenuItem>
                                            <MenuItem value="CarbonFootSmall">投融資碳足跡(由小到大)</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div style={{width: 'fit-content', margin: '2vh 2vw'}}>
                                    <div style={{ flexDirection: "column", width: "100%" }}>
                                        <div style={{ display: 'grid', width: 'fit-content', height: 'fit-content', border: 'solid 2px #eaeaea' }}>
                                            <div style={{ display: 'inline-flex', textAlign: 'center' }}>
                                                <div className='single_display_report_block' style={{ width: '8vw', minWidth: '105px' }}>類別編號</div>
                                                <div className='single_display_report_block' style={{ width: '26vw', minWidth: '450px' }}>主計處產業類別名稱</div>
                                                <div className='single_display_report_block' style={{ width: '13vw', minWidth: '160px' }}>投融資碳排放量<br/> (公噸)</div>
                                                <div className='single_display_report_block' style={{ width: '13vw', minWidth: '160px' }}>投融資碳足跡<br/> (公噸/千元)</div>
                                            </div>
                                            <div style={{ display: 'inline-flex' }}>
                                                <div style={{ display: 'grid', textAlign: 'center' }}>
                                                    {Object
                                                        .entries(SICData.list)
                                                        .map(([id, data]) => <div key={id} className='single_display_report_block' style={{ width: '8vw', minWidth: '105px' }}>{data.industryId || ''}</div>)
                                                    }
                                                </div>
                                                <div style={{ display: 'grid' }}>
                                                    {Object
                                                        .entries(SICData.list)
                                                        .map(([id, data]) => <div key={id} className='single_display_report_block' style={{ width: '26vw', minWidth: '450px' }}>{data.industryNm || ''}</div>)
                                                    }
                                                </div>
                                                <div style={{ display: 'grid', textAlign: 'right' }}>
                                                    {Object
                                                        .entries(SICData.list)
                                                        .map(([id, data]) => <div key={id} className='single_display_report_block' style={{ width: '13vw', minWidth: '160px' }}>{num_to_string_decimal(data.industryCarbonAmt, 2) || ''}</div>)
                                                    }
                                                </div>
                                                <div style={{ display: 'grid', textAlign: 'right' }}>
                                                    {Object
                                                        .entries(SICData.list)
                                                        .map(([id, data]) => <div key={id} className='single_display_report_block' style={{ width: '13vw', minWidth: '160px' }}>{num_to_string_decimal(data.industryCarbonFootPrint, 4) || ''}</div>)
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{margin: 'auto 0', textAlign: 'right', minWidth: '120px', flex: 1}}>
                                <Button variant="outlined" className={classes.sendInfoButton} style={{margin: '20px'}} onClick={() => {reportDownload(batchID)}}>輸出檔下載</Button>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <Menu
                account={account}
                permission={permission}
                cookies={cookies}
                removeCookie={removeCookie}
                activeScreen={activeScreen}
                switchScreen={switchScreen}
                switchShellChoice={switchShellChoice}
                changeDisplayMethod={changeDisplayMethod}
            />
        </div>
    );
}

export default InvestmentITR;