import React, { useState, useEffect } from 'react';
import "../style/style.css";
import { makeStyles } from '@material-ui/core/styles';
import Menu from './Menu';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
    blockContent: {
        margin: "1vh 1vw",
        color: "#797979",
        fontFamily: "PingFangTC",
    },
    blockContentTitle: {
        margin: "2vh 1.9vw 2vh 1vw",
        fontFamily: "PingFangTC",
        fontSize: "2.3vh",
        fontWeight: "600",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        color: "#797979"
    },
    formControl: {
        textAlign: 'center',
        display: 'inline-flex',
        justifyContent: 'center',
        // width: "13vw",
    },
    select: {
        height: "5vh",
        width: "15vw",
        minWidth: '150px',
        margin: '1.5vh 0vw 0vh 0vw',
        fontSize: '2.3vh',
    },
}))

function SICCarbonCoef(props) {
    const classes = useStyles();

    const [sortType, setSortType] = useState("ID");

    const {
        account,
        permission,
        cookies,
        removeCookie,
        activeScreen,
        switchScreen,
        switchShellChoice,
        changeDisplayMethod,
        getSIC_CarbonCoef,
        sic_carbonCoef_data,
        setSIC_carbonCoef } = props

    const changeDataSorting = (sortType) => {
        let data = sic_carbonCoef_data;
        // console.log(data)
        switch (sortType) {
            case 'ID':
                return (
                    data.list.sort((a, b) => {
                        return a.industryId - b.industryId
                    })
                )
            case 'CoefSmall':
                return (
                    data.list.sort((a, b) => {
                        return a.industryCarbonCoef - b.industryCarbonCoef
                    })
                )
            case 'CoefBig':
                return (
                    data.list.sort((a, b) => {
                        return b.industryCarbonCoef - a.industryCarbonCoef
                    })
                )
        }
    }

    useEffect(() => {
        if (Object.values(sic_carbonCoef_data).length === 0) {
            getSIC_CarbonCoef();
        }
    })

    return (
        <div className="new_home_mainContainer">
            <div className="page_container">
                <div className="page_title_container"><p className="page_title_text">產業碳排係數查詢</p></div>
                <div className="DataDownload_block" style={{ display: "inline-block", width: "100%" }}>
                    <p className={classes.blockContentTitle}>係數產製方式</p>
                    <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
                    <div className={classes.blockContent}>
                        <p style={{ fontSize: "2vh" }}>
                            產業係數計算依據為<b>台灣所有已揭露碳排放公司的排放量數據</b>，透過碳排放量及年營收，計算出碳排放強度後，
                            再將同產業的碳強度做平均，碳強度計算方式為：公司碳排放/公司年營收。<br />
                            以下為針對原始資料數量所做的計算處理方式：
                            <ol style={{ paddingLeft: "2vw" }}>
                                <li>公司數大於5家（含5家）：扣除極大值和極小值的碳強度，再將其他公司碳強度進行平均。</li>
                                <li>公司數少於5家（2-4家）：直接將所有已揭露碳排公司的碳強度進行平均，作為產業碳排係數結果。</li>
                                <li>公司數只有1筆：直接使用此家公司的碳排強度。</li>
                                <li>若產業內無原始揭露資料：直接使用國際統計資料（資料來源：英國國家統計局 Office for National Statistics, environment accounts ）。</li>
                            </ol>
                        </p>
                        <p style={{ fontSize: "2vh" }}>資料更新時間：{sic_carbonCoef_data.list === undefined ? "" : sic_carbonCoef_data.updated_at.slice(0, 10)}</p>
                    </div>
                </div>

                <div className="display_container">
                    <div className="display_header_container">
                        <div className='display_header_item_active'>
                            <p className='display_header_item_active_text'>Report</p>
                        </div>
                    </div>
                    <div className="display_body_container">
                        <div style={{ backgroundColor: '#fafafa', borderRadius: '1.28vw', width: '68.6vw', padding: '2.1vh 1.6vw 4vh', overflowX: 'scroll' }}>
                            {sic_carbonCoef_data.list === undefined ? "" :
                                <div style={{width: 'fit-content', margin: 'auto'}}>
                                    <div style={{ display: 'flex', flexDirection: "column", margin: '2vh 2vw' }}>
                                        <p style={{fontSize: '20px', margin: '5px', display: 'inline-flex'}}>資料排序方式：</p>
                                        <FormControl variant="outlined" className={classes.formControl}>
                                            <Select
                                                className={classes.select}
                                                value={sortType}
                                                onChange={(event) => {
                                                    setSortType(event.target.value);
                                                    changeDataSorting(event.target.value);
                                                }}
                                            >
                                                <MenuItem value="ID">編號順序</MenuItem>
                                                <MenuItem value="CoefBig">碳排係數(由大到小)</MenuItem>
                                                <MenuItem value="CoefSmall">碳排係數(由小到大)</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div style={{ flexDirection: "column", width: "100%" }}>
                                        <div style={{ display: 'grid', width: 'fit-content', height: 'fit-content', border: 'solid 2px #eaeaea', margin: '2vh 0 1vh 2vw' }}>
                                            <div style={{ display: 'inline-flex' }}>
                                                <div className='single_display_report_block' style={{ width: '8vw', minWidth: '105px' }}>類別編號</div>
                                                <div className='single_display_report_block' style={{ width: '30vw', minWidth: '450px' }}>主計處產業類別名稱</div>
                                                <div className='single_display_report_block' style={{ width: '11vw', minWidth: '145px' }}>產業碳排係數<br/> (公噸/千元)</div>
                                            </div>
                                            <div style={{ display: 'inline-flex' }}>
                                                <div style={{ display: 'grid', textAlign: 'center' }}>
                                                    {Object
                                                        .entries(sic_carbonCoef_data.list)
                                                        .map(([id, data]) => <div key={id} className='single_display_report_block' style={{ width: '8vw', minWidth: '105px' }}>{data.industryId === null ? "-" : data.industryId}</div>)
                                                    }
                                                </div>
                                                <div style={{ display: 'grid' }}>
                                                    {Object
                                                        .entries(sic_carbonCoef_data.list)
                                                        .map(([id, data]) => <div key={id} className='single_display_report_block' style={{ width: '30vw', minWidth: '450px' }}>{data.industryNm === null ? "-" : data.industryNm}</div>)
                                                    }
                                                </div>
                                                <div style={{ display: 'grid', textAlign: 'center' }}>
                                                    {Object
                                                        .entries(sic_carbonCoef_data.list)
                                                        .map(([id, data]) => <div key={id} className='single_display_report_block' style={{ width: '11vw', minWidth: '145px' }}>{data.industryCarbonCoef === null ? "-" : data.industryCarbonCoef}</div>)
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>

            </div>
            <Menu
                account={account}
                permission={permission}
                cookies={cookies}
                removeCookie={removeCookie}
                activeScreen={activeScreen}
                switchScreen={switchScreen}
                switchShellChoice={switchShellChoice}
                changeDisplayMethod={changeDisplayMethod}
            />
        </div>
    );
}

export default SICCarbonCoef;