import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    maxWidth: '39vw',
    textAlign: 'center',
    marginTop: '1.3vh',
    // borderRadius: "6px",
    // border: "solid 1px #939393",
  },
  select: {
    height: "3.5vh",
    fontSize: "1.6vh",
  }
}));

function ShellStepItem(props) {
  const classes = useStyles();

  if (props.processData[props.index] !== undefined){
    return(
      <div>
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            className={classes.select}
            // labelId="demo-simple-select-outlined-label"
            // id="demo-simple-select-outlined"
            value={props.processData_value}
            onChange={(event) => {console.log(props.index, event.target.value);props.changeDisplayDataState('notReady');props.changeProcessData_value(props.index, event.target.value);props.getProcessData_list(props.index, event.target.value)}}
            // label={props.processData[props.index]}
          >
            <MenuItem value="None"><em>{props.name}</em></MenuItem>
            {
              Object
              .entries(props.processData_list)
              .map(([index, value]) => <MenuItem key={`process_${props.index}_Datalist_${index}`} value={value}>{value}</MenuItem>)
            }
          </Select>
        </FormControl>
      </div>
    )
  } else {
    return(
      <div>
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            disabled={true}
            className={classes.select}
            // labelId="demo-simple-select-outlined-label"
            // id="demo-simple-select-outlined"
            value={props.processData_value}
            onChange={(event) => {props.changeProcessData_value(props.index, event.target.value);props.getProcessData_list(props.index, event.target.value)}}
            // label={props.processData[props.index]}
          >
            <MenuItem value="None"><em>{props.name}</em></MenuItem>
          </Select>
        </FormControl>
      </div>


      // <div key={`process_${props.index}`}>
      //   <div className="shellSteps_process_div">{props.name}</div>
      // </div>
    )
  }
  
}

export default ShellStepItem;