import React, { useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';

const customStyles = {
    control: base => ({
        ...base,
        border: '1px solid rgba(0, 0, 0, 0.42)',
        "&:hover": {
            border: '1px solid black'
        },
        fontSize: '18px',
    }),
    option: (base, {isSelected}) => ({
        ...base,
        color: 'black',
        backgroundColor: isSelected ? 'rgba(0, 0, 0, 0.08)' : 'white',
        "&:hover": {
            backgroundColor: 'rgba(0, 0, 0, 0.08)'
        }
    })
}

// const customStyles = {
//     control: (provided, state) => ({
//         ...provided,
//         border: '1px solid rgba(0, 0, 0, 0.42)',
//     }),
// }

// const customStyles = {
//     control: (provided, state) => {
//         const border = state.isHovered ? '2px solid rgba(0, 0, 0, 0.87)' : '1px solid rgba(0, 0, 0, 0.42)';

//         return { ...provided, border};
//     }
// }

function SelectInput(props) {

    const {
        value,
        setValue,
        placeholdertxt,
        disabled,
        defaultOptions,
        creatable,
        isMulti,
    } = props
    
    const [inputValue, setInputValue] = useState('');
    // const [selectedValues, setselectedValues] = useState([]);

    // const [current, setCurrent] = useState([]);

    // const [selection, setSelection] = useState(defaultOptions);

    const handleOnInputChange = (value) => {
        setInputValue(value);
    };
    const handleOnChange = (value) => {
        setValue(value)
    };

    // const setNewValues = (event) => {
    //     const newOption = { label: inputValue, inputValue };
    //     inputValue !== '' && setSelection([...selection, newOption]);

    //     setCurrent(current);
    //     let difference = current.filter((x) => !value.includes(x));

    //     setInputValue('');

    //     setselectedValues(difference);
    // };
    if(creatable){
        return ( 
            <CreatableSelect
                isMulti={isMulti}
                className='creatable_select'
                styles={customStyles}
                isDisabled={disabled}
                options={defaultOptions}
                onChange={handleOnChange}
                // value={selectedValues.selected}
                value={value}
                onInputChange={handleOnInputChange}
                inputValue={inputValue}
                // onKeyDown={handleKeyDown}
                controlShouldRenderValue={true}
                placeholder={placeholdertxt}
            />
        );
    }else{
        return ( 
            <Select
                // isMulti={isMulti}
                className='creatable_select'
                styles={customStyles}
                isDisabled={disabled}
                options={defaultOptions}
                onChange={handleOnChange}
                placeholder={placeholdertxt}

            />
        );
    }
    
}

export default SelectInput;