import React, { useEffect, useState, useCallback } from 'react';
import CreatableTableInputRow from './CreatableTableInputRow';
import Checkbox from '@material-ui/core/Checkbox';


function CreatableTable(props) {
    const {
        columnList,
        columnDisplay,
        record,
        title,
        inputRowStatus,
        changeInputRowStatus,
        onNewDataUpload,
        utype,
        inputValue,
        changeInputValue,
        onDataStatusChange,
        component, // functional: checkbox, bottom: page menu and buttons, stats: statistics row
        statsRow,
        alertMessage,
        changeAlertMessage,
    } = props

    const [ columnWidth, changeColumnWidth ] = useState({})
    const [ displayPage, changeDisplayPage ] = useState(0)
    const [ checkboxValue, changeCheckboxValue ] = useState({})
    const [ checkboxDisable, changeCheckboxDisable ] = useState({})
    const [ selectList, changeSelectList ] = useState({})
    const [ displayRecord, changeDisplayRecord] = useState([]) // [key, value] of record
    const [ recordSortingItem, changeRecordSortingItem ] = useState([]) // [dataItem, (true or false)]

    const inputSetup = useCallback(() => {
        let inputDefault = {}
        let selectDefault = {}
        columnList.forEach((element, index) => {
            if(!element['userInput']){
                inputDefault[element['dataItem']] = '-'
            }else{
                inputDefault[element['dataItem']] = ''
            }
            if(element['selectList'] === null){
                selectDefault[element['dataItem']] = '-'
            }else{
                // 預設放所有選項
                let all_elements = []
                element['selectList'].forEach((subList) => {
                    subList.forEach((element) => {
                        all_elements.push(element)
                    })
                })
                selectDefault[element['dataItem']] = all_elements
            }
        });
        changeInputValue(inputDefault)
        changeSelectList(selectDefault)
    }, [columnList])

    const checkDependency = (newInputValue) => {
        // check all columns
        columnList.filter((item) => (item['dependency'] !== null)).forEach((item) => {
            // check all items if dependency exist
            item['dependency'].forEach((dependency, index) => {
                let check = false
                Object.entries(dependency).forEach(([key, value]) => {
                    if(newInputValue[key] === value){
                        check = true
                    }else{
                        check = false
                    }
                })
                
                if(check){
                    let newSelectList = {
                        ...selectList,
                        [item['dataItem']]: item['selectList'][index],
                    }
                    changeSelectList(newSelectList)
                }
            })
        })
    }

    const onCheckboxChange = (key, value) => {
        changeCheckboxValue({
            ...checkboxValue,
            [key]: value,
        })
    }

    const onRecordSort = (dataItem, dataType, bool) => {
        let ascending = -1
        if(bool){
            ascending = 1
        }

        let recordList = displayRecord.map(([key, value]) => { return([key, value]) })
        if(dataType === 'int' || dataType === 'float'){
            recordList.sort((a, b) => {
                return (b[1][dataItem] - a[1][dataItem])*ascending
            })
        }else{
            // console.log(recordList)
            recordList.sort((a, b) => {
                if(a[1][dataItem] < b[1][dataItem]){
                    return 1*ascending
                }else if(a[1][dataItem] > b[1][dataItem]){
                    return -1*ascending
                }else if (a[1][dataItem] === null){
                    if(b[1][dataItem] === null){
                        return 0 // both a and b are null
                    }else{
                        return -1*ascending // only a is null
                    }
                }else{
                    return 1*ascending // only b is null
                }
            })
        }
        changeDisplayRecord(recordList)
        changeRecordSortingItem([dataItem, bool])
    }

    useEffect(() => {
        const displayRecordSetup = () => {
            const recordList = Object.entries(record).map(([key, value]) => {return([key, value]) })
            changeDisplayRecord(recordList)
        }

        const checkboxSetup = () => {
            let checkboxDefault = {}
            let checkboxDisableDefault = {}
            Object.entries(record).forEach(([key, value], index) => {
                if(utype === 'user'){
                    if(value['status'] === 'S'){
                        checkboxDefault[key] = false
                        checkboxDisableDefault[key] = false
                    }else{
                        checkboxDefault[key] = false
                        checkboxDisableDefault[key] = true
                    }
                }else{
                    if(value['status'] !== 'R'){
                        checkboxDefault[key] = false
                        checkboxDisableDefault[key] = false
                    }else{
                        checkboxDefault[key] = false
                        checkboxDisableDefault[key] = true
                    }
                }
                // if(value['status'] !== 'R'){
                //     checkboxDefault[key] = false
                //     checkboxDisableDefault[key] = false
                // }else{
                //     checkboxDefault[key] = false
                //     checkboxDisableDefault[key] = true
                // }
            });
            changeCheckboxValue(checkboxDefault)
            changeCheckboxDisable(checkboxDisableDefault)
        }

        const getColumnWidth = () => {
            let newColumnWidth = {}
            columnList.forEach((element) => {
                // console.log(element)
                const dataLengthList = Object.values(record).map((record_element) => {
                    return(String(record_element[element['dataItem']]).length)
                })
                newColumnWidth[element['dataItem']] = `${Math.max(element["columnName"].length*20+10, Math.max(...dataLengthList)*12+60)}px`
            })
            changeColumnWidth(newColumnWidth)
        }

        if(record !== undefined){
            displayRecordSetup()
            getColumnWidth()
            checkboxSetup()
            inputSetup()
        }
        
    }, [record, columnList, inputSetup])

    if(Object.keys(columnList).length === 0){
        return(
            <div style={{width: '100%', position: 'relative'}}>
                <div className="creatable_table_container" />
            </div>
        )
    }else{
        return (
            <div style={{width: '100%', position: 'relative'}}>
                <div className="creatable_table_container">
                    <h2 style={{margin: '1vw auto 2vw auto', display: `${title === '' ? 'none' : ''}`}}>{title}</h2>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <div style={{height: 'fit-content', width: 'min-content', border: '1px solid', margin: '0 auto'}}>
                            <div className="table_row_container table_title">
                                {columnList.filter((value) => (columnDisplay[value['dataItem']])).map((value, index) => (
                                    <div 
                                        key={`column_title_${index}`}
                                        className="table_title_container" 
                                        style={{width: `${columnWidth[value['dataItem']]}`, display: 'inline-flex'}}
                                        onClick={() => {
                                            onRecordSort(
                                                value["dataItem"], 
                                                value["columnType"], 
                                                recordSortingItem === [] ? true :
                                                recordSortingItem[0] !== value["dataItem"] ? true :
                                                !recordSortingItem[1]
                                            )
                                        }}>
                                        <p 
                                            key={`title_column_${index}`} 
                                            className="table_title_text" 
                                        >{value["columnName"]}</p>
                                        {
                                        (!recordSortingItem[1] && recordSortingItem[0] === value["dataItem"]) ? <i className="gg-sort-za is_sorting_on" /> :
                                        <i className={`gg-sort-az ${recordSortingItem[0] === value["dataItem"] ? 'is_sorting_on' : ''}`} />
                                        }
                                    </div>
                                ))}
                            </div>
                            {
                                inputRowStatus === 'none' ? '' : 
                                <CreatableTableInputRow
                                    columnWidth={columnWidth}
                                    columnList={columnList}
                                    columnDisplay={columnDisplay}
                                    inputValue={inputValue}
                                    changeInputValue={(value) => {changeInputValue(value);changeAlertMessage()}}
                                    selectList={selectList}
                                    changeInputRowStatus={changeInputRowStatus}
                                    checkDependency={checkDependency}
                                    changeAlertMessage={changeAlertMessage}
                                />
                            }
                            {
                                displayRecord.map(([key, value], index) => (
                                    <div className="table_row_container" key={`record_${key}`} style={{display: `${ !component['bottom'] ? '' : parseInt(index/10) === displayPage ? '' : 'none' }`, background: `${checkboxValue[key] ? 'aliceblue' : ''}`}}>
                                        {
                                            columnList.filter((col) => (columnDisplay[col['dataItem']])).map((element, index) => (
                                                <p 
                                                    key={`record_${key}_column_${index}`} 
                                                    className="table_text"
                                                    style={{
                                                        width: `${columnWidth[element['dataItem']]}`,
                                                        color: `${
                                                            value[element['dataItem']] === 'A' ? '#3aa40c' :
                                                            value[element['dataItem']] === 'R' ? '#ca1657' :
                                                            value[element['dataItem']] === 'C' ? '#f1c232' : 'black'}`,
                                                        fontWeight: `${element['dataItem'] === 'status' ? 'bold' : ''}`
                                                        }}>{`${value[element['dataItem']] === null ? '' : String(value[element['dataItem']])+' '+element['unit']}`}
                                                </p>))
                                        }
                                    </div>
                                ))
                            }
                            {
                                component['stats'] && Object.keys(statsRow).length !== 0 ? 
                                <div className="table_row_container">
                                    {
                                        columnList.filter((col) => (columnDisplay[col['dataItem']])).map((element, index) => (
                                            <p 
                                                key={`stats_column_${index}`} 
                                                className="table_text"
                                                style={{
                                                    width: `${columnWidth[element['dataItem']]}`,
                                                    fontWeight: 'bold'
                                                }}>{`${statsRow[element['dataItem']] === null ? '' : statsRow[element['dataItem']]}`}
                                            </p>))
                                    }
                                </div>
                                : ''
                            }
                        </div>
                        {
                            component['functional'] ? 
                            <div className="table_functional_container">
                                {
                                    inputRowStatus === 'none' ? 
                                    <i className="gg-add-r creatable_table_button table_newData_button" onClick={() => {changeInputRowStatus('edit');inputSetup()}} /> : 
                                    <i className="gg-remove-r creatable_table_button table_newData_button" onClick={() => {changeInputRowStatus('none')}} />
                                }
                                {
                                    inputRowStatus === 'none' ? '' : <p className={`table_new_data_button ${inputRowStatus === 'ready'? 'is_new_data_ready' : ''}`} onClick={() => {if(inputRowStatus==='ready'){onNewDataUpload();changeInputRowStatus('none')}}} >新增</p>
                                }
                                <div className="table_checkbox_container">
                                {
                                    displayRecord?.map(([key, value], index) => (
                                        <Checkbox 
                                            key={`checkbox_${key}`} 
                                            checked={checkboxValue[key] || false}
                                            disabled={checkboxDisable[key] || false}
                                            onChange={(event) => {onCheckboxChange(key, event.target.checked)}}
                                            color={'primary'}
                                            className="table_checkbox" style={{display: `${parseInt(index/10) === displayPage ? '' : 'none' }`, padding: '4px'}} />
                                    ))
                                }
                                </div>
                            </div>
                            : ''
                        }
                    </div>
                </div>
                {
                    component['bottom'] ?
                    <div className="creatable_table_bottom">
                        {
                            utype === 'user' ?
                            <div className="creatable_table_buttonRow">
                                <button 
                                    className={`creatable_table_button ${Object.values(checkboxValue).filter((item) => (item)).length === 0 ? 'is_button_disable' : '' }`}
                                    onClick={() => {if(Object.values(checkboxValue).filter((item) => (item)).length !== 0){onDataStatusChange(checkboxValue, 'Check')}}}
                                >上呈已勾選項目</button>
                            </div> : utype === 'proj_admin' || utype === 'org_admin' ?
                            <div className="creatable_table_buttonRow">
                                <button
                                    className={`creatable_table_button ${Object.values(checkboxValue).filter((item) => (item)).length === 0 ? 'is_button_disable' : '' }`}
                                    onClick={() => {if(Object.values(checkboxValue).filter((item) => (item)).length !== 0){onDataStatusChange(checkboxValue, 'Authorize')}}}
                                >批准</button>
                                <button
                                    className={`creatable_table_button ${Object.values(checkboxValue).filter((item) => (item)).length === 0 ? 'is_button_disable' : '' }`}
                                    onClick={() => {if(Object.values(checkboxValue).filter((item) => (item)).length !== 0){onDataStatusChange(checkboxValue, 'Reject')}}}
                                >退回</button>
                            </div> :
                            <div className="creatable_table_buttonRow" />
                        }
                        <div className="creatable_table_pageRow">
                            <i className={`gg-chevron-left ${displayPage === 0 ? 'is_lastPage' : ''}`} onClick={() => {changeDisplayPage(Math.max(0, displayPage-1))}}></i>
                            {[...Array(parseInt((displayRecord?.length-1)/10)+1).keys()].map((value) => {
                                if(Math.abs(value-displayPage)<3){
                                    return value
                                }else{
                                    return null
                                }
                            }).map((value) => (
                                <p key={`page_button_${value}`} className={`creatable_table_page_button ${displayPage === value ? 'is_thisPage' : ''}`} onClick={() => {changeDisplayPage(value)}}>{value+1}</p>
                            ))}
                            <i className={`gg-chevron-right ${displayPage === parseInt((displayRecord?.length-1)/10) ? 'is_lastPage' : ''}`} onClick={() => {changeDisplayPage(Math.min(parseInt((displayRecord?.length-1)/10)), displayPage+1)}}></i>
                        </div>
                        <div className='creatable_table_message_container'>
                            <p className='creatable_table_message'>{alertMessage}</p>
                            {/* <p className='creatable_table_message'>test test</p> */}
                        </div>
                    </div>
                    : ''
                }
            </div>
        );
    }
}

export default CreatableTable;