import React from 'react';
import AppConfigN from '../const/AppConfigN.const';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    link: {
        // fontSize: '1vh',
        textAlign: 'left',
        // margin: '1vh 0 0 1vw',
        textDecoration: "underline",
        width: 'fit-content',
        '&:hover': {
            cursor: "pointer",
        }
    },
}));

function DisplayInvestmentCarbonEmissions_DataExport(props) {
    const classes = useStyles();

    const {
        // companyData,
        batchDataList
    } = props;

    const handleCreatedTimeTransfer = (created_time) => {
        let newTime = "";
        created_time = created_time.replace(/[^0-9]/ig, "");
        newTime = created_time.substring(0, 8) + "_" + created_time.substring(8, 14);
        // console.log(newTime);
        return newTime
    }

    const dataDownload = (batchID, ownerID, created_time) => {
        // console.log(batchID, created_time);
        let newTime = ""
        if (batchID !== "") {
            newTime = handleCreatedTimeTransfer(created_time);
            fetch(`${AppConfigN.SERVER_URL}/api/pcaf_batch_download`, {
                method: 'POST',
                headers: {
                    "Authorization": `Token ${props.account.token}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "batch_id": batchID
                })
            })
                .then((response) => response.blob())
                .then((blob) => {
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `投融資組合碳排放計算_${ownerID}_${newTime}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
        else {
            fetch(`${AppConfigN.SERVER_URL}/api/pcaf_batch_download`, {
                method: 'POST',
                headers: {
                    "Authorization": `Token ${props.account.token}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "batch_id": batchID
                })
            })
                .then((response) => response.blob())
                .then((blob) => {
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `投融資組合碳排放計算_最新批次資料.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                })
                .catch((error) => {
                    console.error(error);
                });
        }

    }

    return (
        <div className="display_body_container">
            <div style={{ backgroundColor: '#fafafa', borderRadius: '1.212vw', padding: '2.1vh 1.6vw 4vh', overflowX: 'scroll' }}>
                <div style={{ display: 'inline-grid', width: 'fit-content', height: 'fit-content', border: 'solid 2px #eaeaea', margin: '0vh 1.6vw 1vh 0vw' }}>
                    <div style={{ display: 'inline-flex' }}>
                        <div className='single_display_report_block' style={{ width: '33vw', minWidth: '360px' }}>檔案名稱</div>
                        {/* <div className='single_display_report_block' style={{ width: '8vw' }}>資料數量</div> */}
                        <div className='single_display_report_block' style={{ width: '10vw', minWidth: '180px' }}>上傳使用者</div>
                        <div className='single_display_report_block' style={{ width: '16vw', minWidth: '230px' }}>上傳時間</div>
                        <div className='single_display_report_block' style={{ width: '7vw', minWidth: '100px' }}>下載</div>
                    </div>
                    <div key={"0"} style={{ display: 'inline-flex' }}>
                        <div key="0" className='single_display_report_block' style={{ width: '33vw', minWidth: '360px' }}>投融資組合碳排放計算_最新批次資料</div>
                        <div key="0" className='single_display_report_block' style={{ width: '10vw', minWidth: '180px' }}>-</div>
                        <div key="0" className='single_display_report_block' style={{ width: '16vw', minWidth: '230px' }}>-</div>
                        <div key="0" className='single_display_report_block' style={{ width: '7vw', minWidth: '100px' }}>
                            <a className={classes.link} href={'/#'} onClick={() => dataDownload("", "最新批次")}>檔案下載</a>
                        </div>
                    </div>
                    {
                        Object
                        .entries(batchDataList)
                        .map(([id, batchData]) => (
                            <div key={id + 1} style={{ display: 'inline-flex' }}>
                                <div className='single_display_report_block' style={{ width: '33vw', minWidth: '360px' }}>投融資組合碳排放計算_{batchData.ownerID}_{handleCreatedTimeTransfer(batchData.created_time)}</div>
                                <div className='single_display_report_block' style={{ width: '10vw', minWidth: '180px' }}>{batchData.ownerID}</div>
                                <div className='single_display_report_block' style={{ width: '16vw', minWidth: '230px' }}>{batchData.created_time}</div>
                                <div className='single_display_report_block' style={{ width: '7vw', minWidth: '100px' }}>
                                    <a className={classes.link} href={'/#'} onClick={() => dataDownload(batchData.batch_id, batchData.ownerID, batchData.created_time)}>檔案下載</a>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div >
    )
}

export default DisplayInvestmentCarbonEmissions_DataExport;