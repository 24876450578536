import React, { useState, useEffect } from 'react';
import Menu from './Menu';
import { FormControl ,InputLabel, Select, MenuItem} from '@material-ui/core';
import SelectInput from '../component/SelectInput';
import CreatableTable from '../component/CreatableTable/CreatableTable';
import '../style/EnvVarCDFH_export.css';
import '../style/CreatableTable.css';
import AppConfigN from '../const/AppConfigN.const';
import excel_icon from '../img/excel-icon.png';
import {onPageMount_sample, record_sample, columnList_sample, filter_sample, title_sample, statsRow_sample} from '../EnvVarCDFH_export_test_data';

let today = new Date()
let dd = String(today.getDate()).padStart(2, '0');
let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
let yyyy = today.getFullYear();

today = yyyy + mm + dd;

function EnvVarCDFH_export(props) {
    const {
        account,
        permission,
        cookies,
        utype,
        removeCookie,
        activeScreen,
        switchScreen,
        switchShellChoice,
        changeDisplayMethod,
    } = props

    const [ alertTxt, changeAlertTxt ] = useState([])
    const [ datasetTableList, changeDatasetTableList ] = useState({})
    const [ datasetChoice, changeDatasetChoice ] = useState("")
    const [ tableChoice, changeTableChoice ] = useState('')
    const [ filterChoice, changeFilterChoice ] = useState({}) //{filterItem: filterValue}
    const [ report, changeReport ] = useState({}) //呈現表格紀錄內容
    const [ reportTitle, changeReportTitle ] = useState('')
    const [ reportStats, changeReportStats ] = useState({})
    const [ columnList, changecolumnList ] = useState([])
    const [ filter, changeFilter ] = useState([]) // [{filter_detail}]
    const [ columnDisplay, changeColumnDisplay ] = useState({}) //{dataItem: isDisplay}
    const [ inputRowStatus, changeInputRowStatus ] = useState('none') // none, edit, ready

    const onDatasetChoose = (value) => {
        changeTableChoice('')
        changeDatasetChoice(value)
        changeFilter([])
        changeFilterChoice({})
        changeReport({})
        changecolumnList([])
    }

    const columnDisplaySetup = (newColumnList) => {
        let defaultDisplay = {}
        newColumnList.forEach((element) => {
            defaultDisplay[element['dataItem']] = true
        })
        changeColumnDisplay(defaultDisplay)
    }

    const selecterSetup = (options) => {
        changeFilter(options)
        let defaultFilterChoice = {}
        options.forEach((element) => {
            defaultFilterChoice[element["filter_id"]] = []
        })
        changeFilterChoice(defaultFilterChoice)
    }

    const getReport = (option_value) => {
        // console.log(option_value) // 此處均為單選
       
        fetch(`${AppConfigN.SERVER_URL}/api/esg_envdatafiltero`, {
            method: 'POST',
            headers: {
                "Authorization": `Token ${account.token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                EnvDatasetChoice: datasetChoice,
                EnvTableChoice: tableChoice,
                FilterChoice: Object.assign(
                    {}, ...
                        Object
                        .entries(option_value)
                        .map(([key, value]) => ({[key]: [value['value']]}))
                    )
            }),
            })
            .then(response => response.json())
            .then((responseJson) => {
                // console.log(responseJson)
                reportSetup(responseJson) 
            })
    }

    const reportSetup = (response) => {
        changeReportTitle(response['reportTitle'])
        changeReport(response['record'])
        changecolumnList(response['columnList'])
        columnDisplaySetup(response['columnList'])
        changeReportStats(response['statsRow'])
    }

    const onTableChoose = (value) => {
        changeTableChoice(value)
        fetch(`${AppConfigN.SERVER_URL}/api/esg_envdatatableo`, {
            method: 'POST',
            headers: {
                "Authorization": `Token ${account.token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                EnvDatasetChoice: datasetChoice,
                EnvTableChoice: value,
              }),
            })
            .then(response => response.json())
            .then((responseJson) => {
                // console.log(responseJson)
                selecterSetup(responseJson['Attribute'])
            })
        changeReport({})
        changecolumnList([])
    }

    const onReportOptionSelect = (dataItem, dataValue) => {
        const new_filter = {
            ...filterChoice,
            [dataItem]: dataValue, // [{value, label},...]
        }
        changeFilterChoice(new_filter)
        
        const filtList = Object.values(new_filter).map((item) => (item['value']))
        if(!filtList.includes(undefined)){
            getReport(new_filter)
        }
    }

    const onReportDownload = () => {
        fetch(`${AppConfigN.SERVER_URL}/api/esg_batch_download`, {
            method: 'POST',
            headers: {
                "Authorization": `Token ${props.account.token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                EnvDatasetChoice: datasetChoice,
                EnvTableChoice: tableChoice,
                FilterChoice: Object.assign(
                    {}, ...
                        Object
                        .entries(filterChoice)
                        .map(([key, value]) => ({[key]: [value['value']]}))
                    )
            })
        })
        .then((response) => response.blob())
        .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${reportTitle.split('（')[0].replaceAll(' ', '_')}_${today}.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        })
        .catch((error) => {
            console.error(error);
        });
    }

    useEffect(() => {     
        const onPageMount = () => {
            fetch(`${AppConfigN.SERVER_URL}/api/esg_envdataseto`, {
                method: 'GET',
                headers: {
                    "Authorization": `Token ${account.token}`,
                    "Content-Type": "application/json"
                },
                })
                .then(response => response.json())
                .then((responseJson) => {
                    // console.log(responseJson)
                    changeAlertTxt(responseJson["message"])
                    changeDatasetTableList(responseJson["DatasetTableList"])
                    onDatasetChoose(Object.keys(responseJson["DatasetTableList"])[0])
                })
        }
        onPageMount()
    }, [changeDatasetTableList])

    return ( 
        <div className="new_home_mainContainer">
            <div className="page_container">
                <div className="page_title_container"><p className="page_title_text">環境變數結果輸出</p></div>
                <div className="blockContainer" style={{ display: "inline-block", width: "100%" }}>
                    <div className="blockContent" style={{margin: "0"}}>
                        <p className="blockContentTitle" style={{marginRight: "1vw"}}>產出資料</p>
                        <a className="infoMark" style={{ display: `${alertTxt.length === 0 ? 'none' : 'inline-block'}`}} href={'/#'}>i</a>
                        <ul className="infoContent">
                            {alertTxt.map((item, index) => (
                                <li key={`alertTxt_${index}`}>
                                    <p>{item.split(/(\d+)/)[0]}</p>
                                    <p style={{ color: 'red', margin: '0 4px'}}>{item.split(/(\d+)/)[1]} </p>
                                    <p>{item.split(/(\d+)/)[2]}</p>
                                </li>
                                ))}
                        </ul>
                    </div>
                    <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
                    <div className="display_header_container">
                        {
                            Object.keys(datasetTableList).map((value, index) => (
                                <div key={`dataset_choice_${index}`} className={datasetChoice === value ? 'display_header_item_active' : 'display_header_item_inactive'} onClick={() => { onDatasetChoose(value) }}>
                                    <p className={datasetChoice === value ? 'display_header_item_active_text' : 'display_header_item_inactive_text'} >{value}</p>
                                </div>
                            ))
                        }
                    </div>
                    <div className="blockContent" style={{display: 'flex', alignItems: 'end'}}>
                        <FormControl style={{minWidth: '110px', margin: '0 0 0 1vw'}}>
                            <InputLabel style={{fontWeight: 'bold', top: '0.5vh' }}>報表選擇<p style={{display: 'inline-block', fontSize: 'small', margin: '0', color: 'red', position: 'absolute', top: '-5px', paddingLeft: '3px'}}>*</p></InputLabel>
                            <Select
                                style={{height: '40px', fontSize: '20px', textAlign: 'center'}}
                                value={tableChoice || ''}
                                onChange={(event)=>{
                                    onTableChoose(event.target.value)
                                }}
                            >
                                {/* <MenuItem style={{fontSize: '1.8vh'}} value={"7"}>7. 電力</MenuItem> */}
                                {Object.values(datasetTableList[datasetChoice] || '')?.map((value, index) => <MenuItem style={{fontSize: '1.8vh'}} value={value} key={`GH_list_${index}`}>{value}</MenuItem>)}
                            </Select>
                        </FormControl>
                        {
                            filter.map((item) => (
                                <SelectInput
                                    key={item["filter_id"]}
                                    disabled={tableChoice===''}
                                    value={filterChoice[item["filter_id"]]}
                                    setValue={(value) => {onReportOptionSelect(item["filter_id"], value)}}
                                    defaultOptions={item["filter_options"]}
                                    placeholdertxt={item["placeholder"]}
                                    creatable={true}
                                    isMulti={item["multiple_select"]}
                                />
                            ))
                        }
                    </div>
                    {
                        <div className="blockContent" style={{height: '57vh'}}>
                            <CreatableTable
                                columnList={columnList}
                                columnDisplay={columnDisplay}
                                record={report} 
                                title={reportTitle}
                                component={{functional: false, bottom: false, stats: true}}
                                inputRowStatus={inputRowStatus}
                                changeInputRowStatus={changeInputRowStatus}
                                onNewDataUpload={() => {}}
                                utype={utype}
                                inputValue={{}}
                                changeInputValue={() => {}}
                                onDataStatusChange={() => {}}
                                statsRow={reportStats}
                                alertMessage={''}
                                changeAlertMessage={()=>{}} />
                        </div>
                    }
                    <div className="blockContent" style={{flexDirection: 'row-reverse', height: '8vh'}}>
                        <div className="creatable_table_buttonRow">
                            <button 
                                className={`creatable_table_button ${Object.keys(report).length === 0 ? 'is_button_disable' : '' }`} 
                                style={{display: 'inline-flex', float: 'right'}}
                                onClick={() => {onReportDownload()}}>
                                <p style={{margin: 'auto'}}>輸出完整結果</p>
                                {
                                    Object.keys(report).length === 0 ? '' :
                                    <img alt='result output' src={excel_icon} style={{width: '3vh', height: '3.3vh', marginLeft: '10px'}}/>
                                }
                            </button>
                        </div>
                    </div>
                    
                </div>
            </div>
            <Menu
                account={account}
                permission={permission}
                cookies={cookies}
                removeCookie={removeCookie}
                activeScreen={activeScreen}
                switchScreen={switchScreen}
                switchShellChoice={switchShellChoice}
                changeDisplayMethod={changeDisplayMethod}
            />
        </div>
     );
}

export default EnvVarCDFH_export;