import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles((theme) => ({
  Execution_button: {
    width: "9.6vw",
    height: "4.8vh",
    padding: "1vh 0.7vw",
    borderRadius: "6px",
    border: "solid 1px #ffffff",
  }
}))

function Execution(props) {
  const classes = useStyle();
  return (
    <div>
      <Button 
        onClick={() => {props.onExecutionClick()}}
        className={classes.Execution_button}
      >
        <p className="process_bottom_button_text">執行運算</p>
      </Button>
    </div>
  );
}

export default Execution;