import React, { useState, useEffect } from 'react';
import Menu from './Menu';
import { FormGroup, FormControl, FormControlLabel, InputLabel, Select, MenuItem, Popover, Checkbox} from '@material-ui/core';
import SelectInput from '../component/SelectInput';
import CreatableTable from '../component/CreatableTable/CreatableTable';
import '../style/EnvVarCDFH_import.css';
import '../style/CreatableTable.css';
import AppConfigN from '../const/AppConfigN.const';
import { onPageMount_sample, record_sample, columnList_sample, filter_sample } from '../EnvVarCDFH_import_test_data';

function EnvVarCDFH_import(props) {
    const {
        account,
        permission,
        cookies,
        utype,
        removeCookie,
        activeScreen,
        switchScreen,
        switchShellChoice,
        changeDisplayMethod,
    } = props

    const [ alertTxt, changeAlertTxt ] = useState([])
    const [ datasetTableList, changeDatasetTableList ] = useState({})
    const [ datasetChoice, changeDatasetChoice ] = useState("")
    const [ tableChoice, changeTableChoice ] = useState('')
    const [ filterChoice, changeFilterChoice ] = useState({}) //{filterItem: filterValue}
    const [ record, changeRecord ] = useState({}) //呈現表格紀錄內容
    const [ columnList, changecolumnList ] = useState([])
    const [ filter, changeFilter ] = useState([]) // [{filter_detail}]
    const [ columnDisplay, changeColumnDisplay ] = useState({}) //{dataItem: isDisplay}
    const [ columnDisplayPopover, setColumnDisplayPopover] = useState(false)
    const [ popoverAnchorEl, setPopoverAnchorEl ] = useState(null);
    const [ inputRowStatus, changeInputRowStatus ] = useState('none') // none, edit, ready
    const [ inputValue, changeInputValue ] = useState({})
    const [ alertMessage, changeAlertMessage ] = useState('')
    
    const columnDisplaySetup = (newColumnList) => {
        let defaultDisplay = {}
        newColumnList.forEach((element) => {
            defaultDisplay[element['dataItem']] = element['userInput']  // 預設僅有使用者需自行輸入的欄位會呈現
        })
        defaultDisplay['status'] = true
        changeColumnDisplay(defaultDisplay)
    }

    const onColumnDisplayChange = (dataItem, state) => {
        let newColumnDisplay = {
            ...columnDisplay,
            [dataItem]: state,
        }
        changeColumnDisplay(newColumnDisplay)
    }
    
    const onDatasetChoose = (value) => {
        changeTableChoice('')
        changeDatasetChoice(value)
        changeFilter([])
        changeFilterChoice({})
        changeRecord({})
        changecolumnList([])
        changeAlertMessage('')
    }

    const filterSetup = (filter_fetch) => {
        changeFilter(filter_fetch)
        let defaultFilterChoice = {}
        filter_fetch.forEach((element) => {
            defaultFilterChoice[element["filter_id"]] = []
        })
        changeFilterChoice(defaultFilterChoice)

        return defaultFilterChoice
    }

    const recordSetup = (record, columnList) => {
        changeRecord(record)
        changecolumnList(columnList)
        columnDisplaySetup(columnList)
    }

    const onTableChoose = (value) => {
        // let defaultFilterChoice;
        changeAlertMessage('')
        changeTableChoice(value)
        fetch(`${AppConfigN.SERVER_URL}/api/esg_envdatatable`, {
            method: 'POST',
            headers: {
                "Authorization": `Token ${account.token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                EnvDatasetChoice: datasetChoice,
                EnvTableChoice: value,
              }),
            })
            .then(response => response.json())
            .then((responseJson) => {
                // console.log(responseJson)
                const defaultFilterChoice = filterSetup(responseJson['Filter'])
                return defaultFilterChoice
            })
            .then((defaultFilterChoice) => {
                onRecordFilt(value, null, null, defaultFilterChoice) // FilterChoice初始值，篩選項目及篩選值皆填null
            })
    }

    const onRecordFilt = (tableChoice, dataItem, dataValue, defaultFilterChoice) => {
        let new_filter
        if(defaultFilterChoice !== null){
            new_filter = defaultFilterChoice // FilterChoice初始值
        }else if(dataItem === null){
            new_filter = {
                ...filterChoice // 維持原FilterChoice
            }
        }else{
            new_filter = {
                ...filterChoice,
                [dataItem]: dataValue, // 更新FilterChoice [{value, label},...]
            }
        }
        
        fetch(`${AppConfigN.SERVER_URL}/api/esg_envdatafilter`, {
            method: 'POST',
            headers: {
                "Authorization": `Token ${account.token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                EnvDatasetChoice: datasetChoice,
                EnvTableChoice: tableChoice,
                FilterChoice: defaultFilterChoice !== null ? defaultFilterChoice
                : Object.assign({}, ...
                    Object
                    .entries(new_filter)
                    .map(([key, value]) => ({[key]: value.map((item) => (item['value']))}))
                )
                
                // {
                //     ...filterChoice,
                //     [dataItem]: dataValue.map((item) => (item['value'])), // [value1, value2, ...]
                // }
              }),
            })
            .then(response => response.json())
            .then((responseJson) => {
                // console.log(responseJson)
                recordSetup(responseJson['record'], responseJson['columnList']) 
            })
        changeFilterChoice(new_filter)
    }

    const onNewDataUpload = () => {
        // console.log(inputValue)
        let newData = Object.assign({}, ...
            Object
            .entries(inputValue)
            .filter(([key, value]) => value !== '-')
            .map(([key, value]) => ({[key]: value}))
        );
        // console.log(newData)
        fetch(`${AppConfigN.SERVER_URL}/api/esg_envdataadd`, {
            method: 'POST',
            headers: {
                "Authorization": `Token ${account.token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                EnvDatasetChoice: datasetChoice,
                EnvTableChoice: tableChoice,
                NEW_DATA: newData,
              }),
            })
            .then(response => response.json())
            .then((responseJson) => {
                // console.log(responseJson)
                changeAlertMessage(responseJson['detail'])
            })
            .then(() => {
                onRecordFilt(tableChoice, null, null, null)
            })
    }

    const onDataStatusChange = (checkboxValue, action) => {
        changeAlertMessage('')
        const applyDataList = Object.entries(checkboxValue).filter(([key, value]) => (value)).map(([key, value]) => (key))
        // console.log(applyDataList)
        fetch(`${AppConfigN.SERVER_URL}/api/esg_envdatastatus`, {
            method: 'POST',
            headers: {
                "Authorization": `Token ${account.token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                EnvDatasetChoice: datasetChoice,
                EnvTableChoice: tableChoice,
                ACTION: action,
                dataList: applyDataList,
              }),
            })
            .then(response => response.json())
            .then((responseJson) => {
                // console.log(responseJson)
            })
            .then(() => {
                onRecordFilt(tableChoice, null, null, null)
            })
    }

    useEffect(() => {
        const onPageMount = () => {
            fetch(`${AppConfigN.SERVER_URL}/api/esg_envdataset`, {
                method: 'GET',
                headers: {
                    "Authorization": `Token ${account.token}`,
                    "Content-Type": "application/json"
                },
                })
                .then(response => response.json())
                .then((responseJson) => {
                    // console.log(responseJson)
                    changeAlertTxt(responseJson["message"])
                    changeDatasetTableList(responseJson["DatasetTableList"])
                    onDatasetChoose(Object.keys(responseJson["DatasetTableList"])[0])
                })
        }
        onPageMount()
    }, [changeDatasetTableList])

    return ( 
        <div className="new_home_mainContainer">
            <div className="page_container">
                <div className="page_title_container"><p className="page_title_text">環境變數整合分析</p></div>
                <div className="blockContainer" style={{ display: "inline-block", width: "100%" }}>
                    <div className="blockContent" style={{margin: "0"}}>
                        <p className="blockContentTitle" style={{marginRight: "1vw"}}>輸入資料</p>
                        <a className="infoMark" style={{ display: `${alertTxt.length === 0 ? 'none' : 'inline-block'}`}} href={'/#'}>i</a>
                        <ul className="infoContent">
                            {alertTxt.map((item, index) => (
                                <li key={`alertText_${index}`}>
                                    <p>{item.split(/(\d+)/)[0]}</p>
                                    <p style={{ color: 'red', margin: '0 4px'}}>{item.split(/(\d+)/)[1]} </p>
                                    <p>{item.split(/(\d+)/)[2]}</p>
                                </li>
                                ))}
                        </ul>
                    </div>
                    <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
                    <div className="display_header_container">
                        {
                            Object.keys(datasetTableList).map((value, index) => (
                                <div key={`dataset_choice_${index}`} className={datasetChoice === value ? 'display_header_item_active' : 'display_header_item_inactive'} onClick={() => { onDatasetChoose(value) }}>
                                    <p className={datasetChoice === value ? 'display_header_item_active_text' : 'display_header_item_inactive_text'} >{value}</p>
                                </div>
                            ))
                        }
                    </div>
                    <div className="blockContent" style={{display: 'flex', alignItems: 'end', flexWrap: 'wrap', minHeight: '40px'}}>
                        <FormControl style={{minWidth: '110px', margin: '0 0 0 1vw'}}>
                            <InputLabel style={{fontWeight: 'bold', top: '0.5vh' }}>報表選擇<p style={{display: 'inline-block', fontSize: 'small', margin: '0', color: 'red', position: 'absolute', top: '-5px', paddingLeft: '3px'}}>*</p></InputLabel>
                            <Select
                                style={{height: '40px', fontSize: '20px', textAlign: 'center'}}
                                value={tableChoice || ''}
                                onChange={(event)=>{
                                    onTableChoose(event.target.value)
                                }}
                            >
                                {/* <MenuItem style={{fontSize: '1.8vh'}} value={"7"}>7. 電力</MenuItem> */}
                                {Object.values(datasetTableList[datasetChoice] || '')?.map((value, index) => <MenuItem style={{fontSize: '1.8vh'}} value={value} key={`GH_list_${index}`}>{value}</MenuItem>)}
                            </Select>
                        </FormControl>
                        {
                            filter.map((item) => {
                            return(
                                <SelectInput
                                    key={item["filter_id"]}
                                    disabled={tableChoice===''}
                                    value={filterChoice[item["filter_id"]] || ''}
                                    setValue={(value) => {onRecordFilt(tableChoice, item["filter_id"], value, null)}}
                                    defaultOptions={item["filter_options"]}
                                    placeholdertxt={item["placeholder"]}
                                    creatable={true}
                                    // onChange={(value) => {onRecordFilt("year", value)}}
                                    isMulti={item["multiple_select"]}
                                />
                            )})
                        }
                        <div style={{display: `${tableChoice === '' ? 'none' : ''}`}}>
                            <button className={`EnvVarCDFH_popover_button ${columnDisplayPopover ? 'isButtonClicked' : '' }`} variant="contained" onClick={(event) => {setPopoverAnchorEl(event.currentTarget);setColumnDisplayPopover(true)}}>
                                顯示/隱藏欄位
                            </button>
                            <Popover
                                open={columnDisplayPopover}
                                onClose={() => {setColumnDisplayPopover(false);setPopoverAnchorEl(null)}}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                anchorEl={popoverAnchorEl}
                            >
                                {/* <Typography sx={{ p: 2 }}> */}
                                <FormGroup style={{margin: '10px 0'}}>
                                    {Object.values(columnList || '')?.map((value, index) => (
                                        <FormControlLabel 
                                            key={`columnItem_formControl_${value['columnName']}`}
                                            style={{margin: '0 15px'}}
                                            control={
                                                <Checkbox 
                                                    key={`checkbox_${index}`} 
                                                    checked={columnDisplay[value['dataItem']]}
                                                    disabled={value['userInput']}
                                                    onChange={(event) => {onColumnDisplayChange(value['dataItem'], event.target.checked)}}
                                                    color={'primary'}
                                                    className="table_checkbox"
                                                />
                                            } 
                                            label={`${value['columnName']}`}
                                        />
                                    ))}
                                </FormGroup>
                                {/* </Typography> */}
                            </Popover>
                        </div>
                        <div style={{display: `${tableChoice === '' ? 'none' : ''}`}}>
                            <button className={`EnvVarCDFH_popover_button ${inputRowStatus !== 'none' ? 'isButtonClicked' : '' }`} variant="contained" onClick={() => {changeInputRowStatus(`${inputRowStatus === 'none' ? 'edit' : 'none'}`)}}>
                                {`${inputRowStatus === 'edit' ? '隱藏新增列' : '顯示新增列'}`}
                            </button>
                        </div>
                    </div>
                    {
                        <div className="blockContent">
                            <CreatableTable 
                                columnList={columnList}
                                columnDisplay={columnDisplay}
                                record={record}
                                title=''
                                component={{functional: true, bottom: true, stats: false}}
                                statsRow={''}
                                inputRowStatus={inputRowStatus}
                                changeInputRowStatus={changeInputRowStatus}
                                onNewDataUpload={onNewDataUpload}
                                utype={utype}
                                inputValue={inputValue}
                                changeInputValue={changeInputValue}
                                onDataStatusChange={onDataStatusChange}
                                alertMessage={alertMessage}
                                changeAlertMessage={changeAlertMessage} />
                        </div>
                    }
                    <div className="blockContent remark">
                        <p>狀態說明</p>
                        <div>
                            <li>{"-: 正在編輯"}</li>
                            <li>{"S(Saved): 儲存"}</li>
                            <li>{"C(Checked): 已上呈待批准"}</li>
                            <li>{"A(Authorized): 已批准"}</li>
                            <li>{"R(Rejected): 被退回"}</li>
                        </div>
                        
                    </div>
                </div>
                {/* <div className="blockContainer" style={{ display: "inline-block", width: "100%" }}>
                    <p className="blockContentTitle">產出資料</p>
                    <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
                    <div className="display_header_container">
                        <div className={outputTag === 'waterAndElectricity' ? 'display_header_item_active' : 'display_header_item_inactive'} onClick={() => { changeOutputTag('waterAndElectricity') }}>
                            <p className={outputTag === 'waterAndElectricity' ? 'display_header_item_active_text' : 'display_header_item_inactive_text'} >用水、用電狀況</p>
                        </div>
                        <div className={outputTag === 'CSRData' ? 'display_header_item_active' : 'display_header_item_inactive'} onClick={() => { changeOutputTag('CSRData') }}>
                            <p className={outputTag === 'CSRData' ? 'display_header_item_active_text' : 'display_header_item_inactive_text'} >CSR報告書數據統整</p>
                        </div>
                        <div className={outputTag === 'GHGInventory' ? 'display_header_item_active' : 'display_header_item_inactive'} onClick={() => { changeOutputTag('GHGInventory') }}>
                            <p className={outputTag === 'GHGInventory' ? 'display_header_item_active_text' : 'display_header_item_inactive_text'} >溫室氣體盤查登錄表單</p>
                        </div>
                    </div>
                </div> */}
            </div>
            <Menu
                account={account}
                permission={permission}
                cookies={cookies}
                removeCookie={removeCookie}
                activeScreen={activeScreen}
                switchScreen={switchScreen}
                switchShellChoice={switchShellChoice}
                changeDisplayMethod={changeDisplayMethod}
            />
        </div>
     );
}

export default EnvVarCDFH_import;