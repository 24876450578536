import React, { useState, useEffect } from 'react';
import "../../style/style.css";
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '../Menu';
import AppConfigN from '../../const/AppConfigN.const';
import InvestmentITR_itr from './InvestmentITR_itr';
import InvestmentITR_pf from './InvestmentITR_pf';
import InvestmentITR_tgt from './InvestmentITR_tgt';
import InvestmentITR_export from './InvestmentITR_export';
import '../../style/CreatableTable.css';

const result_Tab_name = {
    "itr_total": '投融資組合ITR結果',
    "pf_list": '標的財務資料',
    "tgt_list": 'ITR減碳目標資料',
    "download_list": '資料匯出'
}

const useStyles = makeStyles((theme) => ({
    blockTitle: {
        margin: "0 0 0 0vw",
        fontFamily: "PingFangTC",
        fontSize: "2.1vh",
        fontWeight: "500",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        color: "#797979",
    },
    blockContent: {
        margin: "0vh 1vw 0vh 1vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        textAlign: "center",
    },
    formControl: {
        width: "100%",
    },
    link: {
        // fontSize: '1vh',
        textAlign: 'left',
        // margin: '1vh 0 0 1vw',
        textDecoration: "underline",
        width: 'fit-content',
        '&:hover': {
            cursor: "pointer",
        }
    },
    inputFile: {
        // margin: '0 0 2vh 0vw',
        width: "100%",
    },
    select: {
        height: "5vh",
        // width: "12vw",
        margin: '1.5vh 0vw 0vh 0vw',
        fontSize: '2.5vh',
    },
    sendInfoButton: {
        height: "4vh",
        fontSize: "2.1vh",
        padding: "5px 10px",
        margin: "0.5vh 1vw",
        // width: "95%",
        backgroundColor: '#ececec',
    }
}))

function InvestmentITR(props) {
    const classes = useStyles();

    const [ itrFile, setItrFile ] = useState();
    const [ pfFile, setPfFile ] = useState();
    const [ latest_itr, changeLatest_itr ] = useState("");
    const [ pfDataUsed, changePfDataUsed ] = useState("")
    const [ batchIdList, setBatchIdList ] = useState({});
    const [ batchID, setBatchID ] = useState("");
    const [ pfRsltBatchIdList, setPfRsltBatchIdList ] = useState({});
    const [ pfRsltBatchId, setPfRsltBatchId ] = useState("");
    const [ resultData, setResultData ] = useState({});
    const [ outputTabList, setOutputTabList ] = useState({
        "itr_total": false,
        "pf_list": false,
        "tgt_list": false,
        "download_list": false
    })
    const[ outputTab, changeOutputTab ] = useState('')

    const [ typeList, setTypeList ] = useState([]);
    const [ ownerList, setOwnerList ] = useState([]);
    const [ isClick, setIsClick ] = useState(false);
    const [ alertMsg_cal, changeAlertMsg_cal ] = useState("");
    const [ alertMsg_itr, changeAlertMsg_itr ] = useState("");
    const [ alertMsg_pf, changeAlertMsg_pf ] = useState("");

    const {
        account,
        permission,
        cookies,
        removeCookie,
        activeScreen,
        switchScreen,
        switchShellChoice,
        changeDisplayMethod
    } = props

    const getLastestITRTime = () => {
        changeAlertMsg_itr("");
        changeAlertMsg_pf("");
        changeAlertMsg_cal("");
        let res = false;
        fetch(`${AppConfigN.SERVER_URL}/api/itr_itr_latest`, {
            method: 'GET',
            headers: {
                "Authorization": `Token ${account.token}`,
                "Content-Type": "application/json"
            },
        })
        .then((response) => {
            if (response.ok) {
                res = true;
            }
            return response.json()
        })
        .then((responseJson) => {
            // console.log(responseJson)
            if (res) {
                changeLatest_itr(responseJson["latest_time"].split('.')[0]);
            }
            else {
                changeAlertMsg_itr(responseJson.detail);
            }
        })
        .catch((error) => {
            console.error(error);
        });
    }

    const getDownloadData = (download_file) => {
        const file_request = {
            'itr_input': 'ITR_ITR_INPUT.xlsx',
            'pf_input': 'ITR_PF_INPUT.xlsx'
        }
        const file_name = {
            'itr_input': 'ITR減碳目標輸入檔',
            'pf_input': '投融資標的組合輸入檔'
        }
        fetch(`${AppConfigN.SERVER_URL}/api/itr_download`, {
            method: 'POST',
            headers: {
                "Authorization": `Token ${props.account.token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "filedownload": file_request[download_file]
            })
        })
        .then((response) => response.blob())
        .then((blob) => {
            console.log(blob)
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${file_name[download_file]}.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        })
        .catch((error) => {
            console.error(error);
        });
    }
    
    const uploadData = (item) => {
        changeAlertMsg_itr("");
        changeAlertMsg_pf("");
        changeAlertMsg_cal("");
        setIsClick(true);

        const upload_api = {
            'tgt': 'itr_tgt_upload',
            'pf': 'itr_pf_upload'
        }
        let res = false;
        const formData = new FormData();
        if(item === 'tgt'){
            formData.append('fileUpload', itrFile);
            console.log(itrFile)
        }else{
            formData.append('fileUpload', pfFile);
            console.log(pfFile)
        }
        
        fetch(`${AppConfigN.SERVER_URL}/api/${upload_api[item]}/`, {
            method: 'POST',
            headers: {
                "Authorization": `Token ${props.account.token}`,
            },
            body: formData
        })
        .then((response) => {
            // console.log(response)
            if (response.ok) {
                res = true;
            }
            return response.json()
        })
        .then((responseJson) => {
            console.log(responseJson)
            if (res) {
                if(item === 'tgt'){
                    getLastestITRTime()
                    changeAlertMsg_itr('上傳ITR減碳目標資料成功');
                    setIsClick(false);
                }else{
                    changeOutputTab('')
                    setResultData("")
                    let id = responseJson.batch_id;
                    // console.log(id)

                    function refreshBatch(ms){
                        return new Promise(resolve => {
                            setTimeout(() => {
                                // getBatchData(id);
                                // console.log(batchIdList)
                                // const item = batchIdList.filter((element) => (element.batch_id===id))[0]
                                // changePfDataUsed(item.created_time)
                            }, ms);
                            resolve();
                        })
                    }
                    
                    refreshBatch(0)
                    .then(() => {
                        getBatchIdList((List) => {
                        // console.log(List)
                        // const item = List.filter((element) => (element.batch_id===id))[0]
                        // changePfDataUsed(item.created_time)
                        // setIsClick(false);
                        getBatchData(id, List)
                        setIsClick(false);
                        changeAlertMsg_pf('上傳投融資標的組合資料成功');
                    })})
                    .catch((error) => {console.log(error)})
                    
                    // getBatchIdList();
                    // getBatchData(id);
                }
            }
            else {
                if(item === 'tgt'){
                    changeAlertMsg_itr(responseJson.detail);
                }else{
                    changeAlertMsg_pf(responseJson.detail);
                }
                setIsClick(false);
            }
        })
        .catch((error) => {
            console.error(error);
            setIsClick(false);
        });
    }

    const getBatchIdList = (callback) => {
        changeAlertMsg_itr("");
        changeAlertMsg_pf("");
        changeAlertMsg_cal("");
        let res = false;
        fetch(`${AppConfigN.SERVER_URL}/api/itr_pf_pcaf_batchid`, {
            method: 'GET',
            headers: {
                "Authorization": `Token ${account.token}`,
                "Content-Type": "application/json"
            },
        })
        .then((response) => {
            // console.log(response)
            if (response.ok) {
                res = true;
            }
            return response.json()
        })
        .then((responseJson) => {
            if (res) {
                let option = responseJson.batch_list;
                setBatchIdList(option);
                callback(option)
            }
            else {
                changeAlertMsg_pf(responseJson.detail);
            }
        })
        .catch((error) => {
            console.error(error);
        });
    }

    const getBatchData = (batchID, List) => {
        changeAlertMsg_itr("");
        changeAlertMsg_pf("");
        changeAlertMsg_cal("");
        setResultData("")
        let id = "";
        let res = false;
        if (batchID === "New") {
            id = ""
        }
        else {
            id = batchID;
        }
        console.log(id)
        if (id.length === 0) {
            changeAlertMsg_pf('請先選擇過去批次資料')
        }
        else {
            fetch(`${AppConfigN.SERVER_URL}/api/itr_pf_pcaf_batch`, {
                method: 'POST',
                headers: {
                    "Authorization": `Token ${account.token}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    batch_id: id
                })
            })
            .then((response) => {
                if (response.ok) {
                    res = true;
                }
                return response.json()
            })
            .then((responseJson) => {
                console.log(responseJson);
                if (res) {
                    let data = responseJson;
                    changeOutputTab('')
                    setResultData(data);
                    setOutputTabList(responseJson.tab_list)
                    
                    let item = batchIdList.filter((element) => (element.batch_id===id))[0]
                    if(item === undefined){
                        item = List.filter((element) => (element.batch_id===id))[0]
                    }
                    // console.log(item)
                    changePfDataUsed(item.created_time)
                }
                else {
                    changeAlertMsg_pf(responseJson.detail);
                }
            })
            .catch((error) => {
                console.error(error);
            });
        }
    }

    const getPfRsltBatchIdList = () => {
        changeAlertMsg_itr("");
        changeAlertMsg_pf("");
        changeAlertMsg_cal("");
        let res = false;
        fetch(`${AppConfigN.SERVER_URL}/api/itr_pf_rslt_batchid`, {
            method: 'GET',
            headers: {
                "Authorization": `Token ${account.token}`,
                "Content-Type": "application/json"
            },
        })
        .then((response) => {
            if (response.ok) {
                res = true;
            }
            return response.json()
        })
        .then((responseJson) => {
            // console.log(responseJson)
            if (res) {
                let option = responseJson.batch_list;
                // console.log(option);
                setPfRsltBatchIdList(option);
            }
            else {
                changeAlertMsg_cal(responseJson.detail);
            }
        })
        .catch((error) => {
            console.error(error);
        });
    }

    const getPfRsltBatchData = (batchID) => {
        changeAlertMsg_itr("");
        changeAlertMsg_pf("");
        changeAlertMsg_cal("");
        setResultData("")
        let id = "";
        let res = false;
        // console.log(batchID)
        if (batchID === "New") {
            id = ""
        }
        else {
            id = batchID;
        }
        fetch(`${AppConfigN.SERVER_URL}/api/itr_pf_rslt_batch`, {
            method: 'POST',
            headers: {
                "Authorization": `Token ${account.token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                batch_id: id
            })
        })
        .then((response) => {
            if (response.ok) {
                res = true;
            }
            return response.json()
        })
        .then((responseJson) => {
            // console.log(responseJson);
            if (res) {
                let data = responseJson;
                // console.log(batchIdList)
                const item = pfRsltBatchIdList.filter((element) => (element.batch_id===batchID))[0]
                changeOutputTab('')
                setResultData(data);
                setOutputTabList(responseJson.tab_list)
            }
            else {
                changeAlertMsg_cal(responseJson.detail);
            }
        })
        .catch((error) => {
            console.error(error);
        });
    }

    const pf_Itr_calculate = () => {
        changeAlertMsg_itr("");
        changeAlertMsg_pf("");
        changeAlertMsg_cal("");
        let res = false;
        if (pfDataUsed === "") {
            changeAlertMsg_cal('請先上傳或選擇投融資組合資料')
        }else if(latest_itr === "") {
            changeAlertMsg_cal('請先上傳ITR減碳目標資料')
        }else{
            const item = batchIdList.filter((element) => (element.created_time===pfDataUsed))[0]
            // console.log(item.batch_id)
            setIsClick(true);
            fetch(`${AppConfigN.SERVER_URL}/api/itr_pf_cal`, {
                method: 'POST',
                headers: {
                    "Authorization": `Token ${account.token}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    batch_id: item.batch_id
                })
            })
            .then((response) => {
                // console.log(response)
                if (response.ok) {
                    res = true;
                }
                return response.json()
            })
            .then((responseJson) => {
                // console.log(responseJson);
                if (res) {
                    let data = responseJson;
                    changeOutputTab('')
                    setResultData(data);
                    setOutputTabList(responseJson.tab_list)
                    getPfRsltBatchIdList()
                    setPfRsltBatchId('')
                }
                else {
                    changeAlertMsg_cal(responseJson.detail);
                }
                setIsClick(false);
            })
            .catch((error) => {
                console.error(error);
            });
        }
    }

    useEffect(() => {
        getBatchIdList(() => {});
        getPfRsltBatchIdList();
        getLastestITRTime();
    }, [])

    return ( 
        <div className="new_home_mainContainer">
            <div className="page_container">
                <div className="page_title_container"><p className="page_title_text">投融資組合之ITR</p></div>
                <div className="page_subtitle_container"><p className="page_subtitle_text">上傳ITR減碳目標與投融資標的組合基本資料</p></div>              
                <div style={{ display: "inline-flex", flexWrap: 'wrap', justifyContent: "space-between", width: 'calc(100% + 10px)' }}>
                    <div className="DataDownload_block" style={{ flexDirection: "column", minWidth: "300px", flex:3, margin: '0 10px 10px 0' }}>
                        <p className="DataDownload_block_title_text">ITR減碳目標基本資料</p>
                        <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
                        <div style={{ display: 'flex', margin: "1vh 1vw 0 1vw" }}>
                            <div style={{ width: "96%", margin: "0 3% 0 1%" }}><p className={classes.blockTitle}>上傳資料 (<a className={classes.link} href={'/#'} onClick={() => getDownloadData('itr_input')}>下載範例檔案</a>)</p></div>
                        </div>
                        <div className={classes.blockContent}>
                            <div style={{ width: "96%", margin: "0 3% 0 1%" }}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <input
                                        className={classes.inputFile}
                                        style={{ marginTop: "0.8vh" }}
                                        type="file"
                                        name="file"
                                        onChange={(event) => {
                                            setItrFile(event.target.files[0]);
                                        }} />
                                </FormControl>
                            </div>
                        </div>
                        <div style={{ display: 'flex', margin: "1vh 1vw 1vh 1vw", textAlign: "center" }}>
                            <div style={{ width: "96%", margin: "0 3% 0 1%" }}>
                                <Button variant="outlined" className={classes.sendInfoButton} onClick={() => {changeAlertMsg_cal('');uploadData('tgt')}}>上傳</Button>
                            </div>
                        </div>
                        {
                            alertMsg_itr === '' ? '' :
                            <div style={{ display: 'flex', margin: "2vh 2vw", textAlign: "left" }}>
                                <p style={{fontSize: '2.1vh', color: 'red', margin: '0'}}>{alertMsg_itr}</p>
                            </div>
                        }
                    </div>
                    <div className="DataDownload_block" style={{ flexDirection: "column", minWidth: "490px", flex: '5', margin: '0 10px 10px 0' }}>
                        <p className="DataDownload_block_title_text">投融資標的組合基本資料</p>
                        <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
                        <div style={{ display: 'flex', margin: "1vh 1vw 0 1vw" }}>
                            <div style={{ width: "56%", margin: "0 3% 0 1%" }}><p className={classes.blockTitle}>上傳資料 (<a className={classes.link} href={'/#'} onClick={() => getDownloadData('pf_input')}>下載範例檔案</a>)</p></div>
                            <div style={{ width: "40%", margin: "0 0 0 0%" }}><p className={classes.blockTitle}>查看過去資料</p></div>
                        </div>
                        <div className={classes.blockContent}>
                            <div style={{ width: "56%", margin: "0 3% 0 1%" }}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <input
                                        className={classes.inputFile}
                                        style={{ marginTop: "0.8vh" }}
                                        type="file"
                                        name="file"
                                        onChange={(event) => {
                                            setPfFile(event.target.files[0]);
                                        }} />
                                </FormControl>
                            </div>
                            <div style={{ width: "40%", margin: "0 0 0 0%" }}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <Select
                                        className={classes.select}
                                        style={{fontSize: '2vh'}}
                                        value={batchID}
                                        onChange={(event) => {
                                            setBatchID(event.target.value);
                                        }}
                                    >
                                        {/* <MenuItem value="New">最新批次資料</MenuItem> */}
                                        {
                                            Object
                                                .entries(batchIdList)
                                                .map(([id, batchData]) => <MenuItem key={`batchIDList_${id}`} value={batchData.batch_id}>{batchData.created_time}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div style={{ display: 'flex', margin: "1vh 1vw 1vh 1vw", textAlign: "center" }}>
                            <div style={{ width: "56%", margin: "0 3% 0 1%" }}>
                                <Button variant="outlined" className={classes.sendInfoButton} onClick={() => {changeAlertMsg_cal('');uploadData('pf')}}>上傳</Button>
                            </div>
                            <div style={{ width: "40%", margin: "0 0 0 0%", display: 'inline-flex', justifyContent: 'space-evenly' }}>
                                <Button variant="outlined" className={classes.sendInfoButton} onClick={() => {changeAlertMsg_cal('');getBatchData(batchID, [])}}>選擇</Button>
                            </div>
                        </div>
                        {
                            alertMsg_pf === '' ? '' :
                            <div style={{ display: 'flex', margin: "2vh 2vw", textAlign: "left" }}>
                                <p style={{fontSize: '2.1vh', color: 'red', margin: '0'}}>{alertMsg_pf}</p>
                            </div>
                        }
                    </div>
                    <div className="DataDownload_block" style={{ flexDirection: "column", minWidth: "490px", flex: '5', margin: '0 10px 10px 0' }}>
                        <p className="DataDownload_block_title_text">計算投融資組合ITR</p>
                        <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
                        <div style={{ display: 'flex', margin: "1vh 1vw 0 1vw", textAlign: "center" }}>
                            <div style={{ width: "50%", margin: "0 3% 0 1%" }}><p className={classes.blockTitle}>{`ITR資料版本: ${latest_itr || '-'}`}</p></div>
                            <div style={{ width: "50%", margin: "0 0 0 0%" }}><p className={classes.blockTitle}>過去投融資組合ITR資料</p></div>
                        </div>
                        <div className={classes.blockContent}>
                            <div style={{ width: "50%", margin: "0 3% 0 1%" }}>
                                <p className={classes.blockTitle}>{`投融資組合資料版本: ${pfDataUsed || '-'}`}</p>
                            </div>
                            <div style={{ width: "50%", margin: "0 0 0 0%" }}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <Select
                                        className={classes.select}
                                        style={{fontSize: '2vh'}}
                                        value={pfRsltBatchId}
                                        onChange={(event) => {
                                            setPfRsltBatchId(event.target.value);
                                        }}
                                    >
                                        {/* <MenuItem value="New">最新批次資料</MenuItem> */}
                                        {
                                            Object
                                                .entries(pfRsltBatchIdList)
                                                .map(([id, batchData]) => <MenuItem key={`batchIDList_${id}`} value={batchData.batch_id}>{batchData.created_time}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div style={{ display: 'flex', margin: "1vh 1vw 1vh 1vw", textAlign: "center" }}>
                            <div style={{ width: "50%", margin: "0 3% 0 1%" }}>
                                <Button variant="outlined" className={classes.sendInfoButton} onClick={() => {pf_Itr_calculate(batchID)}}>計算</Button>
                            </div>
                            <div style={{ width: "50%", margin: "0 0 0 0%" }}><Button variant="outlined" className={classes.sendInfoButton} onClick={() => {changeAlertMsg_cal('');getPfRsltBatchData(pfRsltBatchId)}}>查看</Button></div>
                        </div>
                        {
                            alertMsg_cal === '' ? '' :
                            <div style={{ display: 'flex', margin: "2vh 2vw", textAlign: "left" }}>
                                <p style={{fontSize: '2.1vh', color: 'red', margin: '0'}}>{alertMsg_cal}</p>
                            </div>
                        }
                    </div>
                </div>
                <div className="page_subtitle_container"><p className="page_subtitle_text">投資組合ITR結果</p></div>
                { !isClick ? "" :
                    <div style={{ margin: "0.5vh 0 0 1vw", fontSize: "2.5vh" }}>
                        資料計算中...
                    </div>
                }            
                {
                    (Object.values(resultData).length === 0 || isClick) ? "" :
                    <div className="blockContainer" style={{ display: "inline-block", width: "100%" }}>
                        <div className="display_header_container">
                            {
                                Object.entries(outputTabList).map(([tab_name, isDisplay]) => {
                                    if(isDisplay){
                                        return (
                                            <div key={'tab_display_'+tab_name} className={outputTab === tab_name ? 'display_header_item_active' : 'display_header_item_inactive'} onClick={() => { changeOutputTab(tab_name) }}>
                                                <p className={outputTab === tab_name ? 'display_header_item_active_text' : 'display_header_item_inactive_text'} >{result_Tab_name[tab_name]}</p>
                                            </div>
                                        )
                                    }else{
                                        return (
                                            <div key={'tab_not_display_'+tab_name} className='display_header_item_inavailable' >
                                                <p className='display_header_item_inavailable_text' >{result_Tab_name[tab_name]}</p>
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                        <div>
                            {
                                outputTab === '' ? '' :
                                outputTab === 'itr_total' ? 
                                <InvestmentITR_itr resultData={resultData['itr_total']} /> :
                                outputTab === 'pf_list' ? 
                                <InvestmentITR_pf account={account} resultData={resultData['pf_list']} typeList={typeList} ownerList={ownerList} /> :
                                outputTab === 'tgt_list' ? 
                                <InvestmentITR_tgt account={account} resultData={resultData['tgt_list']} /> :
                                <InvestmentITR_export account={account} resultData={resultData['download_list']} />
                            }
                        </div>
                    </div>
                }
            </div>
            <Menu
                account={account}
                permission={permission}
                cookies={cookies}
                removeCookie={removeCookie}
                activeScreen={activeScreen}
                switchScreen={switchScreen}
                switchShellChoice={switchShellChoice}
                changeDisplayMethod={changeDisplayMethod}
            />
        </div>
    );
}

export default InvestmentITR;