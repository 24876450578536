import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles((theme) => ({
  Validation_button: {
    width: "9.6vw",
    height: "4.8vh",
    padding: "1vh 0.7vw",
    borderRadius: "6px",
    border: "solid 1px #ffffff",
    marginRight: "2vw"
  }
}))

function DataUpload(props) {
  const classes = useStyle();

  return (
    <div>
      <Button 
        onClick={() => {}}
        className={classes.Validation_button}
      >
        <p className="process_bottom_button_text">開始上傳</p>
      </Button>
    </div>
  );
}

export default DataUpload;