import React, { useEffect, useState } from 'react';

const columnNameList = {
    'companyID': '股票代號',
    'companyID': '統一編號',
    'companyName': '公司名稱',
    'companyNameSys': '公司名稱（系統帶入）',
    'targetReportYear': '目標宣告年',
    'targetBaseYear': '目標基準年',
    'targetYear': '目標年度',
    'periodChoice': '目標時期',
    'scopeChoice': '目標範疇',
    'targetCoverage': '目標覆蓋率',
    'degreeOfReduction': '減量程度',
    'targetType': '目標型態',
    'itr': '溫度分數',
    'created_time': '修改時間',
}

function InvestmentITR_tgt(props) {
    const {
        resultData
    } = props

    const [ columnWidth, changeColumnWidth ] = useState({})

    // console.log(resultData)


    useEffect(() => {
        const getColumnWidth = () => {
            let newColumnWidth = {}
            Object.entries(columnNameList).forEach(([key, name]) => {
                const dataLengthList = resultData.map((record_element) => {
                    return(String(record_element[key]).length)
                })
                
                newColumnWidth[key] = `${Math.max(name.length*20, Math.max(...dataLengthList)*12+60)}px`
            })
            changeColumnWidth(newColumnWidth)
        }
        
        getColumnWidth()
    }, [resultData])
    return ( 
        <div className='blockContent' style={{display: 'flex', border: '1px solid', flexDirection: 'column', height: 'fit-content', overflowX: 'scroll'}}>
            <div className="table_row_container table_title">
                {
                    Object.entries(columnNameList).map(([key, value]) => (
                        <div 
                            key={`column_title_${key}`}
                            className="table_title_container" 
                            style={{width: `${columnWidth[key]}`, display: 'inline-flex'}}
                            >
                            <p 
                                key={`title_column_${key}`} 
                                className="table_title_text" 
                            >{value}</p>
                        </div>
                    ))
                }
            </div>
            {
                resultData.map((value, index) => (
                    <div className="table_row_container" key={`record_${index}`} >
                        {
                            Object.entries(columnNameList).map(([key, column_name]) => (
                                <p 
                                    key={`record_${index}_column_${key}`} 
                                    className="table_text"
                                    style={{
                                        width: `${columnWidth[key]}`
                                        }}>{value[key]}
                                </p>))
                        }
                    </div>
                ))
            }
        </div>
    );
}

export default InvestmentITR_tgt;