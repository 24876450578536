import React, { useEffect, useState } from 'react';
import { VictoryChart, VictoryBar, VictoryAxis, VictoryScatter, VictoryLine, VictoryTooltip, VictoryVoronoiContainer, VictoryGroup } from 'victory';
import '../../style/VictoryScatterChart.css';

const numberFormat = (value) => {
    if(typeof value === 'number'){
        return Number(value).toLocaleString('en')
    }else{
        return value
    }
}

function VictoryScatterChart(props) {

    const {
        title,
        label,
        data,
        unit,
        style,
        dependent_chart,
        dependent_chart_offset,
    } = props

    const [ MAIN_X_AXIS_SCALE, set_MAIN_X_AXIS_SCALE ] = useState(1)
    const [ MAIN_Y_AXIS_SCALE, set_MAIN_Y_AXIS_SCALE ] = useState(1)
    const [ DEPENDENT_Y_AXIS_SCALE, set_DEPENDENT_Y_AXIS_SCALE ] = useState(1)

    useEffect(() => {
        if(dependent_chart['axis'] !== undefined){
            let x_scale
            let main_scale
            let dependent_scale
            let x
            let a
            let b
            if(dependent_chart['axis']['independent']){
                a = Math.max(...data.Y) / Math.pow(10, Math.ceil(Math.log10(Math.max(...data.Y))))
                b = Math.max(...dependent_chart['data'].Y) / Math.pow(10, Math.ceil(Math.log10(Math.max(...dependent_chart['data'].Y))))
                
                if(a < 0.5 && b < 0.5){
                    main_scale = Math.pow(10, Math.ceil(Math.log10(Math.max(...data.Y)))) / 2
                    dependent_scale = Math.pow(10, Math.ceil(Math.log10(Math.max(...dependent_chart['data'].Y)))) / 2
                }else{
                    main_scale = Math.pow(10, Math.ceil(Math.log10(Math.max(...data.Y))))
                    dependent_scale = Math.pow(10, Math.ceil(Math.log10(Math.max(...dependent_chart['data'].Y))))
                }
            }else{
                a = Math.max(...data.Y, ...dependent_chart['data'].Y) / Math.pow(10, Math.ceil(Math.log10(Math.max(...data.Y, ...dependent_chart['data'].Y))))
                if(a < 0.5){
                    main_scale = Math.pow(10, Math.ceil(Math.log10(Math.max(...data.Y, ...dependent_chart['data'].Y)))) / 2
                }else{
                    main_scale = Math.pow(10, Math.ceil(Math.log10(Math.max(...data.Y, ...dependent_chart['data'].Y))))
                }
                dependent_scale = main_scale
            }

            x = Math.max(...data.X, ...dependent_chart['data'].X) / Math.pow(10, Math.ceil(Math.log10(Math.max(...data.X, ...dependent_chart['data'].X))))
            if(x < 0.5){
                x_scale = Math.pow(10, Math.ceil(Math.log10(Math.max(...data.X, ...dependent_chart['data'].X)))) / 2
            }else{
                x_scale = Math.pow(10, Math.ceil(Math.log10(Math.max(...data.X, ...dependent_chart['data'].X))))
            }

            // console.log(main_scale)
            // console.log(dependent_scale)
            // console.log(x_scale)
            set_MAIN_Y_AXIS_SCALE(main_scale)
            set_DEPENDENT_Y_AXIS_SCALE(dependent_scale)
            set_MAIN_X_AXIS_SCALE(x_scale)
        }else{
            let main_scale
            const a = Math.max(...data.Y) / Math.pow(10, Math.ceil(Math.log10(Math.max(...data.Y))))
            if(a < 0.5){
                main_scale = Math.pow(10, Math.ceil(Math.log10(Math.max(...data.Y)))) / 2
            }else{
                main_scale = Math.pow(10, Math.ceil(Math.log10(Math.max(...data.Y))))
            }
            set_MAIN_Y_AXIS_SCALE(main_scale)

            let x_scale
            const x = Math.max(...data.X) / Math.pow(10, Math.ceil(Math.log10(Math.max(...data.X))))
            if(x < 0.5){
                x_scale = Math.pow(10, Math.ceil(Math.log10(Math.max(...data.X)))) / 2
            }else{
                x_scale = Math.pow(10, Math.ceil(Math.log10(Math.max(...data.X))))
            }
            // console.log(x_scale)
            set_MAIN_X_AXIS_SCALE(x_scale)
        }
    }, [])

    return ( 
        <div className="scatter_chart_container">
            <p style={{ margin: '0', fontSize: '22px', fontWeight: 'bold', textAlign: 'center', color: 'gray' }}>{`${title}`}</p>
            <VictoryChart
                domainPadding={{x: 60, y: 40}}
                domain={{x: [0, 1], y: [0, 1]}}
                padding={{left: 70, right: 70, top: 20, bottom: 50}}
                containerComponent={<VictoryVoronoiContainer/>}
            >
                {
                    typeof data.X[0] === 'number' ? 
                    <VictoryAxis
                        // tickValues specifies both the number of ticks and where
                        // they are placed on the axis
                        tickValues={[0.25, 0.5, 0.75, 1]}
                        tickFormat={(t)=>(numberFormat(Math.round(t*MAIN_X_AXIS_SCALE*1000000)/1000000))}
                        label={`${label.x}${unit.x === '' ? '':'（'+unit.x+'）'}`}
                        style={{
                            axis: { stroke: "#6a6a6a" },
                            axisLabel: { fontSize: 18, padding: 30, fill: '#6a6a6a', fontWeight: 'bold' },
                            ticks: { stroke: "#6a6a6a", size: 5 },
                            tickLabels: { fontSize: 14, padding: 5, fill: '#6a6a6a' }
                        }}
                    /> :
                    <VictoryAxis
                        // tickValues specifies both the number of ticks and where
                        // they are placed on the axis
                        label={`${label.x}${unit.x === '' ? '':'（'+unit.x+'）'}`}
                        style={{
                            axis: { stroke: "#6a6a6a" },
                            axisLabel: { fontSize: 18, padding: 30, fill: '#6a6a6a', fontWeight: 'bold' },
                            ticks: { stroke: "#6a6a6a", size: 5 },
                            tickLabels: { fontSize: 14, padding: 5, fill: '#6a6a6a' }
                        }}
                    />
                }
                <VictoryAxis
                    dependentAxis
                    tickValues={[0.2, 0.4, 0.6, 0.8, 1]}
                    tickFormat={(t)=>(numberFormat(t*MAIN_Y_AXIS_SCALE))}
                    label={`${label.y}${unit.y === '' ? '':'（'+unit.y+'）'}`}
                    style={{
                        axis: { stroke: "#797979" },
                        axisLabel: { fontSize: 18, padding: 50, fill: style["label_color"] || 'gray', fontWeight: 'bold' },
                        ticks: { stroke: "#797979", size: 5 },
                        tickLabels: { fontSize: 14, padding: 5, fill: style["label_color"] || 'gray' }
                    }}
                />
                {
                    // dependent chart Axis
                    Object.keys(dependent_chart).length === 0 ? '' :
                    <VictoryAxis
                        dependentAxis
                        orientation="right"
                        tickValues={[0.2, 0.4, 0.6, 0.8, 1]}
                        tickFormat={(t)=>(numberFormat(Math.round(t*DEPENDENT_Y_AXIS_SCALE*1000000)/1000000))}
                        label={`${dependent_chart['label'].y}${dependent_chart['unit'].y === '' ? '':'（'+dependent_chart['unit'].y+'）'}`}
                        style={{
                            axis: { stroke: dependent_chart.axis.display ? "#797979" : "transparent" },
                            axisLabel: { fontSize: 18, padding: 50, fill: dependent_chart.axis.display ? dependent_chart["style"]["label_color"] || "gray" : "transparent", fontWeight: 'bold' },
                            ticks: { stroke: dependent_chart.axis.display ? "#797979" : "transparent", size: 5 },
                            tickLabels: { fontSize: 14, padding: 5, fill: dependent_chart.axis.display ? dependent_chart["style"]["label_color"] || "gray" : "transparent" }
                        }}
                    />
                }
                <VictoryGroup
                    offset={dependent_chart_offset}
                >
                    <VictoryScatter
                        style={{ 
                            data: { fill: style["fill_color"] || "gray" }, 
                            labels: {
                                fill: style["fill_color"],
                                fontWeight: 'bold'
                            }
                        }}
                        size={({ active }) => active ? 5 : 3}
                        data={data.X.map((value, index) => {
                            return ({ 'x': value, 'y': Math.round(data.Y[index]*10000)/10000, 'label': data.label[index]?.toString() })
                        })}
                        // labels={d => d.label}
                        labelComponent={
                            <VictoryTooltip
                                flyoutStyle={{
                                    fill: 'transparent',
                                    stroke: 'none',
                                }}
                            />
                        }
                        x={(datum) => typeof data.X[0] === 'number' ? (datum.x / MAIN_X_AXIS_SCALE) : (datum.x)} 
                        y={(datum) => datum.y / MAIN_Y_AXIS_SCALE} // normalized data
                    />
                    {
                        /* dependent chart */
                        dependent_chart['chartType'] === 'scatter' ?
                        <VictoryScatter
                            style={{ 
                                data: { fill: dependent_chart["style"]["fill_color"] || "gray" },
                                labels: {
                                    fill: dependent_chart["style"]["fill_color"] || "gray",
                                    fontWeight: 'bold'
                                }
                            }}
                            size={({ active }) => active ? 5 : 3}
                            data={dependent_chart['data'].X.map((value, index) => {
                                return ({ 'x': value, 'y': Math.round(dependent_chart['data'].Y[index]*10000)/10000, 'label': dependent_chart['data'].label[index]?.toString() })
                            })}
                            labelComponent={
                                <VictoryTooltip
                                    flyoutStyle={{
                                        fill: 'transparent',
                                        stroke: 'none',
                                    }}
                                />
                            }
                            x={(datum) => typeof data.X[0] === 'number' ? (datum.x / MAIN_X_AXIS_SCALE) : (datum.x)} 
                            y={(datum) => datum.y / DEPENDENT_Y_AXIS_SCALE} // normalized data
                        /> :
                        dependent_chart['chartType'] === 'bar' ?
                        <VictoryBar
                            style={{ 
                                data: { fill: dependent_chart["style"]["fill_color"] || "gray" },
                                labels: {
                                    fill: dependent_chart["style"]["fill_color"] || "gray",
                                    fontWeight: 'bold'
                                }
                            }}
                            size={({ active }) => active ? 5 : 3}
                            data={dependent_chart['data'].X.map((value, index) => {
                                return ({ 'x': String(value), 'y': Math.round(dependent_chart['data'].Y[index]*10000)/10000, 'label': dependent_chart['data'].label[index]?.toString() })
                            })}
                            labelComponent={
                                <VictoryTooltip
                                    flyoutStyle={{
                                        fill: 'transparent',
                                        stroke: 'none',
                                    }}
                                />
                            }
                            x={(datum) => typeof data.X[0] === 'number' ? (datum.x / MAIN_X_AXIS_SCALE) : (datum.x)} 
                            y={(datum) => datum.y / DEPENDENT_Y_AXIS_SCALE}
                        /> :
                        dependent_chart['chartType'] === 'line' ?
                        <VictoryGroup>
                            <VictoryLine
                                style={{ 
                                    data: {
                                        fill: dependent_chart["style"]["fill_color"] || "gray",
                                        stroke: dependent_chart["style"]["fill_color"] || "gray",
                                    },
                                    labels: {
                                        fill: dependent_chart["style"]["fill_color"] || "gray",
                                        fontWeight: 'bold'
                                    }
                                }}
                                size={({ active }) => active ? 5 : 3}
                                data={dependent_chart['data'].X.map((value, index) => {
                                    return ({
                                        'x': String(value),
                                        'y': Math.round(dependent_chart['data'].Y[index]*10000)/10000,
                                        'label': dependent_chart['data'].label[index]?.toString(),
                                    })
                                })}
                                labelComponent={
                                    <VictoryTooltip
                                        flyoutStyle={{
                                            fill: 'transparent',
                                            stroke: 'none',
                                        }}
                                    />
                                }
                                x={(datum) => typeof data.X[0] === 'number' ? (datum.x / MAIN_X_AXIS_SCALE) : (datum.x)} 
                                y={(datum) => datum.y / DEPENDENT_Y_AXIS_SCALE}
                            />
                            <VictoryScatter
                                style={{ 
                                    data: {
                                        fill: dependent_chart["style"]["fill_color"] || "gray",
                                    },
                                    labels: {
                                        fill: 'transparent',
                                    }
                                }}
                                size={6}
                                data={dependent_chart['data'].X.map((value, index) => {
                                    return ({
                                        'x': String(value),
                                        'y': Math.round(dependent_chart['data'].Y[index]*10000)/10000,
                                    })
                                })}
                            />
                        </VictoryGroup>: ''
                    }
                </VictoryGroup>
            </VictoryChart>
        </div>
    );
}

export default VictoryScatterChart;