import React, { useState, useEffect } from 'react';
import "../style/style.css";
import "../style/EnterpriseESGReport.css";
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Grid from "@material-ui/core/Grid";
import Switch from '@material-ui/core/Switch';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from './Menu';
import AppConfigN from '../const/AppConfigN.const';
import VictoryScatterChart from '../component/VictoryScatterChart/VictoryScatterChart';
import VictoryBarChart from '../component/VictoryBarChart/VictoryBarChart';
import GaugeChart from '../component/GaugeChart/GaugeChart';
import '../style/SingleTargetITR.css';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputAdornment from '@material-ui/core/InputAdornment';

const nowYear = new Date().getFullYear()

const linechart_legend_text = ['< 1.5°C', '1.5°C~2°C', '2°C~2.6°C', '2.6°C~3.2°C', '> 3.2°C']
const linechart_legend_color_ITR = ["#0a75ad", "#4fb39f", "#F0c51f", "#FF5823", "#b70000"]
const linechart_legend_label_ITR = ["A", 'B', "C", "D", "E"]
const gauge_legend_color_CE_rgb = [[79, 179, 159], [240, 197, 31], [255, 88, 35], [183, 0, 0]]
// const color_0 = "rgb(79, 179, 159)"
// const color_33 = "rgb(240, 197, 31)"
// const color_67 = "rgb(255, 88, 35)"
// const color_100 = "rgb(183, 0, 0)"
const ITR_period_choice_text = {
    "SHORT": "短期",
    "MID": "中期",
    "LONG": "長期",
}

const ITR_scope_choice_text = {
    "1": "範疇一",
    "1+2": "範疇一+二",
    "1+2+3": "範疇一+二+三",
    "2": "範疇二",
    "3": "範疇三",
}

const getGredientColor = (color_legend, data) => { // 0 < data < 1
    const num_of_legend = color_legend.length-1
    if(data%(1/num_of_legend) < Math.pow(10, -5)){ // no gredient
        const color_id = data/(1/num_of_legend)
        // console.log(`rgb(${color_legend[color_id][0]}, ${color_legend[color_id][1]}, ${color_legend[color_id][2]})`)
        return `rgb(${color_legend[color_id][0]}, ${color_legend[color_id][1]}, ${color_legend[color_id][2]})`
    }else{
        const range = parseInt(data/(1/num_of_legend))
        // console.log(range)
        // console.log(data)
        const R_value = parseInt((color_legend[range][0]*((range+1)*(1/num_of_legend) - data) + color_legend[range+1][0]*(data - (range)*(1/num_of_legend)))*num_of_legend)
        const G_value = parseInt((color_legend[range][1]*((range+1)*(1/num_of_legend) - data) + color_legend[range+1][1]*(data - (range)*(1/num_of_legend)))*num_of_legend)
        const B_value = parseInt((color_legend[range][2]*((range+1)*(1/num_of_legend) - data) + color_legend[range+1][2]*(data - (range)*(1/num_of_legend)))*num_of_legend)
        // console.log(`rgb(${R_value}, ${G_value}, ${B_value})`)
        return `rgb(${R_value}, ${G_value}, ${B_value})`
    }
}

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin:'0 10px',
        minHeight: '3.5vh',
        flex: 1,
    },
    blockContent: {
        margin: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-arround",
        height: "100%",
        textAlign: "center",
    },
    text: {
        fontSize: "2vh",
        fontWeight: 'bold',
        color: 'black',
        margin: 0,
        lineHeight: '4vh',
        width: '40%',
        textAlign: 'left',
    },
    input: {
        height: "4.5vh",
        width: "10vw",
        margin: '0vh 0.5vw 0vh 0vh',
        paddingLeft: '0.3vw',
        borderRadius: '5px',
        borderWidth: 'thin',
        borderColor: '#b6b6b6',
        borderStyle: 'solid',
        fontSize: "2vh",
        textAlign: 'center',
    },
    sendInfoButton: {
        height: "4.9vh",
        fontSize: "2vh",
        padding: '5px',
    },
    ITR_info_title: {
        fontSize: '20px',
        color: '#2e2e2e',
    },
    switchItemContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        height: '5vh',
        justifyContent: 'space-around',
    },
    choiceFormControl: {
        paddingBottom: '2vh',
        width: '15vw',
        textAlign: 'center',
        margin: '0 20px',
    },
}))

const numberFormat = (value) => {
    if(typeof value === 'number'){
        return Number(value).toLocaleString('en')
    }else{
        return value
    }
}

function EnterpriseESGReport(props) {
    const classes = useStyles();

    const [companyID, setCompanyID] = useState("");
    const [companyData, changeCompanyData] = useState({})
    const [outputTab, changeOutputTab] = useState('')
    const [periodChoice, changePeriodChoice] = useState('')
    const [scopeChoice, changeScopeChoice] = useState('')
    const [alertMessage, changeAlertMessage] = useState('')

    const {
        account,
        permission,
        cookies,
        removeCookie,
        activeScreen,
        switchScreen,
        switchShellChoice,
        changeDisplayMethod,
    } = props


    const getCompanyData = () => {
        if(companyID !== ''){
            changeCompanyData({})
            changeOutputTab('')
            changeAlertMessage('')
            changePeriodChoice('')
            changeScopeChoice('')
            let status
            // console.log(companyID)
            fetch(`${AppConfigN.SERVER_URL}/api/esg_co_emis_itr`, {
                method: 'POST',
                headers: {
                    "Authorization": `Token ${account.token}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    companyID: companyID,
                })
            })
            .then((response) => {
                // console.log(response)
                status = response.ok
                return response.json()
            })
            .then((responseJson) => {
                if(status){
                    console.log(responseJson)
                    changeCompanyData(responseJson)
                    changeOutputTab(Object.keys(responseJson['outputTabList'])[0])
                }else{
                    changeAlertMessage('請填入8位統編或4位股票代碼或6位股票代碼')
                }
            })
            .catch((error) => {
                console.error(error);
            });
        }else{
            changeAlertMessage('請填入8位統編或4位股票代碼或6位股票代碼');
        }
    }

    useEffect(() => {})

    return ( 
        <div className="new_home_mainContainer">
            {
                companyData['company'] === undefined ?
                <div className="page_container">
                    <div className="page_title_container"><p className="page_title_text">ESG資訊查詢</p></div>
                    <div style={{ display: "inline-flex", flexWrap: 'wrap', justifyContent: "space-between", width: 'calc(100% + 10px)' }}>
                        <div className="DataDownload_block" style={{ flexDirection: "column", maxWidth: "420px", flex:3, margin: '0 10px 10px 0' }}>
                            <p className="DataDownload_block_title_text">企業資料輸入</p>
                            <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
                            <div className={classes.blockContent}>
                                <input
                                    className={classes.input}
                                    type="text"
                                    placeholder="  輸入統編/股票代碼"
                                    value={companyID}
                                    onChange={(event) => {
                                        setCompanyID(event.target.value);
                                    }}
                                />
                                <Button variant="outlined" className={classes.sendInfoButton} onClick={() => {getCompanyData()}}>查詢</Button>
                                {/* <div>
                                    <input
                                        className={classes.input}
                                        type="text"
                                        placeholder="  輸入統編/股票代碼"
                                        value={companyID}
                                        onChange={(event) => {
                                            setCompanyID(event.target.value);
                                        }}
                                    />
                                    <Button variant="outlined" className={classes.sendInfoButton} onClick={() => {getCompanyData()}}>查詢</Button>
                                </div> */}
                               
                            </div>
                            <p style={{fontSize: '18px', color: 'red', fontWeight: 'bold', margin: '0 20px 20px'}}>{alertMessage}</p>
                        </div>
                    </div>
                </div>
                : <div className="page_container">
                    <div className="page_title_container"><p className="page_title_text">ESG資訊查詢</p></div>
                    <div style={{ display: "inline-flex", flexWrap: 'wrap', justifyContent: "space-between", width: 'calc(100% + 10px)' }}>
                        <div className="DataDownload_block" style={{ flexDirection: "column", minWidth: "300px", flex:3, margin: '0 10px 10px 0' }}>
                            <p className="DataDownload_block_title_text">企業資料輸入</p>
                            <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
                            <div className={classes.blockContent}>
                                <input
                                    className={classes.input}
                                    type="text"
                                    placeholder="輸入統編/股票代碼"
                                    value={companyID}
                                    onChange={(event) => {
                                        setCompanyID(event.target.value);
                                    }}
                                />
                                <Button variant="outlined" className={classes.sendInfoButton} onClick={() => {getCompanyData()}}>查詢</Button>
                            </div>
                            <p style={{fontSize: '18px', color: 'red', fontWeight: 'bold', margin: '0 20px 20px'}}>{alertMessage}</p>
                        </div>
                        <div className="DataDownload_block" style={{ flexDirection: "column", minWidth: "300px", flex:3, margin: '0 10px 10px 0' }}>
                            <p className="DataDownload_block_title_text">企業名稱</p>
                            <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
                            <div className={classes.blockContent} style={{ fontSize: "3vh", fontWeight: "bold" }}>
                                {companyData['company']}
                            </div>
                        </div>
                        <div className="DataDownload_block" style={{ flexDirection: "column", minWidth: "300px", flex:3, margin: '0 10px 10px 0' }}>
                            <p className="DataDownload_block_title_text">產業別</p>
                            <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
                            <div className={classes.blockContent} style={{ fontSize: "3vh", fontWeight: "bold" }}>
                                {`第${companyData['industry_id']}類 ${companyData['industry']}`}
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "inline-flex", flexWrap: 'wrap', justifyContent: "space-between", width: 'calc(100% + 10px)' }}>
                        <div className="DataDownload_block" style={{ flexDirection: "column", minWidth: "425px", flex:3, margin: '0 10px 10px 0' }}>
                            <div style={{display: 'inline-flex', justifyContent: 'space-around'}}>
                                <p className="DataDownload_block_title_text">碳排放資訊</p>
                                <p className="DataDownload_block_title_text">{`${companyData['CEInfoYear'] || ''}年資料`}</p>  
                            </div>
                            <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
                            <div className={classes.blockContent} style={{flexDirection: 'column'}}>
                                {
                                    companyData['carbonEmissionInfo'].map((item, index) => (
                                        <div style={{display: 'inline-flex', width: '100%'}}>
                                            <p className={classes.text} style={{margin: '0 auto 0 5%'}}>{item['label']}</p>
                                            <p className={classes.text} style={{margin: '0 5% 0 auto', textAlign: 'right', width: '60%'}}>{numberFormat(item['value'])}{'  '}{item['unit'] || ''}</p>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className="DataDownload_block" style={{ flexDirection: "column", minWidth: "500px", flex:7, margin: '0 10px 10px 0' }}>
                            <p className="DataDownload_block_title_text">ITR資訊</p>
                            <div style={{ height: "0", borderTop: "solid 0.13vh #979797", opacity: '0.13' }}></div>
                            <div className={classes.blockContent} style={{ width: '96%', fontSize: "3vh", fontWeight: "bold", display: 'inline-flex', margin: '0 2%', flexWrap: 'wrap' }}>
                                <div style={{height: 'fit-content', textAlign: 'center', minWidth: '200px', maxWidth: '350px', flex: 1}}>
                                    <GaugeChart 
                                        arcsLength={[1.5, 0.5, 0.6, 0.6, 1.8]}
                                        color='#2e2e2e'
                                        data={companyData["ITR"]["ITR"]*100/5}
                                        colorLegend={linechart_legend_color_ITR}
                                        hideText
                                        nrOfLevels={5}
                                        arcWidth={0.3}
                                        arcPadding={0.03}
                                        cornerRadius={4}
                                    />
                                    <p style={{fontSize: '28px', fontWeight: 'bold', margin: '0', color: '#2e2e2e'}}>{`${Math.round(companyData["ITR"]["ITR"]*10)/10}°C`}</p>
                                    <div style={{ margin: "auto", textAlign: "center" }}>
                                        <p className={classes.ITR_info_title}>{`${'中期目標 範疇1+2+3 ITR'}`}</p>
                                    </div>
                                </div>
                                <div style={{ textAlign: 'left', minWidth: '200px', maxWidth: '300px', flex: 1, margin: 'auto', paddingLeft: '20px'}}>
                                {linechart_legend_color_ITR.map((color, index) => (
                                    <div key={`linchart-legend_${color}`} className="linechart-legend-item" style={{maxWidth: '46%'}}>
                                        <div className="linechart-legend-colorLabel" style={{backgroundColor: color}} />
                                        <p className="linechart-legend-text" style={{fontWeight: 'bold', margin: '0 10px 0 0'}}>{`${linechart_legend_label_ITR[index]}(${linechart_legend_text[index]})`}</p>
                                    </div>
                                ))}
                                </div>
                                <div style={{ textAlign: 'left', minWidth: '320px', maxWidth: '400px', flex: 2, margin: '10px auto', padding: '0 10px', background: '#f2f2f2'}}>
                                    <div className={classes.switchItemContainer}>
                                        <div className={classes.text} style={{margin: 'auto', flex: 3}}>是否設定減碳目標</div>
                                        <Grid component="label" container alignItems="center" spacing={1} style={{flex: 2, minWidth: '121px'}}>
                                            <Grid item style={{fontSize: '2vh'}}>否</Grid>
                                            <Grid item>
                                                <Switch
                                                    checked={companyData['ITR']['target_yn']}
                                                    onChange={() => {}}
                                                    color='primary'
                                                    // disabled
                                                />
                                            </Grid>
                                            <Grid item style={{fontSize: '2vh'}}>是</Grid>
                                        </Grid>
                                    </div>
                                    <div className={classes.switchItemContainer}>
                                        <div className={classes.text} style={{margin: 'auto', flex: 3}}>目標是否經SBT認證</div>
                                        <Grid component="label" container alignItems="center" spacing={1} style={{flex: 2, minWidth: '121px'}}>
                                            <Grid item style={{fontSize: '2vh'}}>否</Grid>
                                            <Grid item>
                                                <Switch
                                                    checked={companyData['ITR']['sbt_yn']}
                                                    onChange={() => {}}
                                                    color='primary'
                                                    // disabled
                                                />
                                            </Grid>
                                            <Grid item style={{fontSize: '2vh'}}>是</Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="blockContainer" style={{ display: "inline-block", width: "100%" }}>
                        <div className="display_header_container">
                            {
                                Object.entries(companyData["outputTabList"]).map(([tab_ID, tab_name]) => {
                                    return (
                                        <div key={'tab_'+tab_ID} className={outputTab === tab_ID ? 'display_header_item_active' : 'display_header_item_inactive'} onClick={() => { changeOutputTab(tab_ID) }}>
                                            <p className={outputTab === tab_ID ? 'display_header_item_active_text' : 'display_header_item_inactive_text'} >{tab_name}</p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div>
                            {
                                outputTab === 'CE_compare' ? 
                                <div className='tab_container'>
                                    {
                                        companyData['outputTab_data']['CE_compare']['chartData'].map((chart_item, index) => {
                                            if(chart_item['chartType'] === 'scatter'){
                                                return(
                                                    <div key={`chart_item_${index}`} className='chart_container' style={{minWidth: '450px', maxWidth: '550px'}}>
                                                        <VictoryScatterChart
                                                            title={chart_item['title']}
                                                            label={chart_item['label']}
                                                            data={chart_item['data']}
                                                            unit={chart_item['unit']}
                                                            style={chart_item['style']}
                                                            dependent_chart={chart_item['dependent_chart']}
                                                            dependent_chart_offset={0}
                                                        />
                                                    </div>
                                                )
                                            }else if(chart_item['chartType'] === 'gauge'){
                                                return(
                                                    <div key={`chart_item_${index}`} className='chart_container' style={{minWidth: '350px', maxWidth: '400px'}}>
                                                        <GaugeChart
                                                            title={chart_item['title']}
                                                            subtitle={chart_item['subtitle']}
                                                            arcsLength={chart_item['arcsLength']}
                                                            color='gray'
                                                            data={chart_item['data']}
                                                            colorLegend={
                                                                [
                                                                    getGredientColor(gauge_legend_color_CE_rgb, chart_item['data']/100),
                                                                    "lightgray"
                                                                ]
                                                            }
                                                            hideText={false}
                                                            max={numberFormat(chart_item['label']['max'])}
                                                            min={numberFormat(chart_item['label']['min'])}
                                                            nrOfLevels={chart_item['arcsLength'].length}
                                                            arcWidth={0.3}
                                                            arcPadding={0}
                                                            cornerRadius={0}
                                                        />
                                                    </div>
                                                )
                                            }else if(chart_item['chartType'] === 'bar'){
                                                return(
                                                    <div key={`chart_item_${index}`} className='chart_container'>
                                                        <VictoryBarChart
                                                            title={chart_item['title']}
                                                            label={chart_item['label']}
                                                            data={chart_item['data']}
                                                            unit={chart_item['unit']}
                                                            style={chart_item['style']}
                                                            dependent_chart={chart_item['dependent_chart'] || ''}
                                                            dependent_chart_offset={0}
                                                        />
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div> :
                                outputTab === 'CE_history' ?
                                <div className='tab_container'>
                                    {
                                        companyData['outputTab_data']['CE_history']['chartData'].map((chart_item) => {
                                            if(chart_item['chartType'] === 'scatter'){
                                                return(
                                                    <div className='chart_container' style={{minWidth: '450px', maxWidth: '550px'}}>
                                                        <VictoryScatterChart
                                                            title={chart_item['title']}
                                                            label={chart_item['label']}
                                                            data={chart_item['data']}
                                                            unit={chart_item['unit']}
                                                            style={chart_item['style']}
                                                            dependent_chart={chart_item['dependent_chart']}
                                                            dependent_chart_offset={0}
                                                        />
                                                    </div>
                                                )
                                            }else if(chart_item['chartType'] === 'gauge'){
                                                return(
                                                    <div className='chart_container' style={{minWidth: '350px', maxWidth: '400px'}}>
                                                        <GaugeChart
                                                            title={chart_item['title']}
                                                            subtitle={chart_item['subtitle']}
                                                            arcsLength={chart_item['arcsLength']}
                                                            color='gray'
                                                            data={chart_item['data']}
                                                            colorLegend={linechart_legend_color_ITR}
                                                            hideText={false}
                                                            max={numberFormat(chart_item['label']['max'])}
                                                            min={numberFormat(chart_item['label']['min'])}
                                                            nrOfLevels={chart_item['arcsLength'].length}
                                                            arcWidth={0.3}
                                                            arcPadding={0}
                                                            cornerRadius={0}
                                                        />
                                                    </div>
                                                )
                                            }else if(chart_item['chartType'] === 'bar'){
                                                return(
                                                    <div className='chart_container' style={{minWidth: '350px', maxWidth: '600px'}}>
                                                        <VictoryBarChart
                                                            title={chart_item['title']}
                                                            label={chart_item['label']}
                                                            data={chart_item['data']}
                                                            unit={chart_item['unit']}
                                                            style={chart_item['style']}
                                                            dependent_chart={chart_item['dependent_chart'] || ''}
                                                            dependent_chart_offset={0}
                                                        />
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div> :
                                outputTab === 'ITR_and_Target' ? 
                                <div className='tab_container'>
                                    <div className="blockContent outputTable-container" style={{flex:1, maxWidth: '500px'}}>
                                        <div className="outputTable" style={{minWidth: '280px'}}>
                                            <div className="outputTable-row">
                                                <p className="outputTable-row-cell">公司名稱</p>
                                                <p className="outputTable-row-cell">範疇1+2</p>
                                                <p className="outputTable-row-cell">範疇3</p>
                                                <p className="outputTable-row-cell">範疇1+2+3</p>
                                            </div>
                                            <div className="outputTable-row">
                                                <p className="outputTable-row-cell">短期</p>
                                                <p className="outputTable-row-cell">{Math.round(companyData['outputTab_data']['ITR_and_Target']["ITR-overview"]["SHORT"]["1+2"]*100)/100}</p>
                                                <p className="outputTable-row-cell">{Math.round(companyData['outputTab_data']['ITR_and_Target']["ITR-overview"]["SHORT"]["3"]*100)/100}</p>
                                                <p className="outputTable-row-cell">{Math.round(companyData['outputTab_data']['ITR_and_Target']["ITR-overview"]["SHORT"]["1+2+3"]*100)/100}</p>
                                            </div>
                                            <div className="outputTable-row">
                                                <p className="outputTable-row-cell">中期</p>
                                                <p className="outputTable-row-cell">{Math.round(companyData['outputTab_data']['ITR_and_Target']["ITR-overview"]["MID"]["1+2"]*100)/100}</p>
                                                <p className="outputTable-row-cell">{Math.round(companyData['outputTab_data']['ITR_and_Target']["ITR-overview"]["MID"]["3"]*100)/100}</p>
                                                <p className="outputTable-row-cell">{Math.round(companyData['outputTab_data']['ITR_and_Target']["ITR-overview"]["MID"]["1+2+3"]*100)/100}</p>
                                            </div>
                                            <div className="outputTable-row">
                                                <p className="outputTable-row-cell">長期</p>
                                                <p className="outputTable-row-cell">{Math.round(companyData['outputTab_data']['ITR_and_Target']["ITR-overview"]["LONG"]["1+2"]*100)/100}</p>
                                                <p className="outputTable-row-cell">{Math.round(companyData['outputTab_data']['ITR_and_Target']["ITR-overview"]["LONG"]["3"]*100)/100}</p>
                                                <p className="outputTable-row-cell">{Math.round(companyData['outputTab_data']['ITR_and_Target']["ITR-overview"]["LONG"]["1+2+3"]*100)/100}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{flex: 3, minWidth: '690px'}}>
                                        <div className="blockContent">
                                            <FormControl variant='standard' className={classes.choiceFormControl}>
                                                <InputLabel style={{fontSize:'2vh', fontWeight: 'bold'}}>未來時期選擇</InputLabel>
                                                <Select
                                                    disabled={Object.keys(companyData['outputTab_data']['ITR_and_Target']["ITR-detail"]).length === 0 ? true : false}
                                                    style={{height: '5vh', fontSize: '2vh'}}
                                                    value={periodChoice}
                                                    onChange={(event)=>{changeScopeChoice('');changePeriodChoice(event.target.value)}}
                                                >
                                                {/* <MenuItem value={""}>請選擇未來時期</MenuItem> */}
                                                {
                                                    Object.keys(companyData['outputTab_data']['ITR_and_Target']["ITR-detail"]).map((value, index) => (
                                                        <MenuItem key={`ITR_period_choice_${index}`} style={{fontSize: '1.8vh'}} value={value}>{ITR_period_choice_text[value]}</MenuItem>
                                                    ))
                                                }
                                                </Select>
                                            </FormControl>
                                            <FormControl variant='standard' className={classes.choiceFormControl}>
                                                <InputLabel style={{fontSize:'2vh', fontWeight: 'bold'}}>範疇選擇</InputLabel>
                                                <Select
                                                    disabled={periodChoice === '' ? true : false}
                                                    style={{height: '5vh', fontSize: '2vh'}}
                                                    value={scopeChoice}
                                                    onChange={(event)=>{changeScopeChoice(event.target.value)}}
                                                >
                                                {/* <MenuItem value={""}>請選擇未來時期</MenuItem> */}
                                                {
                                                    companyData['outputTab_data']['ITR_and_Target']["ITR-detail"][periodChoice] === undefined ? ''
                                                    :Object.keys(companyData['outputTab_data']['ITR_and_Target']["ITR-detail"][periodChoice]).map((value, index) => (
                                                        <MenuItem key={`ITR_scope_choice_${index}`} style={{fontSize: '1.8vh'}} value={value}>{ITR_scope_choice_text[value]}</MenuItem>
                                                    ))
                                                }
                                                </Select>
                                            </FormControl>
                                        </div>
                                        {
                                            Object.keys(companyData['outputTab_data']['ITR_and_Target']["ITR-detail"]).length === 0
                                            ? <div className="blockContent"><p style={{margin: '0 20px', fontSize: '20px', color: 'red'}}>目前無設立減碳目標</p></div> : ''
                                        }
                                        {
                                            periodChoice !== '' && scopeChoice !== '' ?
                                            <div className="blockContent">
                                                <div style={{flex:1}}>
                                                    <div className="inputItemContainer">
                                                        <div className="inputDataName" style={{flex: 2}}>目標型態</div>
                                                        <FormControl variant='outlined' className={classes.formControl} style={{flex: 3}}>
                                                            <Select
                                                                style={{marginRight:"30px", width:"100%", textAlign:'center', fontSize: '2vh'}}
                                                                value={companyData['outputTab_data']['ITR_and_Target']["ITR-detail"][periodChoice][scopeChoice]['targetType'] || ''}
                                                                onChange={()=>{}}
                                                                // disabled
                                                                SelectDisplayProps={{
                                                                    style: {
                                                                        padding: '10px'
                                                                    }
                                                                }}
                                                            >
                                                            <MenuItem style={{fontSize: '1.8vh'}} value="絕對碳排放量">絕對碳排放量</MenuItem>
                                                            <MenuItem style={{fontSize: '1.8vh'}} value="營收碳排強度">營收碳排強度</MenuItem>
                                                            <MenuItem style={{fontSize: '1.8vh'}} value="產量碳排強度">產量碳排強度</MenuItem>
                                                            <MenuItem style={{fontSize: '1.8vh'}} value="電力碳排強度">電力碳排強度</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                    <div className="inputItemContainer">
                                                        <div className="inputDataName">目標年度</div>
                                                        <FormControl variant="outlined" className={classes.formControl}>
                                                            <TextField
                                                                style={{marginRight:"30px", width:"100%", textAlign:'center'}}
                                                                value={companyData['outputTab_data']['ITR_and_Target']["ITR-detail"][periodChoice][scopeChoice]['targetYear'] || ''}
                                                                variant="outlined"
                                                                onChange={(event) => {}}
                                                                inputProps={{
                                                                    style:{
                                                                        padding: '10px',
                                                                        fontSize: '2vh',
                                                                        textAlign: 'center'
                                                                    },
                                                                    min: nowYear-100,
                                                                    max: nowYear+100
                                                                }}
                                                                type="number"
                                                            />
                                                        </FormControl>
                                                    </div>
                                                    <div className="inputItemContainer">
                                                        <div className="inputDataName">目標宣告年</div>
                                                        <FormControl variant="outlined" className={classes.formControl}>
                                                            <TextField
                                                                style={{marginRight:"30px", width:"100%", textAlign:'center'}}
                                                                value={companyData['outputTab_data']['ITR_and_Target']["ITR-detail"][periodChoice][scopeChoice]['targetReportYear'] || ''}
                                                                variant="outlined"
                                                                onChange={(event) => {}}
                                                                inputProps={{
                                                                    style:{
                                                                        padding: '10px',
                                                                        fontSize: '2vh',
                                                                        textAlign: 'center'
                                                                    },
                                                                    min: nowYear-100,
                                                                    max: nowYear+100
                                                                }}
                                                                type="number"
                                                            />
                                                        </FormControl>
                                                    </div>
                                                    <div className="inputItemContainer">
                                                        <div className="inputDataName">目標基準年</div>
                                                        <FormControl variant="outlined" className={classes.formControl}>
                                                            <TextField
                                                                style={{marginRight:"30px", width:"100%", textAlign:'center'}}
                                                                value={companyData['outputTab_data']['ITR_and_Target']["ITR-detail"][periodChoice][scopeChoice]['targetBaseYear'] || ''}
                                                                variant="outlined"
                                                                onChange={(event) => {}}
                                                                inputProps={{
                                                                    style:{
                                                                        padding: '10px',
                                                                        fontSize: '2vh',
                                                                        textAlign: 'center'
                                                                    },
                                                                    min: nowYear-100,
                                                                    max: nowYear+100
                                                                }}
                                                                type="number"
                                                            />
                                                        </FormControl>
                                                    </div>
                                                    <div className="inputItemContainer">
                                                        <div className="inputDataName">目標覆蓋率</div>
                                                        <FormControl variant="outlined" className={classes.formControl}>
                                                            <TextField
                                                                style={{marginRight:"30px", width:"100%", textAlign:'center'}}
                                                                value={companyData['outputTab_data']['ITR_and_Target']["ITR-detail"][periodChoice][scopeChoice]['targetCoverage'] || ''}
                                                                variant="outlined"
                                                                onChange={(event) => {}}
                                                                inputProps={{
                                                                    style:{
                                                                        padding: '10px',
                                                                        fontSize: '2vh',
                                                                        textAlign: 'center'
                                                                    },
                                                                    min: 0,
                                                                    max: 100
                                                                }}
                                                                InputProps={{
                                                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                                    classes: { adornedEnd: classes.adorned }
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                </div>
                                                <div style={{flex:1, position: 'relative'}}>
                                                    <div className="inputItemContainer">
                                                        <div className="inputDataName">減量程度</div>
                                                        <FormControl variant="outlined" className={classes.formControl}>
                                                            <TextField
                                                                style={{marginRight:"30px", width:"100%", textAlign:'center'}}
                                                                value={companyData['outputTab_data']['ITR_and_Target']["ITR-detail"][periodChoice][scopeChoice]['degreeOfReduction'] || ''}
                                                                variant="outlined"
                                                                onChange={(event) => {}}
                                                                inputProps={{
                                                                    style:{
                                                                        padding: '10px',
                                                                        fontSize: '2vh',
                                                                        textAlign: 'center'
                                                                    },
                                                                    min: 0,
                                                                    max: 100,
                                                                }}
                                                                InputProps={{
                                                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                                    classes: { adornedEnd: classes.adorned }
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                    <div className="inputItemContainer">
                                                        <div className="inputDataName">基準年碳排放<br/>（目標範疇）</div>
                                                        <FormControl variant="outlined" className={classes.formControl}>
                                                            <TextField
                                                                style={{marginRight:"30px", width:"100%", textAlign:'center'}}
                                                                value={companyData['outputTab_data']['ITR_and_Target']["ITR-detail"][periodChoice][scopeChoice]['baseYearCE'] || ''}
                                                                variant="outlined"
                                                                onChange={(event) => {}}
                                                                inputProps={{
                                                                    style:{
                                                                        padding: '10px',
                                                                        fontSize: '2vh',
                                                                        textAlign: 'center'
                                                                    },
                                                                    min: 0
                                                                }}
                                                                InputProps={{
                                                                    endAdornment: <InputAdornment position="end">噸</InputAdornment>,
                                                                    classes: { adornedEnd: classes.adorned }
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                    <div className="inputItemContainer" style={{flex: '2', width: '100%', position: 'absolute', bottom: '0', height: 'auto'}}>
                                                        <div className="inputDataName">備註</div>
                                                        <FormControl variant="outlined" className={classes.formControl} style={{flex: '4', marginLeft: '0 auto 0 20px'}}>
                                                            <TextField
                                                                style={{marginRight:"30px", width:"95%", textAlign:'center'}}
                                                                value={companyData['outputTab_data']['ITR_and_Target']["ITR-detail"][periodChoice][scopeChoice]['remark'] || ''}
                                                                variant="outlined"
                                                                multiline
                                                                rows={5}
                                                                onChange={(event) => {}}
                                                                inputProps={{
                                                                    style:{
                                                                        fontSize: '2vh',
                                                                        textAlign: 'left',
                                                                    }
                                                                }}
                                                                InputProps={{
                                                                    style:{
                                                                        padding: '10px',
                                                                    }
                                                                }}
                                                                type="keyword"
                                                            />
                                                        </FormControl>
                                                    </div>
                                                </div>
                                            </div>
                                            :''
                                        }
                                    </div>
                                </div>
                                :
                                ''
                            }
                        </div>
                    </div>
                </div>
            }
            <Menu
                account={account}
                permission={permission}
                cookies={cookies}
                removeCookie={removeCookie}
                activeScreen={activeScreen}
                switchScreen={switchScreen}
                switchShellChoice={switchShellChoice}
                changeDisplayMethod={changeDisplayMethod}
            />
        </div>
    );
}

export default EnterpriseESGReport;