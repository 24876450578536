import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';


const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '19.84vw',
    textAlign: 'left',
    margin: '0',
    padding: "1.2vh auto 1.2vh 1.17vw",
    backgroundColor: '#ffffff',
    borderRadius: "6px",
    border: "solid 1px #939393",
  },
  textInput: {
    // height: "4.8vh",
    fontSize: "1.6vh",
    width: '20vw',
  }
}));

function Login(props) {
  const classes = useStyles();
  const {
    account,
    permission,
    getPermission,
    changeUtype,
    cookies,
    loginMsg,
    switchAccount,
    loginCheck,
    activeScreen,
    switchScreen,
  } = props

  const [inputUsername, changeInputUsername] = useState("");
  const [inputPassword, changeInputPassword] = useState("");

  const companyList = ['開發金控', '凱基銀行', '凱基證券', '中國人壽', '開發資本', 'FCS', '中信金控']

  useEffect(() => {
    if (cookies.Account !== undefined && activeScreen !== "Logout") {
      // console.log(cookies.Account)
      changeUtype(cookies['Account']['utype'])
      getPermission(cookies.Account.token);
    }
  }, [cookies.Account, activeScreen, getPermission])

  return (
    <div className="login_page_container">
      <div onClick={() => { if (account.user !== undefined) { switchScreen(Object.values(permission.pages)[0]); } else if (cookies.Account !== undefined) {switchAccount(cookies.Account);switchScreen(Object.values(permission.pages)[0]); } }}
        style={{ position: 'absolute', backgroundSize: 'cover', width: '100vw', minWidth: '1024px', height: '100vh', backgroundImage: `url(${require('../img/login-bg-2@2x.png')})` }}>
        <Fade timeout={1000} in={account.user === undefined && cookies.Account === undefined}>
          <div>
            <p className="login_title">Ascelleia 金融企業風險平台</p>
            <p className="login_title" style={{ width: 'auto', height: '2vh', margin: '0 auto 0 38vw', fontSize: '1.6vh' }}>Ascelleia為” Ascella”與”EIA”所組成，在西方文化是</p>
            <p className="login_title" style={{ width: 'auto', height: '2vh', margin: '0 auto 0 38.5vw', fontSize: '1.6vh' }}>人馬座最靠近心臟的位置的一顆星，代表著信賴與正氣。</p>
            <div className="login_container">
              <div style={{ marginBottom: "2.67vh" }}>
                <TextField size='small' className={classes.textInput} value={inputUsername} onChange={(event) => { changeInputUsername(event.target.value) }} label="請輸入帳號" variant="outlined" />
              </div>
              <div style={{ marginBottom: "2.67vh" }}>
                <TextField type='password' size='small' className={classes.textInput} value={inputPassword} onChange={(event) => { changeInputPassword(event.target.value) }} label="請輸入密碼" variant="outlined" />
              </div>
              {loginMsg.length === 0 ? "" :
                <p style={{ fontSize: '2vh', color: 'red', marginBottom: '0vh' }}>{loginMsg}</p>
              }
              {/* <p className="login_forgot_text" onClick={() => { }} ><u>忘記密碼？</u></p> */}
              <div className="login_button" style={{ marginTop: '2vh' }} onClick={() => { loginCheck(inputUsername, inputPassword) }} >
                <p className="login_button_text">會員登入</p>
              </div>
            </div>
            <p className="login_copyRight">© FCS all rights reserved</p>
          </div>
        </Fade>
        {loginMsg.length !== 0 ? "" :
          <Fade timeout={1000} in={account.user !== undefined || cookies.Account !== undefined}>
            <div style={{ backgroundSize: 'cover', width: '100vw', minWidth: '1024px', height: '100vh', position: 'absolute', top: '0', left: '0' }}>
              <div style={{ backgroundColor: 'rgba(255,255,255,0.2)', height: '100vh' }}>
                <p className="login_descr">
                  Ascelleia 金融企業風險平台提供金融業管理氣候相關風險的解決方案，建構標準化的氣候風險評估模組與資料庫，協助金融業將氣候變遷風險評估納入投資規劃中，
                  以響應綠色金融2.0與公司治理3.0，提升金融業ESG資訊揭露品質及因應氣候變遷風險的韌性，擴大金融市場的影響力與永續經營。
                </p>

                {
                  account.user === undefined ? "" :
                    !companyList.includes(account.user.organization) ? "" :
                      <img style={{ margin: "0 auto", display: "block", height: "7vh" }} src={require(`../img/Logo/${account.user.organization}.png`)} alt="帳告公司別LOGO" />
                }

                {/* <p style={{fontSize:'4vh', color:'red', textAlign:'center', position:'absolute', bottom:'5vh', width:'100vw'}}>*系統開發中*</p> */}
              </div>
            </div>
          </Fade>
        }
      </div>
    </div>
  );
}

export default Login;